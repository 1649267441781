import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonService } from "src/app/_services/common.service";
import { VariableService } from "src/app/_common/variable.service";
import { ReviewerData } from "src/app/interfaces/ReviewerData";
import { ReviewerItem } from "src/app/interfaces/ReviewerItem";

@Component({
  selector: "app-checkbox-table",
  templateUrl: "./checkbox-table.component.html",
  styleUrls: ["./checkbox-table.component.scss"],
})
export class CheckboxTableComponent {
  /** Checkbox color */
  @Input() checkboxColor = "blue";

  /** Property to hold table columns */
  @Input() columns = [
    { key: "_reviewer", name: "Reviewer" },
    { key: "_applicable", name: "Applicable" },
    { key: "_showToClient", name: "Show To Client" },
  ];

  /** Property to hold table data */
  @Input() tableData: ReviewerData[] = [];

  /** Property to hold selected items list */
  @Input() selectedList: any[] = [];

  /** Property to emit selected items list */
  @Output() checkEvent = new EventEmitter();

  constructor(
    public cos: CommonService,
    public commonVariable: VariableService
  ) {
    // Empty Constructor
  }

  /**
   * Function to handle collapse of items
   */
  toggleItems(item: any): void {
    item.isExpanded = !item?.isExpanded;
  }

  /**
   * Function to handle checkbox click
   */
  onCheckboxChange(item: ReviewerItem, key: string, isChecked: boolean): void {
    // Set Checked Values
    if (key === "applicable") {
      item.applicable = isChecked;
    } else {
      item.showToClient = isChecked;
    }

    // Prepare Object from Selected Item
    const modifiedItem = {
      reviewerId: item?._id,
      applicable: item?.applicable ?? false,
      showToClient: item?.showToClient ?? false,
    };

    // Check if the item is applicable/showToClient
    if (item.applicable || item.showToClient) {
      if (this.selectedList && this.selectedList?.length) {
        const index = this.selectedList.findIndex(
          (rp) => rp.reviewerId === modifiedItem?.reviewerId
        );

        // Update the item if already present the list
        if (index !== -1) {
          this.selectedList[index].applicable =
            modifiedItem?.applicable ?? false;
          this.selectedList[index].showToClient =
            modifiedItem?.showToClient ?? false;
        } else {
          // Push the selected item to the list
          this.selectedList.push(modifiedItem);
        }
      } else {
        // Push the selected item to the list
        this.selectedList.push(modifiedItem);
      }
    } else {
      const index = this.selectedList.findIndex(
        (i) => i?.reviewerId === modifiedItem?.reviewerId
      );
      if (index !== -1) {
        this.selectedList.splice(index, 1);
      }
    }

    // Emit Updated Selected Items List
    this.checkEvent.emit(this.selectedList);
  }
}
