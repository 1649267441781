import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/_services/common.service';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-document-update',
  templateUrl: './document-update.component.html',
  styleUrls: ['./document-update.component.scss']
})
export class DocumentUpdateComponent {
  /**
   * Input property for button data.
   * @property {string} text - The text content of the button.
   * @property {string} icon - The icon class or identifier for the button.
   * @property {number} margin-right - The margin-right value for styling (e.g., spacing).
   */
  @Input() documentModalDetail: {
    fieldVisibility: string,
    buttonData: {
      text: string,
      icon: string,
      'margin-right': number,
      permission: 'project.read || equipment.read || employee.read || company.read || my_task.read || state_rule.read || repair_maintenance.read'
    },
  };
  /**
   * Input property for indicating if the field should have a medium size.
   * @property {boolean} mediumSizeField - If true, the field will have a medium size (col-4).
   */
  @Input() mediumSizeField = true;
  /**
  * Output property for saving documents.
  * @param updateDocumentForm - It contains all the details of the fields and emits the data to the component where it is called.
  */
  @Output() updateDocumentForm = new EventEmitter()

  document = [];
  editDocumentForm = this.formBuilder.group({
    fileName: '',
    link: '',
    note: '',
    _id: '',
    id: '',
    type: '',
    url: ''
  });

  inputProperty = [{
    title: 'Document Name*',
    name: 'fileName',
    placeholder: 'Enter Document Name',
    limit: 30,
    form: this.editDocumentForm,
    value: '',
  }, {
    title: 'Link*',
    name: 'link',
    placeholder: 'https://example.com',
    limit: 30,
    form: this.editDocumentForm,
    value: '',
  }, {
    title: 'Notes',
    name: 'note',
    placeholder: 'Enter Notes',
    limit: 500,
    form: this.editDocumentForm,
    value: '',
  }];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public commonService: CommonService,
  ) {
  }

   // Function to patch the document details
   patchInfoDocument(data) {
    // Extract `docData` and `_id` from the input
    const { docData, _id } = data;

    this.editDocumentForm.patchValue({
      fileName: docData.name ?? docData.fileName ?? '',
      note: docData.note ?? docData.notes ?? '',
      _id: _id , // Use _id from the parent data
      id: _id,
      type: docData.link ? 'Link' : 'Document',
      url: docData.url ?? '',
    });

    this.document = [
      {
        fileName: docData.url ?? '',
        name: docData.name ?? docData.fileName ?? '',
      },
    ];
  }

// Function to submit the form detail to component where it is called
  submitDocument(data) {
    if (data.text === this.documentModalDetail.buttonData.text) {
      const activeLinkOrDoc = this.documentModalDetail.fieldVisibility;
      if(!this.editDocumentForm.value.fileName || (this.editDocumentForm.value.fileName).trim().length === 0) {
        return this.commonService.toastMessage(response.en.NAME, 'warning');
      } else if (activeLinkOrDoc === 'link' && !this.commonService.pattern.test(this.editDocumentForm.value.link)) {
        return this.commonService.toastMessage(response.en.VALID_LINK, 'warning');
      } else if (activeLinkOrDoc === 'document' && this.document.length === 0) {
        return this.commonService.toastMessage(response.en.UPLOAD_DOC, 'warning');
      } 
      this.updateDocumentForm.emit({values: this.editDocumentForm.value, documentUrl: this.document[0].fileName });
    }
  }

  // Function to upload selected document
  upload(event) {
    this.document = [event];
    this.editDocumentForm.patchValue({
      fileName: event.fileName
    });
  }

  // Function to return document
  passImages() {
    return this.document || [];
  }

  // function to delete the uploaded document
  removeImage(event) {
    this.document = this.document.filter(document => document.url
      ? document.url !== event.url
      : document.path !== event.path);
  }

  // Function to get the input value and patch them to basicForm's values
  getInputValue(data: { fieldName: string; fieldValue: string }) {
    this.editDocumentForm.get(data.fieldName)?.patchValue(data.fieldValue);
  }

  // Function to patch the document details
  patchDocument(data) {
    this.editDocumentForm.patchValue({
      fileName: data.name ?? data.fileName,
      link: data.link ?? '',
      note: data.note ?? data.notes,
      _id: data.id ?? '',
      type:  data.link ? 'Link' : 'Document',
    });
    this.document = [{ fileName: data.url ?? '', name: data.name ?? data.fileName }];
  }

}
