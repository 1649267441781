import { GooglePlacesDirective } from "./../../_directive/google-places.directive";
import { DefaultDatePipe } from "./../../_pipe/default-date.pipe";
import { JwtInterceptor } from "./../../_helper/jwt.interceptor";
import { ErrorInterceptor } from "./../../_helper/error.interceptor";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/panel-layout/header/header.component";
import { FooterComponent } from "./components/panel-layout/footer/footer.component";
import { SidebarComponent } from "./components/panel-layout/sidebar/sidebar.component";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatCardModule } from "@angular/material/card";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { MatIconModule } from "@angular/material/icon";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { AgmCoreModule } from "@agm/core";
import { GoogleMapComponent } from "./components/google-map/google-map.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatExpansionModule } from "@angular/material/expansion";
import { ScheduleDetailsComponent } from "./components/schedule-details/schedule-details.component";
import { ScheduleEmpModalComponent } from "./components/schedule-emp-modal/schedule-emp-modal.component";
import { AmountTaskDirective } from "src/app/_directive/amount-task.directive";
import { FilterComponent } from "./components/filter/filter.component";
import { TableComponent } from "./components/table/table.component";
import { DraggableModalDirective } from "../../_directive/modal-draggable.directive";

import { SectionModalComponent } from "./components/common-template/section-modal.component";
import { UploadDocumentsComponent } from "./components/upload-documents/upload-documents.component";
import { CommonExcelComponent } from "./components/common-excel/common-excel.component";
import { EquipmentComponent } from "./components/equipment/equipment.component";
import { ContactFormatDirective } from "src/app/_directive/contact-format.directive";
import { ClientModalComponent } from "./components/client-section/client-modal/client-modal.component";
import { RightModalComponent } from "./components/right-modal/right-modal.component";
import { DropDownComponent } from "./components/AD-common-field/drop-down/drop-down.component";
import { CrewAssignmentComponent } from "./components/crew-assignment/crew-assignment.component";
import { SectionHeadersComponent } from "./components/common-header/section-headers.component";
import { AccessControlDirective } from "src/app/_directive/access-control.directive";
import { ModalComponent } from "./components/mail-modal/modal.component";
import { BoringHoleComponent } from "./components/boring-hole/boring-hole.component";
import { EmployeesDocumentComponent } from "./components/employees-document/employees-document.component";
import { PushNotificationComponent } from "./components/panel-layout/push-notification/push-notification.component";
import { AdInputComponent } from "./components/AD-common-field/ad-input/ad-input.component";
import { AdButtonComponent } from "./components/AD-common-field/ad-button/ad-button.component";
import { AdCommonModalComponent } from "./components/AD-common-field/ad-common-modal/ad-common-modal.component";
import { RepresentativesComponent } from "./components/client-section/representatives/representatives.component";
import { DocumentUpdateComponent } from "./components/document-update/document-update.component";
import { AdTextareaComponent } from "./components/AD-common-field/ad-textarea/ad-textarea.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { RepairModalComponent } from "./components/repair-modal/repair-modal.component";
import { VisibilityControlDirective } from "src/app/_directive/visibility-control-directive";
import { RadioTableComponent } from "./components/radio-table/radio-table.component";
import { CheckboxTableComponent } from "./components/checkbox-table/checkbox-table.component";
import { InputTableComponent } from "./components/input-table/input-table.component";
import { MailModalProjectComponent } from './components/mail-modal-project/mail-modal-project.component';
import { EstimateRadioTableComponent } from './components/estimate-radio-table/estimate-radio-table.component';
import { UploadImageDocumentsComponent } from './components/upload-image-documents/upload-image-documents.component';
import { UploadImageFileComponent } from './components/upload-image-file/upload-image-file.component';
import { EstimatesConsumablesTableComponent } from './components/estimates-consumables-table/estimates-consumables-table.component';
import { EstimatesDynamicDropdownTableComponent } from "./components/estimates-dynamic-dropdown-table/estimates-dynamic-dropdown-table.component";
import { GoogleMapMultipleLocationsComponent } from './components/google-map-multiple-locations/google-map-multiple-locations.component';
import { UnknownRadioTableComponent } from './components/unknown-radio-table/unknown-radio-table.component';
import { CheckboxMultiselectTableComponent } from './components/checkbox-multiselect-table/checkbox-multiselect-table.component';@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DefaultDatePipe,
    GooglePlacesDirective,
    AmountTaskDirective,
    UploadFileComponent,
    ModalComponent,
    GoogleMapComponent,
    ScheduleDetailsComponent,
    ScheduleEmpModalComponent,
    FilterComponent,
    TableComponent,
    SectionHeadersComponent,
    SectionModalComponent,
    UploadDocumentsComponent,
    CommonExcelComponent,
    EquipmentComponent,
    ContactFormatDirective,
    ClientModalComponent,
    RightModalComponent,
    DropDownComponent,
    CrewAssignmentComponent,
    AccessControlDirective,
    BoringHoleComponent,
    EmployeesDocumentComponent,
    PushNotificationComponent,
    DraggableModalDirective,
    AdInputComponent,
    AdButtonComponent,
    AdCommonModalComponent,
    RepresentativesComponent,
    DocumentUpdateComponent,
    AdTextareaComponent,
    PaginationComponent,
    RepairModalComponent,
    VisibilityControlDirective,
    RadioTableComponent,
    CheckboxTableComponent,
    InputTableComponent,
    MailModalProjectComponent,
    EstimateRadioTableComponent,
    UploadImageDocumentsComponent,
    UploadImageFileComponent,
    EstimatesConsumablesTableComponent,
    EstimatesDynamicDropdownTableComponent,
    GoogleMapMultipleLocationsComponent,
    UnknownRadioTableComponent,
    CheckboxMultiselectTableComponent
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSortModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgxSpinnerModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    MatGridListModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSidenavModule,
    MatCardModule,
    NgxChartsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD9EvhpXSHSAu1ksRx2XH96qkfUgLjaXkM",
    }),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ScheduleDetailsComponent,
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSortModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NgxSpinnerModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    DefaultDatePipe,
    GooglePlacesDirective,
    AmountTaskDirective,
    UploadFileComponent,
    ModalComponent,
    GoogleMapComponent,
    MatIconModule,
    MatGridListModule,
    MatMenuModule,
    MatExpansionModule,
    MatAutocompleteModule,
    ScheduleEmpModalComponent,
    FilterComponent,
    SectionHeadersComponent,
    SectionModalComponent,
    TableComponent,
    UploadDocumentsComponent,
    CommonExcelComponent,
    EquipmentComponent,
    ContactFormatDirective,
    ClientModalComponent,
    RightModalComponent,
    DropDownComponent,
    CrewAssignmentComponent,
    MatCardModule,
    NgxChartsModule,
    AccessControlDirective,
    BoringHoleComponent,
    EmployeesDocumentComponent,
    PushNotificationComponent,
    DraggableModalDirective,
    AdInputComponent,
    AdButtonComponent,
    AdCommonModalComponent,
    RepresentativesComponent,
    DocumentUpdateComponent,
    RadioTableComponent,
    PaginationComponent,
    RepairModalComponent,
    VisibilityControlDirective,
    CheckboxTableComponent,
    InputTableComponent,
    MailModalProjectComponent,
    EstimateRadioTableComponent,
    UploadImageDocumentsComponent,
    UploadImageFileComponent,
    EstimatesConsumablesTableComponent,
    EstimatesDynamicDropdownTableComponent,
    GoogleMapMultipleLocationsComponent,
    UnknownRadioTableComponent,
    CheckboxMultiselectTableComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NgxSpinnerService,
  ],
})
export class SharedModule {}
