import { forkJoin } from 'rxjs';
import { Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
import { CommonService } from 'src/app/_services/common.service';
import { RepairMaintenanceService } from 'src/app/_services/repair-maintenance.service';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { Router } from '@angular/router';
import { FormDataItem } from "src/app/interfaces/common-interface";
import { DashboardSummaryDetails } from 'src/app/interfaces/project';
import {
  ProjectDashboard,
  DashboardRepair,
  DashboardEmployee,
  ScheduleDashboard,
  DashboardEquipment,
  NotificationsResponse
} from 'src/app/interfaces/dashboard';
import { AuthService } from 'src/app/_services/auth.service';
import { FormBuilder } from '@angular/forms';
import { GroupService } from 'src/app/_services/group.service';
import { FunctionsService } from 'src/app/_common/functions.service';
import { response } from 'src/app/enum/message';
import Swal from 'sweetalert2';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("canvasRef", { static: true })
  canvasRef: ElementRef<HTMLCanvasElement>;

  newColor: string[] = [];
  selectedGroupId = "";
  baseColors = ["#FFA41B", "#5D9C59", "#1B9C85"];
  dynamicBaseColor = [
    "#FFA41B",
    "#5D9C59",
    "#1B9C85",
    "#7c9bb9",
    "#a47a6a",
    "#CCCCFF",
    "#FF00FF",
    "#2196F3",
  ];
  projectLength = [];
  projectCount = 0;
  employeeLength = [
    { title: "In Project Task", count: 0 },
    { title: "In Schedule", count: 0 },
    { title: "In General Task", count: 0 },
  ];
  repair_maintenance = [
    { title: "High", count: 0 },
    { title: "Medium", count: 0 },
    { title: "Low", count: 0 },
  ];
  employeesData: DashboardEmployee;
  equipmentLength: Equipment[];
  announcement = [];
  notifications = [];
  groups = [];
  currencyDetails;
  currencySymbol: string;
  maintenanceTab: DashboardRepair[];
  projectTab: ProjectDashboard;
  projectCanvasData = [];
  scheduleCount: ScheduleDashboard;
  equipmentCount: DashboardEquipment;
  summaryDetail: DashboardSummaryDetails[];
  summaryCount = [];
  maintenanceCount = 0;
  projectCanvas: HTMLCanvasElement;
  employeeCanvas: HTMLCanvasElement;
  equipmentCanvas: HTMLCanvasElement;
  tooltipElement: HTMLDivElement;
  chart: Chart;
  employeeChart: Chart;
  equipmentChart: Chart;
  groupsData: any;
  completeData = {};
  groupModalForm = this.formBuilder.group({
    group: [""],
  });
  modalForm = this.formBuilder.group({
    title: "",
    notes: "",
    assigned: "",
    employee: [""],
    group: [],
    role: [""],
  });
  search = {
    search: "",
    pagination: true,
    page: 1,
    noOfRecord: 10,
    broadcast: true,
    groups: [],
  };
  modalProperty = {
    header: "Add Notifications",
    id: "AddModal",
  };
  state = [
    {
      buttonName: "Send",
      type: "broadcast",
      permission: "broadcast_notification.write",
    },
  ];
  employees = [];
  groupFormData = [
    {
      title: "Groups*",
      name: "group",
      type: "checkBox",
      multipleValues: [],
      singleSelection: false,
      message: response.en.GROUP_REQUIRED,
    },
  ];
  formData: FormDataItem[] = [
    {
      title: "Title*",
      name: "title",
      placeholder: "Enter Title",
      limit: 500,
      type: "input",
      message: response.en.TITLE,
    },
    {
      title: "Assigned To*",
      name: "assigned",
      type: "checkBox",
      multipleValues: this.cos.crewAssignment,
      singleSelection: true,
    },
    {
      title: "Message*",
      name: "notes",
      placeholder: "Enter Notes",
      limit: 500,
      type: "summary",
      message: response.en.MESSAGE_REQUIRED,
    },
  ];
  btnName = [
    {
      value: "Add Announcement",
      key: "add",
      type: "announcement",
      permission: "bulletin.write",
      icon: "fa fa-plus",
      theme: "combinedButton",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const labelIndex = context.dataIndex;
              const label = this.cos.getTitleCase(
                this.projectLength[labelIndex].title
              );
              const value = this.chartConfig.data.datasets[0].data[labelIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equipmentConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> =
    {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) => {
                const labelIndex = context.dataIndex;
                const label = this.cos.getTitleCase(
                  this.equipmentLength[labelIndex].title &&
                    this.equipmentLength[labelIndex].title.startsWith("In ")
                    ? this.equipmentLength[labelIndex].title.substring(3)
                    : this.equipmentLength[labelIndex].title
                );

                const value =
                  this.chartConfig.data.datasets[0].data[labelIndex];
                return `${label}: ${value}`;
              },
            },
          },
        },
      },
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employeeConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> =
    {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context) => {
                const labelIndex = context.dataIndex;
                const label = this.cos.getTitleCase(
                  this.employeeLength[labelIndex].title &&
                    this.employeeLength[labelIndex].title.startsWith("In ")
                    ? this.employeeLength[labelIndex].title.substring(3)
                    : this.employeeLength[labelIndex].title
                );
                const value =
                  this.chartConfig.data.datasets[0].data[labelIndex];
                return `${label}: ${value}`;
              },
            },
          },
        },
      },
    };

  constructor(
    public cos: CommonService,
    private dashBoardSrvc: DashboardService,
    public maintenance: RepairMaintenanceService,
    private router: Router,
    private commonFunction: FunctionsService,
    private groupSrvc: GroupService,
    private authSrvc: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.getData();
    this.getCurrencyType();
    this.authSrvc.getPermissions();
  }

  ngOnInit() {
    this.getGroups();
    this.getDashboardCount();
    this.currencyDetails = JSON.parse(localStorage.getItem("adminCurrency"));
    this.currencySymbol =
      this.currencyDetails && this.currencyDetails.symbol
        ? this.currencyDetails.symbol
        : "$";
  }

  ngOnChanges() {
    this.getGroups();
  }

  // This function is used to get the dashboard count
  async getDashboardCount() {
    try {
      const { schedule, equipment, employee } = await forkJoin({
        schedule: this.dashBoardSrvc.getScheduleList(),
        equipment: this.dashBoardSrvc.getEquipmentList(),
        employee: this.dashBoardSrvc.getEmployeeList(),
      }).toPromise();
      this.scheduleCount = schedule.data;
      this.equipmentCount = equipment.data;
      this.employeesData = employee.data;
      this.equipmentLength = [
        {
          title: "In Shop",
          count: this.equipmentCount?.in_shop_equipments
            ? this.equipmentCount.in_shop_equipments
            : 0,
        },
        {
          title: "Assigned",
          count: this.equipmentCount?.employee_equipments
            ? this.equipmentCount.employee_equipments
            : 0,
        },
        {
          title: "In R&M",
          count: this.equipmentCount?.rm_equipments
            ? this.equipmentCount.rm_equipments
            : 0,
        },
      ];
      this.employeeLength = [
        {
          title: "In Project's Task",
          count: this.employeesData?.task_employees
            ? this.employeesData.task_employees
            : 0,
        },
        {
          title: "In Schedule",
          count: this.employeesData?.scheduled_employees
            ? this.employeesData.scheduled_employees
            : 0,
        },
        {
          title: "In General Task",
          count: this.employeesData?.general_task_employees
            ? this.employeesData.general_task_employees
            : 0,
        },
      ];
      this.getEmployeeDetails();
      this.getEquipmentDetails();
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // This function is used to get the dashboard details
  async getData() {
    try {
      const { maintenance, projects, summary } = await forkJoin({
        maintenance: this.dashBoardSrvc.getMaintenanceList(),
        projects: this.dashBoardSrvc.getProjectList(),
        summary: this.dashBoardSrvc.getSummaryList(),
      }).toPromise();
      this.maintenanceTab = maintenance.data;
      this.projectTab = projects.data;
      this.summaryDetail = summary.data;
      const totalProfit = this.summaryDetail.reduce(
        (total, item) => total + item.profit,
        0
      );
      const totalProfitPercentage = this.summaryDetail.reduce(
        (total, item) => total + parseFloat(item.profitPercentage),
        0
      );
      const totalTotalInvoiced = this.summaryDetail.reduce(
        (total, item) => total + item.totalInvoiced,
        0
      );
      this.summaryCount.push({
        totalInvoiced: `${this.currencySymbol} ${this.cos.convertToCurrency(
          totalTotalInvoiced,
          this.currencyDetails
        )}`,
        profit: `${this.currencySymbol} ${this.cos.convertToCurrency(
          totalProfit,
          this.currencyDetails
        )}`,
        profitPercentage: (
          totalProfitPercentage / this.summaryDetail.length
        ).toFixed(2),
      });

      this.groupsData = {
        title: "Groups*",
        name: "group",
        type: "checkBox",
        multipleValues: this.groups,
        singleSelection: true,
        message: response.en.GROUP_REQUIRED,
      };

      if (this.maintenanceTab) {
        this.maintenanceData(this.maintenanceTab);
      }
      if (this.projectTab) {
        this.projectData(this.projectTab);
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // This function is used to set page limit and range on the list
  public setPagination(data) {
    if (data) {
      this.search.page = data.page;
      this.search.noOfRecord = data.limit;
      this.getNotifications(this.search);
    }
  }

  /**
   * Function to set Size Ids in Group ModalForm
   */
  async getGroupIds(data) {
    this.selectedGroupId = data.ids;
    this.search.groups = [this.selectedGroupId];
    await this.getNotifications(this.search);
  }

  // Function to get group list
  async getGroups() {
    try {
      this.groups = [];
      this.groupSrvc.getGroupList().subscribe(
        (res) => {
          if (res.data.length) {
            this.groups = this.commonFunction.mapResponseToKeyValue(res.data);
            const defaultGroup = res.data.find((group) => group.isDefault);
            
            // Setting groupsData only after groups are populated
            this.groupsData = {
              title: "Groups*",
              name: "group",
              type: "checkBox",
              multipleValues: this.groups,
              singleSelection: true,
              message: response.en.GROUP_REQUIRED,
            };
  
            if (defaultGroup) {
              const defaultGroupOption = this.groups.find(
                (group) => group.key === defaultGroup._id
              );
              if (defaultGroupOption) {
                defaultGroupOption.isDefault = true;
              }
  
              if (this.groupModalForm) {
                this.groupModalForm.patchValue({
                  group: defaultGroup._id,
                });
              }
  
              this.search.groups = [defaultGroup._id];
              this.getNotifications(this.search);
            }
          }
        },
        (error) => {
          this.cos.handleError(error);
        }
      );
    } catch (error) {
      this.cos.handleError(error);
    }
  }  

 // Modify getNotifications to return a Promise
  async getNotifications(group: any): Promise<void> {
    
    this.notifications = [];

    try {

      if (!group.groups.length) {
        this.notifications = [];
        return;
      }
      const res: NotificationsResponse = await this.dashBoardSrvc.allNotifications(group).toPromise();

      if (res.data && res.data.notifications) {
        this.notifications = res.data.notifications.map((item) => {
          return {
            title: item._refId.title || item.title || "No Title",
            description: item._refId.note || item._refId.name || "No Description",
            createdAt: item.createdAt,
            notificationId: item.broadcastId,
          };
        });
      } else {
        this.notifications = [];
      }
    } catch (error) {
      this.cos.handleError(error);
    } 
  }

  // This function is used to get details regarding maintenance
  maintenanceData(data) {
    const counts = {
      LOW: 0,
      MEDIUM: 0,
      HIGH: 0,
    };
    data.countByPriority.forEach(({ _id, count }) => {
      counts[_id] = count;
    });

    // Convert counts to the desired format
    this.repair_maintenance = Object.entries(counts).map(
      ([priority, count]) => ({
        title: priority.charAt(0) + priority.slice(1).toLowerCase(),
        count,
      })
    );
    this.maintenanceCount = data ? data.totalCount : 0;
  }

  // This function is used to get details of projects
  projectData(data) {
    if (data.statusBasedCount) {
      data?.statusBasedCount.map((val) => {
        this.projectLength.push({
          title: val.status.name,
          count: val.projectCount,
        });
      });
    }
    this.getProjectDetails();
    this.projectCount = data.totalProjects ? data.totalProjects : 0;
  }

  // This function is used to add canvas for project section
  getProjectDetails() {
    this.projectCanvas = document.getElementById(
      "projectCanvas"
    ) as HTMLCanvasElement;
    if (this.projectCanvas) {
      this.chart = new Chart(this.projectCanvas, this.chartConfig);
      const allCountsZero = this.projectLength.every((val) => val.count === 0);

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.chart.data.datasets[0].data = [0.1];
        this.chart.data.datasets[0].backgroundColor = "gray";
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.chart.data.datasets[0].data = this.projectLength.length
          ? this.projectLength.map((val) => val?.count)
          : [];
        this.chart.data.datasets[0].backgroundColor = this.generateColors(
          this.dynamicBaseColor,
          this.projectLength.length
        );
      }

      this.chart.update();
    }
  }

  // This function is used to add canvas for employee section
  getEmployeeDetails() {
    this.employeeCanvas = document.getElementById(
      "employeeCanvas"
    ) as HTMLCanvasElement;
    if (this.employeeCanvas) {
      this.employeeChart = new Chart(this.employeeCanvas, this.employeeConfig);

      const allCountsZero = this.employeeLength.every((val) => val.count === 0);

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.employeeChart.data.datasets[0].data = [0.1];
        this.employeeChart.data.datasets[0].backgroundColor = "gray";
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.employeeChart.data.datasets[0].data = this.employeeLength.map(
          (val) => val.count
        );
        this.employeeChart.data.datasets[0].backgroundColor = this.baseColors;
      }

      this.employeeChart.update();
    }
  }

  // This function is used to add canvas for equipment section
  getEquipmentDetails() {
    this.equipmentCanvas = document.getElementById(
      "equipmentCanvas"
    ) as HTMLCanvasElement;
    if (this.equipmentCanvas) {
      this.equipmentChart = new Chart(
        this.equipmentCanvas,
        this.equipmentConfig
      );

      const allCountsZero = this.equipmentLength.every(
        (val) => val.count === 0
      );

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.equipmentChart.data.datasets[0].data = [0.1];
        this.equipmentChart.data.datasets[0].backgroundColor = "gray";
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.equipmentChart.data.datasets[0].data =
          this.equipmentLength && this.equipmentLength.map((val) => val.count);
        this.equipmentChart.data.datasets[0].backgroundColor = this.baseColors;
      }

      this.equipmentChart.update();
    }
  }

  // This function is used to get the dynamic color
  generateColors(stringsArray: string[], count: number): string[] {
    const step = 360 / count;
    const newColors: string[] = [];

    for (let i = 0; i < count; i++) {
      const hue = (i * step) % 360;
      const color = `hsl(${hue}, 100%, 50%)`;
      newColors.push(color);
    }
    stringsArray = [...new Set(stringsArray)];
    this.newColor = stringsArray.concat(
      newColors.slice(0, newColors.length - stringsArray.length)
    );
    return this.newColor;
  }

  // Function used to redirect dashboard to notification section
  redirectDashboard(type: string) {
    const typeToUrlMap = {
      project: "project/projects",
      employee: "users/employees",
      equipment: "equipments",
      maintenance: "maintenance/list",
      schedule: "/schedule/job",
      myTask: "general-task-list",
    };

    // Use the type-to-url mapping to get the destination URL
    const destinationUrl = typeToUrlMap[type] || "broadcast-notification";

    this.router.navigateByUrl(destinationUrl);
  }

  ngOnDestroy() {
    this.destroyChart(this.projectLength, this.chart);
    this.destroyChart(this.employeeLength, this.employeeChart);
    this.destroyChart(this.equipmentLength, this.equipmentChart);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private destroyChart(dataArray: any[], chartInstance: any) {
    if (dataArray && dataArray.length && chartInstance) {
      chartInstance.destroy();
    }
  }

  // function to set currency type and date format in local storage
  getCurrencyType() {
    const isCurrencyExist = localStorage.getItem("adminCurrency");
    const isDateFormatExist = localStorage.getItem("adminDateFormat");
    if (!isCurrencyExist && !isDateFormatExist) {
      this.dashBoardSrvc.getCurrencyAndDate().subscribe((res) => {
        const result = res.data;
        if (result) {
          localStorage.setItem(
            "adminCurrency",
            JSON.stringify(result.currencyFormat)
          );
          localStorage.setItem(
            "adminDateFormat",
            JSON.stringify(result.dateFormat)
          );
        }
      });
      this.currencyDetails = JSON.parse(localStorage.getItem("adminCurrency"));
      this.currencySymbol =
        this.currencyDetails && this.currencyDetails.symbol
          ? this.currencyDetails.symbol
          : "$";
    }
  }

  // Function to submit the details
  submitForm(element) {
    try {
      if (element.type) {
        const data = element.formValue;
  
        if (!data.assigned || data.assigned.length === 0) {
          return this.cos.toastMessage(response.en.ASSIGNED_TO, "warning");
        }
  
        // Check if the selected option is "GROUP" and if the group field is empty
        if (
          data.assigned === "GROUP" &&
          (!data.group || data.group.length === 0)
        ) {
          return this.cos.toastMessage(response.en.GROUP_REQUIRED, "warning");
        }
  
        // Check if the selected option is "INDIVIDUAL" and if the employee field is empty
        if (
          data.assigned === "INDIVIDUAL" &&
          (!data.employee || data.employee.length === 0)
        ) {
          return this.cos.toastMessage(response.en.EMPLOYEE, "warning");
        }
  
        const payload = {
          ids: data.employee ? data.employee.flat() : [],
          title: data.title ? data.title : "",
          note: data.notes ? data.notes.trim() : "",
          assignedTo:
            data.assigned === "INDIVIDUAL" ? data.employee : data.group,
          group: data.assigned === "GROUP" ? data.group : [],
        };
  
        if (data.assigned === "INDIVIDUAL") {
          this.dashBoardSrvc.sendBroadcast(payload).subscribe(
            (res) => {
              if (res) {
                this.cos.toastMessage(response.en.NOTIFICATION, "success");
                this.commonFunction.modalClose(this.modalProperty);
                this.getNotifications(this.search);
                this.modalForm.reset();
                // Remove employee field from formData only after success
                this.formData = this.formData.filter(
                  (field) => field.name !== "employee"
                );
              }
            },
            (err) => this.cos.handleError(err)
          );
        } else if (data.assigned === "GROUP") {
          this.groupSrvc.groupNotification(payload).subscribe(
            (res) => {
              this.cos.toastMessage(response.en.NOTIFICATION, "success");
              this.commonFunction.modalClose(this.modalProperty);
              this.getNotifications(this.search);
              this.modalForm.reset();
              // Remove group field from formData only after success
              this.formData = this.formData.filter(
                (field) => field.name !== "group"
              );
              // Update multiple values for the group field if needed
              this.formData.forEach((val) => {
                if (val.name === "group") {
                  val.multipleValues = this.groups;
                }
              });
            },
            (err) => this.cos.handleError(err)
          );
        }
  
        // Clean up the data object by removing employee and group fields
        delete data.employee;
        delete data.group;
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }  

  async showModal(data) {
    if (data === "add") {
      this.cos.removeFieldWithName(this.formData, "employee");
      this.cos.removeFieldWithName(this.formData, "group");
      await this.getEmployee();
      await this.getModalGroups();
      this.modalProperty.header = "Add Notifications";
      this.modalForm.reset();
      this.commonFunction.modalOpen(this.modalProperty);
    }
  }

  async getModalGroups() {
    try {
      this.groups = [];
      this.groupSrvc.getGroupList().subscribe((res) => {
        if (res.data.length) {
          this.groups = this.commonFunction.mapResponseToKeyValue(res.data);
          this.formData.map((val) => {
            if (val.name === "group") {
              val.multipleValues = this.groups;
            }
          });
        }
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  async selectedValue(data) {
    if (data[0].value === "GROUP") {
      // Remove employee field from formData if present
      this.formData = this.formData.filter(
        (field) => field.name !== "employee"
      );

      // Check if group field is already present in formData
      const groupExists = this.formData.some((field) => field.name === "group");

      // If group field doesn't exist, add it to formData
      if (!groupExists) {
        const insertedData = {
          title: "Groups*",
          name: "group",
          type: "checkBox",
          multipleValues: this.groups,
          singleSelection: false,
          message: response.en.GROUP_REQUIRED,
        };
        this.formData.splice(2, 0, insertedData);
      }
    } else if (data[0].value === "INDIVIDUAL") {
      // Remove group field from formData if present
      this.formData = this.formData.filter((field) => field.name !== "group");

      // Check if employee field is already present in formData
      const employeeExists = this.formData.some(
        (field) => field.name === "employee"
      );

      // If employee field doesn't exist, add it to formData
      if (!employeeExists) {
        const insertedData = {
          title: "Employees*",
          name: "employee",
          type: "checkBox",
          multipleValues: this.employees,
          singleSelection: false,
          message: response.en.EMPLOYEE,
        };
        this.formData.splice(2, 0, insertedData);
      }
    }
  }

  // Function to get employees list
  async getEmployee() {
    try {
      this.employees = [];
      this.authSrvc.getActiveEmployee().subscribe((res) => {
        if (res.data.length) {
          this.employees = this.commonFunction.mapResponseToKeyValue(res.data);
          this.formData.map((val) => {
            if (val.name === "employee") {
              val.multipleValues = this.employees;
            }
          });
        }
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to get employees or groups
  async getAssigned(data) {
    if (
      ((Array.isArray(data.groups) && data.groups.length === 0) ||
        data.groups === null) &&
      ((Array.isArray(data.assignedTo) && data.assignedTo.length === 0) ||
        data.assignedTo === null)
    ) {
      return [];
    }
    if (Array.isArray(data.groups) && data.groups.length) {
      return "GROUP";
    } else {
      return "INDIVIDUAL";
    }
  }

  // Function to enforce character limits on title and description
  enforceCharacterLimit(event: InputEvent, field: string): void {
    const target = event.target as HTMLElement;

    // Define character limits
    const limits = {
      title: 60,
      description: 800
    };

    // Check the current length of the content
    if (target.innerText.length > limits[field]) {
      // Trim the content to the maximum allowed length
      target.innerText = target.innerText.substring(0, limits[field]);

      // Set the cursor position to the end of the trimmed text
      this.setCursorToEnd(target);
    }
  }

  // Set the cursor position to the end of the content
  setCursorToEnd(element: HTMLElement): void {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false); // Collapse to the end of the content
    selection.removeAllRanges();
    selection.addRange(range);
  }

  // Function to change the pointer style
  setPointerStyle(event: any): void {
    event.target.style.cursor = 'text'; 
  }
  
  // Handle keydown event when editing notification
  onKeydown(event: KeyboardEvent, field: string, notification: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!notification.notificationId) {
        return this.cos.toastMessage(response.en.NOT_EDITABLE, 'warning');
      }

      const payload: any = { notificationId: notification.notificationId };
      const content = (event.target as HTMLElement).innerText.trim();
      if (field === 'title' && content !== '') {
        payload['title'] = content;
      } else if (field === 'description' && content !== '') {
        payload['note'] = content;
      }
      this.updateNotification(payload);
    }
  }

  // Update the notification
  updateNotification(payload: any): void {
    if (!payload.notificationId) {
      return this.cos.toastMessage(response.en.NOT_EDITABLE, 'warning');
    }

    this.dashBoardSrvc.editNotification(payload).subscribe(
      async (res) => {
        if (res) {
          await this.getNotifications(this.search); // Wait for notifications to load
          this.cos.toastMessage(res.message, 'success');
        }
      },
      (error) => {
        this.cos.handleError(error);
      },
    );
  }

  // Method to delete notification
  deleteNotification(notification: any): void {
    if (!notification.notificationId) {
      return this.cos.toastMessage(response.en.NOT_DELETABLE, "warning");
    } else {
      const payload = { notificationId: notification.notificationId };
      Swal.fire(this.cos.deleteText).then(async (result) => {
        if (result.value) {
          this.dashBoardSrvc.deleteNotification(payload).subscribe(
            async (res) => {
              if (res) {
                await this.getNotifications(this.search); // Wait for notifications to load
                this.cos.toastMessage(res.message, "success");
              } else {
                this.cos.toastMessage(res.message, "warning");
              }
            },
            (error) => {
              this.cos.handleError(error);
            },
          );
        } 
      });
    }
  }

}

interface Equipment {
  title: string;
  count: number
}
