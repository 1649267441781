import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ClientService } from 'src/app/_services/client.service';
import { CommonService } from 'src/app/_services/common.service';
import Swal from 'sweetalert2';
import 'bootstrap';
import * as jQuery from 'jquery';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss']
})
export class ClientModalComponent implements OnChanges {
  @Input() modalId = 'addClient';
  @Input() modalType = 'Add Client';
  @Input() modalKey: string;
  @Input() patchData;
  @Input() editClient = true;
  @Output() ClientData = new EventEmitter();
  showRepTable = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  basicDetail: any;
  btnName = [
    { value: 'Add Client', key: 'add', type: 'client', permission: 'client.remove', icon: 'fa fa-plus', theme: 'combinedButton' },
    { key: 'delete', type: 'client', permission: 'client.remove', icon: 'fa fa-search', theme: 'iconButton' },
    { key: 'search', type: 'client', permission: 'client.read', icon: 'fa fa-search', theme: 'iconButton' },
  ];
  addedRep = [];
  address = [];
  representativeForm = this.fb.group({
    representativeName: '',
    representativeNumber: '',
    representativeAddress: '',
    representativeEmail: '',
    representativeNote: '',
    representativeTitle: '',
    representativeCell: '',
  });
  representativeData = [];
  representative = [];
  patchModal = false;
  isContact = '';
  dialCode;
  branchAddress = [];
  formData = [
    { title: 'Client Name*', name: 'userName', placeholder: 'Enter Client Name', limit: 30, type: 'input' },
  ];
  branchData = [
    { title: 'Branch Address', name: 'address', placeholder: 'Enter Branch Address', type: 'address' },
    { title: 'Branch Main #', name: 'phone', placeholder: 'Enter Branch Main #', type: 'phone' },
  ];
  repData = [
    { key: 'representativeName', value: 'Name' },
    { key: 'representativeNumber', value: 'Cell' },
    { key: 'representativeEmail', value: 'Email' },
    { key: 'representativeTitle', value: 'Title' },
    { key: 'representativeAddress', value: 'Address' },
    { key: 'representativeNote', value: 'Note' }
  ];
  modalForm = this.fb.group({
    id: '',
    userName: '',
    phone: {
      dialCode: '',
      country: '',
      number: '',
    },
    address: {
      streetAddress: '',
      locality: '',
      subLocality: '',
      region: '',
      country: '',
      postalCode: '',
      geo: '',
      formattedAddress: ''
    }
  });
  state = [{
    check: 'add',
    buttonName: 'Next',
    type: 'client'
  }];
  branchState = [{
    check: 'addBranch',
    buttonName: 'Add Branch',
    type: 'client'
  }];
  repIndex: number;
  patchRep = [];
  multipleAddress = [];
  representatives = [];
  savedAddress = [];
  activeTab = 'basic-detail';
  searchCode = '';
  countries = [];
  countryDetails = [];
  country;
  representativeCell;
  constructor(
    public cos: CommonService,
    private fb: UntypedFormBuilder,
    private clientSrvc: ClientService
  ) {
  }

  ngOnChanges() {
    this.searchCode = '';
    this.countryDetails = [];
    this.countryDetails = this.countries;
    this.branchAddress = [];
    this.activeTab = 'basic-detail';
    this.modalForm.reset();
    this.modalForm.patchValue({
      address: {
        streetAddress: '',
        locality: '',
        subLocality: '',
        region: '',
        country: '',
        postalCode: '',
        geo: '',
        formattedAddress: ''
      }
    });
    this.representativeCell = { dial_code: 1, country: 'US' };
    this.dialCode = { dial_code: 1, country: 'US' };
    this.savedAddress = [];
    this.address = [];
    this.modalType = 'Add Client';
    this.representativeForm.reset();
    this.showRepTable = false;
    this.addedRep = [];    
  }


  // Function to show representatives filed after client on next button
  showRepresentative() {
    const branches = [];
    if (this.branchAddress.length) {
      this.branchAddress.map(val => {
        branches.push({ value: val.branchAddress.formattedAddress, key: val.branchAddress.formattedAddress });
      });
    }
    this.representativeData = [
      { title: 'Branch Address', name: 'representativeAddress', type: 'checkBox', multipleValues: branches, singleSelection: true },
      { title: 'Rep Name*', name: 'representativeName', placeholder: 'Enter Rep Name', limit: 50, type: 'input' },
      { title: 'Rep Direct Office #', name: 'representativeNumber', type: 'phone', placeholder: 'Rep Direct Office #' },
      { title: 'Email', name: 'representativeEmail', type: 'email', placeholder: 'Enter Rep Email' },
      { title: 'Title', name: 'representativeTitle', type: 'input', placeholder: 'Enter Rep Title' },
      { title: 'Note', name: 'representativeNote', type: 'summary', placeholder: 'Enter Rep Note' },
    ];
    this.representative = [{
      check: 'saveRep',
      buttonName: 'Save Rep',
      type: 'client'
    }, {
      check: 'back',
      buttonName: 'Back',
      type: 'client'
    }];
    this.representativeForm.reset();
    this.dialCode = 1;
    fetch('/assets/countries.json')
      .then((res) => res.json())
      .then((res) => {
        setTimeout(() => {
          this.countries = res;
          this.countryDetails = res;
          this.country = {
            name: "US",
            dial_code: "",
            code: "US"
          };
        }, 1000);
      })
      .catch();
  }

  // Function to save the basic details and representatives
  submitForm(data) {
    const body = data.formValue;
    if (data.value.check === 'add') {
      if (!body.userName || body.userName.trim().length == 0) {
        return this.cos.toastMessage(`CLIENT ${response.en.NAME}`, 'warning');
      } else {
        this.changeTab('additional-info');
        this.showRepresentative();
      }
    }
    if (data.value.check === 'back') {
      this.changeTab('basic-detail');
    }
    if (data.value.check === 'saveRep') {
      const cell = this.representativeForm.value.representativeCell;
      if (!body.representativeName || body.representativeName.trim().length == 0) {
        return this.cos.toastMessage(`Representative ${response.en.NAME}`, 'warning');
      } else if (cell && cell.length < 10) {
        return this.cos.toastMessage(response.en.VALID_CELLNO, 'warning');
      } else {
        const dialCode1 = data.dialCode && data.dialCode.dial_code ? data.dialCode.dial_code : 1;
        const dialCode2 = this.representativeCell.dial_code ? this.representativeCell.dial_code : 1;
        this.showRepTable = true;
        this.addedRep.push({
          representativeName: body.representativeName,
          representativeNumber: body.representativeNumber ? '+' + dialCode1 + ' ' + body.representativeNumber : '',
          representativeAddress: body.representativeAddress,
          representativeEmail: body.representativeEmail ? body.representativeEmail : '',
          representativeTitle: body.representativeTitle ? body.representativeTitle : '',
          representativeNote: body.representativeNote ? body.representativeNote : '',
          representativeCell: cell ? '+' + dialCode2 + ' ' + cell : '',
        });
        this.representativeForm.reset();
        this.dialCode = { dial_code: 1 };
        this.representativeCell = { dial_code: 1 };
        this.representativeForm.patchValue({
          representativeCell: '',
        });
      }
    }
    if (data.value.check === 'saveChanges') {
      this.showRepTable = true;
      const dialCode = data.dialCode && data.dialCode.dial_code ? data.dialCode.dial_code : 1;
      this.addedRep[this.repIndex].representativeName = body.representativeName;
      this.addedRep[this.repIndex].representativeNumber = '+' + dialCode + ' ' + body.representativeNumber;
      this.addedRep[this.repIndex].representativeAddress = body.representativeAddress;
      this.addedRep[this.repIndex].representativeEmail = body.representativeEmail ? body.representativeEmail : '';
      this.addedRep[this.repIndex].representativeTitle = body.representativeTitle ? body.representativeTitle : '';
      this.addedRep[this.repIndex].representativeNote = body.representativeNote ? body.representativeNote : '';
      this.representativeForm.reset();
      this.editRepresentative();
    }
    if (data.value.check === 'backEdit') {
      this.showRep();
    }
  }

  editRep(data, index: number) {
    this.showRepTable = false;
    this.repIndex = index;
    this.representative = [
      {
        check: 'saveChanges',
        buttonName: 'Save Changes',
        type: 'client'
      }, {
        check: 'backEdit',
        buttonName: 'Back',
        type: 'client'
      }];
    this.representativeForm.patchValue({
      representativeName: data.representativeName,
      representativeNumber: this.getContact(data.representativeNumber),
      representativeAddress: data.representativeAddress,
      representativeEmail: data.representativeEmail ? data.representativeEmail : '',
      representativeTitle: data.representativeTitle ? data.representativeTitle : '',
      representativeNote: data.representativeNote ? data.representativeNote : '',
      representativeCell: this.getContact(data.representativeCell)
    });
  }

  // Function to delete the branch address or rep from table
  deleteAddress(index: number, type: string): void {
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        if (type === 'rep') {
          const deletedDocs = this.addedRep[index];
          this.addedRep = this.addedRep.filter((item) => {
            return item !== deletedDocs;
          });
        } else if (type === 'branch') {
          const deletedDocs = this.branchAddress[index];
          this.branchAddress = this.branchAddress.filter((item) => {
            return item !== deletedDocs;
          });
        }
      }
    });
  }
  

  editRepresentative() {
    this.representativeData = [
      { title: 'Branch Address', name: 'representativeAddress', type: 'checkBox', multipleValues: this.address, singleSelection: true },
      { title: 'Rep Name*', name: 'representativeName', placeholder: 'Enter Rep Name', limit: 50, type: 'input' },
      { title: 'Rep Direct Office #', name: 'representativeNumber', type: 'phone', placeholder: 'Rep Direct Office #' },
      { title: 'Email', name: 'representativeEmail', type: 'email', placeholder: 'Enter Rep Email' },
      { title: 'Title', name: 'representativeTitle', type: 'input', placeholder: 'Enter Rep Title' },
      { title: 'Note', name: 'representativeNote', type: 'summary', placeholder: 'Enter Rep Note' },

    ];
    this.representative = [{
      check: 'saveRep',
      buttonName: 'Save Rep',
      type: 'client'
    }, {
      check: 'back',
      buttonName: 'Back',
      type: 'client'
    }];
    this.representativeForm = this.fb.group({
      representativeName: '',
      representativeNumber: '',
      representativeAddress: '',
      representativeEmail: '',
      representativeNote: '',
      representativeTitle: '',
      representativeCell: ''
    });
    this.changeTab('additional-info');
    this.representativeForm.reset();
    this.representative = [{
      check: 'saveRep',
      buttonName: 'Save Rep',
      type: 'client'
    }, {
      check: 'back',
      buttonName: 'Back',
      type: 'client'
    }];
  }

  showRep() {
    this.representativeData = [
      { title: 'Branch Address', name: 'representativeAddress', type: 'checkBox', multipleValues: this.address, singleSelection: true },
      { title: 'Rep Name*', name: 'representativeName', placeholder: 'Enter Rep Name', limit: 50, type: 'input' },
      { title: 'Rep Direct Office #', name: 'representativeNumber', type: 'phone', placeholder: 'Rep Direct Office #' },
      { title: 'Email', name: 'representativeEmail', type: 'email', placeholder: 'Enter Rep Email' },
      { title: 'Title', name: 'representativeTitle', type: 'input', placeholder: 'Enter Rep Title' },
      { title: 'Note', name: 'representativeNote', type: 'summary', placeholder: 'Enter Rep Note' },

    ];
    this.representative = [{
      check: 'saveRep',
      buttonName: 'Save Rep',
      type: 'client'
    }, {
      check: 'back',
      buttonName: 'Back',
      type: 'client'
    }];
    this.representativeForm = this.fb.group({
      representativeName: '',
      representativeNumber: '',
      representativeAddress: '',
      representativeEmail: '',
      representativeNote: '',
      representativeTitle: '',
      representativeCell: ''
    });
    this.changeTab('additional-info');
    this.representativeForm.reset();
    this.addedRep = this.patchRep;
    if (this.addedRep.length) {
      this.showRepTable = true;
    }
  }

  getContact(data) {
    // Extracting the country code
    const countryCodePattern = /^\+(\d+)/; // Match the country code starting with '+'
    const countryCodeMatches = data.match(countryCodePattern);
    const countryCode = countryCodeMatches ? countryCodeMatches[1] : '';
    this.dialCode = { dial_code: countryCode ? countryCode : 1 };
    // Extracting the remaining phone number 
    const remainingNumberPattern = /\(([^)]+)\)\s(.+)/; // Match the pattern '(###) ####-####'
    const remainingNumberMatches = data.match(remainingNumberPattern);
    const remainingNumber = remainingNumberMatches ? `(${remainingNumberMatches[1]}) ${remainingNumberMatches[2]}` : '';
    return remainingNumber ? remainingNumber : this.cos.cellPattern.test(data) === true ? data : this.cos.convertToPhoneNumberFormat(data);

  }

  // Function to call api to add client 
  addClient(data) {
    this.clientSrvc.addClient(data).subscribe((res) => {
      if (res) {
        this.cos.toastMessage(response.en.RECORD_CREATED, 'success');
        jQuery('#addClient').modal('hide');
        this.ClientData.emit([{ key: this.modalKey, id: res.data._id }]);

      } else {
        this.cos.toastMessage(res.message, 'warning');
      }
    }, (err) => { this.cos.handleError(err); });
  }

  // Function is used to submit form/ client data
  submitClientForm() {
    const multipleAddress = [];
  
    this.branchAddress.forEach(address => {
      const matchingReps = this.addedRep.filter(repItem => repItem.representativeAddress === address.branchAddress?.formattedAddress);
      
      const entry = {
        branchAddress: address.branchAddress && address.branchAddress.country ? address.branchAddress : undefined,
        phone: address.phone ? address.phone : undefined,
        representatives: matchingReps.length > 0 ? matchingReps : undefined,
      };
  
      // Add entry if any of the fields are present
      if (entry.branchAddress || entry.phone || entry.representatives) {
        multipleAddress.push(entry);
      }
    });
  
    // Add entries with representatives that do not match any branchAddress
    this.addedRep.forEach(repItem => {
      const matchingAddress = multipleAddress.find(item => item.branchAddress?.formattedAddress === repItem.representativeAddress);
      if (!matchingAddress) {
        multipleAddress.push({
          phone: undefined,
          representatives: [repItem],
          branchAddress: undefined
        });
      }
    });
  
    // Process multipleAddress to ensure the structure is correct
    const formattedAddressList = multipleAddress.map(item => {
      if (!item.phone && !item.branchAddress && item.representatives) {
        return { representatives: item.representatives };
      } else if (!item.representatives && item.branchAddress && item.phone) {
        return {
          branchAddress: item.branchAddress,
          phone: item.phone,
          representatives: [],
        };
      } else if (!item.phone && !item.representatives && item.branchAddress) {
        return { branchAddress: item.branchAddress, representatives: [] };
      } else if (!item.branchAddress && !item.representatives && item.phone) {
        return { phone: item.phone, representatives: [] };
      } else {
        return item;
      }
    });
  
    const payload = {
      userName: this.modalForm.value.userName,
      multipleAddress: formattedAddressList,
    };
  
    this.addClient(payload);
  }
  
  
  changeTab(tab: string): void {
    if (tab === 'additional-info') {
      this.dialCode = 1;
    }
    this.activeTab = tab;
  }

  // Function to add branches in table
  addBranch(data) {
    
    const element = data.formValue;
  
    const isCode = data.dialCode && data.dialCode.dial_code ? data.dialCode.dial_code : 1;
    const isNumber = element.phone ? element.phone.trim() : '';
  
    // Check if both address and phone number are empty or "N/A"
    const isAddressValid = element.address && element.address.formattedAddress && element.address.formattedAddress.trim() !== 'N/A';
    const isPhoneValid = isNumber !== '';
  
    // Show toast only if both address and phone are not valid
    if (!isAddressValid && !isPhoneValid) {
      return this.cos.toastMessage(response.en.BRANCH_OR_PHONE, 'warning');
    }
  
    // Add branch if either address or phone is valid
    if (isAddressValid || isPhoneValid) {
      this.branchAddress.push({
        branchAddress: element.address,
        phone: {
          dialCode: `${isCode}`,
          country: "",
          number: `${isNumber}`,
        },
      });
    }
  
    // Reset form fields after adding branch
    this.modalForm.patchValue({
      address: {
        streetAddress: '',
        locality: '',
        subLocality: '',
        region: '',
        country: '',
        postalCode: '',
        geo: [],
        formattedAddress: ''
      },
      phone: '',
    });
    this.dialCode = 1;
  }
  
  

  // input phone number functionality
  // This function is used to search the country name 
  searchDialCode(data) {
    const search = data.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!regex.test(search)) {
      data.target.value = search.replace(/[^a-zA-Z0-9 ]/g, '');
    } else if (!isNaN(search)) {
      this.countryDetails = [];
      this.countries.map((val) => {
        if (val.dial_code && val.dial_code.match(search)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    } else {
      this.countryDetails = [];
      const regx = new RegExp(search ? search : "" + "$", "i");
      this.countries.map((val) => {
        if (val.name.match(regx)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    }
  }

  // This function is used to get the countries name with dial code
  getSelectedCountry(country: string) {
    this.country = country;
    this.representativeCell = {
      ...this.country,
    };
  }

  repNumber(data) {
    this.representativeForm.patchValue({
      representativeCell: data.target.value,
    });
  }

  // This function is used to reset the values of dial code
  resetDialCode() {
    this.searchCode = '';
    this.countryDetails = [];
    this.countryDetails = this.countries;

  }


}
