<app-ad-common-modal [modalProperty]="modalProperty">
  <form [formGroup]="addEmployeeForm">
    <div class="row p-3">
      <div class="col-sm-4">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Schedule Type*</mat-label>
          <mat-select
            formControlName="type"
            (selectionChange)="typeChange($event.value)"
          >
            <mat-option
              *ngFor="let data of jobTypeValue"
              [value]="data.value"
              class="py-2 ad-text-size"
            >
              {{ data.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-4" *ngIf="addEmployeeForm.value.type">
        <mat-form-field
          (click)="startDate11.open()"
          appearance="outline"
          class="d-block"
        >
          <mat-label>Start Date*</mat-label>
          <input
            matInput
            [matDatepicker]="startDate11"
            [min]="currentStartDate"
            [max]="currentEndDate"
            class="ad-text-size"
            formControlName="startDate"
            (dateChange)="dateSelection()"
            style="border: none"
            maxlength="10"
            onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
          />
          <mat-datepicker-toggle matSuffix [for]="startDate11">
          </mat-datepicker-toggle>
          <mat-datepicker #startDate11></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-sm-4" *ngIf="addEmployeeForm.value.type">
        <mat-form-field
          (click)="endDate.open()"
          appearance="outline"
          class="d-block"
        >
          <mat-label>End Date*</mat-label>
          <input
            matInput
            [matDatepicker]="endDate"
            [max]="currentEndDate"
            [min]="currentStartDate"
            class="ad-text-size"
            (dateChange)="dateSelection()"
            formControlName="endDate"
            style="border: none"
            maxlength="10"
            onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        class="col-sm-4"
        *ngIf="
          isCombined &&
          addEmployeeForm.value.endDate &&
          addEmployeeForm.value.startDate
        "
      >
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Shift*</mat-label>
          <mat-select
            formControlName="shift"
            (selectionChange)="
              onEmployeesChange($event.value, 'combined', 'date', 'index')
            "
          >
            <mat-option
              *ngFor="let shift of shiftArray"
              value="{{ shift.value }}"
              class="ad-text-size"
              >{{ shift.key }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- ****************** Combined Case ***************** -->
    <div
      class="row px-3"
      *ngIf="
        isCombined &&
        addEmployeeForm.value.endDate &&
        addEmployeeForm.value.startDate
      "
    >
      <app-crew-assignment
        (bindEquipment)="bindEmployeeEquip($event)"
        (assignedData)="getAssignedData($event)"
      ></app-crew-assignment>

      <app-equipment
        (addedEquipment)="addedEquipment($event)"
        [fieldWidth]="true"
      ></app-equipment>
    </div>

    <!-- ****************** Individual Case ***************** -->

    <ng-container *ngIf="isIndividual">
      <ng-container
        formArrayName="dateFormArr"
        style="width: 100%"
        *ngIf="
          isIndividual &&
          addEmployeeForm.value.startDate &&
          addEmployeeForm.value.endDate
        "
      >
        <ng-container
          *ngFor="let date of Dates; let i = index"
          [formGroupName]="i"
        >
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              class="my-4 mx-3"
            >
              <mat-expansion-panel-header style="margin-left: -1.2rem">
                <mat-panel-title style="font-size: 16px">
                  <div class="m-3">
                    <input
                      matInput
                      formControlName="date"
                      readonly
                      style="border: none"
                    />
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-sm-4">
                  <mat-form-field class="d-block" appearance="outline">
                    <mat-label>Shift*</mat-label>
                    <mat-select
                      formControlName="shift"
                      (selectionChange)="
                        onEmployeesChange($event.value, 'individual', date, i)
                      "
                    >
                      <mat-option
                        *ngFor="let shift of shiftArray"
                        class="ad-text-size"
                        value="{{ shift.value }}"
                      >
                        {{ shift.key }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <ng-container *ngIf="isEmployee[i]">
                  <app-crew-assignment
                    (bindEquipment)="bindEmployeeEquip($event)"
                    [employeeIndex]="i"
                    (assignedData)="getAssignedData($event)"
                  ></app-crew-assignment>

                  <app-equipment
                    [employeeIndex]="i"
                    [fieldWidth]="true"
                    (addedEquipment)="addedEquipment($event)"
                  ></app-equipment>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="float-right d-flex mb-2">
      <button class="btn btn-primary float-right mr-3" (click)="submitForm()">
        Save
      </button>
    </div>
  </form>
</app-ad-common-modal>
