import { ENVIRONMENT_INITIALIZER } from "@angular/core";

const message = {
  NAME: "Name is Required",
  UNIT_REQUIRED: "Unit is Required",
  SIZE_REQUIRED: "Size is Required",
  COST_REQUIRED: "Cost is Required",
  RATE_REQUIRED: "Rate is Required",
  MEAL_RATE_REQUIRED: "Meal Rate is Required",
  HOTEL_MEAL_RATE_REQUIRED: "Hotel Meal Rate is Required",
  SELLING_COST: "Selling Cost is Required",
  CONTACT: "Phone Number is required",
  VALID_CONTACT: "Please add valid contact",
  EMAIL: "Email is required",
  VALID_EMAIL: "Please add valid email",
  ROLE: "Role is required",
  PASSWORD: "Please add password",
  VALID_PASSWORD:
    "Password must contain at least 1 upper case, 1 lower case, 1 number, 1 special character, and be a minimum of 8 characters",
  DESCRIPTION: "Description is required",
  PERMISSION: "Permissions are required",
  PASSWORD_MATCH: "New Password and Confirm Password do not match!",
  LOGIN: "Login successful",
  OTP_SENT: "An OTP has been sent to your email",
  NEW_PASSWORD: "New password is required",
  CONFIRM_MESSAGE: "Confirm password is required",
  EMAIL_PASSWORD: "Please enter email and password again",
  CODE: "Please enter code",
  AUTHENTICATION:
    "Two-Factor Authentication Enabled Successfully! Please wait while we redirect you to your dashboard.",
  AUTH_TRUST:
    "By trusting this device, you won't have to verify it every time you log in. Want to proceed and mark this device as trusted?",
  TRUST_MFA: "Trust this Device",
  ENABLE_AUTH:
    "By enabling Two-Factor Authentication, you'll add an additional layer of security to your account",
  AUTH_ENABLE: "Two Factor Authentication Enabled.",
  CURRENT_PASSWORD: "Please Enter Current Password",
  USERNAME: "Username is required",
  CONFIRM_QUESTION: "Are You Sure?",
  DISABLE_AUTH:
    "Disabling 2FA may reduce your account's security. You can re-enable it later for increased protection.",
  MESSAGE_REQUIRED: "Message is Required",
  EMPLOYEE: "Please select an employee",
  NOTIFICATION: "Notification Sent Successfully",
  TITLE: "Title is Required",
  DELETED_RECORD: "Please Select a record to delete",
  DELETED_SUCCESSFULLY: "Record is Deleted Successfully",
  TYPE: "Type is Required",
  ADDRESS: "Address is Required",
  NOTES: "Note is Required",
  LICENSE_TYPE: "License Type is Required",
  NUMBER: "Number is Required",
  MAIN_USERS: "Main User for License is Required",
  PAYOFF_DATE: "Please select PayOff Day",
  PAYOFF_INTERVAL: "Please select PayOff Interval",
  DOCUMENT: "Please select Document",
  PROJECT: "Project is Required",
  LINK: "Link is Required",
  QUESTION_CATEGORY:
    "All categories have questions. Please create new categories from configuration",
  CATEGORY: "Please select Category",
  SUBCATEGORY: "Please select a Subcategory",
  QUESTION: "Question is required",
  OPTION: "Options are required",
  ADD: "Please add some fields",
  DIMENSION_NOTES: "Please add dimension value or notes",
  TEMPLATE: "The Selected Category has no Template, Please Select Other",
  START_END: "End date should be equal or greater than the start date",
  REOCCURRING_DATE: "Please add Re-Occurring date",
  REOCCURRING_VALID: "Re-Occurring date should be greater than the End Date",
  NO_RECORD: "Sorry, There is no record",
  TASK: "Task is Required!",
  TASK_SUCCESS: "Task List is updated successfully",
  TEMPLATE_SUCCESS: "Task is saved as Template successfully",
  DIEM_TYPE: "Please Select Diem Type",
  UPLOADED_TYPE: "Please Select Upload Type",
  DIEM_TYPE_REQUEST: "Diem Type Request is Required",
  START: "Start Date is Required",
  END: "End Date is Required",
  AMOUNT_DEPOSITED: "Please Enter the amount deposited date",
  AMOUNT: "Please enter Amount",
  MEAL_RATE: "One day meal rate is required",
  NIGHT_RATE: "Per Night Rate is required",
  STATUS: "Status is required",
  SCHEDULE: "Schedule is required",
  CLIENT: "Client is required",
  NO_SCHEDULE: "There is no schedule associated with this project and client.",
  REASON: "Reason is Required",
  TASK_LIST: "Please select a task list",
  PROJECT_TASK:
    "There is no Task list under this project, please select another Project",
  BULLETIN_TYPE: "Bulletin Type is Required",
  SDS_BRAND: "Brand is required",
  RECORD_UPDATE: "Record Updated Successfully",
  CREW_ASSIGNMENT: "Please select crew type",
  ASSIGNMENT: "Please select assignment type",
  ASSIGNMENT_EXIST:
    "A record already exists. Please delete it and create a new one",
  LEAVE_TYPE: "Leave type is required",
  TIME_SLOT: "Time slot is required",
  DATE: "Please select Date",
  JOB_CLONE:
    "Job Name is required for Clone, Please set job name first then you will be able to clone the schedule",
  OK: "OK",
  CLOSE: "Close",
  NO: "No, Keep it",
  DELETE: "Yes, delete it!",
  VALIDATE_TAB: "Schedule Validation Information",
  SCHEDULE_VALIDATE:
    "The schedule is in alignment with the Project's requirements",
  DOUBLE_BOOKING:
    "This will forcefully add the employees/equipments that are already scheduled in other schedule!",
  IS_DELETE: "Do you want to delete this equipment?",
  DELETE_ALL: "Delete from All",
  DELETE_ONE: "Delete Only This Equipment",
  DELETE_CONFIRMATION: "Delete Equipment Confirmation",
  IS_EQUIP_DELETE: "Are you sure you want to delete this equipment",
  STATE: "State is required",
  UTILITY_TYPE: "Please add only valid Type from Utility Type List.",
  UTILITY_TYPE_VALIDATION: "Please add a type with a comma in one field.",
  SUBCATEGORY_EXIST: "Subcategory with the same name already exists",
  DELETE_COMPANY_DETAIL:
    "After deleting this record, there will be no address available to use in  mail or PDF in the report",
  GROUP_REQUIRED: "Please select a Group",
  ASSIGNED_TO: "Please assign groups or employees",
  CATEGORY_INSPECTION:
    "Selected Category has no items, please add inspection item from configuration",
  DOCUMENT_TYPE: "Document Type is Required",
  DOCUMENT_LIST: "Please Select Document From Document List",
  DOCUMENT_CATEGORY: "Please Select Sub document Category",
  DOCUMENT_EXPIRY: "Please Add Expiry date for Document",
  INSPECTION_CONFIG: "Please select Field from Inspection Config",
  REQUIRED_SUBCATEGORY: "Please add subcategory",
  ITEM_INSPECTED:
    "There is no item to be inspected, please select equipment category/subcategory for items",
  CLIENT_MERGE: "Please select Client to merge",
  JOINING_DATE: "Joining Date is required",
  ADDED: "Record Created Successfully",
  APPROVED_DOCUMENT: "Document is Added and Approved",
  ADD_DOCUMENT: "Please Add Document",
  TASK_ADDED: "The task has been added to the General Tasks successfully",
  No_DATA_FOUND: "No data to show",
  ADD_TABLE_DATA: "Add some data to view",
  SEARCH_TABLE_DATA: "Data is loading. Please Wait...",
  ID_EXIST_DATA: "This record is already assigned",
  CONFIRM_DELETE: "Do you still want to delete them?",
  CONNECT_INTERNET: "Please connect to the internet first!",
  NO_INTERNET: "No internet connection",
  VALID_LINK: "Please Enter Valid Link",
  UPLOAD_DOC: "Please upload Document",
  ADD_SUBCATEGORY: "You can add only 5 subcategories",
  POLICY_CONTENT: "Policy should contain some content",
  CURRENCY_CODE: "Currency Code is Required",
  CURRENCY_SYMBOL: "Currency Symbol is Required",
  EXCHANGE_RATE: "Exchange rate is Required",
  SELECT_FORMAT: "Please Select the format",
  EQUIP_CATEGORY: "Please select Equipment Category",
  EQUIP_SUBCATEGORY: "Please select Equipment Subcategory",
  ITEM_SELECT: "Please select Item",
  RECORD_CREATED: "Record Created Successfully",
  ADD_ITEM: "Please add item",
  ITEM_REQUIRED: "Item is Required",
  DELETE_TASK: "Please Select Task List to Delete",
  DELETE_PROJECT: "Please Select Project to delete",
  INSPECTION_ITEM: "Please Select inspection item",
  RESPONSE_ITEM: "Please select the response for item",
  ADD_VALUE: "Please add value in last added",
  VALID_SUBSTATUS: "Sub Status Name must be valid",
  ADD_LASTFIELD: "Please add a value in the last added field",
  REQUIRED_SUBSTATUS: "Please Enter sub status",
  COLOR_REQUIRED: "Please select a color",
  URL_REQUIRED: "URL is required",
  VALID_URL: "Enter valid URL",
  ADD_SOURCE: "Please add Source",
  PROJECT_EMP: "Please select either Project, Employees, or both",
  REQUIRED_ENDDATE: "Please select end date as you selected start date",
  REQUIRED_ITEMS: "Items are required in case you add subcategory.",
  REQUIRED_STARTDATE: "Please select start date as you selected end date",
  COPED_SUCCESSFULLY: "Message copied successfully!",
  ADD_PROJECTFIRST: "Please Add Project first",
  ADD_DOC: "Please add documents",
  ADD_DETAILS: "Please add some details",
  ADD_COUNT: "Please fill the count",
  FIELD_FILL: "Please add the field value",
  ADD_TITLE: "Please add title",
  DELETE_CONTACTS: "Please Select Contacts to Delete",
  REQUIRED_POSITIVEVALUE: "Please Enter positive value",
  VALID_MOBILENO: "Mobile number must be valid",
  VALID_OFFICENO: "Office number must be valid",
  PASSWORD_VALID:
    "Password must contain at least 1 upper case , 1 lower case , 1 number, 1 special character and minimum 8 character",
  CONTACT_NUMBER: "Contact number is required",
  BRANCH_EXISTS:
    "A branch with the same name already exists. Please add another branch name.",
  DATE_REQUIRED: "Please Enter Date",
  VALID_PERSONALEMAIL: "Enter valid personal email address",
  EMAIL_ALREADY_PRESENT: "This email is already in use within the section",
  REQUIRED_ROLETYPE: "Please select role type",
  REQUIRED_DATEOFJOIN: "Date of Joining is required",
  EMPTY_REPORT: "The selected employee does not fill out daily reports.",
  NODAILY_REPORT: "Sorry, There is no Daily Report",
  REQUIRED_EMPLOYEE: "Please select employee",
  STARTTIME_REQUIRED: "Start time is required",
  TYPE_REQUIRED: "Please Select Type",
  BORINGHOLE_REQUIRED: "Please Select Boring Hole",
  ADD_SUBACTIVITY: "Please Select Sub Activity",
  REQUIRED_ENDTIME: "End time is required",
  VALID_ENDTIME: "End time should be greater than start time",
  VALID_ACTIVITIES:
    "This employee hasn't submitted a daily report, so no activities can be added.",
  REQUIRED_ACTIVITY: "Activity is Required",
  ADD_SITECONSUMABLE: "Please enter site consumable",
  ADD_ONSITECONSUMABLE: "Please enter on site consumable",
  ADD_USEDCONSUMABLE: "Please enter used consumable",
  ENTER_ADDEDCONSUMABLE: "Please enter added consumable",
  REQUIRED_EXPENSETYPE: "Expense Type is Required",
  REQUIRED_EXPENSECATEGORY: "Expense Category is Required",
  ADD_VENDOR: "Please select Vendor",
  ADD_OTHERSTYPE: "Please add type of Others",
  REQUIRED_AMOUNT: "Amount is Required",
  ASSIGNED_FIELD: "Please Select Assigned field",
  NO_GROUPPRESENT: "There is no group, please select other type",
  NO_EMPLOYEES: "There is no employees, please select other type",
  VALID_NOTE: "Please add a valid note",
  ADD_SUMMARY: "Please add summary",
  SUMMARY_REQUIRED: "Summary is Required",
  LOADLIST_SUCCESS: "Load List is finalized successfully",
  NOITEM_PRESENT:
    "There is no item/Documents in this field , you cannot save it as template",
  SELECT_ALLFIELDS: "Please select all fields",
  SKILLLEVEL_REQUIRED: "Skill LEVEL is Required",
  ACCOUNTTYPE_REQUIRED: "Account Type is Required",
  ACCOUNTNO_REQUIRED: "Account Travel Number is Required",
  VALID_TRAVELNO: "Please Enter valid travel number",
  TRAVELACC_NO: "Travel Account Number is required",
  DOCADDED_APPROVED: "Document is Added and Approved",
  RESTRICTION_EXPIRY: "Restriction Expire Date is required",
  RESTRICTION_NOTE: "Restriction Note is required",
  VALID_RESTRICTION: "Enter Valid Restriction Note",
  ENTER_TERMINATIONDATE: "Please Enter Termination Date",
  EMPLOYEE_DEPENDENCY: "Please remove all dependencies of employee",
  SCHEDULED_NEWEMP: "New Employee is Scheduled successfully",
  UPLOAD_TYPE: "Please select upload type",
  ADD_VALUEFIELD: "Please add a value to this field",
  ADD_BOTHDATES: "Please select both dates",
  ADD_TICKETNO: "Please Add Ticket Number",
  ADD_ESTIMATETYPE: "Please Add Estimate Type",
  SELECT_CATEGORY:
    "Please select Category/ Subcategory and add in list by click on Add Category button ",
  ESTIMATE_TYPE: "Please add Estimate Type First",
  ADD_SURFACEITEM: "Please Add Surface Item",
  DRILL_ITEM: "Please Add Drill Methods Item",
  DRILL_CATEGORY: "Please select Drill category",
  ADD_SAMPLEDATA: "Please Add Sampling Supplies Data",
  ADD_WATERSOURCETYPE: "Please Add Water Source Type",
  ADD_TIMECONSIDERATION: "Please add time consideration type",
  ADD_ENVDATA: "Please add environmental data",
  PERDIEM_TYPE: "Please add Per-diem type",
  ADD_PERMITSTYPE: "Please add Permits type",
  VALID_VALUES: "Negative Values are not Allowed",
  LIMIT_OVER: "The limit of categories are over",
  UTILITY_STATUS: "Please Select Utility Status",
  SELECT_RADIUS: "Please enter Radius",
  OWNER_STATE: "Please Select Owner State",
  UTILITY_OWNER: "Please Select Utility Owner",
  OWNER_STATUS: "Please Select Owner Status",
  OWNERSTATE_REQUIRED: "Owner State is Required",
  OWNER: "Please Select an Owner",
  TEMPLATE_CATEGORY: "Template Category is Required",
  BRANCH_REQUIRED: "Please Add Branch Address",
  BRANCH_OR_PHONE: "Please Enter either Branch Address or Phone No ",
  BRANCH_ADDRESS: "Select branch Address for Representative",
  VALID_CELLNO: "Please enter valid cell number",
  ENTER_SUBDOC: "Please enter Sub-Document or field",
  FIELD_REQUIRED: "Please select Field",
  ADD_SUBFIELD: "Please add sub field",
  ENTER_RECEMAIL_ID: "Enter receiver email id",
  ENTER_CC_EMAIL_ID: "Enter cc email id",
  ENTER_EMAILSUB: "Please enter subject for the email",
  VALID_SUBJECT: "Please enter a valid subject",
  ENTER_EMAILBODY: "Please enter body for the email",
  VALID_BODY: "Please enter a valid body",
  CURRENTPASS_REQUIRED: "Current password is required",
  JOBNAME_LIMIT: "Job name limit is up to 30 characters",

  // START: Project Estimate Strings
  ESTIMATE_TYPE_REQUIRED: "Estimate Type is Required",
  ESTIMATE_VERSION_REQUIRED: "Estimate Version is Required",
  ENVIRONMENT_TYPE_REQUIRED: "Environment Type is Required",
  WELL_MATERIAL_REQUIRED: "Well Material is Required",
  WELL_DEVELOPMENT_REQUIRED: "Well Development is Required",
  PER_DIEM_TYPE_REQUIRED: "Per Diem Type is Required",
  PERMITS_TYPE_REQUIRED: "Permits Type is Required",
  TIME_CONSIDERATION_TYPE_REQUIRED: "Time Consideration Type is Required",
  WATER_SOURCE_TYPE_REQUIRED: "Water Source Type is Required",
  SITE_TYPE_REQUIRED: "Site Type is Required",
  PPE_LEVEL_REQUIRED: "PPE Level is Required",
  SAFETY_DOCUMENT_REQUIRED: "Safety Document is Required",
  SAMPLER_REQUIRED: "Sampler is Required",
  SAMPLING_INTERVAL_REQUIRED: "Sampling Interval is Required",
  CATEGORY_REQUIRED: "Category is Required",
  CREW_REQUIRED: "Crew is Required",
  EQUIPMENT : "Equipment is Required",
  DAMAGED_TOOLING: "Damaged Tooling is Required",
  CONTACT_EMAIL: "Office Number or Email is Required",
  PHONE_EMAIL: "Contact Number or Email is Required",
  SELECT_ORDER_ERROR: "Please select order to send the email.",
  SELECT_VENDOR_DATA: "Please select vendor to send the email.",
  ADD_ORDER_QUANTITY_ERROR: "Please enter quantity for every order.",
  NO_CONTACTS_FOUND_ERROR: "No contacts found to send the email, Please select custom email option.",
  AT_LEAST_ONE_VALUE_ERROR: "Please enter at least one value.",
  PASSWORD_UPDATED: "Password updated successfully.",
  NO_OPTIONS_TO_SELECT: "No options available to select",
  NO_SCHEDULE_TO_SELECT: "No schedule available to select",
  DATE_TYPE: "Please select Date Type",
  FILL_DETAILS: "Please fill all fields before adding equipment.",
  NOT_EDITABLE: "This notification is not editable",
  NOT_DELETABLE: "This notification is not deletable",
};

export const response = {
  en: message,
};
