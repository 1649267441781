<form [formGroup]="inputProperty.form" class="d-flex">
  <div style="width: 100%">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{ inputProperty?.title }}</mat-label>
      <input
        maxlength="60"
        matInput
        type="{{inputProperty.type }}"
        formControlName="{{ inputProperty?.name }}"
        [value]="inputProperty?.value ? inputProperty?.value : ''"
        class="ad-text-size"
        placeholder="{{ inputProperty?.placeholder }}"
        (change)="changeInput($event.target.value, inputProperty.name)"
        [readonly]="inputProperty.readOnly"
      />
    </mat-form-field>
  </div>
  <span class="d-flex flex-column" *ngIf="inputProperty.addIcon">
    <span type="button" (click)="addField(inputProperty)">
      <i class="iconStyle fa fa-plus-circle" aria-hidden="true"></i>
    </span>
    <span type="button" (click)="removeField(inputProperty)">
      <i class="iconStyle fa fa-minus-circle" aria-hidden="true"></i>
    </span>
  </span>
</form>
