<div class="header-top-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="row mx-2 header-top-wrapper">
          <div class="col-lg-5 col-md-0 col-sm-1 col-xs-12 d-flex align-items-center" style="margin-left:-15px">
            <img src="../../../../../../assets/images/navIcon.jpg" style="max-width:28px !important"
              class="cursor-pointer" (click)="addClass()" alt="Image">
            <div class="main-sparkline13-hd">
              <h1 class="pl-3 pb-1"> {{currentComponentName}}</h1>
            </div>
          </div>

          <div class="col-lg-7 col-md-11 col-sm-11 col-xs-12">
            <div class="header-right-info pull-right">
              <div class="nav navbar-nav  header-right-menu d-flex">

                <button class="pr-2 pb-1 notification">
                  <i class="fa fa-bell bell " (click)="getNotification()" aria-hidden="true"></i>
                  <span class="count-badge" *ngIf="notificationCount>0">{{notificationCount}}</span>
                    <i class="fa fa-user-circle profile-icon" [matMenuTriggerFor]="menu" aria-hidden="true"></i>
                </button>

                <mat-menu #menu="matMenu" class="header-menu">
                  <button mat-menu-item (click)="isProfile()">
                    <i class="fa fa-user-circle theme-text-color" aria-hidden="true"></i>
                    <span class="theme-text-color"> My Profile</span>
                  </button>
                  <button mat-menu-item (click)="logout()">
                    <i class="fa fa-power-off theme-text-color"></i>
                    <span class="theme-text-color"> Log Out</span>
                  </button>
                </mat-menu>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="changepassword">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" class="close" data-dismiss="modal" (click)='resetForm();'>&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="resetPassword()">
          <div class="row">
            <div class="col-sm-4">

              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Current Password*</mat-label>
                <input matInput [type]="inputType === 'password' ? 'password' : 'text'" formControlName="oldPassword"
                  placeholder="Password">
                <mat-icon matSuffix (click)="inputType = inputType === 'password' ? 'text' : 'password'">
                  {{ inputType === 'password' ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>

            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>New Password*</mat-label>
                <input matInput [type]="inputType1 === 'password' ? 'password' : 'text'" formControlName="password"
                  placeholder="New Password">
                <mat-icon matSuffix (click)="inputType1 = inputType1 === 'password' ? 'text' : 'password'">
                  {{ inputType1 === 'password' ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>

            </div>
            <div class="col-sm-4">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Confirm Password*</mat-label>
                <input matInput [type]="inputType2 === 'password' ? 'password' : 'text'"
                  formControlName="confirmPassword" placeholder="Confirm Password">
                <mat-icon matSuffix (click)="inputType2 = inputType2 === 'password' ? 'text' : 'password'">
                  {{ inputType2 === 'password' ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </div>

          </div>
          <div class="clearfix"></div>
          <div class="float-right text-right">
            <input type="submit" [disabled]="changePasswordForm.invalid" value="Save" class="btn btn-primary">
          </div>

        </form>
      </div>

      <!-- Modal footer -->

    </div>
  </div>
</div>

<app-push-notification modalId="notificationModal"></app-push-notification>
