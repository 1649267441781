import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { ProjectService } from 'src/app/_services/project.service';
import Swal from 'sweetalert2';
import 'bootstrap';
import { FunctionsService } from 'src/app/_common/functions.service';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() urls = [];
  @Input() modalId = 'mailModal';
  data = `<p>Hello, world!</p>`;
  @Output() submitBtnClickEvt = new EventEmitter();
  mailForm = this.fb.group({
    to: [''],
    subject: [''],
    body: ['']
  });
  modalProperty = {
    header: 'Send Mail',
    id: 'mailModal'
  };

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private cos: CommonService,
    private projectSrvc: ProjectService,
    private commonFunction: FunctionsService) {
    this.mailForm.reset();
  }

  ngOnInit() {
    this.projectSrvc.changeEmitted$.subscribe(data => {
      if (data) {
        this.mailForm.reset();
      }
    });
  }
  openUrl(url: string) {
    window.open(url);
  }
  
ngOnChanges(){
  this.urls= this.urls.filter(item=> item);
}
  public submitForm() {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    if (!this.mailForm.value.to) {
    return this.cos.toastMessage(response.en.ENTER_RECEMAIL_ID, 'warning');
    } else if (!validateEmail(this.mailForm.value.to) && this.mailForm.value.to) {
      return this.cos.toastMessage(response.en.VALID_EMAIL, 'warning');
    } else if (!this.mailForm.value.subject) {
      return this.cos.toastMessage(response.en.ENTER_EMAILSUB, 'warning');
    } else if (this.mailForm.value.subject.trim().length == 0) {
      return this.cos.toastMessage(response.en.VALID_SUBJECT, 'warning');
    } else if (!this.mailForm.value.body) {
      return this.cos.toastMessage(response.en.ENTER_EMAILBODY, 'warning');
    } else if (this.mailForm.value.body.trim().length == 0) {
      return this.cos.toastMessage(response.en.VALID_BODY, 'warning');
    }
    this.authService.sendEmail({ ...this.mailForm.value, urls: this.urls.map(val=> val.url ? val.url: val) })
      .subscribe(res => {
        if (res) {
          this.commonFunction.modalClose(this.modalProperty);
        this.mailForm.reset();
        this.urls = [];
        this.cos.toastMessage(res.message, 'success');
        } else {
          this.cos.toastMessage(res.message, 'error');
        }
      }, err => this.cos.handleError(err));

  }
  public removeAttachment(index) {
    const deletedDocs = this.urls[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.urls = this.urls.filter(function (item) {
          return item !== deletedDocs;
        });
      }
    });
  }
}
