<div class="root-container">
  <div class="d-flex justify-content-start mb-3" *ngIf="(!isOnlyConsumables && !isOutsourcedRentals)">
    <button class="btn btn-primary float-left" (click)="addNewRow()">
      + Add New Row
    </button>
  </div>  
  <div class="table-responsive my-2 tableEffect">
    <table class="table rounded-3">
      <thead class="thead bg-header fixedHeading pl-2 ad-text-size">
        <tr>
          <th *ngIf="isOnlyConsumables && isCheckBox"></th>
          <th *ngFor="let column of columns">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody class="ad-text-size">
        <tr *ngFor="let row of orderList; let i = index">
           <!-- Checkbox column, only visible when isOnlyConsumables is true -->
           <td *ngIf="isOnlyConsumables && isCheckBox" class="checkbox-column">
            <label class="checkbox-container">
              <input
                type="checkbox"
                [(ngModel)]="row.isSelected"
                (change)="onCheckboxChange(row.isSelected)"
              />
              <span class="checkmark"></span>
            </label>
          </td>          
          <th scope="row">{{ row.item }}</th>
          <td *ngIf="columnVisible('Quantity')">
            <input
              min="0"
              matInput
              class="ad-text-size text-center input-field"
              type="number"
              [(ngModel)]="row.quantity"
              (ngModelChange)="onQuantityChange(row)"
              (input)="calculateRowTotal(row); calculateTotal()"  
              maxlength="3"
              [disabled]="isInvoicing" 
            />
          </td>
          <td *ngIf="columnVisible('Cost')">
            <input
              min="0"
              matInput
              class="ad-text-size text-right-cost input-field"
              type="text" 
              [ngModel]="cos.formatCurrency(row.cost)"  
              (ngModelChange)="onCostChange($event, row)" 
              (input)="calculateRowTotal(row); calculateTotal()"
              maxlength="3"
              [disabled]="true"
            />

          <td *ngIf="columnVisible('Selling Cost')">
            <input
              min="0"
              matInput
              class="ad-text-size text-right-cost input-field"
              type="text"
              [ngModel]="cos.formatCurrency(row.sellingCost)"  
              (ngModelChange)="onSellingCostChange($event, row)"
              (input)="calculateRowTotal(row); calculateTotal()"
              maxlength="3"
              [disabled]="true"
            />
          </td>      
          <td *ngIf="columnVisible('Unit')">
            <!-- Dynamically binding the unit options for each row -->
            <select class="ad-text-size text-center dropdown" [(ngModel)]="row.selectedUnit" (change)="onUnitChange(row)" [disabled]="isInvoicing" >
              <option *ngFor="let option of row.options" [value]="option">{{ option }}</option>
            </select>
          </td>

          <!-- Markup Percentage Column -->
          <td *ngIf="columnVisible('Markup Percentage')">
            <input
            min="0"
            matInput
            class="ad-text-size text-right-cost input-field"
            type="text"
            [ngModel]="row.markupPercentage ? row.markupPercentage + '%' : ''"  
            (ngModelChange)="onMarkupPercentageInputChange($event, row)" 
            (input)="calculateRowTotal(row); calculateTotal()"
            maxlength="4" 
          />          
          </td>    

          <td *ngIf="(isRentals || isDownholeTests) && columnVisible('Unit Quantity')">
            <input 
              min="0" 
              matInput 
              class="ad-text-size text-center input-field" 
              type="number" 
              [(ngModel)]="row.unitQuantity" 
              (ngModelChange)="onUnitQuantityChange(row)"
              maxlength="3" 
              [disabled]="isInvoicing" 
              />
          </td>  
          
          <td *ngIf="isInvoicingItem">
            <input
              type="number"
              min="0"
              class="ad-text-size text-center input-field"
              [(ngModel)]="row.invoicedQuantity"
              (ngModelChange)="onInvoiceQuantityChange(row)"
              (input)="calculateRowTotal(row); calculateTotal()" 
            />
          </td>
          
          <td *ngIf="isRentals && columnVisible('Fuel Required')">
            <mat-slide-toggle [(ngModel)]="row.isFuelRequired" (change)="onToggleChange($event, i)">
              {{ row.isFuelRequired ? 'Yes' : 'No' }}
            </mat-slide-toggle>
          </td>          
          <td class="text-align" *ngIf="(markupDisplayPercentage || isConsumables) && columnVisible('Total Cost')">{{ cos.formatCurrency(row.totalCost) }}</td>
          <td class="text-align" *ngIf="(markupDisplayPercentage || isConsumables) && columnVisible('Total Selling Cost')">{{ cos.formatCurrency(row.totalSellingCost) }}</td>

          <td class="text-align" *ngIf="isRentals && columnVisible('Total Cost')">{{ cos.formatCurrency(row.totalCost) }}</td>
          <td class="text-align" *ngIf="isRentals && columnVisible('Total Selling Cost')">{{ cos.formatCurrency(row.totalSellingCost) }}</td>
        </tr>

        <!-- Add New Row -->
        <tr *ngFor="let row of newRows; let i = index">
          <!-- Checkbox column, only visible when isOnlyConsumables is true -->
          <td *ngIf="isOnlyConsumables && isCheckBox" class="checkbox-column">
            <label class="checkbox-container">
              <input type="checkbox" [(ngModel)]="row.isSelected" />
              <span class="checkmark"></span>
            </label>
          </td>
        
          <th scope="row">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="row.item"
              (ngModelChange)="onDynamicRowChange($event, 'item', i)"
              placeholder="Enter Item"
              [disabled]="isInvoicing" 
            />
          </th>
        
          <td *ngIf="columnVisible('Quantity')">
            <input
              type="number"
              class="ad-text-size text-center input-field"
              [(ngModel)]="row.quantity"
              (ngModelChange)="onDynamicRowChange($event, 'quantity', i)"
              [disabled]="isInvoicing" 
              min="0"
            />
          </td>
        
          <td *ngIf="columnVisible('Cost')">
            <input
              type="number"
              class="ad-text-size text-right-cost input-field"
              [(ngModel)]="row.cost"
              (ngModelChange)="onDynamicRowChange($event, 'cost', i)"
              min="0"
              [disabled]="isInvoicing" 
            />
          </td>
        
          <td *ngIf="columnVisible('Selling Cost')">
            <input
              type="number"
              class="ad-text-size text-right-cost input-field"
              [(ngModel)]="row.sellingCost"
              (ngModelChange)="onDynamicRowChange($event, 'sellingCost', i)"
              min="0"
              [disabled]="isInvoicing" 
            />
          </td>
        
          <td *ngIf="columnVisible('Unit')">
            <select
              class="ad-text-size text-center dropdown"
              [(ngModel)]="row.selectedUnit"
              (ngModelChange)="onDynamicRowChange($event, 'selectedUnit', i)"
              [disabled]="isInvoicing" 
            >
              <option *ngFor="let option of row.options" [value]="option">
                {{ option }}
              </option>
            </select>
          </td>
        
          <td *ngIf="columnVisible('Markup Percentage')">
            <input
              type="number"
              class="ad-text-size text-right-cost input-field"
              [(ngModel)]="row.markupPercentage"
              (ngModelChange)="onDynamicRowChange($event, 'markupPercentage', i)"
            />
          </td>
        
          <td *ngIf="columnVisible('Total Cost')" class="text-align">
            {{ cos.formatCurrency(row.totalCost) }}
          </td>
        
          <td *ngIf="columnVisible('Total Selling Cost')" class="text-align">
            {{ cos.formatCurrency(row.totalSellingCost) }}
          </td>
        </tr>            

        <tr class="row-bg">
          <th scope="row">Total</th>
          <td *ngIf="columnVisible('Quantity')"></td>
          <td *ngIf="columnVisible('Cost')"></td>
          <td *ngIf="columnVisible('Selling Cost')"></td>
          <td *ngIf="columnVisible('Unit')"></td>
          <td *ngIf="isRentals && columnVisible('Unit Quantity')"></td>
          <td *ngIf="isRentals && columnVisible('Fuel Required')"></td>
          <td *ngIf="markupDisplayPercentage"></td>
          <td *ngIf="isOnlyConsumables && isCheckBox"></td>
          <td *ngIf="isDownholeTests"></td>
          <td *ngIf="isInvoicingItem"></td>
          
          <td *ngIf="markupDisplayPercentage && columnVisible('Total Cost')" class="text-right">{{ cos.formatCurrency(totalCostSum) }}</td>
          <td *ngIf="markupDisplayPercentage && columnVisible('Total Selling Cost')"class="text-right">{{ cos.formatCurrency(totalSellingCostSum) }}</td>
          <td *ngIf="isConsumables && columnVisible('Total Cost')" class="total-sum">{{ cos.formatCurrency(totalCostSum) }}</td>
          <td *ngIf="isConsumables && columnVisible('Total Selling Cost')"class="total-sum">{{ cos.formatCurrency(totalSellingCostSum) }}</td>

          <td *ngIf="isRentals && columnVisible('Total Cost')" class="total-sum">{{ cos.formatCurrency(totalCostSum) }}</td>
          <td *ngIf="isRentals && columnVisible('Total Selling Cost')"class="total-sum-selling">{{ cos.formatCurrency(totalSellingCostSum) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

