<form [formGroup]="modalForm">

<app-section-modal [state]="state" [modalForm]="modalForm" [formData]="formData" (submittedValue)="submitForm($event)"
    [dialCode]="dialCode">

    <div class="col-6">
        <mat-form-field appearance="outline" class="d-block">
            <span matPrefix style="margin-left:-6px">
                <button mat-button [matMenuTriggerFor]="menu" (click)="resetDialCode()">
                    +{{representativeCell?.dial_code ? representativeCell?.dial_code : 1}}</button>
                <mat-menu #menu="matMenu" class="custom-menu">
                    <span class="countryCode">
                        <input type="text" #searchInput matInput name="searchCountry" placeholder="Search Country"
                            class="country-code-list-search" [(ngModel)]="searchCode" (input)="searchDialCode($event)"
                            [ngModelOptions]="{standalone: true}" />
                    </span>
                    <button mat-menu-item class="ml-1" *ngFor="let item of countryDetails"
                        (click)="getSelectedCountry(item)">
                        +{{item.dial_code}} {{item.name}}</button>
                </mat-menu>
            </span>
            <input matInput maxlength="15" placeholder="Enter Cell" (change)="repNumber($event)" [value]="repCell"
                type="text" appContactFormat>
        </mat-form-field>
    </div>

</app-section-modal>

</form>