<form [formGroup]="equipmentSupportForm">
  <div class="row">
    <div [ngClass]="fieldWidth ? 'col-4':'col-6'">
      <mat-form-field appearance="outline" class="d-block">
        <mat-label>Equipment Category</mat-label>
        <mat-select formControlName="category"
        class="ad-text-size"
        (selectionChange)="selectedValue($event.value,'category');"
          (openedChange)="triggerEvent($event,'category',categoryList)">
          <input matInput placeholder="Search" class="searchEffects ad-text-size" [(ngModel)]="searchRecord"
            (ngModelChange)="searchRecordName($event,'category',categoryList)"
            [ngModelOptions]="{standalone: true}">

          <mat-option (click)='count=0' 
          class="ad-text-size"
          *ngFor="let category of categoryList" value="{{category.key}},{{category.value}}" class="py-2"
          (click)="selectSingleEquipment('category')">
            {{category.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div [ngClass]="fieldWidth ? 'col-4':'col-6'" *ngIf="isSubCategory">
      <mat-form-field appearance="outline" class="d-block">
        <mat-label>Sub Category</mat-label>
        <mat-select formControlName="subCategory"
        class="ad-text-size"
        (openedChange)="triggerEvent($event,'subCategory',subCategories)"  (selectionChange)="selectedValue($event.value,'subCategory');">
          <input matInput placeholder="Search" class="searchEffects ad-text-size" [(ngModel)]="searchRecord"
            (ngModelChange)="searchRecordName($event,'subCategory',subCategories)"
            [ngModelOptions]="{standalone: true}">

          <div *ngFor="let item of subCategories">
            <mat-option (click)='count=0' value="{{item.key}},{{item.value}}" 
            class="ad-text-size"
            (click)="selectSingleEquipment('subCategory')">
              {{item.value
              }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div [ngClass]="fieldWidth ? 'col-4':'col-6'" *ngIf="isEquipment">
      <mat-form-field appearance="outline" class="d-block">
        <mat-label>Equipment Name*</mat-label>
        <mat-select formControlName="equipmentName" 
        class="ad-text-size"
        (openedChange)="triggerEvent($event,'equipmentName',equipmentList)" [multiple]="isSingleSelection?false:true">
          <input matInput placeholder="Search" class="searchEffects ad-text-size" [(ngModel)]="searchRecord"
            (ngModelChange)="searchRecordName($event,'equipmentName',equipmentList)"
            [ngModelOptions]="{standalone: true}">

          <div *ngFor="let item of equipmentList">
            <mat-option (click)='count=0' value="{{item.key}},{{item.value}}" (click)="selectSingleEquipment('equipmentName')"
            class="ad-text-size">
              {{item.value
              }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="d-flex flex-column">
<div *ngIf="isActionBtn">
<div class="d-flex mt-3 float-right mb-2" *ngIf="!nextTab">
  <button class="btn btn-primary" *ngIf="isMultiple" (click)="addMoreEquip()">
    Save Equipment
  </button>
  <button class="btn btn-primary" *ngIf="!isMultiple" (click)="SaveEquipments()">
    Save
  </button>
</div>

<div class="d-flex mt-3 float-right mb-2" *ngIf="nextTab">
  <button class="btn btn-primary mr-2" (click)="nextTabShow('BACK')">
    Back
  </button>
  <button class="btn btn-primary mr-2" (click)="addMoreEquip()">
    Save Equipment
  </button>
  <button class="btn btn-primary" (click)="nextTabShow('NEXT')">
    Next
  </button>
 
</div>
</div>

<div class="my-2 scrollType" *ngIf="addEquipments && addEquipments.length">
  <table class="table table-bordered" style="font-size:12px">
    <thead class="searchEffects">
      <th>Equipment Category</th>
      <th>Equipment Sub Category</th>
      <th>Equipment Name</th>
      <th>Action</th>
    </thead>
    <tbody *ngFor="let data of addEquipments;let i=index">
        <tr>
          <td>{{data.category ?this.cos.getCamelCaseValue(data.category):'N/A'}}</td>
          <td>{{data.subCategory ? data.subCategory:'N/A'}}</td>
          <td>{{data.name}}</td>
          <td>
            <div (click)="deleteAddedEquip(i)" class="btn-danger actnbtn"><i
                class="fa fa-trash-o" aria-hidden="true"></i> </div>
          </td>
        </tr>
    </tbody>
  </table>
</div>
</div>
