import { Component } from '@angular/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  expand = false;
  expandedMenu = null;

  toggleMenuItem(target: string): void {
    if (this.expandedMenu === target) {
      this.expandedMenu = null;
    } else {
      this.expandedMenu = target;
    }
  }
}
