import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges{
  @Input() isItemLimit = true;
  @Input() paginationDetail;
  @Input() pageSize = 10;
  @Output() bulkDeleteRecord = new EventEmitter();
  @Output() updatedList = new EventEmitter();
  selection = new SelectionModel(true, []);
  pageSizeOptions = this.cos.pageSizeOptions;
  deletedRecord = [];
  totalPages = 0;
  currentPage = 1;
  totalRecord = 0;
  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  length = 100;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cos: CommonService,
  ) { }

  ngOnChanges() {
    if (this.paginationDetail && this.paginationDetail.totalRecords) {
      this.pager = this.cos.getPager(Number(this.paginationDetail.totalRecords), Number(this.paginationDetail.totalPage));
      this.length = this.pager.totalItems;
      this.currentPage = Number(this.paginationDetail.currentPage);
      this.totalRecord = Number(this.paginationDetail.totalRecords);
      this.totalPages = Number(this.paginationDetail.totalPage);
      const urlResult = this.cos.getUrlParams();
      this.pageSize = urlResult[0] && urlResult[0].limit ? urlResult[0].limit : this.pageSize;
    }
  }

  // Function to show page number
  getPageRange(): number[] {
    const pagesToShow = 4; // Adjust the number of pages to display
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    const startPage = Math.max(1, this.currentPage - halfPagesToShow);
    const endPage = this.paginationDetail ? Math.min(this.paginationDetail.totalPage, startPage + pagesToShow - 1) : 1;
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  }

  // Function used to show dots after limit of page numbers
  shouldShowEllipsisBefore(): boolean {
    if (this.paginationDetail) {
      return this.currentPage < this.paginationDetail.totalPage - 3 && this.paginationDetail.totalPage > 5;
    }
  }

  // This function is used to set page limit and range
  async setPage(pageNumber: number, pageSize: string | number) {
    const record = pageNumber === 0 ? this.pageSize : pageNumber;
    let page: number;
    if (typeof pageSize === 'string') {
      switch (pageSize) {
        case 'previous':
          page = Number(this.paginationDetail.currentPage) - 1;
          break;
        case 'next':
          page = Number(this.paginationDetail.currentPage) + 1;
          break;
        case '':
          page = 1;
          break;
        default:
          page = Number(this.paginationDetail.currentPage);
          break;
      }
    } else {
      page = Number(pageSize);
    }

    const pageCount = Math.min(Math.max(page, 1), this.paginationDetail.totalPage);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: pageCount === 1 ? 1 : pageCount,
        limit: Number(record) === 10 ? 10 : Number(record),
      },
      queryParamsHandling: 'merge',
    });

    const payload = { page: pageCount, limit: Number(record) === 10 ? null : Number(record) };
    const pagination = { page: payload.page, limit: payload.limit || 10 };

    this.selection.clear();
    this.deletedRecord = [];
    this.bulkDeleteRecord.emit([]);
    this.updatedList.emit(pagination);
    this.pageSize = pageNumber === 0 ? this.pageSize : pageNumber;
  }


  // Function to show primary color to active page number
  activePageNumber(page: number) {
    return Number(page) === Number(this.paginationDetail?.currentPage);
  }

}
