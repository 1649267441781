<app-header></app-header>
<div id="wrapper">
  <div class="left side-menu">
    <app-sidebar></app-sidebar>
  </div>
  <div class="all-content-wrapper">
    <div class="sparkline13-list">
      <div class="sparkline13-hd">
       
        <ul class="nav nav-tabs modl-nabs my-2">
          <li><a routerLinkActive="active" data-toggle="tab" routerLink="/maintenance/list">R&M List</a></li>
          <li><a routerLinkActive="active" data-toggle="tab" routerLink="/maintenance/logs">Logs</a></li>
        </ul>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer></app-footer>
