import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";
import { AuthService } from "src/app/_services/auth.service";
import { CommonService } from "src/app/_services/common.service";
import { GroupService } from "src/app/_services/group.service";
import { ScheduleService } from "src/app/_services/schedule.service";
import { ScheduleDetail } from '../../../../interfaces/scheduler';
import { CrewAssigned } from '../../../../interfaces/common-interface';
declare const jquery;
import 'bootstrap';
import { FunctionsService } from "src/app/_common/functions.service";

@Component({
  selector: "app-schedule-emp-modal",
  templateUrl: "./schedule-emp-modal.component.html",
  styleUrls: ["./schedule-emp-modal.component.scss"],
})
export class ScheduleEmpModalComponent implements OnInit, OnChanges {
  get Dates() {
    return (this.addEmployeeForm.get("dateFormArr") as UntypedFormArray).controls;
  }

  get jobEmployees() {
    return (this.addEmployeeForm.get("empFormArr") as UntypedFormArray).controls;
  }

  get getDateFormArr() {
    return this.addEmployeeForm.get("dateFormArr") as UntypedFormArray;
  }

  get getEmpFormArr() {
    return this.addEmployeeForm.get("empFormArr") as UntypedFormArray;
  }

  scheduleArr(index: number) {
    return (
      (
        (this.addEmployeeForm.get("dateFormArr") as UntypedFormArray).controls[
        index
        ] as UntypedFormGroup
      ).get("scheduleArr") as UntypedFormArray
    ).controls;
  }

  scheduleArrForm(index: number) {
    return (
      (this.addEmployeeForm.get("dateFormArr") as UntypedFormArray).controls[
      index
      ] as UntypedFormGroup
    ).get("scheduleArr") as UntypedFormArray;
  }

  @Input() modalValue: ScheduleDetail;
  @Input() modalId = "empModal";
  @Input() type = "";
  projectEquipments = [];
  shiftArray = [
    { key: "First Shift", value: "FIRST_SHIFT" },
    { key: "Second Shift", value: "SECOND_SHIFT" },
    { key: "Third Shift", value: "THIRD_SHIFT" },
  ];
  addEmployeeForm = this.fb.group({
    startDate: [""],
    endDate: [""],
    type: [""],
    shift: ["FIRST_SHIFT"],
    _id: [""],
    dateFormArr: this.fb.array([
      this.fb.group({
        shift: [""],
        assigned: [""],
        employees: [""],
        group: [""],
        rigValue: [""],
        subCategory: [""],
        equipmentName: [""],
      }),
    ]),
    individualEquip: this.fb.array([]),
    subCategory: [""],
    equipmentName: [""],
    rigValue: [""],
  });
  panelOpenState = false;
  createDateForm(date: Date | null) {
    return this.fb.group({
      date: [date],
      equipment: [""],
      shift: ["FIRST_SHIFT"],
      employees: [""],
      group: [],
      assigned: [""],
      rigValue: [""],
      equipmentName: [""],
      subCategory: [""],
      scheduleArr: this.fb.array([]),
    });
  }
  jobTypeValue = [
    { key: "Individual", value: "INDIVIDUAL" },
    { key: "Combined", value: "COMBINED" },
  ];
  equipmentData = [];
  showEquipment = [false];
  isCombined = false;
  isIndividual = false;
  showCombinedEmployee = false;
  empIndOption = false;
  isEmployee = [false];
  showIndGroup = [false];
  showIndEmployee = [false];
  getEmployee = [];
  grpIndOption = [false];
  groupList = [];
  currentStartDate: string;
  currentEndDate: string;
  indvidualData = [];
  equipmentId = [];
  listModal = false;
  showCmbEmployee = false;
  showCmbGroup = false;
  shiftItem = [];
  ids = [];
  individualEmpName = [];
  shiftValues = [];
  groupEmp = [];
  showEquip = [false];
  showEquipName = [false];
  showAddBtn = [false];
  equipments = [];
  crewDetails: CrewAssigned;
  individualData = [];
  individualCrew = [];
  modalProperty = {
    header: 'Add Employee Schedule',
    id: 'empModal'
  };
  constructor(
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private authSrvc: AuthService,
    private scheduleSrvc: ScheduleService,
    private grpSrvc: GroupService,
    private commonFunction: FunctionsService
    
  ) { }

  ngOnInit() {
    this.getDates();
  }

  ngOnChanges() {
    this.scheduleSrvc.emitResetModal.subscribe(() => {
      this.addEmployeeForm.reset();
      this.projectEquipments = [];
    });

    if (this.modalValue && this.modalValue?.startDate) {
      this.currentEndDate = this.modalValue?.endDate;
      this.currentStartDate = this.modalValue?.startDate;
    }
    this.equipmentId = this.modalValue ? this.modalValue.equipmentId : [];
    if (this.equipmentId) {
      this.equipmentId = this.equipmentId.flat();
    }
    this.scheduleSrvc.emitChanges$.subscribe((data) => {
      if (data === "LIST") {
        this.individualData = [];
        this.addEmployeeForm.reset();
        this.individualCrew = [];
        this.empIndOption = false;
        this.showCmbEmployee = false;
        this.showCmbGroup = false;
        this.isIndividual = false;
        this.isCombined = false;
      }
    });
  }

  private getDates() {
    this.getDateFormArr.clear();
    const getDaysArray = function (startDate, endDate) {
      const arr = [];
      for (let dt = new Date(startDate);
        dt <= new Date(endDate);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };

    const daylist = getDaysArray(
      new Date(this.addEmployeeForm.value.startDate),
      new Date(this.addEmployeeForm.value.endDate)
    );

    daylist.forEach((date) => {
      const formatDate = date.toString();
      const formattedDate = formatDate.split(" ").slice(0, 4).join(" ");
      this.getDateFormArr.push(this.createDateForm(formattedDate));
    });
    return daylist;
  }

  // Function to handle crew assignment
  public typeChange(data) {
    this.listModal = false;
    this.showCombinedEmployee = false;
    this.empIndOption = false;
    this.showCmbGroup = false;
    this.showCmbEmployee = false;
    this.showEquipment = [false];
    this.showEquip = [false];
    this.showEquipName = [false];
    if (data === "COMBINED") {
      this.isIndividual = false;
      this.isCombined = true;
      this.showCombinedEmployee = false;
      if (
        this.addEmployeeForm.value.startDate &&
        this.addEmployeeForm.value.endDate
      ) {
        this.addEmployeeForm.patchValue({
          shift: "FIRST_SHIFT",
          equipment: "",
          equipmentName: "",
          rigValue: "",
          employees: "",
        });
        this.onEmployeesChange("FIRST_SHIFT", "combined", "date", "index");
      }
    } else {
      this.dateSelection();
      this.isCombined = false;
      this.equipmentData = [];
      if (
        this.addEmployeeForm.value.startDate &&
        this.addEmployeeForm.value.endDate
      ) {
        this.isIndividual = true;
      } else {
        this.isIndividual = false;
      }
    }
  }

  // Function to handle the date change functionality
  public async dateSelection() {
    if (
      this.addEmployeeForm.value.startDate &&
      this.addEmployeeForm.value.endDate
    ) {
      if (
        new Date(this.addEmployeeForm.value.startDate).valueOf() >
        new Date(this.addEmployeeForm.value.endDate).valueOf()
      ) {
        return this.cos.toastMessage(
          "End date should be equal or greater than start date", 'warning'
        );
      }
      this.showCombinedEmployee = false;
      if (this.addEmployeeForm.value.type === "INDIVIDUAL") {
        this.isIndividual = true;
        this.empIndOption = false;
        const datesLength = this.addEmployeeForm.value.dateFormArr.length;
        this.isEmployee = new Array(datesLength).fill(false);
        this.showIndGroup = new Array(datesLength).fill(false);
        this.showIndEmployee = new Array(datesLength).fill(false);
        const value = await this.getDates();
        this.isIndividual = true;
        for (let i = 0; i < value.length; i++) {
          this.onEmployeesChange("FIRST_SHIFT", "individual", value[i], i);
        }
      } else {
        this.isIndividual = false;
        this.isCombined = true;
        this.addEmployeeForm.patchValue({
          shift: "FIRST_SHIFT",
          equipment: "",
          equipmentName: "",
          rigValue: "",
          employees: "",
        });
        this.onEmployeesChange("FIRST_SHIFT", "combined", "date", "index");
      }
    }
  }

  public async onEmployeesChange(shift, scheduleType, date, index) {
    try {
      if (
        new Date(this.addEmployeeForm.value.startDate).valueOf() >
        new Date(this.addEmployeeForm.value.endDate).valueOf()
      ) {
        return this.cos.toastMessage(
          "End date should be equal or greater than start date", 'warning'
        );
      }
      this.shiftItem = shift;
      if (scheduleType === "combined") {
        this.empIndOption = true;
        this.addEmployeeForm.patchValue({
          assigned: " ",
          employees: "",
          group: " ",
        });
        await this.authSrvc.getActiveEmployee().toPromise().then((res) => {
          this.getEmployee = res.data;
        });
      } else {
        this.isEmployee[index] = true;
        const datesLength = this.addEmployeeForm.value.dateFormArr.length;
        this.showAddBtn = new Array(datesLength).fill(true);
        this.grpIndOption[index] = true;
  
        await this.authSrvc.getActiveEmployee().toPromise().then((res) => {
          this.getEmployee[index] = res.data;
        });
  
        await this.grpSrvc.getGroupList().toPromise().then((res) => {
          this.groupList[index] = res.data;
        });
      }
    } catch (error) {
      this.cos.toastMessage(error, 'error');
    }
  }

  private getcheckDateArray(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const arr = [];
    while (startDate <= endDate) {
      arr.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
    return arr;
  }

  // This function is used to check form validation and save form
  public submitForm() {
    if (!this.addEmployeeForm.value.type) {
      return this.cos.toastMessage("Please select crew assignment", 'warning');
    } else if (!this.addEmployeeForm.value.startDate) {
      return this.cos.toastMessage("Start date is required", 'warning');
    } else if (!this.addEmployeeForm.value.endDate) {
      return this.cos.toastMessage("End date is required", 'warning');
    } else if (
      new Date(this.addEmployeeForm.value.startDate).valueOf() >
      new Date(this.addEmployeeForm.value.endDate).valueOf()
    ) {
      return this.cos.toastMessage(
        "End date should be equal or greater than start date", 'warning'
      );
    } else {
      if (this.addEmployeeForm.value.type === "INDIVIDUAL") {
        this.addIndividualJob();
      } else {
        this.addCombinedJob();
      }
    }
  }

  // This function is used to save form in combined case
  private async addCombinedJob() {
    this.indvidualData.length = 0;
    const body = {
      schedulerId: this.modalValue.id,
      date: this.indvidualData,
    };
    const dateArrayValues = await this.getcheckDateArray(
      this.addEmployeeForm.value.startDate,
      this.addEmployeeForm.value.endDate
    );
    for (let i = 0; i < dateArrayValues.length; i++) {
      this.indvidualData.push({
        date: moment(dateArrayValues[i]).toISOString(),
        shift: this.addEmployeeForm.value.shift,
        equipments: this.equipments.length ? this.equipments.map(val => val.id ? val.id : val) : [],
        employees: this.crewDetails && this.crewDetails.name && this.crewDetails.name === "employee" && Array.isArray(this.crewDetails.ids)
          ? this.crewDetails.ids.flat() : [],
        groups: this.crewDetails && this.crewDetails.name && this.crewDetails.name === "group" && Array.isArray(this.crewDetails.ids)
          ? this.crewDetails.ids.flat() : [],
      });
    }
    this.submitData(body);
  }

  private getEmpGrp(date, values, type) {
    const id = [];
    values.map((val) => {
      id.push(val);
    });
    let body = [];
    if (
      Array.isArray(this.modalValue.scheduleInfo) &&
      this.modalValue.scheduleInfo.length
    ) {
      this.modalValue.scheduleInfo.map((val) => {
        if (this.type === "list") {
          if (jquery.isEmptyObject(val)) {
            return [...new Set(id.flat())];
          } else {
            if (val[date]) {
              val[date].map((item) => {
                if (item.assigned === type) {
                  body.push(item.id);
                }
              });
              body = body.concat(id);
            }
          }
        } else {
          if (jquery.isEmptyObject(val)) {
            return [...new Set(id.flat())];
          } else {
            if (val._id === date) {
              val[type].map((item) => {
                body.push(item._id);
              });
              body = body.concat(id);
            }
          }
        }
      });
      if (body.length) {
        return body.flat();
      } else {
        return [...new Set(id.flat())];
      }
    } else {
      return [...new Set(id.flat())];
    }
  }

  // This function is used to save form in individual case
  private async addIndividualJob() {
    const equipments = [];
    let crew = [];
    this.indvidualData.length = 0;
    this.individualEmpName.length = 0;
    this.shiftValues.length = 0;
    this.groupEmp = [];
    const dateArrayValues = await this.getcheckDateArray(
      this.addEmployeeForm.value.startDate,
      this.addEmployeeForm.value.endDate
    );
    const body = {
      schedulerId: this.modalValue.id,
      date: this.indvidualData,
    };
    this.addEmployeeForm.value.dateFormArr.map(async (item) => {
      if (
        Array.isArray(item.employees) ||
        Array.isArray(item.group) ||
        Array.isArray(this.equipmentData)
      ) {
        const drillerName = item.employees || [];
        const shiftValue = item.shift;
        const group = item.group || [];
        this.individualEmpName.push(drillerName);
        this.shiftValues.push(shiftValue);
        this.groupEmp.push(group);
      }
    });

    if (this.individualData.length) {
        const lastOccurrences = {}; // Store the last occurrence of each index

        for (let i = 0; i < this.individualData.length; i++) {
          const item = this.individualData[i];
          const index = item.index;

          // If the index is already in lastOccurrences, replace the existing entry
          lastOccurrences[index] = item;
        }

        // Convert the lastOccurrences object to an array of unique objects
        for (const index in lastOccurrences) {
          // eslint-disable-next-line
          if (lastOccurrences.hasOwnProperty(index)) {
            equipments.push(lastOccurrences[index]);
          }
        }
    }

    if (this.individualCrew) {
      crew = Object.values(
        [...this.individualCrew].reverse().reduce((acc, obj) => {
          const index = obj.index;
          if (!acc[index] || acc[index].index < obj.index) {
            acc[index] = { ...obj };
          } else if (acc[index].index === obj.index) {
            acc[index].value.ids = [
              ...new Set([...acc[index].value.ids, ...obj.value.ids])
            ];
            acc[index].value.name = obj.value.name;
          }
          return acc;
        }, {})
      );

    }

    for (let i = 0; i < dateArrayValues.length; i++) {
      if (
        this.individualEmpName.length || this.groupEmp.length ||
        (Array.isArray(this.equipmentData) && this.equipmentData[i].length > 0)
      ) {
        this.indvidualData.push({
          date: moment(dateArrayValues[i]).toISOString(),
          shift: this.shiftValues[i],
          equipments: equipments.length ? await this.getEquipmentId(equipments, i) : [],
          employees: crew.length ? await this.crewIds(crew, i, 'employee') : [],
          groups: crew.length ? await this.crewIds(crew, i, 'group') : [],
        });
      }
    }
    this.submitData(body);
  }

  // This function is used to call api to save form
  public submitData(body) {
    try {
      this.scheduleSrvc.updateEmployees(body).subscribe((res) => {
        if (res) {
          if (this.type === "scheduler") {
            this.scheduleSrvc.modalReset("SCHEDULE_DETAIL");
          } else {
            this.scheduleSrvc.modalReset("JOB_DETAILS");
          }
          this.commonFunction.modalClose(this.modalProperty);
  
          this.cos.toastMessage(res.message, 'success');
        } else {
          this.cos.toastMessage(res.message, 'warning');
        }
      }, (err) => { this.cos.handleError(err); });
    } catch (error) {
      this.cos.toastMessage(error, 'error');
    }
  }
  
  // This function is used to get the crew id for individual case
  private crewIds(crew, index: number, type: string) {
    const matchingObj = crew.find(obj => obj.index === index && obj.value.name === type);
    return matchingObj ? matchingObj.value.ids : [];

  }

  // This function is used to get the id of equipment for individual case
  private getEquipmentId(equipment, index: number) {
    if (Array.isArray(equipment) && equipment.length > 0 && equipment[0].equipments) {
      const indexEquipments = equipment.filter((obj) => obj.index === index);
      const indexEquipmentsIds =
        indexEquipments.length > 0
          ? indexEquipments[indexEquipments.length - 1].equipments.map(
            (equipment) => equipment.id
          )
          : [];
      return indexEquipmentsIds;
    } else {
      return equipment;
    }
  }

  // This function is used to get the employee
  public getAssignedData(data) {
    this.crewDetails = Array.isArray(data.value.ids) ? data.value : [];
    if (Array.isArray(data.value.ids)) {
      this.individualCrew.push(data);
      this.individualCrew = this.individualCrew.flat();
    }

  }

  // This function is used to get the bind equipment of employees
  public bindEmployeeEquip(data) {
    this.cos.getSelectedValue(data);
  }

  //This function is used to get equipments
  public addedEquipment(data) {
    this.equipments = Array.isArray(data) && data.length ? data : data.equipments;
    this.individualData.push(data);
    this.individualData = this.individualData.flat();
  }
}
