<div class="root-container">
  <div class="content-container mt-4" *ngIf="dataList.length">
    <p>{{ tableTitle }}</p>
    <div class="list-container">
      <div *ngFor="let item of dataList; let i = index">
        <div class="list-item first-item">
          <span *ngIf="!isDivisionOfWaterResources">{{ item.value }}</span>
          <span *ngIf="isDivisionOfWaterResources">{{ item.name }}</span> 

          <mat-radio-group
            [formControl]="form.get('responseField_' + i)"
            class="d-flex justify-content-between"
          >
            <mat-radio-button
            (change)="onItemChange(choice.id, i)"
            [checked]="isDivisionOfWaterResources
              ? item.status === choice.id
              : form.get('responseField_' + i).value === choice.id"
            class="radio-button ad-text-size"
            *ngFor="let choice of choices"
            [value]="choice.id"
          >
            {{ choice.tag }}
          </mat-radio-button>        
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
