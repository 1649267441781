<!-- Root Container -->
<div class="my-2 tableEffect">
  <!-- Table -->
  <table class="table">
    <!-- Table Header -->
    <thead class="thead-light fixedHeading pl-2 ad-text-size">
      
      <!-- Table Title-->
      <p class="ad-text-size theme-text-color font-weight-bold">
        {{ tableTitle }}
      </p>

      <!-- Table Columns -->
      <tr>
        <th *ngFor="let column of columns">
          {{ column.name }}
        </th>
      </tr>
    </thead>

    <tbody class="ad-text-size">
      <!-- Table Body Container -->
      <ng-container>
        <tr
          *ngFor="let data of tableData"
          (change)="inputEventHandler($event, data)"
        >
          <!-- Table Content Name -->
          <td class="content-text">
            {{ data?.value }}
          </td>

          <td class="content-text">
            {{ data?.cost }}
          </td>

          <td class="content-text">
            {{ data?.quantity }}
          </td>

          <td class="content-text">
            {{ data?.totalCost }}
          </td>
        </tr>
      </ng-container>

      <!-- Total Cost Container -->
      <ng-container class = " totalCost ">
        <tr *ngIf="totalCost">
          <td class="total-text">Total Cost</td>
          <td></td>
          <td></td>
          <td>{{ totalCost }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
