
          <app-ad-common-modal [modalProperty]="modalProperty">
          <p class="text-center theme-text-color font-weight-bold">INSTRUCTION</p>
          <div class="mb-3" style="line-height:1.8">
            1. Please upload the valid format<br>
            2. File should not be more than <b>35MB</b><br>
            3. File's Header should be like sample file's header<br>
            4. Any change in the file's header will not verify the document<br>
            <ng-container *ngIf="uploadedData[0]?.instruction">
            <div *ngFor="let data of uploadedData[0]?.instruction;let i = index">
             {{i+5}}.  {{data}}
            </div>
          </ng-container>
          </div>
          <div class="d-flex justify-content-between">
            <div  style="margin-top:0.3rem">
              <button (click)="submitForm('sample')" class="btn btn-custon-four btn-success" style="height:2.5rem">
                Download Sample <i class="fa fa-download" aria-hidden="true"></i></button>
            </div>
            <div class="d-flex">
              <div *ngIf="uploadButton" class="upload">
                <app-upload-file (uploadImage)="upload($event)" [uploadedImages]="passImages()"
                  (removeImage)="removeImage($event)">
                </app-upload-file>
              </div>
                         
                <button type="button" *ngIf="saveButton" class="ml-3 mt-1 btn btn-custon-four btn-success"
                style="height:2.7rem" (click)="submitForm('save')">
                Save</button>
            </div>
  
          </div>
        </app-ad-common-modal>