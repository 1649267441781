<button  
   type="button" 
   appAccessControl 
   permission="{{buttonProperty.permission}}"
   class="btn btn-custon-four btn-success text-size" 
   (click)="handleButtonClick(buttonProperty)"
   [ngStyle]="{ 'margin-right.px': buttonProperty['margin-right'] }">

   <i *ngIf="buttonProperty.icon" [ngClass]="buttonProperty.icon"></i>
   {{buttonProperty.text}}

</button>