<div class="root-container">
  <div class="content-container mt-4">
    <p>{{ tableTitle }}</p>
    <div class="list-container">
      <mat-radio-group class="d-flex justify-content-between" [(ngModel)]="selectedValue" (ngModelChange)="onValueChange($event)">
        <mat-radio-button class="radio-button ad-text-size" *ngFor="let choice of choices" [value]="choice.id">
          {{ choice.tag }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
