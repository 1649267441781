<div class="modal common-modal" id="addModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalType }}</h5>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
  
        <div class="modal-body padding-none">
          <ul class="nav nav-tabs modl-nabs pl-2">
            <li>
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'basic-detail' }"
                id="details_tab"
                (click)="activeTab = 'basic-detail'"
                style="pointer-events: none"
                >Basic Details</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'documents' }"
                id="doc_tab"
                (click)="activeTab = 'documents'"
                style="pointer-events: none"
                >Documents</a
              >
            </li>
          </ul>
          <div class="tab-content modal-form-part">
            <div
              class="tab-pane project-form-common"
              [ngClass]="{ active: activeTab === 'basic-detail' }"
              id="basic-detail"
            >
              <app-section-modal
                [state]="state"
                [modalForm]="repairForm"
                [formData]="formData"
                (submittedValue)="saveBasicDetail($event)"
                (selectedValue)="selectedValue($event)"
                [fieldWidth]=true
              ></app-section-modal>
            </div>
            <div
              class="tab-pane"
              [ngClass]="{ active: activeTab === 'documents' }"
              id="documents"
            >
              <app-upload-documents
                [documentBtn]="documentBtn"
                (docsData)="submitForm($event)"
                [patchData]="patchDocument"
                (submittedDocs)="submittedDocs($event)"
              ></app-upload-documents>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  