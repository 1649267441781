import { CommonService } from 'src/app/_services/common.service';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { NgxSpinnerService } from 'ngx-spinner';
import { VariableService } from '../_common/variable.service';
import { response } from '../enum/message';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    public commonVariable: VariableService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    this.commonVariable.apiCount++;
    if (!navigator.onLine) {
      this.cos.toastMessage(response.en.CONNECT_INTERNET, 'error');
      return throwError(response.en.NO_INTERNET);
    }
    const currentUser = JSON.parse(localStorage.getItem('authen@User'));

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    return next.handle(request).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.status) {
            this.spinner.show();
            this.commonVariable.apiCount--;
            /**
             * Owner: Meenakshi Devi
             * Description: SetTimeout is used in case api send 400 in each case so after some time loader will stop
             */
            setTimeout(() => {
              if (this.commonVariable.apiCount === 1 || this.commonVariable.apiCount < 0) {
                this.spinner.hide();
                this.commonVariable.apiCount = 0;
              }
            }, 10000);
            if (this.commonVariable.apiCount === 0) {
              this.spinner.hide();
            }
          }
        } else {
          this.spinner.show();
        }
      })
    );
  }

}
