import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ad-button',
  templateUrl: './ad-button.component.html',
  styleUrls: ['./ad-button.component.scss']
})
export class AdButtonComponent {
@Input() buttonProperty: {
  text: string,
  icon: string,
  'margin-right': number,
  permission?: '',
};
@Output() buttonHandler = new EventEmitter();

// Function to send the handler when you click on button
  handleButtonClick(data) {
    this.buttonHandler.emit(data);
  }
}
