import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonService } from "src/app/_services/common.service";
import { VariableService } from "src/app/_common/variable.service";

@Component({
  selector: "app-radio-table",
  templateUrl: "./radio-table.component.html",
  styleUrls: ["./radio-table.component.scss"],
})
export class RadioTableComponent {
  /** Property to hold table title */
  @Input() tableTitle = "Title";

  /** Property to hold data list */
  @Input() dataList: any[] = []; // Example data

  /** Flag to identify if the component is handling Division of Water Resources */
  @Input() isDivisionOfWaterResources = false;

  /** Flag to include the 'Unknown' option */
  @Input() includeUnknown = false;

  /** Property to hold choices list */
  @Input() choices = [
    { id: "is_yes", tag: "Yes" },
    { id: "is_no", tag: "No" },
  ];

  @Input() isMultiSelect = false;
  @Output() updatedList = new EventEmitter();
  @Output() clickEvent = new EventEmitter();
  form: FormGroup;

  constructor(
    public cos: CommonService,
    public commonVariable: VariableService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    // Dynamically add 'Unknown' option if includeUnknown is true
    if (this.includeUnknown) {
      this.choices.push({ id: "is_unknown", tag: "Unknown" });
    }
  
    this.form = this.fb.group({});

    this.dataList.forEach((item, index) => {

      let isSelected = false;
      
      if (this.isDivisionOfWaterResources) {
        // Use 'value' directly for the specific case
        isSelected = item.value === true;
      } else {
        // Generic behavior for other components
        isSelected = item.status === item.key;
      } 

      this.form.addControl(
        `responseField_${index}`,
        this.fb.control(isSelected ? "is_yes" : "is_no")
      );

    });
  }


  /**
  * Radio Button Click Event
  */
  onItemChange(choiceId: string, index: number) {
    if (!this.isMultiSelect) {
      // Reset all to 'No' if not in multi-select mode
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].setValue("is_no");
      });
    }

    // Toggle the selected item
    const currentValue = this.form.controls[`responseField_${index}`].value;
    if (currentValue === choiceId) {
      // If the same choice is selected again, deselect it
      this.form.controls[`responseField_${index}`].setValue("is_no");
    } else {
      // Select the choice
      this.form.controls[`responseField_${index}`].setValue(choiceId);
    }

    const controlName = `responseField_${index}`;

   // Handle the special case for `includeUnknown` (Unknown status)
  if (this.includeUnknown) {
    const selectedValue = this.form.controls[controlName].value;
    const payload = [];

    // Loop through all the items and check if they are selected as 'is_yes', 'is_no', or 'is_unknown'
    this.dataList.forEach((item, i) => {
      const currentItemValue = this.form.controls[`responseField_${i}`].value;

      if (
        currentItemValue === "is_yes" ||
        currentItemValue === "is_no" ||
        currentItemValue === "is_unknown"
      ) {
        // Add the item to the payload array with its corresponding status
        payload.push({
          value: item.key, // Use 'key' as 'value'
          status:
            currentItemValue === "is_yes"
              ? "YES"
              : currentItemValue === "is_no"
              ? "NO"
              : "UNKNOWN", // Set status
        });
      }
    });

    this.clickEvent.emit(payload); // Emit the array of selected items with their statuses
  } else {
      // Default behavior (No changes to multi-select or non-multi-select cases)
      if (this.isMultiSelect) {
        const selectedItems = this.dataList
          .filter((item, i) => this.form.controls[`responseField_${i}`].value === "is_yes");
        this.clickEvent.emit(selectedItems.map((data)=>{return data?.key}));
      } else {
        // Emit only the selected item if not in multi-select mode
        // const selectedItem = this.dataList[index];
        const selectedValue = this.form.controls[controlName].value;
        const selectedItem = (selectedValue === 'is_yes') ? this.dataList[index] : null
        this.clickEvent.emit(selectedItem);
      }
    }
  }

  
}

