import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../interfaces/common-interface';
import { Activity, RepairList, RepairResponse, RepairLogs, RecordResponse } from '../interfaces/maintenance';
@Injectable({
  providedIn: 'root'
})
export class RepairMaintenanceService {
  REPAIR = 'aggregation/repair-maintenance/list';
  REPAIR_CRUD = 'aggregation/repair-maintenance';
  REPAIR_LOG = 'aggregation/activity-logs';
  ALL_LOGS = 'aggregation/repair-maintenance/logs';

  constructor(private http: HttpClient) { }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue$ = new BehaviorSubject<any>([]);

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterChild$ = new BehaviorSubject<any>([]);


  getFilterChild(child){
    this.filterChild$.next(child)
  }

  getRepairList(search) {
    return this.http.get<RepairList>(`${environment.BASE_URL}${this.REPAIR}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&status=${JSON.stringify(search.status)}&search=${search.search}&prioritySort=${search.prioritySort}&equipmentSort=${search.equipmentSort}`)
  }

  addRequest(data) {
    return this.http.post<RepairResponse>(`${environment.BASE_URL}${this.REPAIR_CRUD}`, data);
  }

  updateRequest(data) {
    return this.http.put<RepairResponse>(`${environment.BASE_URL}${this.REPAIR_CRUD}`, data);
  }

  deleteRepair(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.REPAIR_CRUD, options);

  }

  getDetails(id: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<RecordResponse>(`${environment.BASE_URL}${this.REPAIR_CRUD}?id=${id}`);
  }

  getRepairLogs(id: string) {
    return this.http.get<Activity>(`${environment.BASE_URL}${this.REPAIR_LOG}?type=REPAIR_MAINTENANCE&id=${id}`);
  }

  getAllLogs(search) {
    return this.http.get<RepairLogs>(`${environment.BASE_URL}${this.ALL_LOGS}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}`)
  }
}
