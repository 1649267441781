
<div class="root-container">
  <div class="table-responsive my-2 tableEffect">
    <table class="table rounded-3">
      <thead class="thead bg-header fixedHeading pl-2 ad-text-size">
        <tr>
          <th *ngIf="isOnlyConsumables"></th>
          <th *ngFor="let column of columns">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody class="ad-text-size">
        <tr *ngFor="let row of orderList; let i = index">
           <!-- Checkbox column, only visible when isOnlyConsumables is true -->
           <td *ngIf="isOnlyConsumables" class="checkbox-column">
            <label class="checkbox-container">
              <input
                type="checkbox"
                [(ngModel)]="row.isSelected"
                (change)="onCheckboxChange(row.isSelected)"
              />
              <span class="checkmark"></span>
            </label>
          </td>          
          <th scope="row">{{ row.item }}</th>
          <td *ngIf="columnVisible('Quantity')">
            <input
              min="0"
              matInput
              class="ad-text-size text-center input-field"
              type="number"
              [(ngModel)]="row.quantity"
              (input)="calculateRowTotal(row); calculateTotal()"  
              maxlength="3"
            />
          </td>
          <td *ngIf="columnVisible('Cost')">
            <input
              min="0"
              matInput
              class="ad-text-size text-right-cost input-field"
              type="text"  
              [ngModel]="cos.formatCurrency(row.cost)"  
              (ngModelChange)="onCostChange($event)" 
              (input)="calculateRowTotal(row); calculateTotal()"
              maxlength="3"
              [disabled]="true"
            />

          <td *ngIf="columnVisible('Selling Cost')">
            <input
              min="0"
              matInput
              class="ad-text-size text-right-cost input-field"
              type="text" 
              [ngModel]="cos.formatCurrency(row.sellingCost)"  
              (ngModelChange)="onSellingCostChange($event)"
              (input)="calculateRowTotal(row); calculateTotal()"
              maxlength="3"
              [disabled]="true"
            />
          </td>      
          <td *ngIf="columnVisible('Unit')">
          <!-- Dynamically binding the unit options for each row -->
          <select class="ad-text-size text-center dropdown" [(ngModel)]="row.selectedUnit" (change)="onUnitChange(row)">
            <option *ngFor="let option of row.options" [value]="option">{{ option }}</option>
          </select>
          </td>

          <!-- Markup Percentage Column -->
          <td *ngIf="columnVisible('Markup Percentage')">
            <input
            min="0"
            matInput
            class="ad-text-size text-right-cost input-field"
            type="text"
            [ngModel]="row.markupPercentage ? row.markupPercentage + '%' : ''"  
            (ngModelChange)="onMarkupPercentageInputChange($event, row)" 
            (input)="calculateRowTotal(row); calculateTotal()"
            maxlength="4" 
          />          
          </td>    

          <td *ngIf="isRentals && columnVisible('Unit Quantity')">
            <input min="0" matInput class="ad-text-size text-center input-field" type="number"
              [(ngModel)]="row.unitQuantity" maxlength="3" />
          </td>
          <td *ngIf="isRentals && columnVisible('Fuel Required')">
            <mat-slide-toggle [(ngModel)]="row.isFuelRequired" (change)="onToggleChange($event, i)"></mat-slide-toggle>
          </td>
          <td class="text-align" *ngIf="(markupDisplayPercentage || isConsumables) && columnVisible('Total Cost')">{{ cos.formatCurrency(row.totalCost) }}</td>
          <td class="text-align" *ngIf="(markupDisplayPercentage || isConsumables) && columnVisible('Total Selling Cost')">{{ cos.formatCurrency(row.totalSellingCost) }}</td>

          <td class="text-align" *ngIf="isRentals && columnVisible('Total Cost')">{{ cos.formatCurrency(row.totalCost) }}</td>
          <td class="text-align" *ngIf="isRentals && columnVisible('Total Selling Cost')">{{ cos.formatCurrency(row.totalSellingCost) }}</td>
        </tr>

        <tr class="row-bg">
          <th scope="row">Total</th>
          <td *ngIf="columnVisible('Quantity')"></td>
          <td *ngIf="columnVisible('Cost')"></td>
          <td *ngIf="columnVisible('Selling Cost')"></td>
          <td *ngIf="columnVisible('Unit')"></td>
          <td *ngIf="isRentals && columnVisible('Unit Quantity')"></td>
          <td *ngIf="isRentals && columnVisible('Fuel Required')"></td>
          <td *ngIf="markupDisplayPercentage"></td>
          <td *ngIf="markupDisplayPercentage && columnVisible('Total Cost')" class="text-right">{{ totalCostSum }}</td>
          <td *ngIf="markupDisplayPercentage && columnVisible('Total Selling Cost')"class="text-right">{{ totalSellingCostSum }}</td>
          <td *ngIf="isConsumables && columnVisible('Total Cost')" class="text-right">{{ totalCostSum }}</td>
          <td *ngIf="isConsumables && columnVisible('Total Selling Cost')"class="text-right">{{ totalSellingCostSum }}</td>

          <td *ngIf="isRentals && columnVisible('Total Cost')" class="total-sum">{{ totalCostSum }}</td>
          <td *ngIf="isRentals && columnVisible('Total Selling Cost')"class="total-sum-selling">{{ totalSellingCostSum }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>

