import {Injectable} from "@angular/core";
import * as moment from "moment";
import {BehaviorSubject, throwError} from "rxjs";
import Swal, {SweetAlertOptions} from "sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {PermissionsResponse} from "../interfaces/common-interface";
import {environment} from "src/environments/environment";
import {response} from "../enum/message";

declare const jQuery;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  taskAllDetails = [
    {key: "name", value: "Name"},
    {key: "startDate", value: "Start Date"},
    {key: "endDate", value: "End Date"},
    {key: "status", value: "Status"},
    {key: "priority", value: "Priority"},
    {key: "note", value: "Note"},
    {key: "reOccurringType", value: "Re-Occurring Type"},
    {key: "reOccurringOffsetDate", value: "Re-Occurring Offset Date"},
    {key: "emailReminder", value: "Email Reminder"},
  ];
  status = [
    {value: "Pending", key: "PENDING"},
    {value: "Approved", key: "APPROVED"},
    {value: "Verification Rejected", key: "REJECTED"},
    {value: "Closed", key: "CLOSED"},
  ];
  priorityValue = [
    {key: "LOW", value: "Low"},
    {key: "MEDIUM", value: "Medium"},
    {key: "HIGH", value: "High"},
  ];

  statusValue = [
    {key: "OPEN", value: "Open"},
    {key: "IN-PROGRESS", value: "In-Progress"},
    {key: "COMPLETED", value: "Completed"},
  ];

  uploadType = [
    {key: "", value: "Clear"},
    {key: "DOCUMENT", value: "Document"},
    {key: "LINK", value: "Link"},
  ];

  uploadDoc = [{key: "DOCUMENT", value: "Document"}];

  emailReminder = [
    {key: "ONE_DAY", value: "One Day"},
    {key: "TWO_DAY", value: "Two Day"},
    {key: "ONE_WEEK", value: "One Week"},
    {key: "BI_WEEKLY", value: "Biweekly"},
    {key: "ONE_MONTH", value: "One Month"},
    {key: "ANNUAL", value: "Annual"},
    {key: "BIENNIAL", value: "Biennial"},
  ];

  crewAssignment = [
    {key: "GROUP", value: "Group"},
    {key: "INDIVIDUAL", value: "Employees"},
  ];
  shiftArray = [
    {value: "First Shift", key: "FIRST_SHIFT"},
    {value: "Second Shift", key: "SECOND_SHIFT"},
    {value: "Third Shift", key: "THIRD_SHIFT"},
  ];

  unitArray =  [
    {value: "Each", key: "EACH"},
    {value: "Day(s)", key: "DAY(s)"},
    {value: "Week", key: "WEEK"},
    {value: "Month", key: "MONTH"},
    {value: "Bag(s)", key: "BAG(s)"},
    {value: "Feet", key: "FEET"},
    {value: "Hours", key: "HOURS"},
    {value: "Lump", key: "LUMP"},
    {value: "Miles", key: "MILES"},
    {value: "Pail", key: "PAIL"},
    {value: "Shift", key: "SHIFT"},
  ]

  // Pattern for https://abc.example.com link format
  pattern = new RegExp("^(http|https)://", "i");

  // Pattern for (123) 4567-890 cell format
  cellPattern = /^\(\d{3}\) \d{3}-\d{4}$/;

  //pagination data start
  pageSizeOptions: number[] = [5, 10, 25, 100];
  rolePermission = "api/role/fetch-permissions";

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
  ) {
  }

  // This function is used to reset document
  resetDoc$ = new BehaviorSubject<string>("");

  resetDocTable(reset) {
    this.resetDoc$.next(reset);
  }

  selectValue$ = new BehaviorSubject([]);

  getSelectedValue(select) {
    this.selectValue$.next(select);
  }

  selectedValue$ = new BehaviorSubject([]);

  getReturnData(data) {
    this.selectedValue$.next(data);
  }

  documentValue$ = new BehaviorSubject([]);

  getResetForm(data) {
    this.documentValue$.next(data);
  }

  resetField$ = new BehaviorSubject([]);

  getModalValue(data) {
    this.resetField$.next(data);
  }

  bulkDelete$ = new BehaviorSubject([]);

  bulkDeleteIds(data) {
    this.bulkDelete$.next(data);
  }

  latLng: BehaviorSubject<[number, number]> = new BehaviorSubject([0, 0]);

  setLatLng(lat: number, lng: number) {
    this.latLng.next([lat, lng]);
  }

  // Service to get account details and permissions
  getPermissions() {
    return this.http.get<PermissionsResponse>(
      `${environment.BASE_URL}${this.rolePermission}`
    );
  }

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: [],
  };

  //pagination data end

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Toast: any = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
      toast.style.backgroundColor = this.backgroundColor
        ? this.backgroundColor
        : "white";
      toast.style.border = `1px solid ${this.borderColor}`;
      toast.style.boxShadow = "none"; // Remove box shadow
      toast.style.color = this.textColor;
      toast.style.fontFamily =
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

      // Create a close button (cross icon) element
      const closeButton = document.createElement("button");
      closeButton.classList.add("swal2-close");
      closeButton.innerHTML = "&times;";

      // Change the color of the close button
      closeButton.style.position = "absolute";
      closeButton.style.top = "0px";
      closeButton.style.cursor = "pointer";
      closeButton.style.color = this.textColor;
      closeButton.style.fontSize = "25px";
      closeButton.style.height = "1.2em";

      // Add a click event listener to the close button
      closeButton.addEventListener("click", () => {
        Swal.close();
        // Close the toast
      });
      // Append the close button to the toast
      toast.appendChild(closeButton);
    },
  });

  toastMessage(message: string, type: string) {
    if (type === "success") {
      this.backgroundColor = "#C8E6C9";
      this.textColor = "#224a23";
      this.borderColor = "#C8E6C9"; //439446
    } else if (type === "error") {
      this.textColor = "#73000c";
      this.borderColor = "#FFCDD2"; //e60017
      this.backgroundColor = "#FFCDD2";
    } else {
      this.textColor = "#6d5100";
      this.borderColor = "#FFECB3"; //d9a300
      this.backgroundColor = "#FFECB3";
    }
    return this.Toast.fire({
      title: message,
    });
  }

  getPager(totalItems, currentPage = 1, pageSize = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  // Get date in MM/default/YYYY format
 getFormattedDate(data: string) {
    if (data) {
      const isDateFormat = JSON.parse(localStorage.getItem('adminDateFormat'));
      const dateFormat = isDateFormat && isDateFormat.value ? isDateFormat.value : 'MM/DD/YYYY'
      return moment(new Date(data)).format(dateFormat);
    } else {
      return "N/A";
    }
  }

   // Get date in MM/default/YYYY format
   getFormatDate(data: string): string {
    if (data) {
      const isDateFormat = JSON.parse(localStorage.getItem('adminDateFormat'));
      const dateFormat = isDateFormat && isDateFormat.value ? isDateFormat.value : 'MM/DD/YYYY';
  
      // Split data by dash if it's a date range
      const dates = data.split(' - ');
  
      if (dates.length === 2) {
        // Handle date range
        const [startDate, endDate] = dates;
        return `${moment(new Date(startDate)).format(dateFormat)} - ${moment(new Date(endDate)).format(dateFormat)}`;
      } else {
        // Handle single date
        return moment(new Date(data)).format(dateFormat);
      }
    } else {
      return "N/A";
    }
  }  

  getFormattedDateWithDay(date: string | Date): string {
    if (!date) return '';
  
    const dateObj = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',   
      day: 'numeric',   
      weekday: 'short'  
    };
  
    const formattedDate = dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    const shortWeekDay = dateObj.toLocaleDateString('en-US', { weekday: 'short' });
  
    return `${formattedDate}   ${shortWeekDay}`;
  }
  
  
  // Get date in default format by subtracting the day by one
  getCheckDate(date) {
    if (date) {
      const filterDate = moment(date).subtract(1, "d").toDate();
      const isDateFormat = JSON.parse(localStorage.getItem('adminDateFormat'));
      const dateFormat = isDateFormat && isDateFormat.value ? isDateFormat.value : 'MM/DD/YYYY'
      return moment(filterDate).format(dateFormat);
    } else {
      return "N/A";
    }
  }

  // Get camel case value
  getCamelCaseValue(value) {
    if (value && typeof value === 'string') {
      if (value.includes("_")) {
        const replace = value.replace(/_/g, " ");
        const camelCaseValue = replace
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
        return camelCaseValue;
      } else {
        const camelCaseValue = value
          .split(" ")
          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
        return camelCaseValue.replace(/-/g, " ");
      }
    } else {
      return "N/A";
    }
  }

  // Get camel case value without converting underscores to spaces
  getCamelCaseSpaceValue(value) {
    if (value && typeof value === 'string') {
      // Replace underscores with spaces
      const replace = value.replace(/_/g, " ");
  
      // Convert to Camel Case, but do not replace dashes
      const camelCaseValue = replace
        .split(" ")
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
  
      return camelCaseValue;
    } else {
      return "N/A";
    }
  }
  
  // This functionality remove record from array of objects(arr) based on given id(value) (id variable must be present on array)
  removeObjectWithId(arr: { id: number }[], id: number): { id: number }[] {
    const arrCopy = [...arr];
    const objWithIdIndex = arrCopy.findIndex(
      (obj) => obj.id.toString() === id.toString()
    );
    if (objWithIdIndex < 0) {
      return arrCopy;
    } else {
      arrCopy.splice(objWithIdIndex, 1);
      return arrCopy;
    }
  }

  // This functionality remove object from array of objects(list) based on given ids(deleteId)
  deleteObjectsById(list, deleteId) {
    if (Array.isArray(list) && Array.isArray(deleteId)) {
      for (const idToDelete of deleteId) {
        const index = list.findIndex((item) => item.id === idToDelete);
        if (index !== -1) {
          list.splice(index, 1);
        }
      }
      return list;
    }
  }

  selectOption(value) {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    if (value === "week") {
      const first = new Date().getDate() - new Date().getDay();
      const last = first + 6;
      return [
        new Date(new Date().setDate(first)).toISOString(),
        new Date(new Date().setDate(last)).toISOString(),
      ];
    } else if (value === "month") {
      return [
        new Date(year, month, 1).toISOString(),
        new Date(year, month + 1, 0).toISOString(),
      ];
    } else if (value === "today") {
      return [
        moment(new Date().toDateString()).toISOString(),
        moment(new Date().toDateString()).toISOString(),
      ];
    } else {
      return [
        new Date(new Date().getFullYear(), 0, 1).toISOString(),
        new Date(new Date().getFullYear(), 11, 31).toISOString(),
      ];
    }
  }

  // this functionality remove the duplicate item from array
  removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // This functionality remove record from array of objects(arr) based on given id(value)
  removeSameItem(arr, value) {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  // get Time
  getTime(data) {
    try {
      if (data) {
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const currentDate = this.getFormattedDate(new Date().toISOString());
        const givenDate = this.getFormattedDate(new Date(data).toISOString());
        const newHour = new Date().getHours();
        const newMinute = new Date().getMinutes();
        if (currentDate === givenDate) {
          const currentHour = new Date(data).getHours();
          const currentMinute = new Date(data).getMinutes();
          if (newHour - currentHour === 0) {
            if (newMinute - currentMinute === 0) {
              return "Just Now";
            } else {
              return currentMinute + " " + "minute's ago";
            }
          } else if (currentHour === 0) {
            return currentMinute + " " + "minute's ago";
          } else if (currentHour > 0 && currentHour > 12) {
            return new Date().getHours() - currentHour + " " + "hour ago";
          } else {
            return currentHour + " " + "hour's ago";
          }
        } else if (currentDate > givenDate) {
          const dayDiff =
            new Date(currentDate).getDate() - new Date(givenDate).getDate();
          if (dayDiff === 1) {
            return "Yesterday";
          } else if (dayDiff > 1 && dayDiff < 7) {
            return "Last" + " " + days[new Date(givenDate).getDay()];
          } else {
            return "At" + " " + new Date(data).toLocaleTimeString();
          }
        } else {
          return "At" + " " + new Date(data).toLocaleTimeString();
        }
      } else {
        return "N/A";
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  //get url prams
  getUrlParams() {
    let data;
    this.route.queryParams.subscribe((value) => {
      data = value;
    });
    if (jQuery.isEmptyObject(data)) {
      return [];
    } else {
      if (data.page || data.search || data.limit) {
        return [data];
      } else {
        return [];
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteText: any = {
    title: "Are you sure?",
    text: "You will not be able to recover this data!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: response.en.DELETE,
    cancelButtonText: response.en.NO,
  };

  bulkDeleteMessage(ids: number): SweetAlertOptions {
    const record = ids > 1 ? "records" : "record";
    return {
      title: "Are you sure?",
      text:
        "Do you really want to delete" +
        " " +
        ids +
        " " +
        record +
        ". You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: response.en.DELETE,
      cancelButtonText: response.en.NO,
    };
  }

  openUrl(element) {
    const document = element.url ? element.url : element.link ? element.link : element.value;
    window.open(document);
  }

  goToMap(gps) {
    if (Array.isArray(gps)) {
      window.open(
        `https://www.google.co.in/maps/dir/?saddr=&daddr=${gps[1]},${gps[0]}&directionsmode=driving`
      );
    } else {
      window.open(gps);
    }
  }

  //
  verifyContact(event) {
    if (event) {
      const isContact = event.charCode;
      return (
        (isContact > 64 && isContact < 91) ||
        (isContact > 96 && isContact != 101 && isContact < 123) ||
        isContact == 8 ||
        isContact == 32 ||
        (isContact >= 48 && isContact <= 57)
      );
    }
  }

  // email verification
  validateEmail(email: string): boolean {
    if (typeof email !== "string") {
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email)
  }

  // password verification
  isValidPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  downloadModule(src: string) {
    const link = document.createElement("a");
    link.href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      src;
    link.download = "SampleFile";
    link.click();
  }

  downloadModulePdf(src: string, name: string) {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + src;
    link.download = name ?? "SampleFile";
    link.click();
  }

  // number validation without special character but decimal number
  isNumber(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode || event.which);
    const pattern = /^\d+$/; // Allow only digits (whole numbers)

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  compareDates(actualDate, date1, date2) {
    // Extract day, month, and year from date1
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    // Extract day, month, and year from date2
    const day2 = date2.getDate();
    const month2 = date2.getMonth();
    const year2 = date2.getFullYear();

    if (year1 === year2) {
      if (month1 === month2) {
        const difference = Math.abs(day1 - day2);
        return moment(actualDate).add(difference, "d").toDate();
      } else {
        const nextMonthDate = new Date(year1, month1 + 1, 1);

        // Subtract 1 day from the next month's first day
        const lastDayOfMonth = new Date(nextMonthDate.getTime() - 1);
        const totalDays = lastDayOfMonth.getDate();
        const dateDifference = Math.abs(totalDays - day1) + day2;
        return moment(actualDate).add(dateDifference, "d").toDate();
      }
    }
  }

  dateFormat(inputDate) {
    if (inputDate) {
      const year = inputDate.getUTCFullYear();
      const month = inputDate.getUTCMonth() + 1; // Months are zero-based, so add 1
      const day = inputDate.getUTCDate();
      const hours = 18; // Hours in UTC
      const minutes = 30; // Minutes in UTC
      const seconds = 0;
      const milliseconds = 0;

      // Create a new date object with the desired format
      const outputDate = new Date(
        Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds)
      );
      return moment(outputDate).subtract(1, "d").toISOString();
      // return moment(new Date(date)).toISOString();
    }
  }

  removeFieldWithName(arr, name: string) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].name === name) {
        arr.splice(i, 1); // Remove the object at index i from the array
      }
    }
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode || event.which);
    const pattern = /^[-\d.]$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // this functionality delete same objects from array
  removeDuplicateObjects(array) {
    return array.filter((obj, index, self) => {
      const stringifiedObj = JSON.stringify(obj);
      return (
        index ===
        self.findIndex(
          (innerObj) => JSON.stringify(innerObj) === stringifiedObj
        )
      );
    });
  }

  // this functionality makes a structure for display documents data
  async getDocumentDetails(documents) {
    const updatedData = [];
    documents.forEach((item) => {
      const fileNameEntry = {
        title: "Name",
        value: item.fileName,
      };
      const typeEntry = {
        title: "Type",
        value: this.getTitleCase(item.type),
      };
      const urlEntry = {
        title: "URL/Link",
        value: item.url ? item.url : item.link,
      };
      const noteEntry = {
        title: "Notes",
        value: item.note,
      };
      updatedData.push(fileNameEntry, typeEntry, urlEntry, noteEntry);
    });
    return updatedData;
  }

  async getBasicName(data: string) {
    if (data) {
      const updatedValue = this.taskAllDetails.find(
        (item) => item.key === data
      );
      return updatedValue ? updatedValue.value : "N/A";
    }
  }

  // This function is used to convert the contact  into (123) 4567-890 format
  convertToPhoneNumberFormat(number: string): string {
    if (number) {
      // Remove all non-digit characters from the input number
      const cleanedNumber = number.replace(/\D/g, "");

      // Apply the desired format: (XXX) XXX-XXXX
      const formattedNumber = cleanedNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );

      return formattedNumber;

      // Return the original number if it doesn't match the desired format
      return number;
    } else {
      return;
    }
  }

  // This function is used to handle error from api
  handleError(error: HttpErrorResponse) {
    const err = new Error(error.error);
    return throwError(() => err);
  }

  documentsType = {
    title: "Upload Type",
    name: "type",
    singleSelection: true,
    multipleValues: this.uploadType,
  };

  typeDocument = {
    title: "Upload  Document",
    name: "type",
    singleSelection: true,
    multipleValues: this.uploadDoc,
  };

  // Function used to return a new array with non-null and non-undefined values
  filterNonNullAndUndefined(arr) {
    if (arr) {
      return arr.filter(
        (value) => value !== null && value !== undefined && value !== ""
      );
    }
  }

  // Function to convert uppercase text to lowercase with first string capital
  getTitleCase(data: string) {
    if (data) {
      const lowercasedText = data.toString().toLowerCase();
      return lowercasedText.charAt(0).toUpperCase() + lowercasedText.slice(1);
    }
  }

  // Define ti time set for same date range
  adjustDateRange(startDate, endDate) {
    if (startDate && endDate) {
      if (
        new Date(startDate).toISOString() === new Date(endDate).toISOString()
      ) {
        startDate.setUTCHours(0, 0, 0, 0); // Set to 12:00 AM UTC
        endDate.setUTCHours(23, 59, 59, 999); // Set to 11:59 PM UTC
        return {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        };
      } else {
        return {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        };
      }
    }
  }

  // function used to send valid cell number
  getValidCell(data) {
    const isValidCell =
      this.cellPattern.test(data.phone.number) === true
        ? data.phone.number
        : this.convertToPhoneNumberFormat(data.phone.number);
    return isValidCell;
  }

  // Function to return the name and count of assigned employee/group
  async getAssignValue(element) {
    if (Array.isArray(element.groups) && element.groups.length) {
      const firstGroupName = element.groups[0].name;
      const groupCount = element.groups.length - 1;
      const result = groupCount > 0 ? `${firstGroupName} +${groupCount}` : `${firstGroupName}`;
      return result;
    }
    if (
      (Array.isArray(element.assignedTo) && element.assignedTo.length) &&
      ((Array.isArray(element.groups) && element.groups.length === 0) || !element.groups)
    ) {
      const firstUserName = element.assignedTo[0].userName;
      const userCount = element.assignedTo.length - 1;
      const result = userCount > 0 ? `${firstUserName} +${userCount}` : `${firstUserName}`;
      return result;
    }
    if (
      ((Array.isArray(element.groups) && element.groups.length === 0) || !element.groups) &&
      ((Array.isArray(element.assignedTo) && element.assignedTo.length === 0) || !element.assignedTo)
    ) {
      return 'N/A';
    }
  }

  // Function to convert amount to active currency code
  convertToCurrency(amount, currencyDetails) {
    if (amount) {
      const code = currencyDetails && currencyDetails.currency_code ? currencyDetails.currency_code : 'USD';
      const exchangeRate = currencyDetails && currencyDetails.exchange_rates ? currencyDetails.exchange_rates : '1';
      const filteredAmount = typeof amount === 'string' && amount.includes(',') ? Number(amount.replace(/,/g, '')) : Number(amount);
      const decimalAmount = filteredAmount.toFixed(2);
      if (code.toUpperCase() !== 'USD') {
        const decimalExchange = Number(exchangeRate).toFixed(2);
        const currentAmount = parseFloat(decimalAmount) / 1 * parseFloat(decimalExchange);
        const commaSeparatedAmount = this.formatNumberWithCommas(currentAmount, 2);
        return commaSeparatedAmount;
      } else {
        const currentAmount = parseFloat(decimalAmount);
        const commaSeparatedAmount = this.formatNumberWithCommas(currentAmount, 2);
        return commaSeparatedAmount;
      }
    } else {
      return 0;
    }
  }

  // Function to save amount in USD currency from any currency
  saveUSDCurrency(amount, currencyDetails) {
    if (amount) {
      const activeCurrencyCode = currencyDetails && currencyDetails.currency_code ? currencyDetails.currency_code : 'USD';
      const activeExchangeRate = currencyDetails && currencyDetails.exchange_rates ? currencyDetails.exchange_rates : '1';
      if (activeCurrencyCode.toUpperCase() === 'USD') {
        return typeof amount === 'string' && amount && amount.includes(',') ? amount.replace(/,/g, '') : Number(amount);
      } else {
        const filteredAmount = typeof amount === 'string' && amount.includes(',') ? Number(amount.replace(/,/g, '')) : Number(amount);
        const decimalAmount = filteredAmount.toFixed(2);
        const decimalExchange = activeExchangeRate.toFixed(2);
        const amountInUSD = parseFloat(decimalAmount) / parseFloat(decimalExchange);
        const commaSeparatedAmount = this.formatNumberWithCommas(amountInUSD, 2);
        return typeof commaSeparatedAmount === 'string' && commaSeparatedAmount.includes(',') ? commaSeparatedAmount.replace(/,/g, '') : Number(commaSeparatedAmount);
      }
    } else {
      return 0;
    }
  }

  // Function to add commas in amount
  formatNumberWithCommas(number, decimalPlaces = 2) {
    if (typeof number !== 'number') {
      return '0.00'; // Return a default value if the input is not a number
    }
    const options = {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    };

    return number.toLocaleString(undefined, options);
  }

  // Function to set filter properties in url
  async setFilterOnUrl(filterData: { type: string, data: string[] }[]) {
    const queryParams = {};
    filterData.forEach(filter => {
      const {type, data} = filter;
      if (data.length > 0) {
        const concatenatedStatus = data.join('&');
        queryParams[type] = concatenatedStatus;
      } else {
        queryParams[type] = null;
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

// Function to set filter string properties in URL
async setFilterStringOnUrl(filterData: { type: string, data: string }[]) {
  const queryParams = {};
  filterData.forEach(filter => {
    const { type, data } = filter;
    if (data) {
      queryParams[type] = data; // Assign string directly
    } else {
      queryParams[type] = null; // Handle empty string case
    }
  });

  this.router.navigate([], {
    relativeTo: this.route,
    queryParams,
    queryParamsHandling: 'merge',
  });
}


  // Function to give the filter variable from the url bar
  async getFilterFromUrl(types: string[]) {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentURL).search);
    const filters = [];

    types.forEach(type => {
      const filterParam = urlParams.get(type);
      if (filterParam) {
        const filterArray = filterParam.split('&');
        filters.push({type, value: filterArray});
      } else {
        filters.push({type, value: []});
      }
    });
    return filters;
  }

  getActiveDateFormat() {
    const currentDateFormat = JSON.parse(localStorage.getItem('adminDateFormat'));
    const uploadText = `Please add Date in '${currentDateFormat.value}' format`;
    return [uploadText];
  }

  warningMessage(details: {
    text: string,
    title: string,
    cancelButtonText: string,
    confirmButtonText?: string
  }, isConfirm: boolean): SweetAlertOptions {
    const options: SweetAlertOptions = {
      title: details.title,
      text: details.text,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: details.cancelButtonText ?? 'OK',
      showConfirmButton: isConfirm
    };
    if (isConfirm) {
      options.confirmButtonText = details.confirmButtonText ?? "Yes";
    }
    return options;
  }

  projectWarningMessage = {
    text: "Do you want to save information from other tabs before proceeding? Click 'Yes' to save additional changes or 'No' to continue with current saving.",
    title: "Save Other Tab Information?",
    cancelButtonText: 'No',
  }

  // Function to return array without empty, null or undefined
  filterByProperty(arr, key: string) {
    return arr.filter(item => item[key] !== '' && item[key] !== null && item[key] !== undefined);
  }

  // Function to return array with original
  removeOriginalAndKeepDuplicatesByKey(data) {
    const keySet = new Set();
    let result = [];

    for (const currentItem of data) {
      if (keySet.has(currentItem.key)) {
        result = result.filter(obj => obj.key !== currentItem.key);
      }
      result.push(currentItem);
      keySet.add(currentItem.key);
    }

    return result;
  }

  /**
   * Processes a string by capitalizing the first letter of each word and replacing hyphens with spaces.
   * @param {string} inputString - The input string to be processed
   * @returns {string} - The processed string
   */
  processString(inputString: string): string {
    return inputString.split('-').map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  /**
   * Validates an input value to check if it's empty or not.
   * @param inputValue The input value to be validated.
   * @returns Returns true if the input value is empty, otherwise false.
   */
  public isValidInput(inputValue?: string | undefined | null): boolean {
    let isValid = false
    if (inputValue === undefined || inputValue === null || inputValue.length === 0) {
      isValid = false;
    }
    // If inputValue's length is falsy (null, undefined, or zero), it's considered empty
    isValid = !!(inputValue && inputValue.length)
    return isValid;
  }

  /**
  * Formats a number to a currency string with a dollar sign and comma separation.
  * @param value - The number to format.
  * @param decimalPoints - The number of decimal points to display. Default is 2.
  * @returns A formatted string representing the currency.
  */
  formatCurrency(value: number, decimalPoints = 4): any {
    if (isNaN(value)) {
      return "$0.00";
    }
  
    // Check if the number has a fractional part
    const hasFraction = value % 1 !== 0;
  
    // Format the number with comma separation and apply decimal points only if necessary
    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: hasFraction ? 2 : 0, 
      maximumFractionDigits: decimalPoints, 
    });
  
    return `$ ${formattedValue}`;
  }  

  
  /**
   * Extract the active tab from the URL based on the given screen name.
   */
  extractActiveTab(url: string, screenName: string): string {
    const parts = url.split("/");
    const screenIndex = parts.findIndex((part) => part === screenName);
    
    // If the screen name is found, return the active tab next to it
    return screenIndex !== -1 && parts[screenIndex + 1] ? parts[screenIndex + 1] : "";
  }

}
