import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // Boolean property to control the visibility of the spinner
  showSpinner = true; // Set it to true by default, or set based on your condition
  
  constructor() {
    // Example: hide spinner after 1 seconds (simulate loading)
    setTimeout(() => {
      this.showSpinner = false;
    }, 1000);
  }
}
