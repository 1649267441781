import { Component, Input, Output, EventEmitter, OnChanges, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: "app-estimates-consumables-table",
  templateUrl: "./estimates-consumables-table.component.html",
  styleUrls: ["./estimates-consumables-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EstimatesConsumablesTableComponent implements OnChanges {
  @Input() orderList: any[] = [];
  @Input() isOrderList = false;
  @Input() columns: any[] = [];
  @Input() row: any; 
  @Input() newRows = [];
  @Input() options: string[] = ["day", "week"];
  @Input() isConsumables = false;
  @Input() isRentals = false;
  @Input() isDownholeTests = false;
  @Input() markupPercentage: number;
  @Input() markupDisplayPercentage = false;
  @Input() isOnlyConsumables = false;
  @Input() isOutsourcedRentals = false;
  @Input() isInvoicingItem = false;
  @Input() isCheckBox = false;
  @Input() isInvoicing = false;

  @Output() save = new EventEmitter<string>();
  @Output() unitChange = new EventEmitter<{
    itemName: string;
    selectedUnit: string;
  }>();
  @Output() fetchUpdatedCost = new EventEmitter<void>();
  @Output() markupPercentageChange = new EventEmitter<number>();
  @Output() checkboxChange = new EventEmitter<boolean>();
  @Output() toggleChange = new EventEmitter<{
    index: number;
    value: boolean;
  }>();
  @Output() orderListChange = new EventEmitter<any[]>();
  @Output() newRowsChange = new EventEmitter<any[]>();

  totalCost = 0;
  totalSellingCost = 0;
  totalCostSum = 0;
  totalSellingCostSum = 0;

  btnName = [
    { value: 'Add Custom Row', key: 'add', type: 'miscellaneous', permission: 'bulletin.write', icon: 'fa fa-plus', theme: 'combinedButton' },
  ];

  /** List of unit options */
  unitArray = [
    { value: "Each", key: "EACH" },
    { value: "Day(s)", key: "DAY(s)" },
    { value: "Week", key: "WEEK" },
    { value: "Month", key: "MONTH" },
    { value: "Bag(s)", key: "BAG(s)" },
    { value: "Feet", key: "FEET" },
    { value: "Hours", key: "HOURS" },
    { value: "Lump", key: "LUMP" },
    { value: "Miles", key: "MILES" },
    { value: "Pail", key: "PAIL" },
    { value: "Shift", key: "SHIFT" },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    public cos: CommonService,
  ) {}

  ngOnChanges(): void {
    if (this.isInvoicingItem && !this.columns.some(c => c.name === 'Invoice Quantity')) {
      const unitQtyIndex = this.columns.findIndex(col => col.name === 'Unit Quantity');
      const unitIndex = this.columns.findIndex(col => col.name === 'Unit');
      const insertIndex = unitQtyIndex !== -1 ? unitQtyIndex + 1 : unitIndex !== -1 ? unitIndex + 1 : this.columns.length;
  
      this.columns.splice(insertIndex, 0, { name: 'Invoice Quantity' });
    }
  
    this.calculateTotal();
  }   

  columnVisible(columnName: string): boolean {
    return this.columns.some((column) => column.name === columnName);
  }

  calculateRowTotal(row: any) {
    const quantity = parseFloat(row.quantity) || 0;
    const cost = parseFloat(row.cost) || 0;
    const sellingCost = parseFloat(row.sellingCost) || 0;

    // Calculate the total costs
    row.totalCost = quantity * cost;
    row.totalSellingCost = quantity * sellingCost;

    // Format the total costs for display
    row.totalCostFormatted = this.cos.formatCurrency(row.totalCost);
    row.totalSellingCostFormatted = this.cos.formatCurrency(row.totalSellingCost);

    this.totalCostSum = row.totalCost;
    this.totalSellingCostSum = row.totalSellingCost;

    // Update the overall totals
    this.calculateTotal();
    this.updateOrderList(); 

  }

  calculateTotal() {
    let totalCostSum = 0;
    let totalSellingCostSum = 0;
  
    // Loop through the order list and accumulate totals
    [...this.orderList, ...this.newRows].forEach((row: any) => {
      const rowTotalCost = parseFloat(row.totalCost) || 0;
      const rowTotalSellingCost = parseFloat(row.totalSellingCost) || 0;
  
      totalCostSum += rowTotalCost;
      totalSellingCostSum += rowTotalSellingCost;
    });
  
    // Update component-level properties with calculated totals
    this.totalCostSum = totalCostSum;
    this.totalSellingCostSum = totalSellingCostSum;
  }  

  updateRecord(action: string) {
    this.save.emit(action);
    this.updateOrderList(); 
  }

  onFetchUpdatedCost() {
    this.fetchUpdatedCost.emit();
  }

  onToggleChange(event: any, index: number) {
    this.orderList[index].isFuelRequired = event.checked;
    this.toggleChange.emit({ index, value: event.checked });
  }

  // Function triggered when the unit is changed
  onUnitChange(row: any) {
    const selectedUnit = row.selectedUnit;

    if (selectedUnit) {
      // Emit the unit change
      this.unitChange.emit({ itemName: row.item, selectedUnit });

      // Find the unitInfo for the selected unit
      const unitInfo = this.findUnitInfo(row.item, selectedUnit);

      if (unitInfo) {
        row.costPrice = unitInfo.costPrice.value;
        row.sellingPrice = unitInfo.sellingPrice.value;
        row.totalCost = unitInfo.costPrice.value * (row.quantity || 0); // Update as needed
        row.totalSellingCost =
          unitInfo.sellingPrice.value * (row.quantity || 0); // Update as needed
      } else {
        row.costPrice = null;
        row.sellingPrice = null;
        row.totalCost = null;
        row.totalSellingCost = null;
      }
    }

    // Format costs after unit change
    this.calculateRowTotal(row);
    this.updateOrderList(); 
  }

  findUnitInfo(itemName: string, selectedUnit: string) {
    // Find the item from orderList
    const item = this.orderList.find((o) => o.item === itemName);

    if (item) {
      // Find the unitInfo that matches the selected unit
      return item.options.find((u) => u.unit === selectedUnit);
    }

    return null;
  }

  onCostChange(value: string, row: any) {
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    row.cost = isNaN(numericValue) ? 0 : numericValue;

    // Recalculate the row's total costs
    this.calculateRowTotal(row);
    this.calculateTotal();  // Update the overall total if needed
    this.updateOrderList(); 
}

  onSellingCostChange(value: string, row: any) {
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    row.sellingCost = isNaN(numericValue) ? 0 : numericValue;

    // Recalculate the row's total costs
    this.calculateRowTotal(row);
    this.calculateTotal();  // Update the overall total if needed
    this.updateOrderList(); 
}

  /**
   * Emit change in markup percentage to the parent component
   */
  onMarkupPercentageInputChange(value: string, row: any) {
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
  
    if (!isNaN(numericValue)) {
      row.markupPercentage = numericValue;
    } else {
      row.markupPercentage = 0; // Reset to 0 if input is invalid
      this.updateOrderList(); 
    }
  
    // Recalculate the row's totals after markup change
    this.calculateRowTotal(row);
    this.calculateTotal();  // Update the overall total if needed
  }

  onCheckboxChange(isSelected: boolean): void {
    this.checkboxChange.emit(isSelected);
    this.updateOrderList(); 
  }

  onQuantityChange(row: any) {
    const quantity = parseFloat(row.quantity) || 0;
    row.quantity = quantity;  // Update the quantity

    // Recalculate the row's total costs
    this.calculateRowTotal(row);

    // Recalculate the overall totals
    this.calculateTotal();
    this.updateOrderList(); 

 }

 onInvoiceQuantityChange(row: any) {
  const invoicedQuantity = parseFloat(row.invoicedQuantity) || 0;
  row.invoicedQuantity = invoicedQuantity;  // Update the invoicedQuantity

  // Recalculate the row's total costs
  this.calculateRowTotal(row);

  // Recalculate the overall totals
  this.calculateTotal();
  this.updateOrderList();
 }

  onUnitQuantityChange(row: any) {
    const unitQuantity = parseFloat(row.unitQuantity) || 0;
    row.unitQuantity = unitQuantity;  // Update the unitQuantity

    // Recalculate the row's total costs
    this.calculateRowTotal(row);

    // Recalculate the overall totals
    this.calculateTotal();
    this.updateOrderList(); 
    
  }

  addNewRow() {
    this.newRows.push({
      item: "", // Empty item name
      quantity: 0, // Default quantity
      cost: 0, // Default cost
      sellingCost: 0, // Default selling cost
      selectedUnit: this.unitArray?.[0]?.key || "", // Default to first unit
      totalCost: 0,
      totalSellingCost: 0,
      markupPercentage: 0, // Default markup
      options: this.unitArray.map((unit) => unit.key), // Populate unit options dynamically
    });
  
    this.calculateTotal(); // Recalculate total whenever a new row is added
  }
  
  onDynamicRowChange(event: any, field: string, index: number) {
    this.newRows[index][field] = event;
  
    // If quantity, cost, or sellingCost is updated, recalculate total cost
    if (field === "quantity" || field === "cost" || field === "sellingCost") {
      this.calculateRowTotal(this.newRows[index]);
      this.calculateTotal();
    }
     this.updateOrderList(); 
  }

  // Emit updated orderList and newRows whenever a change occurs
  updateOrderList() {
    this.orderListChange.emit(this.orderList);
    this.newRowsChange.emit(this.newRows);
  }
    
}
