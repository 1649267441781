import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-common-excel',
  templateUrl: './common-excel.component.html',
  styleUrls: ['./common-excel.component.scss']
})
export class CommonExcelComponent  {
@Input() modalId = 'uploadExcel';
@Input() additionalInstruction: [];
@Input() uploadedData = [];
@Output() uploadedFile=new EventEmitter;
uploadButton = true;
documents = [];
saveButton = false;
url = [];
modalProperty = {
  header: 'Upload Excel',
  id: 'uploadExcel'
};


  constructor(
    public cos:CommonService) { }


  public upload(event) {
    this.documents = [event];
    this.uploadButton = true;
    this.saveButton = true;
  }
 
  public passImages() {
    return this.documents || [];
  }

  public removeImage(event) {
    this.url = this.url.filter(document => document.url
      ? document.url !== event.url
      : document.path !== event.path);
    this.uploadButton = true;
    this.saveButton = false;
  }

  public submitForm(type:string){
    const result = [{value:type,fileName:this.documents[0] ?this.documents[0].url:'',key:this.uploadedData[0].key}];
    if(type==='sample'){
      this.uploadedFile.emit(result);
    }else{
      this.uploadedFile.emit(result);
    }
  }

  public resetForm(){
    this.documents=[];
    this.uploadButton=true;
    this.saveButton=false;

  }
}
