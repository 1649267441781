import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ClientService } from 'src/app/_services/client.service';
import { CommonService } from 'src/app/_services/common.service';
import 'bootstrap';
import * as jQuery from 'jquery';
import { DialCodeFormat } from 'src/app/interfaces/common-interface';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-representatives',
  templateUrl: './representatives.component.html',
  styleUrls: ['./representatives.component.scss']
})
export class RepresentativesComponent implements OnChanges, OnInit {
  @Input() branchList = false;
  @Input() clientId = '';
  @Output() submitRep = new EventEmitter();
  formData = [
    { title: 'Rep Name*', name: 'name', placeholder: 'Enter Rep Name', limit: 50, type: 'input' },
    { title: 'Rep Direct Office #', name: 'number', type: 'phone', placeholder: 'Rep Direct Office #' },
    { title: 'Email', name: 'mail', type: 'email', placeholder: 'Enter Rep Email' },
    { title: 'Title', name: 'title', type: 'input', placeholder: 'Enter Rep Title' },
    { title: 'Note', name: 'note', type: 'summary', placeholder: 'Enter Rep Note' },
  ];
  modalTitle: string;
  state = [{
    buttonName: 'Save',
    type: 'ClientRep'
  }];
  modalForm = this.fb.group({
    name: '',
    number: '',
    mail: '',
    note: '',
    title: '',
    cell: '',
    address: '',
    id: ''
  });
  deleteIDs = [];
  selectionReset = false;
  dialCode: DialCodeFormat;
  tableData = [];
  completeData = {};
  searchCode = '';
  countries = [];
  countryDetails = [];
  country;
  representativeCell;
  repCell = '';
  constructor(
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private clientSrvc: ClientService,
  ) {

  }

  ngOnInit() {
    fetch('/assets/countries.json')
      .then((res) => res.json())
      .then((res) => {
        setTimeout(() => {
          this.countries = res;
          this.countryDetails = res;
          this.country = {
            name: "US",
            dial_code: 1,
            code: "US"
          };
        }, 1000);
      })
      .catch(error => {
        this.cos.handleError(error);
      });
  }

  ngOnChanges() {
    if (this.branchList && this.clientId) {
      const branches = [];
      this.clientSrvc.getAllBranches(this.clientId)
        .subscribe(res => {
          if (res.data.length) {
            res.data.map(val => {
              branches.push({
                value: val.branchAddress.formattedAddress, key: val._id
              });
            });
          } else {
            this.cos.toastMessage("In the selected client, there is no branch address, so you can't add a representative.", 'warning');
          }
        });
      this.cos.removeFieldWithName(this.formData, 'address');
      const insertedData = { title: 'Branch Address*', name: 'address', placeholder: 'Select Address', type: 'checkBox', multipleValues: branches, singleSelection: true }
      this.formData.splice(0, 0, insertedData);
    }
  }

  // Function to split the contact number
  splitNumber(input) {
    const pattern = /^(\+\d{1,3}|\d{1,3})\s?(\(\d+\))?\s?(.+)/;
    const [, dialCode, areaCode, number] = input.match(pattern);
    const formattedNumber = areaCode ? `${areaCode} ${number}` : number;
    return { dialCode: dialCode.replace(/\D/g, ''), number: formattedNumber };
  }


  // Function to save branch
  submitForm(element) {
    const data = element.formValue;
    this.dialCode = element.dialCode;
    if (!data.name || data.name.trim().length == 0) {
      return this.cos.toastMessage(response.en.NAME, 'warning');
    } else {
      const ids = {
        branch: data.address,
        _client: this.clientId,
      };
      const payload = {
        representativeName: data.name ?? '',
        representativeNumber: data.number ? `+${this.dialCode.dial_code} ${data.number}` : '',
        representativeEmail: data.mail ?? '',
        representativeTitle: data.title ?? '',
        representativeNote: data.note ?? '',
        representativeCell: data.cell ? `+${this.representativeCell.dial_code} ${data.cell}` : '',
      };
      if (data.id) {
        this.clientSrvc.updateRepresentative({ ...payload, ...{ id: data.id } }).subscribe((res) => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            this.submitRep.emit(this.branchList);
            jQuery('#repModal').modal('hide');

          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        }, (err) => { this.cos.handleError(err); });
      } else {
        this.clientSrvc.addRepresentative({ ...payload, ...ids }).subscribe((res) => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            jQuery('#repModal').modal('hide');
            this.submitRep.emit(this.branchList);
          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        }, (err) => { this.cos.handleError(err); });
      }

    }

  }

  editValues(data) {
    this.representativeCell = { dial_code: 1, country: 'US' };
    this.dialCode = { country: 'US', dial_code: 1 };
    this.repCell = '';
    this.modalTitle = 'Edit Representative';
    jQuery('#repModal').modal('show');
    this.clientSrvc.RepresentativeDetails(data[0]?.value?.id).subscribe(res => {
      const result = res.data;
      const directCell = result.representativeNumber ? this.splitNumber(result.representativeNumber) : '';
      const additionalCell = result.representativeCell ? this.splitNumber(result.representativeCell) : "";
      this.modalForm.patchValue({
        name: result.representativeName,
        number: directCell ? directCell.number : '',
        mail: result.representativeEmail,
        note: result.representativeNote,
        title: result.representativeTitle,
        cell: additionalCell ? additionalCell.number : '',
        id: result._id,
      });
      this.representativeCell = { dial_code: additionalCell ? additionalCell.dialCode : 1 };
      this.dialCode = { dial_code: directCell ? directCell.dialCode : 1 };
      this.repCell = additionalCell ? additionalCell.number : '';
    });

  }

  // input phone number functionality
  // This function is used to search the country name 
  searchDialCode(data) {
    const search = data.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!regex.test(search)) {
      data.target.value = search.replace(/[^a-zA-Z0-9 ]/g, '');
    } else if (!isNaN(search)) {
      this.countryDetails = [];
      this.countries.map((val) => {
        if (val.dial_code && val.dial_code.match(search)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    } else {
      this.countryDetails = [];
      const regx = new RegExp(search ? search : "" + "$", "i");
      this.countries.map((val) => {
        if (val.name.match(regx)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    }
  }

  // This function is used to get the countries name with dial code
  getSelectedCountry(country: string) {
    this.country = country;
    this.representativeCell = {
      ...this.country,
    };
  }

  repNumber(data) {
    this.modalForm.patchValue({
      cell: data.target.value,
    });

  }

  // Function to reset the modal
  resetModal() {
    this.repCell = '';
    this.modalForm.reset();
    this.modalTitle = 'Add Representative';
    this.dialCode = { dial_code: 1, country: 'US' };
    this.representativeCell = { dial_code: 1, country: 'US' };
  }

   // This function is used to reset the values of dial code
   resetDialCode() {
    this.searchCode = '';
    this.countryDetails = [];
    this.countryDetails = this.countries;

  }

}
