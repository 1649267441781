import { Component, OnInit ,ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import 'bootstrap';
import * as jQuery from 'jquery';
import * as platform from 'platform';
import Swal from 'sweetalert2';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { PushNotificationComponent } from '../push-notification/push-notification.component';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild(PushNotificationComponent) pushNotification: PushNotificationComponent;
  inputType = 'password';
  inputType1 = 'password';
  inputType2 = 'password';
  name = '';
  primaryColor = 'var(--primary-color)';
  secondaryColor = 'var(--secondary-color)';
  roleName = '';
  // Will update in future ,as not working under equipments
  currentComponentName = 'Equipment Inspection Details';
  notificationDetails = {};
  notificationCount: number;
  search = {
      pagination: true,
      pageNumber: 1,
      noOfRecord: 10,
  };
  changePasswordForm = this.fb.group({
    oldPassword: [''],
    password: [''],
    confirmPassword: ['']
  });

  constructor(
    private fb: UntypedFormBuilder,
    private cos: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardSrvc: DashboardService,
    private authSrvc: AuthService,
  ) {
    this.getHeaderName();
  }


  ngOnInit() {
    this.dashboardSrvc.getPushNotification(this.search).subscribe(res => {
      this.notificationDetails = res.data;
      const notifications = res.data.filter(val => val.read === false);
      this.notificationCount = notifications.length;
    });
  }
  // Function to get header name
  private getHeaderName() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        const componentName = await this.getCurrentComponentName(this.activatedRoute.root);
        this.currentComponentName = componentName || 'Project Details';
      }
    }); 
  }

  // Recursive function to get the component name
  private async getCurrentComponentName(route: ActivatedRoute): Promise<string> {
    if (route.firstChild) {      
      return this.getCurrentComponentName(route.firstChild);
    } else {
     return route.snapshot.data['componentName'];
    }
  }


  setUserName() {
    const storedData = JSON.parse(localStorage.getItem('authen@User'));
    this.roleName = storedData.userName;
  }

  resetPassword() {
    if (!this.changePasswordForm.value.oldPassword) {
      return this.cos.toastMessage(response.en.CURRENTPASS_REQUIRED, 'warning');
    } else if (!this.changePasswordForm.value.password) {
      return this.cos.toastMessage(response.en.NEW_PASSWORD, 'warning');
    } else if (!this.changePasswordForm.value.confirmPassword) {
      return this.cos.toastMessage(response.en.CONFIRM_MESSAGE, 'warning');
    }
    const pass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    if (!pass.test(this.changePasswordForm.value.password)) {
      this.cos.toastMessage(response.en.PASSWORD_VALID, 'warning');
      return;
    } else if (this.changePasswordForm.value.password !== this.changePasswordForm.value.confirmPassword) {

      this.cos.toastMessage(response.en.PASSWORD_MATCH, 'warning');
      return;
    }

    const data = {
      oldPassword: this.changePasswordForm.value.oldPassword,
      newPassword: this.changePasswordForm.value.confirmPassword
    };

    this.authSrvc.changePassword(data).subscribe((res) => {
      if (res) {
        jQuery('#changepassword').modal('hide');
        this.cos.toastMessage(res.message, 'success');
      } else {
        this.cos.toastMessage(res.message, 'warning');
      }
    }, (err) => { this.cos.handleError(err); });
  }

  resetForm() {
    jQuery('#changepassword').modal('show');
    this.changePasswordForm.reset();
  }

  addClass() {
    document.body.classList.toggle('mini-navbar');
  }

  // Logout function
  logout() {
    const systemType = this.getOperatingSystem();
    const browserType = this.getBrowserType();
    Swal.fire({
      title: 'Are you sure?',
      text: 'Logging out will end your current session and you will need to log in again to access your account.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        const body = {
          deviceId: systemType,
          useragent: browserType
        }
        this.authSrvc.logout(body).subscribe(res => {
          if (res.status) {
            // this.router.navigate(['/dashboard']);
            this.cos.toastMessage(res.message, 'success');
            this.router.navigateByUrl('/auth/login');
          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        }, error => { this.cos.handleError(error); });
      }
    });
  }

  // Function to get browser type
  getBrowserType(): string {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome')) {
      return 'Google Chrome';
    } else if (userAgent.includes('Firefox')) {
      return 'Mozilla Firefox';
    } else if (userAgent.includes('Safari')) {
      return 'Apple Safari';
    } else if (userAgent.includes('Edge')) {
      return 'Microsoft Edge';
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return 'Opera';
    } else {
      return 'Unknown';
    }
  }

  // Function to get operating system type
  getOperatingSystem(): string {
    const osInfo = platform.os;

    if (osInfo.family.includes('Win')) {
      return 'Windows';
    } else if (osInfo.family.includes('Mac')) {
      return 'Mac';
    } else if (osInfo.family.includes('OS X')) {
      return 'Mac';
    } else if (osInfo.family.includes('Linux')) {
      return 'Linux';
    } else {
      return 'Unknown';
    }
  }

  isProfile() {
    this.router.navigateByUrl('/auth/account-details');
  }

  // Function to get all notifications
  getNotification() {
    this.pushNotification.getNotification();
      jQuery('#notificationModal').modal('show');
  }


  // function to set currency type and date format in local storage
  getCurrencyType() {
    const isCurrencyExist = localStorage.getItem('adminCurrency');
    const isDateFormatExist = localStorage.getItem('adminDateFormat');
    if (!isCurrencyExist && !isDateFormatExist) {
      this.dashboardSrvc.getCurrencyAndDate().subscribe((res) => {
        const result = res.data;
        if (result) {
        localStorage.setItem('adminCurrency', JSON.stringify(result.currencyFormat));
        localStorage.setItem('adminDateFormat', JSON.stringify(result.dateFormat));
        }
      });
    }
  }

}
