import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-estimate-radio-table",
  templateUrl: "./estimate-radio-table.component.html",
  styleUrls: ["./estimate-radio-table.component.scss"],
})
export class EstimateRadioTableComponent implements OnInit {
  @Input() tableTitle = "Title";
  @Input() selectedValue = "UNKNOWN"; // Set default value to "UNKNOWN"
  @Output() selectedValueChange = new EventEmitter<string>();

  choices = [
    { id: "YES", tag: "Yes" },
    { id: "NO", tag: "No" },
    { id: "UNKNOWN", tag: "Unknown" },
  ];

  ngOnInit() {
    // Emit the default value when the component initializes
    this.selectedValueChange.emit(this.selectedValue);
  }

  onValueChange(newValue: string) {
    this.selectedValue = newValue;
    this.selectedValueChange.emit(newValue);
  }
}
