<form
  [formGroup]="formData"
  class="d-flex"
  *ngIf="dropDownData && dropDownData.title">
  <div class="w-100">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{ dropDownData?.title }}</mat-label>
      <mat-select
        formControlName="{{ dropDownData?.name }}"
        [multiple]="dropDownData?.singleSelection ? false : true"
        (openedChange)="
          triggerEvent($event, dropDownData.name, dropDownData.multipleValues)
        "
        class="ad-text-size"
      >
        <input
          matInput
          placeholder="Search"
          class="searchEffects"
          [(ngModel)]="searchRecord"
          type="text"
          (ngModelChange)="
            searchRecordName(
              $event,
              dropDownData.name,
              dropDownData.multipleValues
            )
          "
          [ngModelOptions]="{ standalone: true }"
        />

        <mat-option
          #matOption
          (click)="
            patchSelectedValue(
              matOption.selected,
              data.key,
              dropDownData.name,
              dropDownData.singleSelection,
              data.value
            )
          "
          class="py-2"
          *ngFor="let data of dropDownData?.multipleValues"
          value="{{ data?.key }}"
        >
          {{ data?.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
