import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CommonService } from "src/app/_services/common.service";
import { VariableService } from "src/app/_common/variable.service";

@Component({
  selector: "app-unknown-radio-table",
  templateUrl: "./unknown-radio-table.component.html",
  styleUrls: ["./unknown-radio-table.component.scss"]
})
export class UnknownRadioTableComponent {

   /** Property to hold table title */
   @Input() tableTitle = "Title";

   /** Property to hold data list */
   @Input() dataList: any[] = []; 
 
   /** Flag to identify if the component is handling Division of Water Resources */
   @Input() isDivisionOfWaterResources = false;
 
   /** Boolean to control if "Unknown" should be shown */
   @Input() isUnknown = false;

   /** Boolean to control if "Not asked" should be shown */
   @Input() isNotAsked = false;

   /** Property to hold choices list */
   choices = [
     { id: "is_yes", tag: "Yes" },
     { id: "is_no", tag: "No" }
   ];

   selectedItems = [];
 
   @Input() isMultiSelect = false;
   @Input() isMultiEventEmit = false;
   @Input() isCheckBox = false;
   @Output() updatedList = new EventEmitter();
   @Output() clickEvent = new EventEmitter();
   form: FormGroup;
   @Input() checkedItems: boolean[] = []; 
 
   constructor(
     public cos: CommonService,
     public commonVariable: VariableService,
     private fb: FormBuilder
   ) {}
 
   ngOnInit() {

     // Add "Unknown" choice only if isUnknown is true
     if (this.isUnknown) {
      this.choices.push({ id: "is_unknown", tag: "Unknown" });
    }

    if (this.isNotAsked) {
      this.choices.push({ id: "is_not_asked", tag: "Not Asked" })
    }

    // Initialize the form group dynamically based on the data list
    this.form = this.fb.group({});
  
    // Loop through the dataList and initialize form controls
    this.dataList.forEach((item, index) => {
      let defaultSelection = ""; 
  
        // Ensure `status` maps correctly to radio buttons
        if (item.status === "YES") {
          defaultSelection = "is_yes";
        } else if (item.status === "NO") {
          defaultSelection = "is_no";
        } else if (item.status === "UNKNOWN") {
          defaultSelection = "is_unknown";
        } else if (item.status === "NOT_ASKED") {
          defaultSelection = "is_not_asked";
        } else {
          defaultSelection = "is_not_asked";
        }                 
  
      // Patch radio button value
      this.form.addControl(`responseField_${index}`, this.fb.control(defaultSelection));
  
      // Check if the item is default selected by the API and mark it
      if (item.isDefault === true) {

        this.checkedItems[index] = true;
  
        // Emit the selected row data for default selection
        const selectedItem = this.createSelectedItem(index);
        this.selectedItems.push(selectedItem);
        this.clickEvent.emit(this.selectedItems); 
      }
    });
  
    // Initialize 'checkedItems' based on the values passed from the parent
    if (this.checkedItems && this.checkedItems.length > 0) {
      this.checkedItems.forEach((checked, index) => {
        this.checkedItems[index] = checked;  
      });
    }
  }  
 
   /**
   * Radio Button Click Event
   */
   onItemChange(choiceId: string, index: number) {

      // Update the form control value
      this.form.controls[`responseField_${index}`].setValue(choiceId);
      
      let selectedItem;
  
      // Handle "Not Asked" separately
      if (choiceId === "is_not_asked") {
        selectedItem = { value: this.dataList[index].key, status: "NOT_ASKED" };
      } else {
        selectedItem = this.createSelectedItem(index);
      }
 
       // Default behavior (No changes to multi-select or non-multi-select cases)
       if (this.isMultiSelect) {
         const selectedItems = this.dataList
           .filter((item, i) => this.form.controls[`responseField_${i}`].value === "is_yes");
 
         if (this.isMultiEventEmit) {
           // Emit detailed information about selected items in multi-select mode
           const payload = selectedItems.map((item) => ({
             key: item.key,
             value: item.value, // Assuming each item has a 'value' field
             additionalInfo: item.additionalInfo, // Add other relevant fields if needed
           }));
           this.clickEvent.emit(payload);
         } else {
           // Default multi-select emission
           this.clickEvent.emit(selectedItems.map((data) => data?.key));
         }
       } else {
        this.clickEvent.emit([selectedItem]);
       }
    }

  /** Creates Selected Item Object */
  private createSelectedItem(index: number) {
    return {
      value: this.dataList[index].key,
      status: this.getFormattedStatus(this.form.controls[`responseField_${index}`].value),
    };
  }

  /** Checkbox Selection Handler */
  onItemCheckChange(event: any, index: number) {
    const isChecked = event.target.checked;

    if (this.isMultiSelect) {

      this.checkedItems[index] = isChecked;

      if (isChecked) {
        const selectedItem = this.createSelectedItem(index);
        this.selectedItems.push(selectedItem);
      } else {
        this.selectedItems = this.selectedItems.filter(item => item.value !== this.dataList[index].key);
      }

      this.clickEvent.emit(this.selectedItems);

    } else {
      // Single-Select Mode: Only one checkbox can be selected
      this.checkedItems.fill(false); // Reset all checkboxes
      this.checkedItems[index] = isChecked; // Set only the selected checkbox

      if (isChecked) {
        this.selectedItems = [this.createSelectedItem(index)];
      } else {
        this.selectedItems = [];
      }

      this.clickEvent.emit(this.selectedItems.length > 0 ? this.selectedItems[0] : null);
    }
  }

  /**  Converts "is_yes", "is_no", "is_unknown" to "YES", "NO", "UNKNOWN", "NOT_ASKED" */
  private getFormattedStatus(status: string): string {
    return status === "is_yes"
      ? "YES"
      : status === "is_no"
      ? "NO"
      : status === "is_not_asked"
      ? "NOT_ASKED"
      : "UNKNOWN";
  }  

 }
 
 