import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-section-headers',
  templateUrl: './section-headers.component.html',
  styleUrls: ['./section-headers.component.scss']
})
export class SectionHeadersComponent implements OnChanges, OnDestroy {
  // Input: Array containing details of buttons to be displayed in the header, with or without icons
  @Input() btnName: [];

  // Input: Array containing details of delete button to be displayed in the header, with or without icons
  @Input() deleteBtn: [];

  // Input: Placeholder text for the search input field
  @Input() searchPlaceholder: string;

  // Input: Permission string to determine whether the button should be displayed
  @Input() permission: string;

  // Input: Number representing the filter count
  @Input() filterCount = 0;

  // Output: Emits the string entered into the search input field
  @Output() sendSearch = new EventEmitter();

  // Output: Emits the keys when a button in the header is clicked
  @Output() showModal = new EventEmitter();

  searchDelay = new Subject<string>();
  isSearch = false;
  search: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cos: CommonService,
  ) {
    this.searchDelay.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            search: value.trim() === '' ? null : value.trim(),
          },
          queryParamsHandling: 'merge',
        });
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            page: null
          },
          queryParamsHandling: 'merge',
        });
        this.sendSearch.emit(value.trim());
      });
  }


  ngOnChanges() {
    this.cos.documentValue$.subscribe(data => {
      if (typeof data === 'string' && data === 'Search_Control') {
        this.isSearch = false;
        this.search = '';
      }
    });
  }

  public openModal(data) {
    if (data.key === 'search') {
      this.isSearch = !this.isSearch;
    } else {
      this.showModal.emit(data);
    }
  }

  // Function is used to prevent some special character 
  public onKeyDown(event: KeyboardEvent) {
    const forbiddenCharacters = ['\\', '[', '?', ')', '(', '*'];
    if (forbiddenCharacters.includes(event.key)) {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.sendSearch.unsubscribe();
  }

}
