import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../interfaces/common-interface';
import { ProjectData } from '../interfaces/project';
import {
  AvailableEmployee, AvailableEquipment, Clone, Conflicts, EquipmentCheck, ProfileResponse, Schedule,
  ScheduleCheck, ScheduleInterface, ScheduleListing,
  ScheduleMissingRequirement,
  ScheduleProject, ScheduleValidate, SecondaryPayload, UnAvailable, UpdateSchedule,
  UpdateShift
} from '../interfaces/scheduler';
import { EmployeeCount } from '../interfaces/auth';
import { EmployeeInfo } from '../interfaces/employee';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  schedule = 'aggregation/schedule/v1';
  scheduleList = 'aggregation/schedule/v3/list';
  clone = 'aggregation/schedule/v3/clone';
  support = 'aggregation/equipment/scheduled/available';
  employee = 'api/employee-schedule/available';
  updateData = 'aggregation/schedule/v3/epmployees-equipments';
  addEmploye = 'aggregation/schedule/v2/epmployees';
  equipData = 'aggregation/schedule/v2/equipments';
  availableEquipment = 'aggregation/equipment/available-equipment';
  availableEmployee = 'aggregation/schedule/v6/available-employees';
  nonAvailableEmpEquip = 'aggregation/schedule/v2/unavailable/employee-equipment';
  project = 'aggregation/project';
  check = 'aggregation/schedule/v4/check';
  conflictCount = 'aggregation/schedule/v5/conflicts-count';
  clientProject = 'aggregation/project';
  availableClientProject = 'aggregation/project/get-available-projects';
  supportEquipment = 'aggregation/equipment/list';
  scheduleValidate = 'aggregation/schedule/v6/validate-schedule';
  requirementsConflict = 'aggregation/schedule/v6/requirement-conflicts';
  scheduleAdditionalData = 'aggregation/additional-schedule/equipment-check';
  conflict = 'aggregation/scheduler-conflicts';
  scheduleMapping = 'aggregation/schedule-employee-mapping';
  updateShift = 'aggregation/schedule/v4/update-shift'

  constructor(
    private http: HttpClient
    ) { }

  reset = '';
  conflicts$ = new BehaviorSubject<number>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public emitResetModal = new BehaviorSubject<any>(this.reset);
  emitChanges$ = this.emitResetModal.asObservable();


  modalReset(change) {
    this.emitResetModal.next(change);
  }


  setConflicts(conflicts) {
    this.conflicts$.next(conflicts)
  }

  updateEmployeesDetails(data) {
    return this.http.put<ProjectData>(environment.BASE_URL + this.project, data);
  }

  updateField(data, query) {
    return this.http.put<UpdateSchedule>(`${environment.BASE_URL}${this.schedule}?doubleBooking=${query}`, data);
  }

  conflictsCount(data) {
    return this.http.get<EmployeeCount>(`${environment.BASE_URL}${this.conflictCount}?startDate=${data.startDate}&endDate=${data.endDate}`);
  }

  getEmployeeConflicts(data) {
    return this.http.get<Conflicts>(`${environment.BASE_URL}${this.conflict}/employee?startDate=${data.startDate}&endDate=${data.endDate}&pagination=${data.pagination}&page=${data.pageNumber}&limit=${data.noOfRecord}`);
  }

  getEquipmentConflicts(data) {
    return this.http.get<Conflicts>(`${environment.BASE_URL}${this.conflict}/equipment?startDate=${data.startDate}&endDate=${data.endDate}&pagination=${data.pagination}&page=${data.pageNumber}&limit=${data.noOfRecord}`);
  }

  getGroupConflicts(data) {
    return this.http.get<Conflicts>(`${environment.BASE_URL}${this.conflict}/groups?startDate=${data.startDate}&endDate=${data.endDate}&pagination=${data.pagination}&page=${data.pageNumber}&limit=${data.noOfRecord}`);
  }

  /**
   * 
   * @param data 
   * @returns 
   * Function to get the missing requirements
   */
  requirementConflict(data) {
    return this.http.get<ScheduleMissingRequirement>(`${environment.BASE_URL}${this.requirementsConflict}?startDate=${data.startDate}&endDate=${data.endDate}&pagination=${data.pagination}&page=${data.pageNumber}&limit=${data.noOfRecord}`);
  }

  getEmployeeCombined(data) {
    return this.http.get<EmployeeInfo>(`${environment.BASE_URL}${this.employee}?startDate=${data.startDate}&endDate=${data.endDate}&shift=${JSON.stringify(data.shift)}&type=${data.type}`);
  }

  createJob(data) {
    return this.http.post<Schedule>(environment.BASE_URL + this.schedule, data);
  }

  createBooking(data, query) {
    return this.http.post<Clone>(`${environment.BASE_URL}${this.clone}?doubleBooking=${query}`, data);
  }

  getScheduleList(data) {
    return this.http.get<ScheduleListing>(`${environment.BASE_URL}${this.scheduleList}?pagination=${data.pagination}&page=${data.page}&startDate=${data.startDate}&endDate=${data.endDate}&limit=${data.noOfRecord}&search=${data.search}`);
  }

  getAllSchedule() {
    return this.http.get<ScheduleListing>(`${environment.BASE_URL}${this.scheduleList}`);
  }

  updateEmployees(data) {
    return this.http.put<Clone>(environment.BASE_URL + this.addEmploye, data);
  }

  deleteEquipEmpData(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    return this.http.delete<Message>(environment.BASE_URL + this.updateData, options);
  }

  deleteSchedule(data) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.schedule}?schedulerId=${data}`);
  }

  getAvailableEquipment(data) {
    return this.http.get<AvailableEquipment>(`${environment.BASE_URL}${this.availableEquipment}?startDate=${data.startDate}&endDate=${data.endDate}&category=${JSON.stringify(data.category)}&subCategory=${JSON.stringify(data.subCategory)}&shift=${JSON.stringify(data.shift)}&search=${data.search}`);
  }

  getAvailableEmployee(data) {
    return this.http.get<AvailableEmployee>(`${environment.BASE_URL}${this.availableEmployee}?startDate=${data.startDate}&endDate=${data.endDate}&search=${data.search}&shift=${JSON.stringify(data.shift)}`);
  }

  getNonAvailableWeekEmpEquip(data) {
    return this.http.post<UnAvailable>(environment.BASE_URL + this.nonAvailableEmpEquip, data);
  }


  deleteJob(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };

    return this.http.delete<Message>(environment.BASE_URL + this.schedule, options);
  }

  getEmployeeDetails(data) {
    return this.http.get<ScheduleInterface>(`${environment.BASE_URL}${this.nonAvailableEmpEquip}?startDate=${data.startDate}&endDate=${data.endDate}&schedulerId=${data.scheduleId}&shift=${JSON.stringify(data.shift)}`);
  }

  checkSchedule(data) {
    return this.http.post<ScheduleCheck>(environment.BASE_URL + this.check, data);
  }

  getClientProject(data) {
    return this.http.get<ScheduleProject>(`${environment.BASE_URL}${this.clientProject}?_client=${data}&pagination=false`);
  }

  getAvailableClientProjects(data) {
    return this.http.get<ScheduleProject>(`${environment.BASE_URL}${this.availableClientProject}?client=${data}&pagination=true`);
  }

  validateSchedule(data) {
    return this.http.post<ScheduleValidate>(environment.BASE_URL + this.scheduleValidate, data);
  }

  getEquipmentMapping(data) {
    return this.http.get<EquipmentCheck>(`${environment.BASE_URL}${this.scheduleAdditionalData}?schedulerId=${data.schedulerId}&id=${data.id}`);
  }

  submitProfile(data) {
    return this.http.post<SecondaryPayload>(environment.BASE_URL + this.scheduleMapping, data);
  }

  getProfile(data) {
    return this.http.get<ProfileResponse>(`${environment.BASE_URL}${this.scheduleMapping}/employee-ids?scheduleId=${data.schedulerId}`);
  }

  updateSchedulerShift(data) {
    return this.http.put<UpdateShift>(environment.BASE_URL + this.updateShift, data);
  }

}
