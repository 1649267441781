<div class="modal right-lightbox modal right fade" id="rightModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modalStyle">
        <h4 class="modal-title" id="myModalLabel2">{{modalDetails?.heading}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">×</span></button>
      </div>
      <!-- Modal Sub Heading -->
      <div class="modal-body">
        <div class="tabling-boxt6 rightSubHead">
          <ul class="nav nav-tabs modl-nabs pl-2">
            <li *ngFor="let data of modalDetails?.subHeading"><a class="nav-link"
                [ngClass]="{ 'active': activeTab === data.id }" (click)="changeTab(data.id)">{{data.title}}</a></li>
          </ul>
        </div>

        <!-- Modal Sub Heading's contents-->
        <div class="tab-content mb-5">

          <div class="tab-pane project-form-common" *ngFor="let item of modalDetails?.subHeading"
            [ngClass]="{ 'active': activeTab === item.id }" [id]="item.id">
            <ng-container *ngIf="item.data.length">

              <ng-container *ngIf="item.id !== 'docs' && item.id!=='activity-log' ">
                <div class="row">
                  <div class="col-12 row justify-content-between">

                    <!-- Show sub header -->
                    <ng-container *ngFor="let info of item.data">
                      <div class="col-12 mt-1" *ngIf="info.style">
                        <span class="titleStyle" *ngIf="info.title">
                          {{info.title}}
                        </span>
                      </div>

                      <!-- Show information for notes, description, summary, signature under border formatted -->
                      <ng-container *ngIf="info.isSummary && info?.value?.trim()">
                        <div class="col-12 details mt-1 mb-3">
                          <div class="title" *ngIf="info.title">
                            {{info.title}}:
                          </div>
                          <div class="notesValue p-1 mt-1">
                            {{info.value ? info.value : 'N/A'}}
                          </div>

                        </div>
                      </ng-container>

                      <!-- Show information for other detail not in a box format -->
                      <ng-container *ngIf="!info.isSummary">
                        <div class="col-6 details mt-2" *ngIf="!info.style">
                          <span class="title" *ngIf="info.title">
                            {{info.title}}:
                          </span>
                          <span class="value pl-1" *ngIf="!info.isSignature" (click)="info.title === 'View' ? openDocument(info.value) : null">
                            <a href=""></a>
                            <ng-container *ngIf="!info.title.includes('URL') && !info.title.includes('Business Hours')">
                              {{info.value}}
                            </ng-container>
                            <ng-container *ngIf="info.title === 'Business Hours'">
                              <span class="value pl-1" [innerHTML]="info.value"></span>
                            </ng-container>
                          </span>
                          <span *ngIf="info.isSignature">
                            <img [src]="info.value" alt="No Image Found">
                          </span>

                          <mat-icon *ngIf="info.title.includes('URL')" (click)="this.cos.openUrl(info)"
                            style="position: absolute;"
                            [ngClass]="{'cursor-pointer': info.title.includes('URL')}">insert_drive_file
                          </mat-icon>

                        </div>
                      </ng-container>

                      

                      <hr *ngIf="info.margin" class="my-2">

                    </ng-container>
                  </div>
                </div>
              </ng-container>


              <!-- Map  -->
              <div *ngIf="item.isMap" class="position-relative mb-2" style="height: 310px">
                <agm-map style="height: 300px" [latitude]="item.latitude"
                  [longitude]="item.longitude" [disableDefaultUI]="true" [zoomControl]="true">
                  <agm-circle [latitude]="item.latitude" [longitude]="item.longitude"
                    [radius]="item.radius ? item.radius * 0.3048:'0'" [fillColor]="'gray'"
                    [fillOpacity]="0.3" [strokeColor]="'orangered'" [strokeWeight]="1"></agm-circle>
                  <agm-marker [latitude]="item.latitude"
                    [longitude]="item.longitude"></agm-marker>
                </agm-map>
              </div>

              <!-- Document section -->
              <ng-container *ngIf="item.id === 'docs'">
                    <div *ngFor="let docs of item.data">
                      <ng-container *ngIf="docs.title !== 'Notes'">
                        <div class="details mt-2" [ngClass]="{'d-flex': docs.title.includes('Link')}">
                          <span class="title">
                            {{docs.title}}:
                          </span>
                          <span class="value pl-1 noteField" [ngClass]="{'cursor-pointer': docs.title.includes('URL')}"
                            [ngStyle]="{'color': docs.title.includes('URL') || docs.title.includes('Link') ? '#1B96C3' : 'gray'}"
                            (click)="this.cos.openUrl(docs)">
                          
                              <mat-icon *ngIf="docs.title.includes('URL')" (click)="this.cos.openUrl(docs)"
                                style="position: absolute;"
                                [ngClass]="{'cursor-pointer': docs.title.includes('URL')}">insert_drive_file
                              </mat-icon>
        
                            <ng-container *ngIf="!docs.title.includes('URL')">
                              {{docs.value}}
                            </ng-container>
                          </span>

                        </div>
                      </ng-container>

                      <!-- Fot note section -->
                      <ng-container *ngIf="docs.title === 'Notes'">
                        <div class="details mt-1 mb-3">
                          <div class="title">
                            {{docs.title}}:
                          </div>
                          <div class="notesValue p-1 mt-1">
                            {{docs.value ? docs.value : 'N/A'}}
                          </div>
                        </div>
                      </ng-container>

                    </div>
              </ng-container>

              <!-- Activity log -->
              <ng-container *ngIf="item.id === 'activity-log'">

                <!-- display this, if no changes are done yet -->
                <ng-container *ngIf="item.data[0]?.changes?.length===0">
                  <div class="my-2 px-2" style="border-bottom:1px solid #d4d4d4;background-color:#e0e0e07a">
                    <span style="font-size:15px" class="my-2">
                      {{item.data[0]?.createdBy?.userName}} created this record on
                      {{this.cos.getFormattedDate(item.data[0].updatedAt)}}</span>
                  </div>
                </ng-container>

                <!-- display this, if changes are done -->
                <div *ngFor="let log of item.data;let i=index" class="mt-3 mr-1">
                  <ng-container *ngIf="log?.changes?.length">
                    <div class="d-flex my-1 px-2" style="border-bottom:1px solid #d4d4d4;background-color:#e0e0e07a">
                      <span style="font-size:15px" class="my-2 text-capitalize">
                        <b>{{log?.createdBy?.userName}}</b> changed following data on
                        {{this.cos.getFormattedDate(log?.updatedAt)}}</span>
                    </div>
                    <div class="px-2 pb-2">
                      <div class="d-flex justify-content-between font-weight-bold my-1" style="font-size:14px">
                        <span>Field Name</span>
                        <span>Updated Value</span>
                      </div>
                      <div *ngFor="let data of log.changes" class="row pt-1" style="font-size:14px">
                        <div class="col-6">{{data.key | titlecase}}</div>
                        <div class="col-6" style="text-align:end">{{getValues(data.nextState,data.key )}}</div>

                      </div>
                    </div>
                  </ng-container>
                </div>

              </ng-container>

              <ng-content></ng-content>

            </ng-container>

            <ng-container *ngIf="item.data.length===0">
              <div class="mt-5 text-center">No Record Found</div>
            </ng-container>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>