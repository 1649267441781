import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';
import { RightModal } from '../../../../interfaces/common-interface';

@Component({
  selector: 'app-right-modal',
  templateUrl: './right-modal.component.html',
  styleUrls: ['./right-modal.component.scss']
})
export class RightModalComponent implements OnChanges {
  @Input() modalDetails: RightModal;
  activeTab: string;
  constructor(
    public cos: CommonService, private renderer: Renderer2
  ) { }


  ngOnChanges() {
    if (this.modalDetails && this.modalDetails.heading) {
      this.activeTab = this.modalDetails.subHeading[0].id;
    }
  }

  changeTab(tab: string): void {
    this.activeTab = tab;
  }

  getValues(data, key: string) {
    if (data) {
      if (key === 'documents') {
        return 'Document Updated';
      } else {
        if (Array.isArray(data)) {
          return data.map(val => val);
        } else {
          return data;
        }
      }
    }
  }
  openDocument(value: string) {
    if (value.toLowerCase().endsWith('.pdf,.jpg,.png,.jpeg')) {
      const anchor = this.renderer.createElement('a');
      anchor.href = '';
      anchor.target = '_blank';
      anchor.click();
    } 
}
}