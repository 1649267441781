import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'defaultDate' })
export class DefaultDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {

  }

  transform(value, ...args) {
    if (args[0] === 'date') {
      return this.datePipe.transform(value, 'mm-dd-yyyy');
    } else if (args[0] === 'time') {
      return this.datePipe.transform(value, 'hh:mm aaa');
    } else if (args[0] === 'dateTime') {
      return this.datePipe.transform(value, 'yyyy-MM-dd hh:mm aaa');
    } else {
      return 'date, time, dateTime';
    }
  }

}
