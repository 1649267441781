import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AllVendors, CategoryPayload, CategoryResponse, CommonOrder, ContactList, ContactPayload, EmailLogs, VendorList, VendorPayload } from '../interfaces/vandor';
import { Message } from '../interfaces/common-interface';
@Injectable({
  providedIn: "root",
})
export class VendorsService {
  VENDOR_LIST = "configuration/vendor-item-category/list";
  VENDOR_CRUD = "configuration/vendor-item-category";

  LIST = "api/vendor/list";
  LIST_CRUD = "api/vendor";
  ALL_VENDOR = "api/vendor/all-vendors";

  CONTACT_LIST = "api/vendor/contact/list";
  CONTACT_CRUD = "api/vendor/contact";

  ITEM_DOWNLOAD = "api/vendor-items/excel-download";
  ITEM_UPLOAD = "api/vendor-items/excel-upload";

  VENDOR_DOWNLOAD = "api/vendor/excel-download";
  VENDOR_UPLOAD = "api/vendor/excel-upload";

  VENDOR_BRANCH = "api/vendor-branches";
  SEND_MAIL = "api/vendor-items/send-email";
  SEND_VENDOR_MAIL = "api/vendor/send-mail";
  GET_EMAIL_LIST = "api/vendor-items/get-email-list";

  COMMON_ORDER = "api/vendor/update-common-order";

  constructor(private http: HttpClient) {}

  /**
   * Vendor Categories services
   */
  getList(search) {
    return this.http.get<CategoryResponse>(
      `${environment.BASE_URL}${this.VENDOR_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`
    );
  }

  getEmailList(search) {
    return this.http.get<EmailLogs>(
      `${environment.BASE_URL}${this.GET_EMAIL_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&startDate=${search.startDate}&endDate=${search.endDate}&createdBy=${JSON.stringify(search._user)}&vendor=${JSON.stringify(search.vendorId)}`
    );
  }

  vendorCategory() {
    return this.http.get<CategoryResponse>(
      `${environment.BASE_URL}${this.VENDOR_LIST}?pagination=false&sort=true`
    );
  }

  createCategory(data) {
    return this.http.post<CategoryPayload>(
      environment.BASE_URL + this.VENDOR_CRUD,
      data
    );
  }

  updateCategory(data) {
    return this.http.put<CategoryPayload>(
      environment.BASE_URL + this.VENDOR_CRUD,
      data
    );
  }

  checkCategory(data) {
    return this.http.get<CategoryPayload>(
      `${environment.BASE_URL}${this.VENDOR_CRUD}/check?id=${JSON.stringify(
        data
      )}`
    );
  }

  deleteCategory(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.VENDOR_CRUD,
      options
    );
  }

  /**
   * Vendor list services
   */
  getVendorList(search) {
    let url = `${environment.BASE_URL}${this.LIST}?pagination=${
      search.pagination
    }&search=${search.search}&page=${search.pageNumber}&limit=${
      search.noOfRecord
    }&category=${JSON.stringify(search.category)}&keywords=${JSON.stringify(
      search.keyword
    )}&isLocalized=${search.isLocalized}&maxDistance=${
      search.maxDistance
    }&pickup=${search.pickup}&delivery=${search.delivery}&sort=true`;

    if (search.latitude && search.longitude) {
      url += `&latitude=${search.latitude}&longitude=${search.longitude}`;
    }

    return this.http.get<VendorList>(url);
  }

  getAllVendor() {
    return this.http.get<any>(`${environment.BASE_URL}${this.LIST}`);
  }

  getVendors() {
    return this.http.get<AllVendors>(`${environment.BASE_URL}${this.ALL_VENDOR}`);
  }
  

  getVendorDetails(id: string) {
    return this.http.get<VendorPayload>(
      `${environment.BASE_URL}${this.LIST_CRUD}?id=${id}`
    );
  }

  deleteVendor(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.LIST_CRUD,
      options
    );
  }

  addVendor(data) {
    return this.http.post<VendorPayload>(
      environment.BASE_URL + this.LIST_CRUD,
      data
    );
  }

  updateVendor(data) {
    return this.http.put<VendorPayload>(
      environment.BASE_URL + this.LIST_CRUD,
      data
    );
  }

  downloadVendor() {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.VENDOR_DOWNLOAD}`
    );
  }

  uploadVendor(data) {
    return this.http.post<Message>(
      environment.BASE_URL + this.VENDOR_UPLOAD,
      data
    );
  }

  /**
   * Vendor Contact services
   */
  getContact(id: string) {
    return this.http.get<ContactList>(
      `${environment.BASE_URL}${this.CONTACT_LIST}?pagination=true&vendor=${id}`
    );
  }

  getContactDetail(id: string) {
    return this.http.get<ContactPayload>(
      `${environment.BASE_URL}${this.CONTACT_CRUD}?id=${id}`
    );
  }

  addContact(data) {
    return this.http.post<ContactPayload>(
      environment.BASE_URL + this.CONTACT_CRUD,
      data
    );
  }

  updateContact(data) {
    return this.http.put<ContactPayload>(
      environment.BASE_URL + this.CONTACT_CRUD,
      data
    );
  }

  deleteContact(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.CONTACT_CRUD,
      options
    );
  }

  /**
   * Vendor Item Upload services
   */

  downloadSample() {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.ITEM_DOWNLOAD}`
    );
  }

  uploadExcel(data) {
    return this.http.post<Message>(
      environment.BASE_URL + this.ITEM_UPLOAD,
      data
    );
  }

  /**
   * Vendor Branch Services
   */

  branchList(search) {
    return this.http.get<any>(
      `${environment.BASE_URL}${this.VENDOR_BRANCH}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&vendor=${search.vendor}&sort=true`
    );
  }

  allBranchList(id: string) {
    return this.http.get<any>(
      `${environment.BASE_URL}${this.VENDOR_BRANCH}?pagination=false&vendor=${id}`
    );
  }

  deleteBranches(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.VENDOR_BRANCH,
      options
    );
  }

  addBranch(data) {
    return this.http.post<any>(environment.BASE_URL + this.VENDOR_BRANCH, data);
  }

  updateBranch(data) {
    return this.http.put<any>(environment.BASE_URL + this.VENDOR_BRANCH, data);
  }

  /**
   * Send Order Email
   * @param data request payload
   */
  sendEmail(data) {
    return this.http.post<Message>(environment.BASE_URL + this.SEND_MAIL, data);
  }

  /**
   * Send vendor Email
   * @param data request payload
   */
  sendVendorEmail(data) {
    return this.http.post<Message>(
      environment.BASE_URL + this.SEND_VENDOR_MAIL,
      data
    );
  }

  // Api for common order updation
  updateCommonOrder(data) {
    return this.http.put<CommonOrder>(
      environment.BASE_URL + this.COMMON_ORDER,
      data
    );
  }
}
