import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/_services/common.service';
import { GroupService } from 'src/app/_services/group.service';
import { ProjectService } from 'src/app/_services/project.service';
import { ScheduleService } from 'src/app/_services/schedule.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ScheduleRightModal, ScheduleShift, AssignmentsByDate, ScheduleDetail, DeleteEquipmentData } from '../../../../interfaces/scheduler';
import 'bootstrap';
import * as jQuery from 'jquery';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss']
})
export class ScheduleDetailsComponent implements OnInit, OnChanges {
  get Dates() {
    return (this.addEmployeeForm.get('dateFormArr') as UntypedFormArray).controls;
  }
  get jobEmployees() {
    return (this.addEmployeeForm.get('empFormArr') as UntypedFormArray).controls;
  }
  get getDateFormArr() {
    return (this.addEmployeeForm.get('dateFormArr') as UntypedFormArray);
  }
  get getEmpFormArr() {
    return (this.addEmployeeForm.get('empFormArr') as UntypedFormArray);
  }
  scheduleArr(index: number) {
    return (((this.addEmployeeForm.get('dateFormArr') as UntypedFormArray).controls[index] as UntypedFormGroup).get('scheduleArr') as UntypedFormArray).controls;
  }
  scheduleArrForm(index: number) {
    return (((this.addEmployeeForm.get('dateFormArr') as UntypedFormArray).controls[index] as UntypedFormGroup).get('scheduleArr') as UntypedFormArray);
  }
  @Output() reloadSchedule = new EventEmitter();
  @Input() modalId = 'rightModal';
  @Input() details: ScheduleRightModal;
  @Input() shift: ScheduleShift;
  @Input() type = '';
  @Input() weekDate = [];
  permission = { edit: 'schedule.write' };
  scheduleStatus: string;
  scheduleGroup = [];
  empName = [];
  dateArray;
  startDate;
  endDate;
  editButton = false; // use for mouse event functionality
  employeeDate = [];
  uniqueEmpDate: AssignmentsByDate;
  equipmentDate = [];
  uniqueEquipDate: AssignmentsByDate;
  equipmentId = [];
  scheduleDetail = [];
  empNameDetails = [];
  groupEmployeesList = [];
  uniqueDate: AssignmentsByDate | undefined;
  scheduleEmployees = [];
  scheduleEquipment = [];
  empIndOption = false;
  grpIndOption = [false];
  checkValues = [];
  employeeDetails = [];
  updateDateForm = this.fb.group({
    startDate: [''],
    endDate: ['']
  });
  addEmployeeForm = this.fb.group({
    startDate: [''],
    endDate: [''],
    type: [''],
    shift: [''],
    employees: [],
    assigned: [''],
    group: [''],
    dateFormArr: this.fb.array([]),
    empFormArr: this.fb.array([]),
    _id: [''],
  });
  createDateForm(date) {
    return this.fb.group({
      date: [date],
      scheduleArr: this.fb.array([]),
      equipment: [''],
      shift: ['FIRST_SHIFT'],
      employees: [],
      group: [],
      assigned: [''],
    });
  }
  createEmpForm(emp: string) {
    return this.fb.group({
      driller: [emp],
      shift: ['FIRST_SHIFT'],
      id: ['']
    });
  }
  groupsDate = [];
  schedulePermission: boolean;
  uniqueGroupDate: AssignmentsByDate;
  groupList = [];
  scheduleInfo = [];
  modalValue: ScheduleDetail;
  support = [];
  rigs = [];
  groups = [];
  employees = [];
  editNote = false; // used in html for mouse event
  editDateButton = false // used in html for mouse event
  constructor(
    public cos: CommonService,
    private projectSrvc: ProjectService,
    private scheduleSrvc: ScheduleService,
    private fb: UntypedFormBuilder,
    private grpSrvc: GroupService,) {
    this.getSchedulePermission();
  }

  ngOnInit() {
    this.getGroupList();
    this.scheduleSrvc.emitChanges$.subscribe(data => {
      if (data === 'SCHEDULE_DETAIL') {
        this.getDetails();
      }
    });
  }

  ngOnChanges() {
    if (!Array.isArray(this.details)) {
      this.getDetails();
    }
  }

  // get permission of access control
  private getSchedulePermission() {
    this.cos.getPermissions().subscribe((res) => {
      if (res) {
        const result = res.data.resources;
        const permission = 'schedule.write';
        if (result.includes(permission)) {
          this.schedulePermission = false;
        } else {
          this.schedulePermission = true;
        }
      }
    }, (err) => { this.cos.handleError(err); });
  }

  private getGroupList() {
    this.grpSrvc.getGroupList().subscribe(res => {
      this.groupList = res.data;
    });
  }

  private async getDetails() {
    this.scheduleInfo.length = 0;
    this.empNameDetails = [];
    this.groupEmployeesList = [];
    this.uniqueDate = {};
    this.groups = [];
    this.employees = [];
    this.support = [];
    this.rigs = [];

    const data = {
        startDate: this.details?.scheduler ? this.details?.scheduler?.jobDateRange[0]?.from : this.details?.jobDateRange[0]?.from,
        endDate: this.details?.scheduler ? this.details?.scheduler?.jobDateRange[0]?.to : this.details?.jobDateRange[0]?.to,
        scheduleId: this.details.scheduler ? this.details.scheduler?._id : this.details._id,
        shift: [this.shift._id.shift]
    };

    // First, call getEmployeeDetails API
    this.scheduleSrvc.getEmployeeDetails(data).subscribe((res) => {
        if (res) {
            this.scheduleInfo = res.data;

            // Then, call getProfile API
            this.scheduleSrvc.getProfile({ schedulerId: data.scheduleId }).subscribe(profileDetails => {
                // Create a map for quick profile lookup by employee ID
                const profileMap = new Map<string, { profile: string, updatedAt: string }>();
                profileDetails.data.forEach(profile => {
                    const empId = profile._employee?._id;
                    if (empId) {
                        // Keep the profile with the latest updatedAt timestamp
                        const existingProfile = profileMap.get(empId);
                        if (!existingProfile || new Date(profile.updatedAt) > new Date(existingProfile.updatedAt)) {
                            profileMap.set(empId, { profile: profile._profile, updatedAt: profile.updatedAt });
                        }
                    }
                });

                if (this.scheduleInfo.length) {
                    this.getDates();
                    this.getScheduleStatus();
                    this.scheduleDetail = this.scheduleInfo;

                    for (let i = 0; i < this.scheduleDetail.length; i++) {
                        // Employees
                        this.scheduleDetail[i].employees.map((employee) => {
                            const profileInfo = profileMap.get(employee._id);
                            this.empNameDetails.push({
                                date: res.data[i]._id,
                                name: employee.userName + (profileInfo ? ` (${profileInfo.profile})` : ''),
                                id: employee._id,
                                assigned: 'employees'
                            });
                        });

                        // Groups and group employees
                        const groupEmployeesTemp = [];
                        this.scheduleDetail[i].groups.map((group) => {
                            this.empNameDetails.push({
                                date: this.scheduleInfo[i]._id,
                                name: group.name,
                                id: group._id,
                                assigned: 'groups'
                            });

                            group?.employees?.forEach((groupEmp) => {
                                const profileInfo = profileMap.get(groupEmp._id);
                                groupEmployeesTemp.push({
                                    date: res?.data[i]?._id,
                                    name: (groupEmp.nickName || groupEmp.userName) + (profileInfo ? ` (${profileInfo.profile})` : ''),
                                    id: groupEmp._id,
                                    assigned: 'groupEmployees'
                                });
                            });
                        });

                        // Merge group employees for this schedule and ensure uniqueness
                        this.groupEmployeesList[i] = this.getUniqueListBy(groupEmployeesTemp, 'id');

                        // Equipments (including rigs)
                        this.scheduleDetail[i].equipments.forEach((element) => {
                            const isRig = element.category?.isRig;
                            const disposedDate = element.disposedDate ? this.cos.getFormattedDate(element.disposedDate) : null;
                            const startDate = this.cos.getFormattedDate(data.startDate);

                            if (!disposedDate || disposedDate >= startDate) {
                                if (isRig) {
                                    this.empNameDetails.push({
                                        date: res?.data[i]?._id,
                                        name: element.name,
                                        id: element._id,
                                        assigned: 'rigs'
                                    });
                                } else {
                                    this.empNameDetails.push({
                                        date: res?.data[i]?._id,
                                        name: element.name,
                                        id: element._id,
                                        category: element.category?.name || '',
                                        subCategory: element.subCategory?.name || '',
                                        assigned: 'equipment'
                                    });
                                }
                            }
                        });
                    }

                    // Process unique employees, groups, rigs, and equipment
                    let keys = [];
                    this.uniqueDate = {};
                    this.empNameDetails.forEach((item) => {
                        if (!this.uniqueDate[item.date]) {
                            this.uniqueDate[item.date] = [{ name: item.name, id: item.id, assigned: item.assigned }];
                            keys.push(item.date);
                        } else {
                            this.uniqueDate[item.date].push({ name: item.name, id: item.id, assigned: item.assigned });
                            keys.push(item.date);
                        }
                    });

                    keys = [...new Set(keys)];
                    for (let i = 0; i < keys.length; i++) {
                        const emp = [];
                        const rigEquipSupport = [];
                        const grp = [];
                        const grpEmp = [];

                        this.uniqueDate[keys[i]].forEach((val) => {
                            if (val.assigned === 'employees') {
                                emp.push({ name: val.name, id: val.id, key: keys[i] });
                                this.employees[i] = this.getUniqueListBy(emp, 'id');
                            } else if (val.assigned === 'groups') {
                                grp.push({ name: val.name, id: val.id, key: keys[i] });
                                this.groups[i] = this.getUniqueListBy(grp, 'id');
                            } else if (val.assigned === 'groupEmployees') {
                                grpEmp.push({ name: val.name, id: val.id, key: keys[i] });
                                this.groupEmployeesList[i] = [...this.groupEmployeesList[i], ...this.getUniqueListBy(grpEmp, 'id')];
                            } else if (val.assigned === 'rigs') {
                                rigEquipSupport.push({ name: val.name, id: val.id, key: keys[i], isRig: true });
                            } else if (val.assigned === 'equipment') {
                                rigEquipSupport.push({ name: val.name, id: val.id, key: keys[i], isRig: false });
                            }
                        });

                        this.rigs[i] = this.getUniqueListBy(rigEquipSupport.filter(item => item.isRig), 'id');
                        this.support[i] = this.getUniqueListBy(rigEquipSupport.filter(item => !item.isRig), 'id');
                    }
                } else {
                    this.scheduleDetail = [];
                }
            }, err => this.cos.handleError(err));
        }
    }, err => this.cos.handleError(err));
}
  


  public checkData(data) {
    if (Array.isArray(data) && data.length) {
      return true;
    } else {
      return false;
    }
  }

  private reloadScreen() {
    const scheduleInfo = [{
      type: this.type,
      startDate: this.weekDate[0] ? this.weekDate[0].startDate : '',
      endDate: this.weekDate[0] ? this.weekDate[0].endDate : '',
    }];
    this.projectSrvc.emitChange(scheduleInfo);
  }

  private getUniqueListBy<T>(arr: T[], id: keyof T): T[] {
    const seen = new Set();
    return arr.filter(item => {
      const key = item[id];
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    });
  }

  private getScheduleStatus() {
    const startTime = moment(this.scheduleInfo[0].rangeId.jobDateRange[0].from).subtract(1, 'd').toDate();
    const endTime = moment(this.scheduleInfo[0].rangeId.jobDateRange[0].to).subtract(1, 'd').toDate();
    const todayTime = moment(new Date()).toDate();
    const updatedStartDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
    const updatedEndDate = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());
    const currentDate = new Date(todayTime.getFullYear(), todayTime.getMonth(),
      todayTime.getDate());
    if (currentDate < updatedStartDate) {
      this.scheduleStatus = 'Not Started';
    } else if (currentDate <= updatedStartDate) {
      if (currentDate <= updatedEndDate) {
        this.scheduleStatus = 'In-Progress';
      } else {
        this.scheduleStatus = 'Completed';
      }
    } else if (currentDate > updatedStartDate) {
      if (currentDate > updatedEndDate) {
        this.scheduleStatus = 'Completed';
      } else {
        this.scheduleStatus = 'In-Progress';
      }
    } else {

      this.scheduleStatus = 'N/A';
    }

  }

  // to set the background color of schedule status
  public getBackground(value) {
    if (value === 'Completed') {
      return 'var(--primary-color)';
    } else if (value === 'In-Progress') {
      return 'green';
    } else {
      return 'gray';
    }
  }

  // to set the width of job name
  public getRows(data) {
    if (data && data.length > 200) {
      return 10;
    } else if (data && data.length > 100) {
      return 6;
    } else if (data && data.length > 10) {
      return 3;
    } else {
      return 1;
    }
  }

  // to change the name of job
  public applyFilter(event) {
    const value = event.target.value;
    if (value.length > 30) {
      return this.cos.toastMessage(response.en.JOBNAME_LIMIT, 'warning');
    }
    const data = {
      schedulerId: this.scheduleInfo[0].rangeId._id,
      name: value ? value.trim() : '',
    };
    const query = false;
    this.scheduleSrvc.updateField(data, query).subscribe(res => {
      if (res) {
        this.cos.toastMessage(res.message, 'success');
        this.getDetails();
        this.reloadScreen();
        this.reloadSchedule.emit('Reload');
      } else {
        this.cos.toastMessage(res.message, 'error');
      }

    }, err => this.cos.handleError(err));
  }

  // patch value to update date modal
  public scheduleIndex() {
    jQuery('#UpdateDate').modal('show');
    this.updateDateForm.patchValue({
      startDate: moment(this.scheduleInfo[0].rangeId.jobDateRange[0].from).toDate(),
      endDate: moment(this.scheduleInfo[0].rangeId.jobDateRange[0].to).toDate(),
    });
    this.employeeDate.length = 0;
    this.equipmentDate.length = 0;
    this.uniqueEmpDate = {};
    this.uniqueEquipDate = {};
  }

  // update dates of schedule
  public submitDate() {
    if (!this.updateDateForm.value.startDate) {
      return this.cos.toastMessage(response.en.START, 'warning');
    } else if (!this.updateDateForm.value.endDate) {
      return this.cos.toastMessage(response.en.END, 'warning');
    } else if (new Date(this.updateDateForm.value.startDate).valueOf() > new Date(this.updateDateForm.value.endDate).valueOf()) {
      return this.cos.toastMessage(response.en.START_END, 'warning');
    } else {
      const body = {
        employees: this.scheduleEmployees,
        schedulerId: this.scheduleInfo[0].rangeId._id,
        jobDateRange: [
          {
            from: this.updateDateForm.value.startDate,
            to: this.updateDateForm.value.endDate,
            rangeType: this.scheduleInfo[0].rangeId.jobDateRange[0].rangeType,
          }
        ]
      };
      const query = false;
      this.scheduleSrvc.updateField(body, query)
        .subscribe(res => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            jQuery('#UpdateDate').modal('hide');
            jQuery('#rightModal').modal('hide');
            this.reloadSchedule.emit('Reload');
          } else {
            this.cos.toastMessage(res.message, 'warning');
          }
        }, (err) => { this.cos.handleError(err); });
    }
  }

  // return uniques date of employee, equipment,group
  public getKeys(data) {
    if (data) {
      return Object.keys(data);
    }
  }

  // Method to get the day of the week in abbreviated format (Sun, Mon, etc.)
  public getDayOfWeek(dateString: string): string {
    const date = new Date(dateString);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek[date.getDay()]; // Use getDay() for local time
  }

  // functionality for making payload for check api
  public async dateFilter() {
    const dateArrayValues = await this.getDateArray(this.updateDateForm.value.startDate, this.updateDateForm.value.endDate);
  
    // Handling employees
    if (this.scheduleDetail[0].employees.length > 0) {
      this.scheduleDetail[0].employees.map((value) => {
        this.scheduleEmployees.push(value._id);
      });
    } else {
      this.scheduleEmployees = [];
    }
  
    // Handling equipment and rigs separately
    const scheduleRigs = []; // Array to store rigs IDs
    if (this.scheduleDetail[0].equipments.length > 0) {
      this.scheduleDetail[0].equipments.map((value) => {
        const isRig = value.category?.isRig;
        if (isRig) {
          scheduleRigs.push(value._id); // Push to rigs array
        } else {
          this.scheduleEquipment.push(value._id); // Push to equipment array
        }
      });
    } else {
      this.scheduleEquipment = [];
    }
  
    // Handling groups
    if (this.scheduleDetail[0].groups.length > 0) {
      this.scheduleDetail[0].groups.map((value) => {
        this.scheduleGroup.push(value._id);
      });
    } else {
      this.scheduleGroup = [];
    }
  
    this.checkValues.length = 0;
    for (let i = 0; i < dateArrayValues.length; i++) {
      this.checkValues.push(
        {
          date: moment(dateArrayValues[i]).add(1, 'd').toISOString(),
          shift: this.scheduleInfo[0]._id.shift,
          equipments: this.scheduleEquipment,
          rigs: scheduleRigs, // Adding rigs to checkValues
          employees: this.scheduleEmployees,
          groups: this.scheduleGroup
        }
      );
    }
  
    const data = {
      schedulerId: this.details.scheduler ? this.details.scheduler._id : this.details._id,
      date: this.checkValues
    };
    
    this.checkSchEmployee(data);
  }
  

  // return array of dates
  private getDateArray(start, end) {
    const updateStart = new Date(start);
    const updateEnd = new Date(end);
    const arr = [];
    const date = new Date(updateStart);
    while (date <= updateEnd) {
      arr.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return arr;
  }

  // api to check conflicts
  private async checkSchEmployee(data) {
    this.resetCheckValues();
    this.scheduleSrvc.checkSchedule(data).subscribe(async (res) => {
      if (res.data.length) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].employees.length && (Array.isArray(res.data[i].groups) && res.data[i].groups.length === 0) || res.data[i] === null) {
            res.data[i].employees.filter((element) => {
              if (this.scheduleEmployees.includes(element._id)) {
                this.employeeDate.push({
                  date: res.data[i].date,
                  shift: res.data[i].shift,
                  name: element.userName,
                  schedule: res.data[i].rangeId.name
                });
              }
            });
          }
          if (res.data[i].equipments.length) {
            res.data[i].equipments.filter((element) => {
              if (this.scheduleEquipment.includes(element._id)) {
                this.equipmentDate.push({
                  date: res.data[i].date,
                  shift: res.data[i].shift,
                  name: element.name,
                  schedule: res.data[i].rangeId.name
                });
              }
            });
          }
          if (res.data[i].groups.length) {
            res.data[i].groups.filter((element) => {
              if (this.scheduleEquipment.includes(element._id)) {
                this.groupsDate.push({
                  date: res.data[i].date,
                  shift: res.data[i].shift,
                  name: element.name,
                  schedule: res.data[i].rangeId.name
                });
              }
            });
          }
        }
        this.uniqueEmpDate = {};
        if (this.employeeDate.length) {
          this.employeeDate.map((item) => {
            if (item.name.length) {
              if (!this.uniqueEmpDate[item.date]) {
                this.uniqueEmpDate[item.date] = [{ name: item.name, shift: this.scheduleInfo[0]._id.shift, schedule: item.schedule }];
              } else {
                this.uniqueEmpDate[item.date].push({ name: item.name, shift: this.scheduleInfo[0]._id.shift, schedule: item.schedule });
              }
            } else {
              this.employeeDate.length = 0;
            }
          });
        }
        this.uniqueEquipDate = {};
        if (this.equipmentDate.length) {
          this.equipmentDate.map((item) => {
            if (item.name.length) {
              if (!this.uniqueEquipDate[item.date]) {
                this.uniqueEquipDate[item.date] = [{ name: item.name, schedule: item.schedule, shift: item.shift }];
              } else {
                this.uniqueEquipDate[item.date].push({ name: item.name, schedule: item.schedule, shift: item.shift });
              }
            } else {
              this.equipmentDate.length = 0;
            }
          });
        }
        this.uniqueGroupDate = {};
        if (this.groupsDate.length) {
          this.groupsDate.map((item) => {
            if (item.name.length) {
              if (!this.uniqueGroupDate[item.date]) {
                this.uniqueGroupDate[item.date] = [{ name: item.name, schedule: item.schedule, shift: item.shift, }];
              } else {
                this.uniqueGroupDate[item.date].push({ name: item.name, schedule: item.schedule, shift: item.shift, });
              }
            } else {
              this.groupsDate.length = 0;
            }
          });
        }
      }
    }, err => this.cos.handleError(err));
  }

  // reset check variables
  private resetCheckValues() {
    this.employeeDate.length = 0;
    this.uniqueEmpDate = {};
    this.equipmentDate.length = 0;
    this.uniqueEquipDate = {};
    this.uniqueGroupDate = {};
    this.groupsDate.length = 0;
  }

  private getDates() {
    this.getDateFormArr.clear();
    const getDaysArray = function (startDate, endDate) {
      const arr = [];
      for (const dt = new Date(startDate);
        dt <= new Date(endDate);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(new Date(dt));
      }
      return arr;
    };
    const daylist = getDaysArray(
      new Date(this.addEmployeeForm.value.startDate),
      new Date(this.addEmployeeForm.value.endDate)
    );
    daylist.forEach((date) => {

      this.getDateFormArr.push(this.createDateForm(this.cos.getFormattedDate(date)));
    });
    return daylist;
  }


  public deleteSchedule() {
    const id = this.details.scheduler ? this.details.scheduler._id : this.details._id;
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.scheduleSrvc.deleteSchedule(id).subscribe(res => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            jQuery('#rightModal').modal('hide');
            const startDate = this.details.scheduler ? this.details.scheduler.jobDateRange[0].from : this.details.jobDateRange[0].from;
            const endDate = this.details.scheduler ? this.details.scheduler.jobDateRange[0].to : this.details.jobDateRange[0].to;
            this.scheduleSrvc.setConflicts({ startDate: startDate, endDate: endDate });
            this.getDetails();
            this.reloadScreen();
            this.reloadSchedule.emit('Reload');
          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        }, err => this.cos.handleError(err));
      }
    });

  }

  private getDaysArray(startDate, endDate) {
    const date = [];
    for (const d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) { date.push(new Date(d)); }
    this.dateArray = date;

  }

  public doubleBooking() {
    if (!this.updateDateForm.value.startDate) {
      return this.cos.toastMessage(response.en.START, 'warning');
    } else if (!this.updateDateForm.value.endDate) {
      return this.cos.toastMessage(response.en.END, 'warning');
    } else if (new Date(this.updateDateForm.value.startDate).valueOf() > new Date(this.updateDateForm.value.endDate).valueOf()) {
      return this.cos.toastMessage(response.en.START_END, 'warning');
    } else {
      const data = {
        schedulerId: this.scheduleInfo[0].scheduler._id,
        jobDateRange: [
          {
            from: moment(this.updateDateForm.value.startDate).add(1, 'd').toDate(),
            to: moment(this.updateDateForm.value.endDate).add(1, 'd').toDate(),
            rangeType: this.scheduleInfo[0].scheduler.jobDateRange[0].rangeType,
          }
        ]
      };
      const query = true;
      Swal.fire({
        title: 'Are you sure?',
        text: 'This will forcefully add the employees/equipments that are already scheduled in other schedule!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.scheduleSrvc.updateField(data, query).subscribe((res) => {
            if (res) {
              this.cos.toastMessage(res.message, 'success');
              jQuery('#UpdateDate').modal('hide');
              jQuery('#rightModal').modal('hide');
              this.reloadSchedule.emit('Reload');
            } else {
              this.cos.toastMessage(res.message, 'error');
            }
          }, err => this.cos.handleError(err));
        }
      });
    }
  }

  public reloadGroups() {
    this.projectSrvc.emitChange('RELOAD');
  }

  public addEmployees() {
    const equipmentId = [];
    if (Array.isArray(this.employeeDetails) && this.employeeDetails.length) {
      this.employeeDetails.map((val) => {
        equipmentId.push(val.id);
      });
    }
    this.modalValue = {
      shift: [this.shift._id.shift],
      startDate: this.details.scheduler ? this.details.scheduler.jobDateRange[0].from : this.details.jobDateRange[0].from,
      endDate: this.details.scheduler ? this.details.scheduler.jobDateRange[0].to : this.details.jobDateRange[0].to,
      equipmentId: equipmentId.length ? equipmentId : [],
      id: this.details.scheduler ? this.details.scheduler._id : this.details._id,
      scheduleInfo: this.scheduleInfo ? this.scheduleInfo : []
    };
    this.scheduleSrvc.modalReset('LIST');
    jQuery('#empModal').modal('show');
  }


  public deleteEmployee(id, date, value) {
    const body = {
      schedulerId: this.details.scheduler ? this.details.scheduler._id : this.details._id,
      id: id,
      type: value,
      date: date
    };
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.scheduleSrvc.deleteEquipEmpData(body)
          .subscribe((res) => {
            if (res) {
              if (value === 'employee') {
                this.cos.toastMessage(`Employee ${response.en.DELETED_SUCCESSFULLY}`, 'success');
              } else if (value === 'groups' ) {
                this.cos.toastMessage(`Group ${response.en.DELETED_SUCCESSFULLY}`, 'success');
              } else {
                this.cos.toastMessage(`Group Employee ${response.en.DELETED_SUCCESSFULLY}`, 'success');
              }
              this.getDetails();
              this.reloadScreen();
              this.reloadSchedule.emit('Reload');
            } else {
              this.cos.toastMessage(res.message, 'warning');
            }
          }, (err) => { this.cos.handleError(err); });
      }
    });
  }

  async deleteEquipment(deletedId: string, date: string, type: 'rigs' | 'equipments') {
    const body: DeleteEquipmentData = {
      schedulerId: this.details.scheduler ? this.details.scheduler._id : this.details._id,
      id: deletedId,
      date: date,
      type: type
    };
  
    try {
      // Get the mapping for the item to determine if confirmation is needed
      const equipmentJobMapping = await this.scheduleSrvc.getEquipmentMapping({
        schedulerId: body.schedulerId,
        id: deletedId
      }).toPromise();
  
      // Define confirmation message
      const deleteConfirmMessage = {
        text: response.en.IS_EQUIP_DELETE,
        title: response.en.DELETE_CONFIRMATION,
        cancelButtonText: response.en.DELETE_ALL,
        confirmButtonText: response.en.DELETE_ONE,
      };
  
      if (Array.isArray(equipmentJobMapping.data) && equipmentJobMapping.data.length > 1) {
        // Show confirmation dialog
        const options: SweetAlertOptions = this.cos.warningMessage(deleteConfirmMessage, true);
        Swal.fire(options).then((result) => {
          let payload = body;
          if (!result.isConfirmed) {
            if (result.dismiss.toString() === 'backdrop') {
              return;
            } else {
              payload = { ...body, allDelete: true };
              this.callEquipDeleteApi(payload);
            }
          } else {
            this.callEquipDeleteApi(body);
          }
        });
      } else {
        // Show delete confirmation without additional confirmation
        Swal.fire(this.cos.deleteText).then((result) => {
          if (result.value) {
            this.callEquipDeleteApi(body);
          }
        });
      }
  
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  
  // Function to show success message
  private showDeleteSuccessMessage(value: 'equipments' | 'rigs') {
    switch (value) {
      case 'equipments':
        this.cos.toastMessage(`Equipment ${response.en.DELETED_SUCCESSFULLY}`, 'success');
        break;
      case 'rigs':
        this.cos.toastMessage(`Rig ${response.en.DELETED_SUCCESSFULLY}`, 'success');
        break;
      default:
        this.cos.toastMessage(`${response.en.DELETED_SUCCESSFULLY}`, 'success');
    }
  }
  

  // Api call to delete equipment
  private callEquipDeleteApi(payload: DeleteEquipmentData) {
    this.scheduleSrvc.deleteEquipEmpData(payload)
      .subscribe((res) => {
        if (res) {
          this.getDetails();
          this.reloadScreen();
          this.reloadSchedule.emit('Reload');
          // Show success message based on type
          if (payload.type === 'equipments') {
            this.cos.toastMessage(`Equipment ${response.en.DELETED_SUCCESSFULLY}`, 'success');
          } else if (payload.type === 'rigs') {
            this.cos.toastMessage(`Rig ${response.en.DELETED_SUCCESSFULLY}`, 'success');
          }
        } else {
          this.cos.toastMessage(res.message, 'warning');
        }
      }, (err) => { this.cos.handleError(err); });
  }
  

  // to change the name of job
  public updateNotes(event) {
    if (event.keyCode === 13) {
      event.preventDefault();

      const value = event.target.value;
      const data = {
        schedulerId: this.scheduleInfo[0].rangeId._id,
        note: value ? value.trim() : '',
      };

      this.scheduleSrvc.updateField(data, false).subscribe(
        (res) => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            this.getDetails();
            this.reloadScreen();
            this.reloadSchedule.emit('Reload');
          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        },
        (err) => this.cos.handleError(err)
      );
    }
  }

}
