<div class="form__group">
      <input
      #inputFile
      accept="image/* , .pdf"
      (change)="onFileSelected($event)"
      [attr.id]="randomId"
      class="file-upload d-none"
      id="file"
      type="file"
    />
    
    <div
      class="upload-container"
      appDnd
      (fileDropped)="upload($event, inputFile)"
      [ngClass]="{ changePadding: changePadding }"
      (click)="inputFile.click()"
    >
      <button type="button" class="upload-btn">
        <i class="fa fa-upload" aria-hidden="true"></i> Upload
      </button>
    </div>
    <div *ngFor="let item of uploadedImages" class="documents-div mt-2">
      <div
        class="d-flex cursor-pointer w-100 justify-content-between theme-text-color"
      >
        <span>
          <i class="fa fa-file-text" aria-hidden="true"></i>
          <span class="mx-1" (click)="viewLink(item)">{{
            item.name ?? item.fileName | slice : 0 : 30
          }}</span>
        </span>
        <i
          class="fa fa-trash theme-text-color mt-1"
          aria-hidden="true"
          (click)="remove(item, inputFile)"
        ></i>
      </div>
    </div>
  </div>
  