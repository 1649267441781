<app-ad-common-modal [modalProperty]="modalProperty">
  <div class="d-flex">
    <div class="col-lg-5 col-md-6 col-sm-12 filter-heading">
      <div class="filter-text">Filters On</div>
      <ul class="my-2">
        <li
          *ngFor="let data of items"
          [class.selected]="data.displayName === filterName"
          class="cursor-pointer pl-1 ad-text-size"
          (click)="selectItem(data)"
        >
          {{ data?.displayName }}
        </li>
      </ul>
    </div>

    <div class="col-lg-7 col-md-6 col-sm-12 d-flex flex-column">
      <div class="filter-text">Filter By {{ filterName }}</div>
      <input
        *ngIf="!filterName.includes('Date')"
        class="search-input mt-2 py-1 pl-3 ml-2"
        [(ngModel)]="filterValSearch"
        spellcheck="false"
        (ngModelChange)="searchDelay($event, 'filterValue')"
        placeholder="Search"
      />

      <ul *ngIf="childFilter && childFilter.length" class="my-2 px-2">
        <li *ngFor="let item of childFilter[0]?.children" class="d-flex justify-content-between">
          <span class="ad-text-size">{{ this.cos.getCamelCaseSpaceValue(item?.displayName) }}</span>
          <mat-checkbox
            [color]="color"
            [checked]="checkboxStates[item.id] || false"
            (change)="toggle(item, childFilter[0]?.displayName)"
          ></mat-checkbox>
        </li>
      </ul>      
      
      <form
        [formGroup]="dateForm"
        *ngIf="filterName.includes('Date')"
        class="dates"
      >
        <div class="my-3 px-1">
          <mat-form-field class="d-block">
            <input
              class="datesEffect"
              matInput
              [matDatepicker]="picker22"
              placeholder="Start Date"
              class="ad-text-size"
              formControlName="startDate"
              maxlength="10"
              [max]="maxDate && maxDate"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
              (click)="picker22.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker22"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker22></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="my-3 px-1">
          <mat-form-field class="d-block">
            <input
              class="datesEffect"
              matInput
              [matDatepicker]="picker33"
              placeholder="End Date"
              class="ad-text-size"
              formControlName="endDate"
              maxlength="10"
              [max]="maxDate && maxDate"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
              (click)="picker33.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker33"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker33></mat-datepicker>
          </mat-form-field>
        </div>
      </form>

      <form [formGroup]="modalForm">
        <div class="row px-1" *ngIf="formData.length">
          <ng-container *ngFor="let data of formData; let i = index">
            <div *ngIf="data.type === 'input'" class="{{ setWidth() }}">
              <!-- Your input field code here -->
            </div>
            <div *ngIf="data.type === 'toggle'" class="{{ setWidth() }}">
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{ data?.title }}</mat-label>
                <span class="text-invisible" style="font-size: 16px;">
                  {{ data.toggle1 ? data.toggle1 : "No" }}
                </span>
                <mat-slide-toggle
                  color="warn"
                  class="ml-1"
                  [formControlName]="data.name"
                  (change)="onToggleChange($event)"
                >
                  <span
                    class="text-invisible mr-1"
                    style="
                      position: absolute !important;
                      top: 18px !important;
                      font-size: 16px;
                      color: #212529 !important;
                      font-weight: 400;
                    "
                  >
                    {{ data.toggle2 ? data.toggle2 : "Yes" }}
                  </span>
                </mat-slide-toggle>
                <input matInput hidden />
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-4 mr-3">
    <button class="btn btn-primary" (click)="submitData('reset')">
      Reset Filter
    </button>
    <button class="btn btn-primary ml-2" (click)="submitData('value')">
      Apply Filter
    </button>
  </div>
</app-ad-common-modal>
