<!-- Root Container -->

<div class="my-2 tableEffect">
  <!-- Table -->
  <table class="table">
    <!-- Table Header -->
    <thead class="thead-light fixedHeading pl-2 ad-text-size">
      <!-- Table Columns -->
      <tr>
        <th *ngFor="let column of columns">
          {{ column.name }}
        </th>
      </tr>
    </thead>

    <tbody class="ad-text-size">
      <!-- Table Body Container -->
      <ng-container *ngFor="let data of tableData">
        <!-- SubType Text -->
        <tr (click)="toggleItems(data)">
          <td class="sub-type-text">
            <mat-icon class="pt-2">
              {{
                data?.isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }}</mat-icon
            >
            {{ data?._id }}
          </td>
        </tr>

        <ng-container *ngIf="data?.reviewers && data?.isExpanded">
          <tr *ngFor="let item of data?.reviewers">
            <!-- Table Content Name (First Column Values) -->
            <td class="content-text">
              {{ item?.content }}
            </td>

            <!-- Applicable Checkbox Column Values -->
            <td style="padding: 0px">
              <mat-checkbox
                [checked]="item?.applicable"
                (change)="onCheckboxChange(item, 'applicable', $event.checked)"
              >
              </mat-checkbox>
            </td>

            <!-- Show to Client Checkbox Column Values -->
            <td style="padding: 0px">
              <mat-checkbox
                [checked]="item?.showToClient"
                (change)="
                  onCheckboxChange(item, 'showToClient', $event.checked)
                "
              >
              </mat-checkbox>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
