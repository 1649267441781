<app-section-headers [btnName]="btnName" (sendSearch)="searchData($event)" (showModal)="showModal($event)"
  permission="configuration.read"></app-section-headers>

<app-table [tableData]="tableData" isClickable=true [column]="column"
  [permission]="{edit: 'configuration.write', remove : 'configuration.remove' ,read:'configuration.read' }"
  [completeData]="completeData" (updatedList)="setPagination($event)" (rightModalData)="rightModalData($event)"
  (deleteRecord)="deleteRecord($event)" (editValues)="editValues($event)">
</app-table>


<app-ad-common-modal [modalProperty]="modalProperty[0]">
        <form [formGroup]="subDocument">
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Name*</mat-label>
                <input formControlName="name" maxlength="60" matInput type="text" [readonly]="docName">
              </mat-form-field>
            </div>

            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Sub Document</mat-label>
                <input formControlName="doc" maxlength="50" matInput type="text">

              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Field</mat-label>
                <mat-select formControlName="field">
                  <mat-option [value]="data.key" *ngFor="let data of docsField" class="py-2">{{data.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <ng-container *ngFor="let item of fieldLoop;let j=index">
              <div *ngIf="subDocument.value.field==='endorsement' || subDocument.value.field==='restrictions' "
                class="d-flex col-6">
                <mat-form-field appearance="outline" class="d-block w-100">
                  <mat-label>Sub Field</mat-label>
                  <input name="item" [value]="item.subfields" maxlength="50" matInput type="text"
                    (change)="setFieldValue(j,$event)">

                </mat-form-field>
                <span class="d-flex flex-column">
                  <span type="button" class="material-icons pointer" (click)="addNewFields(j);"
                    *ngIf="j === fieldLoop.length-1">add_circle</span>
                  <span type="button" class="material-icons pointer" (click)="removeField(j);"
                    *ngIf="j>0">remove_circle</span>
                </span>
              </div>
            </ng-container>
          </div>
        </form>
        <div class="d-flex float-right">
          <button type="submit" class="btn btn-primary float-right mr-2 mt-2" (click)="addFields()">Add Field</button>
          <button type="submit" class="btn btn-primary float-right mt-2" (click)="addDocumentType()">Save</button>
        </div>

        <div style="margin-top:3.5rem" *ngIf="addField.length">
        <table class="table table-bordered" style="font-size:13px">
          <thead>
            <th>Name</th>
            <th>Sub Document</th>
            <th>Field Name</th>
            <th>Sub-Field</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr *ngFor="let element of addField;let i=index">
              <td>{{element?.name}} </td>
              <td>{{element?.type ? element?.type : 'N/A'}}</td>
              <td>{{element?.field ? element?.field : 'N/A'}}</td>
              <td><span *ngFor="let value of element.subField">{{value ? value :
                'N/A'}}</span></td>
                <td> <a (click)="deleteAddedField(i)"
                  class="actnbtn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i>
                </a></td>
            </tr>
          </tbody>
        </table>
      </div>
</app-ad-common-modal>

<app-ad-common-modal [modalProperty]="modalProperty[1]">

        <!-- when you click on document name listing edit button -->
        <ng-container *ngIf="showTable">
          <form [formGroup]="editDocument">
            <div style="width:50%">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Name*</mat-label>
                <input formControlName="name" maxlength="50" matInput type="text" (ngModelChange)="editDocumentName($event)" >
              </mat-form-field>
            </div>
            <div *ngIf="documentTypeData?.length > 0">
              <p style="font-size:15px" class="theme-text-color font-weight-bold">Sub Document Types:</p>
              <table class="table-bordered">
                <thead style="font-size:15px" class="text-center">
                  <th>Sub Document</th>
                  <th>Field</th>
                  <th>Sub Field</th>
                  <th>Action</th>
                </thead>

                <tbody *ngFor="let data of documentTypeData;let i=index" style="font-size:14px">
                  <tr class="text-center">
                    <td>{{data.parent=== true && data.name.trim().length ?
                      data.name:'N/A'}} </td>
                    <td class="p-1">
                      <ng-container *ngIf="data?.fields?.length">
                        <span class="d-flex justify-content-center" *ngFor=" let item of data?.fields">{{item?.name
                          ?item.name :'N/A'}}</span>
                      </ng-container>
                      <ng-container *ngIf="data?.fields?.length === 0">
                        N/A
                      </ng-container>
                    </td>
                    <td style="position:relative">
                      <span *ngIf="data?.fields?.length">
                        <span class="d-flex justify-content-center"
                          *ngFor="let value of data.fields">{{value.subFields[0]?value.subFields[0] : 'N/A'}}
                          <span class="ml-2 moreValue" (mouseenter)="subField[i] = true;getHoverStatus(value,i)"
                            (mouseleave)="subField[i] = false">{{getSubFieldLength(value)}}</span>
                        </span>
                      </span>
                      <span *ngIf="data?.fields?.length === 0">
                        N/A
                      </span>
                      <span *ngIf="showEffect[i]">
                        <span *ngIf="subField[i] === true" class="field-values-items d-flex flex-column">
                          <ng-container *ngFor="let value of subFieldsValues;let j=index">
                            <span>{{j+1}}. {{value}}</span>
                          </ng-container>
                        </span>
                      </span>

                    </td>
                    <td class="d-flex" style="border: none">
                      <div class="w-100">
                        <i class="fa fa-pencil actnbtn" aria-hidden="true" (click)="showList(data,i)" ></i>
                        <i class="fa fa-trash-o actnbtn btn-danger" aria-hidden="true"  (click)="deleteDocType(data._id,i)"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>

          </form>
          <div class="d-flex float-right">
            <button type="submit" class="btn btn-primary mt-3 mx-3" (click)="showSubDocument()">Add Sub
              Document</button>
            <button type="submit" style="height:fit-content" class="btn btn-primary mt-3"
              (click)="UpdateDocument()">{{UpdateBtn}}</button>
          </div>
        </ng-container>

        <!-- Add SubDocument Type -->
        <div *ngIf="showDocument">
          <form [formGroup]="addSubDocumentType" class="mt-2">
            <div class="row mt-2">
              <div class="col-6">
                <mat-form-field appearance="outline" class="d-block">
                  <mat-label>Sub Document Type*</mat-label>
                  <input formControlName="docType" maxlength="50" matInput type="text"
                    placeholder='Enter Sub Document Name'>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="d-block">
                  <mat-label>Field</mat-label>
                  <mat-select formControlName="field" (selectionChange)="resetSubField($event.value)">
                    <mat-option [value]="data.key" *ngFor="let data of docsField">{{data.value}}</mat-option>

                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6" *ngFor="let item of subDocumentType;let i=index">
                <div class="row">
                  <div class="col-md-11">
                    <mat-form-field appearance="outline" class="d-block">
                      <mat-label>Sub Field</mat-label>
                      <input name="item" [value]="item.subField" maxlength="50" matInput type="text"
                        (change)="setSubDoc(i,$event)">
                    </mat-form-field>
                  </div>
                  <span class="col-md-1 col-xs-12" style="margin-left:-20px">
                    <span class="material-icons pointer cursor-pointer" (click)="addNewDoc(i);"
                      *ngIf="i === subDocumentType.length-1">add_circle</span>
                    <span class="material-icons pointer cursor-pointer" (click)="removeDoc(i);"
                      *ngIf="i>0">remove_circle</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="d-flex float-right mb-3">
               
                  <button type="submit" style="font-size:18px" class="float-right mt-2 strokeBtn"
                  (click)="resetDoc();">Back  </button>
                  <button type="submit" class="btn btn-primary float-right ml-2 mt-2" (click)="addDocField()">Add
                    Field</button>
              
            </div>

          </form>
          <div *ngIf="newDocumentField.length" class="my-2">
            <table class="table table-bordered" style="font-size:14px">
              <thead>
                <th>Sub Document</th>
                <th>Field</th>
                <th>Sub Field</th>
                <th style="width:2rem">Action</th>
              </thead>
              <tbody *ngFor="let data of newDocumentField;let i=index">
                <tr>
                  <td>{{data.name ? data.name :'N/A'}}</td>
                  <td>{{data.field ? data.field : 'N/A'}}</td>
                  <td><span *ngFor="let val of data.subField">{{val}},</span></td>
                  <td>
                    <div class="mt-1 btn-danger actnbtn" (click)="deleteSubDocField(i)">
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Details of sub document-->
        <div *ngIf="showEditIndex">
          <form [formGroup]="editFields">
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline" class="d-block">
                  <mat-label>Sub Document Name</mat-label>
                  <input formControlName="name" maxlength="60" matInput type="text" placeholder="Enter Activity Name"
                    readonly="{{subDocumentDetail.length ? true: false}}">
                </mat-form-field>
              </div>

              <div class="col-sm-6">
                <mat-form-field appearance="outline" class="d-block">
                  <mat-label>Field</mat-label>
                  <mat-select formControlName="field">
                    <mat-option [value]="data.key" *ngFor="let data of docsField">{{data.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <ng-container *ngIf="editFields.value.field==='endorsement' || editFields.value.field==='restrictions'">
                <ng-container *ngFor="let item of subLoop;let j=index">
                  <div class="d-flex col-6">
                    <mat-form-field appearance="outline" class="d-block w-100">
                      <mat-label>Sub Field</mat-label>
                      <input [value]="item.item" maxlength="30" name="item" matInput type="text"
                        placeholder="Enter Sub Fields" (change)="setSubType(j,$event)">
                    </mat-form-field>
                    <span class="d-flex flex-column">
                      <span type="button" class="material-icons pointer cursor-pointer" (click)="addSubType(j);" *ngIf="
                          j===subLoop.length-1">add_circle</span>
                      <span type=" button" class="material-icons pointer cursor-pointer" (click)="removeSubType(j);"
                        *ngIf="j>0">remove_circle</span>
                    </span>
                  </div>
                </ng-container>
              </ng-container>

            </div>
          </form>
          <div class="float-right my-3">
            <button class="btn btn-primary" (click)="showDocumentList()">Back</button>
            <button class="btn btn-primary ml-2" (click)="updateSubDocument()">Add Fields</button>
          </div>


          <table class="table table-bordered" style="font-size:14px" *ngIf="subDocumentDetail.length">
            <thead>
              <th>Sub Document</th>
              <th>Field</th>
              <th>Sub Fields</th>
              <th style="width:5rem">Action</th>
            </thead>
            <tbody>
              <ng-container *ngFor="let data of subDocumentDetail;let i=index">
                <tr>
                  <td>{{data.name ? data.name : 'N/A'}}</td>
                  <td>{{data.field}}</td>
                  <td><span *ngFor="let val of data.subFields">{{val}},</span></td>
                  <td>
                    <div (click)="patchDetails(data, i)" class="actnbtn mt-1">
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                    <div class="mt-1 btn-danger actnbtn" (click)="deleteDocField(i)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>


        </div>
        
</app-ad-common-modal>


<div class=" modal right-lightbox modal right fade  all-project-page" id="rightModal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header modalHeading">
        <h5 class="modal-title">Document Details</h5>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="mt-2 mb-5">
          <div class="d-flex heading">
            <span class="col-3"> Name:</span>
            <span class="col-9" style="text-align:end">{{documentName}}</span>
          </div>
          <div *ngFor=" let data of documentDetail" class="py-2">
            <div class="d-flex subHeading">
              <span class="col-6">Sub Document Type</span>
              <span class="col-6" style="text-align:end">{{data.parent=== true ?
                data.name:'N/A'}}</span>
            </div>
            <div *ngFor="let item of data.fields">
              <div class="d-flex">
                <span class="col-6"> Field:</span>
                <span class="col-6" style="text-align:end">{{item.name ? item.name : 'N/A'}}</span>
              </div>
              <div class="d-flex">
                <span class="col-6">Sub-Field</span>
                <span class="col-6" style="text-align:end">
                  <span class="d-flex justify-content-end" *ngFor="let value of item.subFields">{{value?value :
                    'N/A'}}</span></span>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>


<!-- <app-right-modal modalId="rightModal" [modalDetails]="rightModal"></app-right-modal> -->