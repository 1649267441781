import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { List } from "../interfaces/common-interface";
import {
  HoleInfo,
  BasicHoleInfo,
  HoleInfoPayload,
} from "../interfaces/hole-info";
import {
  UtilityType,
  UtilityStatus,
  UtilityOwner,
  UtilityData,
  UtilitySingleData,
} from "../interfaces/Utility-locate";
import { Message } from "../interfaces/common-interface";

@Injectable({
  providedIn: "root",
})
export class UtilityLocatesService {
  HOLE_INFO = "aggregation/hole-info/list";
  HOLE_INFO_CRUD = "aggregation/hole-info";

  UTILITY_TYPE = "configuration/locate-type/list";
  UTILITY_STATUS = "configuration/locate-status/list";
  UTILITY_OWNER = "configuration/locate-owner/list";

  UTILITY_LOCATES_CRUD = "aggregation/utility-locates";
  UTILITY_LOCATES = "aggregation/utility-locates/list";
  EXCEL_DOWNLOAD = "aggregation/hole-info/excel-download";
  EXCEL_UPLOAD = "aggregation/hole-info/excel-upload";
  UTILITY_DOWNLOAD = "aggregation/utility-locates/excel-download";
  UTILITY_UPLOAD = "aggregation/utility-locates/excel-upload";
  UTILITY_CLONE = "aggregation/utility-locates/clone";
  UTILITY_MARK = "aggregation/utility-locates/mark-completed";
  constructor(private http: HttpClient) {}

  getHoleInfo(search: List) {
    return this.http.get<HoleInfo>(
      `${environment.BASE_URL}${this.HOLE_INFO}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&project=${search.project}`
    );
  }

  getHolesInfo(projectId, isUnused = true) {
    return this.http.get<HoleInfo>(
      `${environment.BASE_URL}${this.HOLE_INFO}?project=${projectId}&isUnused=${isUnused}`,
    );
  }

  getBoringDetails(id: string) {
    return this.http.get<BasicHoleInfo>(
      `${environment.BASE_URL}${this.HOLE_INFO_CRUD}?id=${id}`
    );
  }

  deleteHoleInfo(id) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        ids: id,
      },
    };
    const url = `${environment.BASE_URL}${this.HOLE_INFO_CRUD}`;
    return this.http.delete<Message>(url, options);
  }

  addHoleInfo(data) {
    return this.http.post<HoleInfoPayload>(
      environment.BASE_URL + this.HOLE_INFO_CRUD,
      data
    );
  }

  updateHoleInfo(data) {
    return this.http.put<HoleInfoPayload>(
      environment.BASE_URL + this.HOLE_INFO_CRUD,
      data
    );
  }

  getUtilityType() {
    return this.http.get<UtilityType>(
      `${environment.BASE_URL}${this.UTILITY_TYPE}?pagination=false`
    );
  }

  getUtilityStatus() {
    return this.http.get<UtilityStatus>(
      `${environment.BASE_URL}${this.UTILITY_STATUS}?pagination=false`
    );
  }

  getUtilityOwner() {
    return this.http.get<UtilityOwner>(
      `${environment.BASE_URL}${this.UTILITY_OWNER}?pagination=false`
    );
  }

  addUtilityLocates(data) {
    return this.http.post<UtilitySingleData>(
      environment.BASE_URL + this.UTILITY_LOCATES_CRUD,
      data
    );
  }

  updateUtilityLocates(data) {
    return this.http.put<UtilitySingleData>(
      environment.BASE_URL + this.UTILITY_LOCATES_CRUD,
      data
    );
  }

  getUtilityDetails(id) {
    return this.http.get<UtilitySingleData>(
      `${environment.BASE_URL}${this.UTILITY_LOCATES_CRUD}?id=${id}`
    );
  }

  getUtilityLocates(search) {
    // Start building the query string with mandatory parameters
    let queryParams = `?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&project=${search.project}&listType=${JSON.stringify(search.listType)}&status=${JSON.stringify(search.status)}`;
  
    // Conditionally add the isDrilled parameter if it is not null or undefined
    if (search.isDrilled !== null && search.isDrilled !== undefined) {
      queryParams += `&isDrilled=${search.isDrilled}`;
    }
  
    return this.http.get<UtilityData>(`${environment.BASE_URL}${this.UTILITY_LOCATES}${queryParams}`);
  }  
  

  deleteUtilityLocates(id) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        ids: id,
      },
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.UTILITY_LOCATES_CRUD,
      options
    );
  }

  downloadSample() {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.EXCEL_DOWNLOAD}`
    );
  }

  uploadExcel(data) {
    return this.http.post<Message>(
      environment.BASE_URL + this.EXCEL_UPLOAD,
      data
    );
  }

  downloadUtility() {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.UTILITY_DOWNLOAD}`
    );
  }

  uploadUtility(data) {
    return this.http.post<Message>(
      environment.BASE_URL + this.UTILITY_UPLOAD,
      data
    );
  }

  createClone(data) {
    return this.http.put<Message>(
      environment.BASE_URL + this.UTILITY_CLONE,
      data
    );
  }

  /**
   * Function to hit mark-completed api route.
   * @param data contains payload
   * @returns promise with data or error.
   */
  public markUtilityLocate(data) {
    return this.http.put<UtilitySingleData>(
      environment.BASE_URL + this.UTILITY_MARK,
      data
    );
  }
}
