import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';
import { Search } from '../../../../interfaces/common-interface';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-section-modal',
  templateUrl: './section-modal.component.html',
  styleUrls: ['./section-modal.component.scss'],
})
export class SectionModalComponent implements OnInit, OnChanges {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() state = [];
  @Input() modalForm;
  @Input() formData = [];
  @Input() dialCode;
  @Input() fieldWidth = false;
  @Input() patchDocument = [];
  @Input() isDocument = false;
  @Input() isAddressMap = false;
  @Input() smallSizeField = false;
  @Input() fullWidthField = false;
  @Input() largeSizeField = false;
  @Input() isRequired = false;
  @Input() buttonMargin = false;
  @Input() showPassword = false; 
  @Input() showConfirmPassword = false;
  @Output() submittedValue = new EventEmitter();
  @Output() sendResult = new EventEmitter();
  @Output() searchDataList = new EventEmitter();
  @Output() selectedValue = new EventEmitter();
  @Output() searchNames = new EventEmitter();
  @Output() selectedDate = new EventEmitter();
  @Output() addNewRecord = new EventEmitter();
  @Output() getAddressValue = new EventEmitter();
  @Output() getInputData = new EventEmitter();
  @Input() maxDate: Date = null;
  @Output() drilledStatusChange = new EventEmitter<boolean>();

  disabledSelect=false;

  modalTitle: string;
  searchRecord: string;
  searchText: string;
  selectedData = [];
  showSelectBox = false;
  contactStatus = false;
  searchData = '';
  password: string;
  country;
  countries = [];
  searchCode = '';
  show = false;
  countryDetails = [];
  originalData: Search;
  selectedIds = [];
  count = 0;
  documents = [];
  mapAddress;
  initialColor: 'red';
  usedEmails = [];

  constructor(
    public cos: CommonService
  ) { }

  ngOnInit() {
    this.password = 'password';
  }

  ngOnChanges() {
    this.resetForm();
    if (Array.isArray(this.formData) && this.formData.length) {
      this.formData.map((val) => {
        if (val.type === 'phone') {
          fetch('/assets/countries.json')
            .then((res) => res.json())
            .then((res) => {
              setTimeout(() => {
                this.countries = res;
                this.countryDetails = res;
                this.country = {
                  name: "US",
                  dial_code: "",
                  code: "US"
                };
              }, 1000);
            })
            .catch(error => {
              this.cos.handleError(error);
            });
        }
      });
    }
  }

  // This function is used to get the countries name with dial code
  getSelectedCountry(country: string, name: string) {
    this.country = country;
    this.dialCode = {
      ...this.country,
      filedName: name
    };
  }

  // This function is used send the data to corresponding component
  submitForm(data) {
    
    // Trim all modalForm values before validation
    Object.keys(this.modalForm.value).forEach(key => {
      if (typeof this.modalForm.value[key] === 'string') {
          this.modalForm.value[key] = this.modalForm.value[key].trim();
      }
    });
    const fields = this.formData.filter(item => item.title.includes('*') && item.message);
    let warningShown = false;

    if (Array.isArray(fields) && fields.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const fieldValidation = field.name === 'address' ? this.modalForm.value[field.name]?.formattedAddress : this.modalForm.value[field.name];
        if (!fieldValidation && !warningShown) {
          warningShown = true;
          return this.cos.toastMessage(field.message, 'warning');
          break;
        }
      }
    }

    const number = this.formData.filter(val => val.type === 'number');
    number.forEach(input => {
      const name = input.name;

      if (this.modalForm.controls[name]) {
        const currentValue = this.modalForm.get(name).value;

        if (typeof currentValue === 'string') {
          const numberWithCommaRemoved = parseFloat(currentValue.replace(/,/g, ''));
          this.modalForm.get(name).patchValue(numberWithCommaRemoved);
        }
      }
    });
    if (this.modalForm.value.email || this.modalForm.value.representativeEmail) {
      const email = (this.modalForm.value.email || this.modalForm.value.representativeEmail).trim();
      if (!this.cos.validateEmail(email)) {
        return this.cos.toastMessage(response.en.VALID_EMAIL, 'warning');
      }
    }
    if (this.modalForm.value.contact) {
      if (this.modalForm.value.contact.length === 0 || this.modalForm.value.contact.trim().length < 9) {
        return this.cos.toastMessage(response.en.VALID_CONTACT, 'warning');
      }
    }
    if (this.modalForm.value.password) {
      if (!this.cos.isValidPassword.test(this.modalForm.value.password)) {
        return this.cos.toastMessage(response.en.VALID_PASSWORD, 'warning');
      }
    }
    if (this.modalForm.value.link) {
      if (!this.cos.pattern.test(this.modalForm.value.link)){
        return this.cos.toastMessage(response.en.VALID_LINK, 'warning');
      }
    }
    if (this.modalForm.value.startDate && this.modalForm.value.endDate) {
      if (new Date(this.modalForm.value.startDate).valueOf() > new Date(this.modalForm.value.endDate).valueOf()) {
        return this.cos.toastMessage(response.en.START_END, 'warning');
      }
    }
    if (this.modalForm.value.meetStartTime || this.modalForm.value.meetEndTime) {
      const dtStart = new Date(`1/1/2022 ${this.modalForm.value.meetStartTime}`);
      const dtEnd = new Date(`1/1/2022 ${this.modalForm.value.meetEndTime}`);
      if (dtStart.valueOf() >= dtEnd.valueOf()){
        return this.cos.toastMessage(response.en.VALID_ENDTIME, 'warning');
      }
    }
    const result = {
      formValue: this.modalForm.value,
      type: data.type,
      dialCode: this.dialCode,
      value: data,
      document: this.documents,
      mapLocation: this.mapAddress
    };
    this.submittedValue.emit(result);

  }

  resetForm() {
    this.cos.resetField$.subscribe(data => {
      if (data.toString() === 'RESET') {
        this.selectedIds = [];
        this.modalForm.reset();
        this.showSelectBox = false;
        this.modalTitle = this.state[0] ? this.state[0].modalTitle : '';
        this.password = 'password';
        this.show = false;
        this.showSelectBox = false;
        this.dialCode = [];
      }
    });
  }

  // This function specify the validation on Password
  passwordHash() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  // This function send the data after selecting any value from drop down
  selectValue(data: string, name: string, index: number, details) {
    this.selectedValue.emit([{ value: data, controlName: name, index: index, allData: details }]);
  }

  // This function is used to show if no option available to select
  onMatSelect(data) {
    if (!data.multipleValues.length){
      this.cos.toastMessage(data.errorMessage ? data.errorMessage : response.en.NO_OPTIONS_TO_SELECT, 'warning')
    }
  }

  // This function is used to change the address of field
  changeAddress(event) {
    if (!event.target.value) {
      this.modalForm.patchValue({
        address: {
          streetAddress: ' ',
          locality: ' ',
          subLocality: ' ',
          region: '',
          country: ' ',
          postalCode: ' ',
          geo: [],
          formattedAddress: ' ',
        }
      });
    }
  }

  // This function is used to get the address from drop down
  getAddress(event) {
    this.modalForm.patchValue({
      address: {
        streetAddress: event.route ? event.route : '',
        locality: event.locality ? event.locality : '',
        subLocality: event.sub_locality ? event.sub_locality : '',
        region: event.country ? event.country : '',
        country: event.country ? event.country : '',
        postalCode: event.postal_code ? event.postal_code : '',
        geo: event.geo ? event.geo : [],
        formattedAddress: event.formattedAddress ? event.formattedAddress : '',
      }
    });
    this.getAddressValue.emit(event);
  }

  // This function is used to search the country name
  searchDialCode(data) {
    const search = data.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!regex.test(search)) {
      data.target.value = search.replace(/[^a-zA-Z0-9 ]/g, '');
    } else if (!isNaN(search)) {
      this.countryDetails = [];
      this.countries.map((val) => {
        if (val.dial_code && val.dial_code.match(search)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    } else {
      this.countryDetails = [];
      const regx = new RegExp(search ? search : "" + "$", "i");
      this.countries.map((val) => {
        if (val.name.match(regx)) {
          this.countryDetails.push({
            name: val.name, dial_code: val.dial_code, code: val.code
          });
        }
      });
    }
  }

  // This function is used to reset the values of dial code
  resetDialCode() {
    this.searchCode = '';
    this.countryDetails = [];
    this.countryDetails = this.countries;
  }

  // This function specify the search operation on drop down
  searchRecordName(data: string, fieldName: string, tableData) {
    if (this.count === 0) {
      this.originalData = { name: fieldName, value: tableData };
    }
    this.count++;
    if (data) {
      const filteredData = this.originalData.value.filter((item) =>
        item.value.toLowerCase().includes(data.toLowerCase())
      );
      this.formData.forEach((val) => {
        if (val.name === fieldName) {
          val.multipleValues = filteredData;
        }
      });
    } else {
      this.formData.forEach((val) => {
        if (val.name === fieldName) {
          val.multipleValues = this.originalData.value;
        }
      });
    }
  }

  // This function specify the operation when drop down open
  triggerEvent(data: boolean, fieldName: string, arr) {
    this.count = 0;
    if (!data) {
      this.formData.map((val) => {
        if (val.name === fieldName) {
          val.multipleValues = this.originalData && this.originalData.name === fieldName ? this.originalData.value : arr;
        }
      });
      this.searchRecord = '';
    }
  }

  // This function is used to patch multiple value from drop down
  patchSelectedValue(id: string) {
    if (id) {
      this.selectedIds.push(id);
      this.selectedIds = this.selectedIds.flat();
      this.selectedIds = this.cos.removeDuplicates(this.selectedIds);
      if (this.selectedIds.length === 0) {
        this.selectedIds = [];
      }
      this.modalForm.patchValue({
        fieldName: this.selectedIds
      });
    }
  }

// This function is used to get the width of fields
  isFullWidth() {
    return this.fullWidthField;
  }

  // This function is used to set the width of fields
  setWidth() {
    if (this.isFullWidth()) {
      return 'col-12';
    } else if (this.fieldWidth) {
      return 'col-4';
    } else if (this.smallSizeField) {
      return 'col-3';
    } else {
      return 'col-6';
    }
  }

  // This function is used to set the width of fields
  setAddressWidth() {
    if (this.largeSizeField) {
      return 'col-8';
  } else if (this.fieldWidth) {
      return 'col-4';
  } else {
      return this.smallSizeField ? 'col-3' : 'col-6';
  }
  }

  // This function is used to get the date
  dateChange(date, name: string) {
    this.selectedDate.emit({ dated: date, fieldName: name });
  }

  // Function used to send the data from input-number field
  searchInputData(value, name: string) {
    this.getInputData.emit({ data: value, fieldName: name });
  }

  // Function used to add new field via modal
  addRecord(data) {
    this.addNewRecord.emit(data);
  }

  // Function to save the documents from uploaded service to a variable
  submittedDocs(data) {
    this.documents = data;
  }

  // Function to send the details of location from map
  setAddress(event) {
    this.mapAddress = event;
  }

  onToggleChange(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.drilledStatusChange.emit(true);
    } else {
      this.drilledStatusChange.emit(false);
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  // Function to display summary conditionally 
  shouldDisplaySummary(data: any): boolean {
    return data?.type === 'summary' && data?.title?.trim().length > 0;
  }  

}
