
<div class="modal" id="{{modalProperty.id}}">
    <div class="modal-dialog modal-lg">
      <div class="modal-content pb-0">
        <div class="modal-header">
          <h5 class="modal-title">{{modalProperty.header}}</h5>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
         <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>