/**
 * Keys for Mail Receiver Type
 */
export enum MAIL_TO {
  DROPDOWN_OPTIONS = 'DROPDOWN_OPTIONS',
  CUSTOM = 'CUSTOM',
  VENDOR = 'VENDOR',
  CLIENT = 'CLIENT',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
}
