<div class="root-container">
  <div class="content-container mt-4" *ngIf="dataList.length">
    <p>{{ tableTitle }}</p>
      <div class="list-container">

        <section
          class="table-section"
          *ngFor="let list of dataList; let listIndex = index"
        >
          <!-- PARENT CHECKBOX + RADIO GROUP -->
          <span class="section-list list-item">
            <mat-checkbox
              [checked]="isListChecked(listIndex)"
              [indeterminate]="isListPartiallyChecked(listIndex)"
              (change)="update(listIndex, $event.checked)"
            >
              {{ list?.value }}
            </mat-checkbox>

            <!-- PARENT RADIO GROUP -->
            <mat-radio-group
              *ngIf="radioButtonRequired"
              [value]="list.radioValue"
              (change)="parentRadioChange(listIndex, $event.value)"
            >
              <mat-radio-button
                *ngFor="let option of radioOptions"
                [value]="option.value"
              >
                {{ option?.name }}
              </mat-radio-button>
            </mat-radio-group>
          </span>

          <!-- SUB-ITEM CHECKBOX + RADIO GROUP -->
          <span class="section-subList">
            <ul>
              <li class="list-item" *ngFor="let subItem of list?.child; let i = index">
                <mat-checkbox
                  class="ml-4"
                  [checked]="isSubListChecked(listIndex, i)"
                  (change)="toggleSubListUpdate(listIndex, i, $event.checked)"
                >
                  {{ subItem?.name || subItem }}
                </mat-checkbox>

                <!-- SUB-ITEM RADIO GROUP -->
                <mat-radio-group
                  *ngIf="radioButtonRequired"
                  [value]="subItem.radioValue"
                  (change)="subListRadioChange(listIndex, i, $event.value)"
                >
                  <mat-radio-button
                    *ngFor="let option of radioOptions"
                    [value]="option.value"
                  >
                    {{ option?.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </li>
            </ul>
          </span>
        </section>

      </div>
  </div>
</div>
