<div class="w-100 d-flex justify-content-between my-2 paginationTab">
  <!-- display items per page UI -->
  <div class="pagination-left d-flex">
    <span class="pagination-text">Items per page
    </span>

    <button mat-button [matMenuTriggerFor]="menu" class="menuButton">
      <span class="itemCount"> {{ pageSize }}</span>
      <i class="fa fa-chevron-down arrowIcon" aria-hidden="true"
      [ngClass]="{ disabledPage: totalRecord === 0 }"></i>
    </button>

    <mat-menu #menu="matMenu" class="pageRecord">
      <button
        mat-menu-item
        *ngFor="let option of pageSizeOptions"
        (click)="setPage(option, '')"
        class="ad-text-size"
      >
        {{ option }}
      </button>
    </mat-menu>
  </div>

  <!-- Pagination arrow button with number -->
  <div class="pagination-right d-flex">
    <mat-icon
      (click)="currentPage === 1 ? null : setPage(0, 'previous')"
      [ngClass]="{ disabledPage: currentPage === 1 }"
      class="pt-0"
      >keyboard_arrow_left</mat-icon
    >

    <div class="d-flex pt-1">
      <span
        *ngFor="let pageNumber of getPageRange()"
        class="pagination-text mx-2 cursor-pointer"
        [class.active]="pageNumber === paginationDetail?.currentPage"
        [ngClass]="{ activeColor: activePageNumber(pageNumber) }"
        (click)="setPage(0, pageNumber)"
      >
        {{ pageNumber }}
      </span>
    </div>

    <!-- Ellipsis for more pages -->
    <span *ngIf="shouldShowEllipsisBefore()" class="mr-2 pagination-text"
      >&hellip;</span
    >

    <mat-icon
      (click)="
        currentPage === totalPages ||
        currentPage > totalPages ||
        totalPages === 0
          ? null
          : setPage(0, 'next')
      "
      [ngClass]="{
        disabledPage:
          currentPage === totalPages ||
          currentPage > totalPages ||
          totalPages === 0
      }"
      class="pt-0"
      >keyboard_arrow_right</mat-icon
    >
  </div>
</div>
