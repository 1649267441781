import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }
  
  // @typescript-eslint/no-unused-vars
  canActivate():
    boolean | Observable<boolean> | Promise<boolean> {
    if (!localStorage.getItem('authen@User')) {
      this.router.navigate(['auth/login']);
      return false;

    } else {
      return true;
    }
  }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  //   boolean | Observable<boolean> | Promise<boolean> {
  //   return this.canActivate();
  // }
  canActivateChild():
    boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate();
  }
}
