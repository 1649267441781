import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { CommonService } from "src/app/_services/common.service";
import { VariableService } from "src/app/_common/variable.service";

@Component({
  selector: "app-input-table",
  templateUrl: "./input-table.component.html",
  styleUrls: ["./input-table.component.scss"],
})
export class InputTableComponent {
  /** Property to hold total cost */
  @Input() totalCost = "0";

  /** Property to hold table title */
  @Input() tableTitle = "Title";

  /** Property to hold table columns */
  @Input() columns = [
    { key: "_item", name: "Item" },
    { key: "_projectedCost", name: "Projected Cost" },
    { key: "_quantity", name: "Quantity" },
    { key: "_totalCost", name: "Total Cost" },
  ];

  /** Property to hold table data */
  @Input() tableData: any[] = [];

  /** Property to hold selected items list */
  @Input() selectedList: any[] = [];

  /** Property to emit selected items list */
  @Output() emitList = new EventEmitter();

  constructor(
    public cos: CommonService,
    public commonVariable: VariableService
  ) {
    // Empty Constructor
  }

  /**
   * Function to handle the input event
   */
  inputEventHandler(event: any, data: any) {
    const itemIndex = this.tableData.findIndex(
      (item) => item?._id === data?._id
    );
    if (itemIndex !== -1) {
      this.tableData[itemIndex].cost.value = event?.target?.value;
    }
    this.totalCost = `${this.getTotalCost(this.tableData)}`;

    // Filter Items with costs
    const filteredList = this.tableData.filter((item) => item?.cost?.value);

    // Format the list according to api payload
    const formattedList = filteredList.map((item) => {
      if (item?.cost?.value) {
        return {
          id: item?._id,
          cost: item?.cost,
        };
      }
    });

    // Emit the formatted list
    this.emitList.emit(formattedList);
  }

  /**
   * Function to get the total cost
   */
  getTotalCost(items) {
    let totalCost = 0;
    for (const item of items) {
      if (item?.cost && item?.cost?.value) {
        totalCost += parseFloat(item?.cost?.value);
      }
    }
    return totalCost;
  }
}
