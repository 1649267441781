<app-ad-common-modal [modalProperty]="modalProperty">
  <form [formGroup]="mailForm" (ngSubmit)="onSendClick()" #rstState="ngForm">
    <div class="row py-2 pr-2">

      <!-- Mail To Dropdown Input -->
      <div class="col-sm-6 form-field" *ngIf="isMailToVisible">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Mail To*</mat-label>
          <mat-select [(value)]="mailToSelected" (selectionChange)="onMailToOptionClick($event)">
            <mat-option
              class="ad-text-size"
              maxlength="50"
              placeholder="Select Mail To*"
              *ngFor="let mail of mailTo"
              [value]="mail.key">{{ mail.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Email Options Dropdown Input -->
      <div class="col-sm-6 form-field"
           *ngIf="mailToSelected === MAIL_TO.DROPDOWN_OPTIONS && isEmailOptionsDropdownVisible">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ emailDropdownLabel }}</mat-label>
          <mat-select (selectionChange)="onEmailSelectClick($event)">
            <mat-option
              class="ad-text-size"
              maxlength="50"
              *ngFor="let data of emailOptions"
              [value]="data.email">{{ data.email ? data.title : 'N/A' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Custom Email Input -->
      <div class="col-sm-6 form-field" *ngIf="mailToSelected === MAIL_TO.CUSTOM || isCustomMailVisible">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ isCustomMailVisible ? 'Mail To*' : 'Send Custom Mail Id*' }}</mat-label>
          <input
            class="ad-text-size"
            matInput
            type="email"
            formControlName="to"
            maxlength="50"
            placeholder="Enter Custom Mail*"
          />
        </mat-form-field>
      </div>

      <!-- CC Email Input -->
      <div class="col-sm-6 form-field" *ngIf="isCcMailVisible">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ isCcMandatory ? 'Enter CC Mail Id*' : 'Enter CC Mail Id' }}</mat-label>
          <input
            class="ad-text-size"
            matInput
            type="email"
            formControlName="cc"
            maxlength="50"
            placeholder="{{ isCcMandatory ? 'Enter CC Mail*' : 'Enter CC Mail' }}"
          />
        </mat-form-field>
      </div>

      <!-- Email Subject Input -->
      <div class="col-sm-6 form-field" *ngIf="!isVendorOrderEmail && !isVendorOrderEmailNotRequired">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Subject*</mat-label>
          <input
            class="ad-text-size"
            type="text"
            matInput
            formControlName="subject"
            maxlength="200"
            placeholder="Subject"
          />
        </mat-form-field>
      </div>

      <!-- Email Body Input -->
      <div class="col-sm-12 form-field" *ngIf="!isVendorOrderEmail && !isVendorOrderEmailNotRequired">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Body*</mat-label>
          <textarea
            class="ad-text-size"
            type="text"
            matInput
            formControlName="body"
            placeholder="Body"
          ></textarea>
        </mat-form-field>
      </div>


      <!-- Email Attachments List -->
      <div class="col-sm-12 form-field">
        <div *ngFor="let attachment of emailAttachments; let i = index" class="documents-div mt-2">
          <a [href]="attachment" target="_blank">
            <div class="d-flex align-item-center mr-1">
              <span class="material-icons file-icon">description</span>
            </div>
            <span class="ml-3">{{ attachment.name ? attachment.name : "Attachment " + (i + 1) }}</span>
          </a>
        </div>
      </div>

      <!-- Vendor Orders List -->
      <div class="col-sm-12 form-field" *ngIf="vendorOrders">

        <div *ngFor="let order of vendorOrders; let i = index" class="mt-2">
          <div class="row">
            <!-- Order Name Input -->
            <div class="col-sm-6 form-field">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Order Name</mat-label>
                <input
                  class="ad-text-size"
                  matInput
                  type="text"
                  maxlength="50"
                  placeholder="Enter Order Name*"
                  [value]="order.name"
                  [disabled]="true"
                />
              </mat-form-field>
            </div>

            <!-- Order Quantity Input -->
            <div class="col-sm-6 form-field">
              <mat-form-field appearance="outline" class="d-block">
                <mat-label>Order Quantity*</mat-label>
                <input
                  class="ad-text-size"
                  matInput
                  type="text"
                  maxlength="50"
                  type="number"
                  placeholder="Enter Quantity*"
                  [value]="order.quantity"
                  (change)="handleQuantityChange($event, i)"
                  (keyup)="handleQuantityChange($event, i)"
                />
              </mat-form-field>
            </div>

          </div>

        </div>

      </div>

    </div>
    <div class="float-right text-right mt-3">
      <button type="button" (click)="onSendClick()" class="btn btn-primary">Send</button>
    </div>
  </form>
</app-ad-common-modal>
