import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-multiselect-table',
  templateUrl: './checkbox-multiselect-table.component.html',
  styleUrls: ['./checkbox-multiselect-table.component.scss']
})
export class CheckboxMultiselectTableComponent {

  // list to render on table
  @Input() dataList = [];
  
  // title for table
  @Input() tableTitle = '';

  // radio button text
  @Input() radioOptions: { value: string; name: string }[] = [];

  // default selection of the radio buttom
  @Input() defaultRadioValue = '';

  // flag - if radio buttons are required
  @Input() radioButtonRequired = false;

  // Emit selection changes (either parent-only or parent+subItem)
  @Output() selectionChange = new EventEmitter();

  // If the parent component wants a default radio selected
  ngOnInit() {
    this.dataList.forEach((list) => {
      // If parent has no radioValue, assign default
      if (this.radioButtonRequired && (!Object.prototype.hasOwnProperty.call(list, 'radioValue') || list.radioValue == null)) {
        list.radioValue = this.defaultRadioValue;
      }
  
      // For sub-items, assign default if radioValue is missing
      if (Array.isArray(list.child)) {
        list.child.forEach((subItem) => {
          if (!Object.prototype.hasOwnProperty.call(subItem, 'radioValue') || subItem.radioValue == null) {
            subItem.radioValue = this.defaultRadioValue;
          }
        });
      }
    });
  }

  /**
   * Checks if a parent list item is fully checked.
   */
  isListChecked(index: number): boolean {
    if (
      Array.isArray(this.dataList) &&
      Object.prototype.hasOwnProperty.call(this.dataList[index], 'isChecked')
    ) {
      return !!this.dataList[index].isChecked;
    }
    return false;
  }  

  /**
   * Shows the indeterminate state if some (but not all) sub-items are checked.
   */
  isListPartiallyChecked(index: number): boolean {
    const list = this.dataList[index];
    if (!list || !Array.isArray(list.child) || !list.child.length) {
      return false;
    }
    const checkedCount = list.child.filter(item => item.isChecked).length;
    // Indeterminate if at least one subItem is checked but not all
    return (checkedCount > 0 && checkedCount < list.items.length);
  }

  /**
   * Triggered when the parent checkbox is toggled.
   * If parent is checked, emit only the parent data.
   */
  update(index: number, checked: boolean): void {
    const list = this.dataList[index];
    if (!list) { return }

    list.isChecked = checked;

    // If unchecking the parent, also uncheck any subItems
    if (!checked && Array.isArray(list.child)) {
      list.child.forEach(subItem => {
        subItem.isChecked = false;
      });
    }

    // Emit only the parent data when its checkbox is toggled
    this.selectionChange.emit(list);
  }

  /**
   * Checks if a sub-item in the list is fully checked.
   */
  isSubListChecked(listIndex: number, subIndex: number): boolean {
    const list = this.dataList[listIndex];
    if (!list || !Array.isArray(list.child) || !list.child[subIndex]) {
      return false;
    }
    return !!list.child[subIndex].isChecked;
  }

  /**
   * Triggered when a sub-item checkbox is toggled.
   * Parent must be selected automatically if any sub-item is checked.
   * Emits the parent + subItem data when a sub-item is toggled.
   */
  toggleSubListUpdate(listIndex: number, subIndex: number, checked: boolean): void {
    const list = this.dataList[listIndex];
    if (!list || !list.child || !list.child[subIndex]) {
      return;
    }

    // Set the subItem's new checked state
    list.child[subIndex].isChecked = checked;

    // If any subItem is checked, ensure the parent is checked
    if (checked) {
      list.isChecked = true;
    }

    // Emit parent + the subItem that was toggled
    this.selectionChange.emit({
      parent: list,
      subItem: list.items[subIndex]
    });
  }

  /**
   * Called when the parent radio group selection changes.
   * - Save the newly selected value to parent
   * - Emit an event with the new value
   */
  parentRadioChange(listIndex: number, newValue: string): void {
    const list = this.dataList[listIndex];
    if (!list) { return; }

    list.radioValue = newValue;

    // Emit the new radio state for the parent
    this.selectionChange.emit({
      type: 'parentRadioChange',
      parentIndex: listIndex,
      parent: list,
      radioValue: newValue
    });
  }

  /**
   * Called when a sub-item's radio group selection changes.
   * - Save the newly selected value to subItem
   * - Emit an event with the new value (and parent too, if desired)
   */
  subListRadioChange(listIndex: number, subIndex: number, newValue: string): void {
    const list = this.dataList[listIndex];
    if (!list || !Array.isArray(list.child) || !list.child[subIndex]) {
      return;
    }

    const subItem = list.child[subIndex];
    subItem.radioValue = newValue;

    // Emit the new radio state for the sub-item
    this.selectionChange.emit({
      type: 'subItemRadioChange',
      parentIndex: listIndex,
      subIndex,
      parent: list,
      subItem,
    });
  }

}
