import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { CommonService } from "src/app/_services/common.service";
import { EquipmentsService } from "src/app/_services/equipments.service";
import { Search } from '../../../../interfaces/common-interface';
import Swal from "sweetalert2";
import { Subscription } from "rxjs";
import { FunctionsService } from "src/app/_common/functions.service";

@Component({
  selector: "app-equipment",
  templateUrl: "./equipment.component.html",

  styleUrls: ["./equipment.component.scss"],
})
export class EquipmentComponent implements OnInit, OnChanges, OnDestroy {
  @Output() addedEquipment = new EventEmitter();
  @Output() sendEquipment = new EventEmitter();
  @Output() nextTabs = new EventEmitter();
  @Input() projectEquipments = [];
  @Input() isSingleSelection = false;
  @Input() fieldWidth = false;
  @Input() isActionBtn = true;
  @Input() isMultiple = true;
  @Input() nextTab = false;
  @Input() employeeIndex: number;
  @Input() equipmentIndex: number | null;
  private selectValueSubscription: Subscription;
  equipmentList = [];
  equipmentSupportForm = this.fb.group({
    category: '',
    equipmentName: '',
    subCategory: '',
  });
  categoryList = [];
  count = 0;
  searchRecord = "";
  individualRigValues = [];
  allSupportEquipData = [];
  addEquipments = [];
  originalData: Search;
  equipmentsId = [];
  subCategories = [];
  isSubCategory = false;
  isEquipment = false;
  categoryId = "";
  filteredData = {};
  constructor(
    private fb: UntypedFormBuilder,
    private equipSrvc: EquipmentsService,
    public cos: CommonService,
    public commonFunction: FunctionsService,
  ) { }

  ngOnInit() {
    this.resetForm();
    this.getEquipmentCategory();
    this.selectValueSubscription = this.cos.selectValue$.subscribe(data => {
      if (Array.isArray(data) && data.length) {
        this.bindEmployeeEquipment(data);
      }
    });
  }

  ngOnChanges() {
    if (this.projectEquipments.length) {
      let equipmentsId = [];
      this.projectEquipments.map(val => {
        this.addEquipments.push({
          name: val?.name,
          category: val.category ? val.category?.name : '',
          subCategory: val.subCategory ? val.subCategory.name : '',
          id: val._id ? val._id : val.id,
        });
      });
      this.projectEquipments.map((val) => {
        equipmentsId.push(val._id ? val._id : val);
      });
      equipmentsId = [...new Set(equipmentsId)];
      this.addedEquipment.emit({ equipments: this.addEquipments, index: this.employeeIndex });


    }

  }

  // This function is used to display sub category field
  async selectedValue(id: string, fieldName: string) {
    let data;
    const newId = id.split(",")[0];
    if (fieldName === "category") {
      this.equipSrvc.getCategoryDetails(newId).subscribe(async (res) => {
        if (res.data.length) {
          this.categoryId = newId;
          this.equipmentSupportForm.patchValue({
            subCategory: '',
            equipmentName: '',
          });
          this.subCategories = [];
          res.data.map((val) => {
            this.subCategories.push({
              key: val._id,
              value: val.name,
            });
          });
          this.isEquipment = false;
          this.isSubCategory = true;
        } else {
          data = {
            category: newId,
            subCategory: '',
          };
          this.getEquipments(data, fieldName);
        }
      });
    } else {
      data = {
        category: this.categoryId ? this.categoryId : newId,
        subCategory: this.categoryId ? newId : '',
      };
      this.getEquipments(data, fieldName);
    }
  }

  // This function gives the equipment list of selected category
  getEquipments(data, fieldName) {
    this.equipSrvc.getEquipmentsList(data).subscribe((res) => {
      if (res.data.length) {
        this.isEquipment = true;
        this.equipmentList = [];
        this.equipmentList = this.commonFunction.mapResponseToKeyValue(res.data)
        if (fieldName === "category") {
          this.isEquipment = true;
          this.isSubCategory = false;
        } else {
          this.isEquipment = true;
          this.isSubCategory = true;
        }
      } else {
        if (fieldName === "category") {
          this.isEquipment = false;
          this.isSubCategory = false;
        } else {
          this.isEquipment = false;
          this.isSubCategory = true;
        }
        return this.cos.toastMessage(
          "The selected Category has no equipments, Please select other",
          "warning"
        );
      }
    });
  }

  // This function is used to delete equipment form list
  deleteAddedEquip(index: number) {
    const deleteEquipment = this.addEquipments[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.addEquipments = this.addEquipments.filter(function (item) {
          return item !== deleteEquipment;
        });
        this.addedEquipment.emit({ equipments: this.addEquipments, index: this.employeeIndex });

      }
    });
  }

  // Save multiple equipments in case isMultiple variable true
  async addMoreEquip() {
    if (!this.equipmentSupportForm.value.category) {
      return this.cos.toastMessage("Please Select equipment category", "warning");
    } else if (
      this.isSubCategory &&
      !this.equipmentSupportForm.value.subCategory
    ) {
      return this.cos.toastMessage("Please Select equipment sub category", "warning"
      );
    } else if (
      this.isEquipment &&
      !this.equipmentSupportForm.value.equipmentName
    ) {
      return this.cos.toastMessage("Please Select equipment name", "warning");
    } else if (!this.equipmentSupportForm.value.equipmentName) {
      return this.cos.toastMessage("Please Select equipment name", "warning");
    }
    else {
      this.equipmentSupportForm.value.equipmentName.map((item) => {
        this.addEquipments.push({
          name: item.includes(",") ? item.split(",")[1] : item,
          category: this.equipmentSupportForm.value.category && this.equipmentSupportForm.value.category.includes(",")
            ? this.equipmentSupportForm.value.category.split(",")[1]
            : this.equipmentSupportForm.value.category,
          subCategory: this.equipmentSupportForm.value.subCategory && this.equipmentSupportForm.value.subCategory.includes(",")
            ? this.equipmentSupportForm.value.subCategory.split(",")[1]
            : this.equipmentSupportForm.value.subCategory,
          id: item.includes(",") ? item.split(",")[0] : item,
        });
      });
      this.addEquipments.map((val) => {
        this.equipmentsId.push(val.id);
      });
      this.equipmentSupportForm.reset();
      this.isSubCategory = false;
      this.isEquipment = false;
      this.addEquipments = this.addEquipments.filter((obj, index, self) => {
        return index === self.findIndex((o) => (
          o.id === obj.id
        ));
      });
      this.addedEquipment.emit({ equipments: this.addEquipments, index: this.employeeIndex });
    }
  }

  // This function reset the equipment form
  resetForm() {
    this.cos.resetField$.subscribe((data) => {
      if (data.toString() === "RESET_EQUIPMENT") {
        this.equipmentSupportForm.reset();
        if (typeof this.equipmentIndex === 'number') {
          this.addEquipments[this.equipmentIndex] = [];
        }
        this.addEquipments = [];
        this.isSubCategory = false;
        this.isEquipment = false;
      }
    });
  }

  // This property perform search functionality
  searchRecordName(data: string, fieldName: string, tableData) {
    if (this.count === 0) {
      this.originalData = { name: fieldName, value: tableData };
    }
    this.count++;
    if (data) {
      const filteredData = this.originalData.value.filter((item) =>
        item.value.toLowerCase().includes(data.toLowerCase())
      );
      if (fieldName === "category") {
        this.categoryList = filteredData;
      } else if (fieldName === "subCategory") {
        this.subCategories = filteredData;
      } else {
        this.equipmentList = filteredData;
      }
    } else {
      if (fieldName === "category") {
        this.categoryList = this.originalData.value;
      } else if (fieldName === "subCategory") {
        this.subCategories = this.originalData.value;
      } else {
        this.equipmentList = this.originalData.value;
      }
    }
  }

  // This function specify the trigger operation when select box is open
  triggerEvent(data: boolean, fieldName: string, arr) {
    this.count = 0;
    if (!data) {
      let selectedValue = null;
      if (fieldName === "category") {
        selectedValue = this.equipmentSupportForm.get("category").value;
        this.categoryList = this.originalData && this.originalData.name === "category" ? this.originalData && this.originalData.value : arr;
      } else if (fieldName === "subCategory") {
        selectedValue = this.equipmentSupportForm.get("subCategory").value;
        this.subCategories = this.originalData && this.originalData.name === "subCategory"
          ? this.originalData.value
          : arr;
      } else {
        selectedValue = this.equipmentSupportForm.get("equipmentName").value;
        this.equipmentList = this.originalData && this.originalData?.name === "equipmentName"
          ? this.originalData.value
          : arr;
      }

      // Set the selected value back to the form control
      this.equipmentSupportForm.get(fieldName).setValue(selectedValue);
      this.searchRecord = '';
    }
  }

  // Save equipments in case isMultiple variable false
  async SaveEquipments() {
    let equipmentsId = [];
    if (!this.equipmentSupportForm.value.category) {
      return this.cos.toastMessage("Please Select equipment category", "warning");
    } else if (
      this.isSubCategory &&
      !this.equipmentSupportForm.value.subCategory
    ) {
      return this.cos.toastMessage(
        "Please Select equipment sub category",
        "warning"
      );
    } else if (
      this.isEquipment &&
      !this.equipmentSupportForm.value.equipmentName
    ) {
      return this.cos.toastMessage("Please Select equipment name", "warning");
    } else {
      this.equipmentSupportForm.value.equipmentName.map((val) => {
        if (val.includes(",")) {
          equipmentsId.push(val.split(",")[0]);
        } else {
          equipmentsId.push(val);
        }
      });
      equipmentsId = [...new Set(equipmentsId)];
      this.addedEquipment.emit(equipmentsId);

    }
  }

  nextTabShow(data: string) {
    this.nextTabs.emit(data);
  }

  // This function is used to get the equipment category
  getEquipmentCategory() {
    this.categoryList = [];
    this.equipSrvc.getEquipCategory().subscribe((res) => {
      if (res.data.length) {
        this.categoryList = this.commonFunction.mapResponseToKeyValue(res.data);        
      }
    });
  }

  // This function send the single equipment
  selectSingleEquipment(fieldName: string) {
    this.sendEquipment.emit({
      equipment: this.isEquipment && this.equipmentSupportForm.value.equipmentName ? this.equipmentSupportForm.value.equipmentName : '',
      category: this.equipmentSupportForm.value.category ? this.equipmentSupportForm.value.category.split(",")[0] : '',
      subCategory: this.isSubCategory && this.equipmentSupportForm.value.subCategory ? this.equipmentSupportForm.value.subCategory.split(",")[0] : '',
      fieldName: fieldName
    });
  }

  // function is used to bind employees's with their equipments
  bindEmployeeEquipment(data) {
    if (Array.isArray(data) && data.length) {
      const equipmentIds = [];
      if (this.employeeIndex) {
        data.filter((val) => val.employeeIndex === this.employeeIndex)
          .map((val) => {
            this.addEquipments.push({
              name: val.name,
              category: val.category.name,
              subCategory: val.subCategory ? val.subCategory.name : '',
              id: val._id ? val._id : val.id,
            });
          });
      } else {
        data.map((val) => {
          if (val) {
            this.addEquipments.push({
              name: val?.name,
              category: val.category ? val.category.name : '',
              subCategory: val.subCategory ? val.subCategory.name : '',
              id: val._id ? val._id : val.id,
            });
          }
        });
      }
      const uniqueSet = new Set();

      // Filter out the duplicate objects based on the 'id' property
      const uniqueData = this.addEquipments.filter(item => {
        if (uniqueSet.has(item.id)) {
          return false;
        } else {
          uniqueSet.add(item.id);
          return true;
        }
      });
      this.addEquipments = [];
      this.addEquipments = uniqueData;
      this.addEquipments.map((val) => {
        equipmentIds.push(val.id ? val.id : val);
      });
      this.addedEquipment.emit({ equipments: this.addEquipments, index: this.employeeIndex });

    } else {
      this.addEquipments = this.cos.deleteObjectsById(this.addEquipments, data?.deletedIds);
    }
  }



  // Unsubscribe to avoid memory leaks
  ngOnDestroy() {
    this.selectValueSubscription.unsubscribe();
  }

}


