import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './modules/shared/components/panel-layout/dashboard/dashboard.component'
import { AuthGuard } from './guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],data: { componentName: 'Dashboard' } },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(module => module.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('./modules/project/project.module').then(module => module.ProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'equipments',
    loadChildren: () => import('./modules/equipments/equipments.module').then(module => module.EquipmentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'off-time-management',
    loadChildren: () => import('./modules/off-time-management/off-time-management.module').then(module => module.OffTimeManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'state-rules',
    loadChildren: () => import('./modules/state-rules/state-rules.module').then(module => module.StateRulesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule',
    loadChildren: () => import('./modules/schedule/schedule.module').then(module => module.ScheduleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./modules/live-chat/live-chat.module').then(module => module.LiveChatModule),
  },
  {
    path: 'general-task-list',
    loadChildren: () => import('./modules/general-task/general-task.module').then(module => module.GeneralTaskModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./modules/configuration/configuration.module').then(module => module.ConfigurationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'group',
    loadChildren: () => import('./modules/group/group.module').then(module => module.GroupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/repair-maintenance/repair-maintenance.module').then(module => module.RepairMaintenanceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bulletin',
    loadChildren: () => import('./modules/bulletin/bulletin.module').then(module => module.BulletinModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'access-control',
    loadChildren: () => import('./modules/access-control/access-control.module').then(module => module.AccessControlModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('./modules/audit-users/user-logs.module').then(module => module.UserLogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth/account-details',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vendor-list',
    loadChildren: () => import('./modules/vendor/vendor.module').then(module => module.VendorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'perdiem',
    loadChildren: () => import('./modules/global-per-diem/global-per-diem.module').then(module => module.GlobalPerDiemModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'broadcast-notification',
    loadChildren: () => import('./modules/broadcast-notification/broadcast-notification.module').then(module => module.BroadcastNotificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then(module => module.CompanyModule),
    canActivate: [AuthGuard]
  },

  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
