import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { AuthService } from "src/app/_services/auth.service";
import { CommonService } from "src/app/_services/common.service";
import { ConfigurationService } from "src/app/_services/configuration.service";
import { GroupService } from "src/app/_services/group.service";
import { PatchAssigned } from '../../../../interfaces/common-interface';

@Component({
  selector: "app-crew-assignment",
  templateUrl: "./crew-assignment.component.html",
  styleUrls: ["./crew-assignment.component.scss"],
})
export class CrewAssignmentComponent implements OnChanges {
  @Input() patchAssigned: PatchAssigned;
  @Input() fieldWidth = false;
  @Input() employeeIndex: number;
  @Input() isRequired = false;
  @Output() bindEquipment = new EventEmitter();
  @Output() assignedData = new EventEmitter();
  assignedCrewId = {};
  employeeForm = this.fb.group({
    employee: [""],
    assigned: "",
    group: [""],
  });
  crewData = [
    {
      title: this.isRequired ? "Assigned To*" : "Assigned To",
      name: "assigned",
      multipleValues: this.cos.crewAssignment,
      singleSelection: true,
    },
  ];
  groups = [];
  employees = [];
  duplicateValue = [];
  ids = [];
  equipmentList = [];
  employeeEquip = [];
  constructor(
    public cos: CommonService,
    private authSrvc: AuthService,
    private configSrvc: ConfigurationService,
    private groupSrvc: GroupService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnChanges() {
    this.resetForm();
    if (!Array.isArray(this.patchAssigned)) {
      if (this.patchAssigned) {
        const employee = Array.isArray(this.patchAssigned.groups) && this.patchAssigned.groups.length ? [] : this.patchCrew(this.patchAssigned);
        this.employeeForm.patchValue({
          assigned: this.getAssigned(this.patchAssigned),
          group: Array.isArray(this.patchAssigned.groups) && this.patchAssigned.groups.length ? this.patchAssigned.groups.map((val) => val._id) : [],
          employee: employee,
        });
        this.emitAssignedData();
      }
    }
  }

  // Function provide assigned field which we have selected form crew assignment
  getAssigned(data) {
    if (
      ((Array.isArray(data.groups) && data.groups.length === 0) ||
        !data.groups) &&
      ((Array.isArray(data.employees) && data.employees.length === 0) ||
        !data.employees) &&
      ((Array.isArray(data.assignedTo) && data.assignedTo.length === 0) ||
        !data.assignedTo)
    ) {
      return [];
    }
    if (Array.isArray(data.groups) && data.groups.length) {
      const value = { ids: "GROUP" };
      this.setAssignedData(value);
      return "GROUP";
    }
    if (
      (((Array.isArray(data.employees) && data.employees.length) ||
        (Array.isArray(data.assignedTo) && data.assignedTo.length)) &&
        Array.isArray(data.groups) &&
        data.groups.length === 0) ||
      !data.groups
    ) {
      const value = { ids: "INDIVIDUAL" };
      this.setAssignedData(value);
      return "INDIVIDUAL";
    }
  }

  async setAssignedData(data) {
    this.assignedData.emit({ value: data, index: this.employeeIndex });
    if (Array.isArray(data.ids)) {
      this.getEmpEquip(data);
    } else {
      if (data.ids === "GROUP") {
        await this.getGroups();
        await this.cos.removeFieldWithName(this.crewData, "employee");
        const insertedData = {
          title: this.isRequired ? 'Groups*':"Groups",
          name: "group",
          multipleValues: this.groups,
          singleSelection: false
        };
        this.crewData.splice(1, 0, insertedData);
      }
      if (data.ids === "INDIVIDUAL") {
        await this.getEmployee();
        await this.cos.removeFieldWithName(this.crewData, "group");
        const insertedData = {
          title: this.isRequired ?"Employees*":"Employees",
          name: "employee",
          multipleValues: this.employees,
          singleSelection: false
        };
        this.crewData.splice(1, 0, insertedData);
      }
    }
    this.emitAssignedData();
  }

  async getGroups() {
    this.groups = [];
    this.groupSrvc.getGroupList().subscribe((res) => {
      if (res.data.length) {
        this.groups = res.data.map((val) => ({
          value: val.name, key: val._id
        }));
        this.updateCrewData('group', this.groups);
      }
    });
  }

  async getEmployee() {
    this.employees = [];
    this.authSrvc.getActiveEmployee().subscribe((res) => {
      if (res.data.length) {
        this.employees = res.data.map((val) => ({
          key: val._id, value: val.userName
        }));
        this.updateCrewData('employee', this.employees);
      }
    });
  }

  updateCrewData(fieldName, values) {
    this.crewData = this.crewData.map((field) => {
      if (field.name === fieldName) {
        return { ...field, multipleValues: values };
      }
      return field;
    });
  }

  // Function gives the bind equipment of employee
  getEmpEquip(body) { 
    if (body) {
      const id = body.assignId;
      const deletedIds = [];
      const foundItem = body.state === false && this.employeeEquip.length ? this.employeeEquip.find(item => item.id === id) : null;
      if (foundItem) {
        deletedIds.push(foundItem.equipments);
        this.employeeEquip = this.cos.removeObjectWithId(this.employeeEquip, id);
        this.equipmentList = this.cos.deleteObjectsById(this.equipmentList, deletedIds.flat());
        const result = { deletedIds: deletedIds.flat() };
        this.bindEquipment.emit(result);
        this.assignedCrewId = this.equipmentList.length ? { name: 'Crew', ids: this.equipmentList.map(val => val.id) } : {};
      } else {
        this.configSrvc.getEquipment(id).subscribe((res) => {
          if (res.data && Array.isArray(res.data.equipment) && res.data.equipment.length) {
            this.employeeEquip.push({
              id: id,
              equipments: res.data.equipment.map(val => val._id)
            });
            this.equipmentList.push(...res.data.equipment.map(val => ({
              category: val.category,
              name: val.name,
              id: val._id,
              subCategory: val.subCategory ? val.subCategory : "",
            })));
            this.bindEquipment.emit(this.equipmentList);
            this.assignedCrewId = this.equipmentList.length ? { name: 'Crew', ids: this.equipmentList.map(val => val.id) } : {};
          }
        }, err => { this.cos.handleError(err); });
      }
    }
  }

  emitAssignedData() {
    const ids = this.employeeForm.value.group.length ? this.employeeForm.value.group : this.employeeForm.value.employee;
    this.assignedCrewId = ids.length ? { name: 'Crew', ids: ids } : {};
    this.assignedData.emit({
      value: {
        ids: this.employeeForm.value.group.length ? this.employeeForm.value.group : this.employeeForm.value.employee,
        name: this.employeeForm.value.group.length ? 'group' : 'employee'
      },
      index: this.employeeIndex
    });
  }

  // This property reset the crewAssignment form
  resetForm() {
    this.cos.resetField$.subscribe((data) => {
      if (data.toString() === "RESET_CREW") {
        this.employeeForm.reset();
        this.employeeEquip = [];
        this.crewData = [
          {
            title: this.isRequired ? "Assigned To*":"Assigned To",
            name: "assigned",
            multipleValues: this.cos.crewAssignment,
            singleSelection: true,
          },
        ];        
      }
    });
  }

  // This property is used to check assignedTo field is present or not
  hasAssignedEmployees(obj) {
    return obj.assignedTo && obj.assignedTo.length > 0;
  }

  // This functionality is used to patch the employees
  patchCrew(data) {
    if (data) {
      const crewData = this.hasAssignedEmployees(data);
      if (crewData) {
        return data.assignedTo.map((val) => val._id);
      } else {
        return data.employees.map((val) => val._id);
      }
    }
  }
}
