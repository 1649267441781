import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './modules/shared/components/panel-layout/dashboard/dashboard.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SharedModule } from './modules/shared/shared.module';
import { GlobalPerDiemComponent } from './modules/global-per-diem/global-per-diem.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { ErrorInterceptor } from './_helper/error.interceptor';
import { HideSpinnerDirective } from './_directive/hide-spinner.directive';
import { MobeSiteFuelComponent } from './mobe-site-fuel/mobe-site-fuel.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GlobalPerDiemComponent,
    HideSpinnerDirective,
    MobeSiteFuelComponent,
  ],
  imports: [
    BrowserModule,
    EditorModule,
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NgxSpinnerService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
