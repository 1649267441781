import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appVisibilityControl]",
})
export class VisibilityControlDirective implements OnInit {
  @Input() enabled = true;
  constructor(private element: ElementRef) {}
  ngOnInit(): void {
    if (!this.enabled) {
      this.element.nativeElement.remove();
    }
  }
}
