import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../interfaces/common-interface';
import { AddRole, AdminUsers, AllPermission, EmployeeRole, RoleList, USerDetails, UserResponse, UserUpdateResponse } from '../interfaces/acl';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  ADD_ROLE = 'api/role';
  ROLE_LIST = 'api/role/list';
  ROLE_PERMISSION = 'api/permissions/permissions-aggregate';
  ADMIN_USERS = 'api/admin/list';
  ADMIN_USER_CRUD = 'api/user/register/admin';
  ADMIN_USER_DETAIL = 'api/admin'
  EMPLOYEE_ROLE='api/role/employee-roles';

  constructor(
    private http: HttpClient
  ) { }

  permissions = new BehaviorSubject({ fetched: false, resources: [] });
  

  addAcRole(data) {
    return this.http.post<AddRole>(environment.BASE_URL + this.ADD_ROLE, data)
  }

  getAcRole(search) {
    return this.http.get<RoleList>(`${environment.BASE_URL}${this.ROLE_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  getRoles() {
    return this.http.get<RoleList>(`${environment.BASE_URL}${this.ROLE_LIST}?pagination=false`)
  }


  deleteAcRole(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids: [id] }
    }
    return this.http.delete<Message>(environment.BASE_URL + this.ADD_ROLE, options)
  }

  updateAcRole(data) {
    return this.http.put<AddRole>(environment.BASE_URL + this.ADD_ROLE, data)
  }

  getAcRoleDetail(id: string) {
    return this.http.get<AddRole>(`${environment.BASE_URL}${this.ADD_ROLE}?id=${id}`);
  }

  getPermission() {
    return this.http.get<AllPermission>(`${environment.BASE_URL}${this.ROLE_PERMISSION}`)
  }

  getAdminUser(search) {
    return this.http.get<AdminUsers>(`${environment.BASE_URL}${this.ADMIN_USERS}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  updateUserRole(data) {
    return this.http.put<UserUpdateResponse>(environment.BASE_URL + this.ADMIN_USER_DETAIL, data)
  }

  addUserRole(data) {
    return this.http.post<UserResponse>(environment.BASE_URL + this.ADMIN_USER_CRUD, data)
  }

  getUserDetails(id: string) {
    return this.http.get<USerDetails>(`${environment.BASE_URL}${this.ADMIN_USER_DETAIL}?id=${id}`)
  }

  deleteAdminUser(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: { ids: [id] }
    }
    return this.http.delete<Message>(environment.BASE_URL + this.ADMIN_USER_DETAIL, options)
  }

   getEmployeeRoles(role: string) {
    return this.http.get<EmployeeRole>(`${environment.BASE_URL}${this.EMPLOYEE_ROLE}?role=${role}`)
  }



}
