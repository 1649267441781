<form [formGroup]="inputProperty.form" class="d-flex">
  <div style="width: 100%">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{ inputProperty?.title }}</mat-label>
      <textarea
        maxlength="{{ inputProperty?.length ?? 500 }}"
        matInput
        type="text"
        formControlName="{{ inputProperty?.name }}"
        [value]="inputProperty?.value ? inputProperty?.value : ''"
        class="ad-text-size"
        placeholder="{{ inputProperty?.placeholder }}"
        (change)="changeInput($event.target.value, inputProperty.name)"
      ></textarea>
    </mat-form-field>
  </div>
</form>
