import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/_services/common.service';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-ad-input',
  templateUrl: './ad-input.component.html',
  styleUrls: ['./ad-input.component.scss']
})
export class AdInputComponent {
  @Input() inputProperty = {
    title: '',
    placeholder: '',
    type: 'text',
    length: 60,
    width: 'col-6',
    name: 'input',
    form: this.fb.group({}),
    addIcon: false,
    value: '',
    readOnly: false,
  }
  @Input() patchInput: object;
  @Output() inputValue = new EventEmitter<{fieldValue: string, fieldName: string}>;
  @Output() updateFiled = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public cos: CommonService,
  ) {}


  // Function to get the value from input
  changeInput(data: string, type: string) {
    this.inputValue.emit({fieldValue: data, fieldName: type});
  }

  // Function to sent value in case you want to add new input field
  addField(data) {
    if(!this.inputProperty.form.value[data.name]) {
      return this.cos.toastMessage(response.en.ADD_VALUEFIELD, 'warning');
    } else {
      this.updateFiled.emit({ details: data, type: 'add' });
    }
  }

  // Function to sent value in case you want to remove existing field
  removeField(data): void {
    this.updateFiled.emit({ details: data, type: 'remove' });
  }

}
