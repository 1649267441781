import { Injectable } from "@angular/core";
import { CommonService } from "../_services/common.service";
import "bootstrap";
import * as jQuery from "jquery";
import { VariableService } from "./variable.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  constructor(
    public cos: CommonService,
    public commonVariable: VariableService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  // Function to concatenate objects with the same value for a specified key
  mergeObjectsByKey(array, key: string) {
    const result = {};
    array.forEach((obj) => {
      const keyValue = obj[key];
      const items = obj.items || [];
      if (result[keyValue]) {

        // Update Items
        result[keyValue].items = items?.length ? [...new Set([...result[keyValue].items, ...items])] : [];

        // Update depth if present
        if (obj.depth !== undefined) {
          result[keyValue].depth = Math.max(
            result[keyValue].depth || 0,
            obj.depth
          );
        }
        if (obj.cost) {
          result[keyValue].cost = Math.max(
            result[keyValue].cost || 0,
            obj.cost
          );
        }
      } else {
        result[keyValue] = { ...obj, items: [...new Set(items)] };
      }
    });

    return Object.values(result);
  }

  // Function to get the active tab name in project estimates
  async getTabName(data: string): Promise<string> {
    try {
      switch (data) {
        case "estimate-type":
          return "_estimates";
        case "scope-notes":
          return "scopeNotes";
        case "downhole-tests":
          return "_downHoles";
        case "client-request":
          return "_clientRequest";
        case "project-costs":
          return "_projectCosts";
        case "ppe":
          return "_ppeDetails";
        case "rentals":
          return "_specialOrder";
        case "water-source":
          return "_waterSource";
        case "per-diem":
          return "_perDiem";
        case "time-considerations":
          return "_timeConsideration";
        case "support-equipment":
          return "_supportEquipment";
        case "sampling-interval":
          return "_sampling";
        case "additional-detail":
          return "_additionalDetails";
        case "drill-methods":
          return "_drill";
        case "sampling-supplies":
          return "_samplingSupplies";
        default:
          return `_${data.toLowerCase()}`;
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to make an array with only value and its unique id
  public mapResponseToKeyValue(data) {
    return data
      .filter(
        (val) => val?.name || val?.userName || val.projectName || val.boringId || val?.scheduler?.name || val?._id || val?._schedule?.name,
      )
      .map((val) => ({
        key: val._id,
        status: val.status ?? "",
        value: val?.name || val?.userName || val.projectName || val.boringId || val?.scheduler?.name || val?._id,
        items: val?.items,
        cost: val?.cost,
        quantity: val?.quantity ?? 1,
        sizes: val?.sizes,
        size: val?.size,
        sizeIds: val?.sizeIds,
        topic: val?.topic,
        isDefault: val?.isDefault
      }));
  }

  // Function to make an array with only value and its unique id, along with pricePerUnit
  public mapResponseToUnitValue(data) {
    return data
      .filter(
        (val) =>
          val?.name ||
          val?.userName ||
          val.projectName ||
          val.boringId ||
          val?.scheduler?.name ||
          val?._id
      )
      .map((val) => ({
        key: val._id,
        status: val.status ?? "",
        value:
          val?.name ||
          val?.userName ||
          val.projectName ||
          val.boringId ||
          val?.scheduler?.name ||
          val?._id,
        items: val?.items,
        cost: val?.cost,
        quantity: val?.quantity ?? 1,
        sizes: val?.sizes,
        size: val?.size,
        sizeIds: val?.sizeIds,
        pricePerUnit: val?.pricePerUnit || [] // Add pricePerUnit to the response
      }));
  }

  // Function to make an array with only value and its unique id
  public mapResponseToKeyEstimateValue(data) {
    
    return data
      .filter(
        (val) => val?.name || val?.userName || val.projectName || val.boringId || val?.scheduler?.name
      )
      .map((val) => ({
        key: val._id,
        status: val.status ?? "",
        value: val?.name || val?.userName || val.projectName || val.boringId || val?.scheduler?.name,
        items: val?.items,
        costPrice: val?.pricePerUnit[0]?.costPrice?.value,
        sellingPrice: val?.pricePerUnit[0]?.sellingPrice?.value,
        cost: val?.cost,
        unit: val?.pricePerUnit[0]?.unit,
        quantity: val?.quantity ?? 1,
        sizes: val?.sizes,
        size: val?.size,
        sizeIds: val?.sizeIds,
      }));
  }

  // Function to make an array with only value and its unique id
  public mapResponseToProjectValue(data) {
    
    return data
      .filter(
        (val) => val.projectName || val.jobName
      )
      .map((val) => ({
        key: val._id,
        status: val.status ?? "",
        value: val.projectName || val.jobName,
        items: val?.items,
        costPrice: val?.pricePerUnit[0]?.costPrice?.value,
        sellingPrice: val?.pricePerUnit[0]?.sellingPrice?.value,
        cost: val?.cost,
        unit: val?.pricePerUnit[0]?.unit,
        quantity: val?.quantity ?? 1,
        sizes: val?.sizes,
        size: val?.size,
        sizeIds: val?.sizeIds,
      }));
  }

  // Function to make table data to display
  generateFilteredData(data, componentKey: string) {
    const assigned = this.getAssignValue(data);
    const payload = {
      name: data.name,
      id: data._id,
      key: componentKey,
      assigned_to: assigned,
      start_date: this.cos.getFormattedDate(data.startDate),
      end_date: this.cos.getFormattedDate(data.endDate),
      status: this.cos.getCamelCaseValue(data.status),
      priority: this.cos.getCamelCaseValue(data.priority),
      docs: Array.isArray(data.documents) ? data.documents.length : 0,
    };
    return payload;
  }

  // This property used to assign the crew assignment type
  getAssignValue(element) {
    if (element) {
      if (Array.isArray(element.groups) && element.groups.length) {
        const firstGroupName = element.groups[0].name;
        const groupCount = element.groups.length - 1;
        const result =
          groupCount > 0
            ? `${firstGroupName} +${groupCount}`
            : `${firstGroupName}`;
        return result;
      }
      if (
        Array.isArray(element.assignedTo) &&
        element.assignedTo.length &&
        ((Array.isArray(element.groups) && element.groups.length === 0) ||
          !element.groups)
      ) {
        const firstUserName = element.assignedTo[0].userName;
        const userCount = element.assignedTo.length - 1;
        const result =
          userCount > 0 ? `${firstUserName} +${userCount}` : `${firstUserName}`;
        return result;
      }
      if (
        ((Array.isArray(element.groups) && element.groups.length === 0) ||
          !element.groups) &&
        ((Array.isArray(element.assignedTo) &&
          element.assignedTo.length === 0) ||
          !element.assignedTo)
      ) {
        return "N/A";
      }
    }
    return "N/A";
  }

  // Function to open modal
  modalOpen(modalProperty: { id: string; header: string }) {
    jQuery(`#${modalProperty.id}`).modal("show");
  }

  // Function to close modal
  modalClose(modalProperty: { id: string; header: string }) {
    jQuery(`#${modalProperty.id}`).modal("hide");
  }

  // Function to filter the array/ remove fields from object
  filterArray(data) {
    const modifiedData = data.map(
      ({ id, uploadType, type, name, ...rest }) => ({
        ...rest,
        ...(name ? { fileName: name } : {}),
      })
    );
    return modifiedData;
  }

  // Function to filter the array/ remove fields from object acc to id
  filterIdArray(data) {
    const modifiedData = data.map(({ _id, uploadType, type, name, ...rest }) => ({
      ...rest,
      id: _id, 
      ...(name ? { fileName: name } : {}),
    }));
    return modifiedData;
  }

  // Function to get start /end date of previous month
  getMonthRange(type: string, date) {
    const currentDateClone = moment(date);
    let formattedStartDate = new Date().toISOString();
    let formattedLastDate = new Date().toISOString();
    if (type === "next") {
      const nextMonthFirstDate = currentDateClone.add(1, "months").date(1);
      const startDate = nextMonthFirstDate.toDate();
      formattedStartDate = moment(
        new Date(startDate).toDateString()
      ).toISOString();
    } else {
      const previousMonthFirstDate = currentDateClone
        .subtract(1, "months")
        .date(1);
      const lastDate = previousMonthFirstDate.toDate();
      formattedLastDate = moment(
        new Date(lastDate).toDateString()
      ).toISOString();
    }
    return { startDate: formattedStartDate, endDate: formattedLastDate };
  }

  // Function to get the next tab value
  getNextTab(tab: string): string | null {
    const index = this.commonVariable.estimateTabs.indexOf(tab);

    if (index !== -1 && index < this.commonVariable.estimateTabs.length - 1) {
      return this.commonVariable.estimateTabs[index + 1];
    }

    return null;
  }

  // Function to get the number field
  findStringWithNumbers(fromPath, toPath) {
    const isNumber = (str) => /\d/.test(str);
    if (isNumber(fromPath) && isNumber(toPath)) {
      return [fromPath, toPath];
    } else if (isNumber(fromPath)) {
      return [fromPath];
    } else if (isNumber(toPath)) {
      return [toPath];
    } else {
      return null;
    }
  }

  // Function to navigate the tabs of estimates
  navigateToEstimate(tab: string): void {
    const routerSegments =
      this.route.snapshot["_urlSegment"].children.primary.segments;
    let tabType = routerSegments[2].path;
    const tabName = ["subCategory", "items"];
    if (tabName.includes(tabType)) {
      tabType = routerSegments[3].path;
    }
    const projectParams = this.findStringWithNumbers(
      routerSegments[routerSegments.length - 3].path,
      routerSegments[routerSegments.length - 2].path
    );
    const isEdit = tabType === "edit";
    const tabLink = this.getNextTab(tab);
    if (!tabLink) {
      this.router.navigateByUrl(
        `/project/project-information/project-estimate/${
          projectParams.length > 1 ? projectParams[1] : projectParams[0]
        }`
      );
      return;
    }
    let url;
    const itemsTab = [
      "environmental",
      "installation",
      "water-source",
      "time-considerations",
      "per-diem",
      "permits",
      "abandonment",
      "support-equipment",
      "sampling-interval",
      "sampler",
    ];
    const subcategoryTab = [
      "site",
      "sampling-supplies",
      "surface",
      "drill-methods",
      "additional-detail",
    ];
    if (itemsTab.includes(tabLink)) {
      url = `/project/project-estimate/items/${isEdit ? "edit" : "add"}/${
        projectParams[0]
      }/${projectParams.length > 1 ? projectParams[1] + "/" : ""}${tabLink}`;
    } else if (subcategoryTab.includes(tabLink)) {
      url = `/project/project-estimate/subCategory/${isEdit ? "edit" : "add"}/${
        projectParams[0]
      }/${projectParams.length > 1 ? projectParams[1] + "/" : ""}${tabLink}`;
    } else {
      url = `/project/project-estimate/${isEdit ? "edit" : "add"}/${
        projectParams[0]
      }/${projectParams.length > 1 ? projectParams[1] + "/" : ""}${tabLink}`;
    }
    this.router.navigateByUrl(url);
  }

  // Function to remove field from array after get the user input field
  removeObjectsAfterName(arr, targetName: string) {
    const indexToRemove = arr.findIndex((obj) => obj.name === targetName);
    if (indexToRemove !== -1) {
      arr.splice(indexToRemove + 1);
    }
    return arr;
  }

  // Function to return data's first value and length
  getValueAndLength(data) {
    if (Array.isArray(data) && data.length) {
      const firstValue = data[0].name;
      const length = data.length - 1;
      const result =
        length > 0 ? `${firstValue} (+${length})` : `${firstValue}`;
      return result;
    }
    return "N/A";
  }

  // Function to get current week date
  getCurrentWeekDate() {
    const startOfWeek = moment().startOf("week").toDate();
    const formattedStartDate = startOfWeek.toISOString();
    const endOfWeek = moment().endOf("week").toDate();
    const formattedEndDate = endOfWeek.toISOString();
    return { formattedStartDate, formattedEndDate };
  }

  // Function to check given variable is array with some length
  isArrayWithLength(data) {
    if (Array.isArray(data) && data.length) {
      return true;
    } else {
      return false;
    }
  }

  // Function to get the kry object pair from array
  public getKeys(data) {
    if (data) {
      return Object.keys(data);
    } else {
      return " ";
    }
  }

  // Function to return boolean if single array contain same object with save value
  checkForDuplicates(arr, type: string) {
    const namesSet = new Set();
    for (const obj of arr) {
      if (namesSet.has(obj[type])) {
        return true;
      }
      namesSet.add(obj[type]);
    }
    return false;
  }

  // Function to return boolean if an array's object have same name as compared to provided name
  isItemPresent(array, name: string, type: string) {
    for (const obj of array) {
      if (obj[type] === name) {
        return true;
      }
    }
    return false;
  }
}
