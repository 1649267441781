<app-ad-common-modal [modalProperty]="modalProperty">
  <form [formGroup]="mailForm" (ngSubmit)="submitForm()" #rstState="ngForm">
    <div class="row py-2 pr-2">
      <div class="col-sm-6 form-field">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>To*</mat-label>
          <input
            class="ad-text-size"
            matInput
            type="email"
            formControlName="to"
            maxlength="50"
            placeholder="To"
          />
        </mat-form-field>
      </div>
      <div class="col-sm-6 form-field">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Subject*</mat-label>
          <input
            class="ad-text-size"
            type="text"
            matInput
            formControlName="subject"
            maxlength="200"
            placeholder="Subject"
          />
        </mat-form-field>
      </div>
      <div class="col-sm-12 form-field">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>Body*</mat-label>
          <textarea
            class="ad-text-size"
            type="text"
            matInput
            formControlName="body"
            placeholder="Body"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-sm-12 form-field">
        <div *ngFor="let url of urls; let i = index" class="documents-div mt-2">
          <a [href]="url" target="_blank">
            <div class="d-flex align-item-center mr-1">
              <span class="material-icons file-icon">description</span>
            </div>
            <span class="ml-3">{{
              url.name ? url.name : "Attachment " + (i + 1)
            }}</span>
          </a>
          <span class="material-icons delete-icon" (click)="removeAttachment(i)"
            >delete</span
          >
        </div>
      </div>
    </div>
    <div class="float-right text-right mt-3">
      <button type="button" (click)="submitForm()" class="btn btn-primary">
        Send
      </button>
    </div>
  </form>
</app-ad-common-modal>
