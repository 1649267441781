<form [formGroup]="inputProperty[0].form" class="row">
  <div [ngClass]="mediumSizeField ? 'col-4' : 'col-6'">
    <app-ad-input
      [inputProperty]="inputProperty[0]"
      (inputValue)="getInputValue($event)"
    ></app-ad-input>
  </div>
  <div
    *ngIf="documentModalDetail.fieldVisibility === 'link'"
    [ngClass]="mediumSizeField ? 'col-4' : 'col-6'"
  >
    <app-ad-input
      [inputProperty]="inputProperty[1]"
      (inputValue)="getInputValue($event)"
    >
    </app-ad-input>
  </div>
  <div
    *ngIf="documentModalDetail.fieldVisibility === 'document'"
    [ngClass]="mediumSizeField ? 'col-4' : 'col-6'"
  >
    <app-upload-file
      (uploadImage)="upload($event)"
      [uploadedImages]="passImages()"
      (removeImage)="removeImage($event)"
      [uploadedImages]="document"
    >
    </app-upload-file>
  </div>
  <div class="col-sm-12">
    <app-ad-textarea
      [inputProperty]="inputProperty[2]"
      (textAreaValue)="getInputValue($event)"
    >
    </app-ad-textarea>
  </div>
</form>

<div class="float-right">
  <app-ad-button
    [buttonProperty]="documentModalDetail.buttonData"
    (buttonHandler)="submitDocument($event)"
  >
  </app-ad-button>
</div>
