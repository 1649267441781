import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { FunctionsService } from "src/app/_common/functions.service";
import { forkJoin } from "rxjs";
import { CommonService } from "src/app/_services/common.service";
import { RepairMaintenanceService } from "src/app/_services/repair-maintenance.service";
import { EquipmentsService } from "src/app/_services/equipments.service";
import { GroupService } from "src/app/_services/group.service";
import { AuthService } from "src/app/_services/auth.service";
import * as jQuery from "jquery";
import { ConfigurationService } from "src/app/_services/configuration.service";
import { response } from "src/app/enum/message";
import { VariableService } from "src/app/_common/variable.service";

@Component({
  selector: "app-repair-modal",
  templateUrl: "./repair-modal.component.html",
  styleUrls: ["./repair-modal.component.scss"],
})
export class RepairModalComponent implements OnChanges {
  @Output() submitRepairData = new EventEmitter();
  @Input() modalId = "addRepair";
  @Input() editRepairMaintenance: string;
  @Input() equipmentInfo = [];
  @Input() patchData;
  modalType = "Add Repair & Maintenance";
  activeTab = "basic-detail";
  documents = [];
  basicDetail = {};
  groupList = [];
  employeesList = [];
  equipments = [];
  patchDocument = [];
  repairForm = this.fb.group({
    startDate: "",
    endDate: "",
    status: "",
    priority: "",
    group: "",
    employee: "",
    assigned: "",
    name: "",
    parts: "",
    description: "",
    note: "",
    id: "",
    request: "",
  });
  state = [
    {
      buttonName: "Next",
      type: "Maintenance",
    },
  ];

  documentBtn = [{ name: "Save", type: "Maintenance", showBackBtn: true }];
  formData = [
    {
      title: "Equipment Name*",
      name: "name",
      type: "checkBox",
      multipleValues: [],
      singleSelection: true,
      message:`Equipment ${response.en.NAME}`,
    },
    {
      title: "Start Date",
      name: "startDate",
      placeholder: "MM/DD/YYYY",
      type: "date",
    },
    {
      title: "End Date",
      name: "endDate",
      placeholder: "MM/DD/YYYY",
      type: "date",
    },
    {
      title: "Status",
      name: "status",
      type: "checkBox",
      multipleValues: this.commonVariable.statusData,
      singleSelection: true,
    },
    {
      title: "Priority",
      name: "priority",
      type: "checkBox",
      multipleValues: this.cos.priorityValue,
      singleSelection: true,
    },
    {
      title: "Assigned To*",
      name: "assigned",
      type: "checkBox",
      multipleValues: this.cos.crewAssignment,
      singleSelection: true,
      message: response.en.ASSIGNMENT,
    },
    {
      title: "Parts Required",
      name: "parts",
      placeholder: "Enter Required Parts Name",
      limit: 500,
      type: "summary",
    },
    {
      title: "Description",
      name: "description",
      placeholder: "Enter Equipment Description",
      limit: 500,
      type: "summary",
    },
    {
      title: "Note",
      name: "note",
      placeholder: "Enter Note",
      limit: 500,
      type: "summary",
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private equipmentSrvc: EquipmentsService,
    public commonFunction: FunctionsService,
    private repairSrvc: RepairMaintenanceService,
    private grpSrvc: GroupService,
    private authSrvc: AuthService,
    private configSrvc: ConfigurationService,
    private commonVariable: VariableService,
  ) {
  }

  ngOnChanges() {
    if (this.editRepairMaintenance) {
      this.modalType = "Edit Repair and Maintenance";
      this.activeTab = "basic-detail";
      this.repairForm.reset();
      this.cos.resetDocTable("RESET_DOC");
      this.patchRepair(this.editRepairMaintenance);
    }

    // Logic runs When we access it from Field inspection
    if (this.equipmentInfo.length > 0) {

        this.cos.removeFieldWithName(this.formData, "name");
        const insertedData = {
          title: "Equipment Name*",
          name: "name",
          type: "checkBox",
          multipleValues: [],
          singleSelection: false,
          message: `Equipment ${response.en.NAME}`,
        };
        this.formData.splice(0, 0, insertedData);
      this.resetForm();
    }
  }

  // Function to save the basic details of equipment
  public saveBasicDetail(element) {
    const body = element.formValue;
    if (
      body.assigned === "GROUP" &&
      (!body.group || (Array.isArray(body.group) && body.group.length === 0))
    ) {
      return this.cos.toastMessage(`GROUP ${response.en.NAME}`, "warning");
    } else if (
      body.assigned === "INDIVIDUAL" &&
      (!body.employee ||
        (Array.isArray(body.employee) && body.employee.length === 0))
    ) {
      return this.cos.toastMessage(response.en.EMPLOYEE, "warning");
    } else {
      this.basicDetail = {
        startDate: body.startDate ? body.startDate : null,
        dueDate: body.endDate ? body.endDate : null,
        _equipment: this.repairForm.value.id ? body.name : [body.name],
        groups:
          body.assigned === "GROUP" &&
          Array.isArray(body.group) &&
          body.group.length
            ? body.group
            : [],
        assignedTo:
          body.assigned === "INDIVIDUAL" &&
          Array.isArray(body.employee) &&
          body.employee.length
            ? body.employee
            : [],
        status: body.status,
        priority: body.priority,
        description: body.description ? body.description.trim() : "",
        partsRequired: body.parts ? body.parts : "",
        note: body.note ? body.note.trim() : "",
      };
      this.activeTab = "documents";
    }
  }

  public async selectedValue(data) {
    if (data[0].value === "GROUP") {
      this.cos.removeFieldWithName(this.formData, "group");
      this.cos.removeFieldWithName(this.formData, "employee");
      const insertedData = {
        title: "Groups",
        name: "group",
        type: "checkBox",
        multipleValues: this.groupList,
        singleSelection: false,
      };
      this.formData.splice(6, 0, insertedData);
    }
    if (data[0].value === "INDIVIDUAL") {
      this.cos.removeFieldWithName(this.formData, "employee");
      this.cos.removeFieldWithName(this.formData, "group");
      const insertedData = {
        title: "Employees",
        name: "employee",
        type: "checkBox",
        multipleValues: this.employeesList,
        singleSelection: false,
      };
      this.formData.splice(6, 0, insertedData);
    }

    if (data[0].controlName === "name") {
      this.patchDocument = [];
      this.equipmentSrvc
        .documentListWithoutPagination(data[0].value)
        .subscribe((res) => {
          this.patchDocument = res.data ? res.data.map((val) => val.docs) : [];
        });
    }
  }

  public submitForm(data) {
    if (data[0].type === "previous") {
      this.activeTab = "basic-detail";
    } else {
      const filterDocs = this.documents.length
        ? this.documents.map(({ uploadType, ...rest }) => rest)
        : [];
      const payload = {
        documents: filterDocs,
        ...this.basicDetail,
        ...(this.repairForm.value.id && { id: this.repairForm.value.id }),
      };
      if (this.repairForm.value.id) {
        this.repairSrvc.updateRequest(payload).subscribe(
          (res) => {
            if (res) {
              jQuery("#addModal").modal("hide");
              this.cos.toastMessage(res.message, "success");
              this.submitRepairData.emit("RELOAD");
            } else {
              this.cos.toastMessage(res.message, "warning");
            }
          },
          (error) => {
            this.cos.handleError(error);
          }
        );
      } else {
        this.repairSrvc.addRequest(payload).subscribe(
          (res) => {
            if (res) {
              jQuery("#addModal").modal("hide");
              this.cos.toastMessage(res.message, "success");
              this.submitRepairData.emit("RELOAD");
            } else {
              this.cos.toastMessage(res.message, "warning");
            }
          },
          (error) => {
            this.cos.handleError(error);
          }
        );
      }
    }
  }

  // This property give uploaded documents list
  public submittedDocs(data) {
    this.documents = data.length ? data : [];
  }

  //This property give equipments
  private getEquipments() {
    this.equipmentSrvc.getEquipments().subscribe(
      (res) => {
        if (res.data.length) {
          const equipment = this.commonFunction.mapResponseToKeyValue(res.data);
          this.formData.map((val) => {
            if (val.name === "name") {
              val.multipleValues = equipment;
            }
          });
        }
      },
      (err) => {
        this.cos.handleError(err);
      }
    );
  }

  // Function to get employees and groups
  private getEmployeesAndGroup() {
    try {
      this.employeesList = [];
      this.groupList = [];
      
      forkJoin({
        groups: this.grpSrvc.getGroupList(),
        employees: this.authSrvc.getActiveEmployee(),
      }).subscribe(({ groups, employees }) => {
        this.groupList = this.commonFunction.mapResponseToKeyValue(groups.data);
        this.employeesList = this.commonFunction.mapResponseToKeyValue(employees.data);
  
        // Extract mechanic groups
        const mechanicGroups = (groups.data || [])
          .filter((group) => group.isMechanic) 
          .map((group) => group._id); 
  
        // Patch the form with mechanic groups
        this.repairForm.patchValue({  
          group: mechanicGroups.length ? mechanicGroups : [], 
        });
  
        // Update dropdown options
        this.formData.map((val) => {
          if (val.name === "group") {
            val.multipleValues = this.groupList;
          }
          if (val.name === "employee") {
            val.multipleValues = this.employeesList;
          }
        });
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }  

  private getDefaultAssignment() {
    this.configSrvc.getDefaultList().subscribe((res) => {
      const body = res.data;
      if (body.length) {
        this.repairForm.patchValue({
          assigned: this.getAssigned(body[0]),
        });
      }
    });
  }

  private getAssigned(data) {
    if (
      ((Array.isArray(data.groups) && data.groups.length === 0) ||
        data.groups === null) &&
      ((Array.isArray(data.assignedTo) && data.assignedTo.length === 0) ||
        data.assignedTo === null)
    ) {
      return [];
    }
    if (Array.isArray(data.groups) && data.groups.length) {
      const value = [{ value: "GROUP" }];
      this.selectedValue(value);

      return "GROUP";
    } else {
      const value = [{ value: "INDIVIDUAL" }];
      this.selectedValue(value);
      return "INDIVIDUAL";
    }
  }

  // Function to patch Document
  private patchRepair(id: string) {
    try {
      this.getEquipments();
      this.getEmployeesAndGroup();
      this.repairSrvc.getDetails(id).subscribe((res) => {
        const data = res.data;
        this.repairForm.patchValue({
          assigned: this.getAssigned(data),
          name: data._equipment._id,
          startDate: data.startDate ? data.startDate : null,
          endDate: data.dueDate ? data.dueDate : null,
          status: data.status ? data.status : "OPEN",
          priority: data.priority ? data.priority : "LOW",
          parts: data.partsRequired ? data.partsRequired : "",
          description: data.description ? data.description : "",
          note: data.note ? data.note : "",
          id: data._id,
          employee:
            (!data.groups ||
              (Array.isArray(data.groups) && data.groups.length === 0)) &&
            Array.isArray(data.assignedTo) &&
            data.assignedTo.length > 0
              ? data.assignedTo.map((val) => val._id)
              : [],
          group:
            Array.isArray(data.groups) && data.groups.length > 0
              ? data.groups.map((val) => val._id)
              : [],
        });
        this.patchDocument =
          Array.isArray(data.documents) && data.documents.length
            ? data.documents
            : [];
      });
      this.editRepairMaintenance = "";
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to reset the form
  public resetForm() {
    try {
      jQuery("#addModal").modal("show");
      this.activeTab = "basic-detail";
      this.getEquipments();
      this.getEmployeesAndGroup();
      this.repairForm.reset();
      this.cos.resetDocTable("RESET_DOC");
      this.modalType = "Add Repair and Maintenance";
      this.repairForm.patchValue({
        status: "OPEN",
        priority: "LOW",
        name:
          this.equipmentInfo && this.equipmentInfo.length > 0
            ? this.equipmentInfo
            : [],
      });
      this.getDefaultAssignment();
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  
}
