import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appContactFormat]'
})
export class ContactFormatDirective {
private isInputProgrammatic = false;

constructor(private elementRef: ElementRef<HTMLInputElement>) { }

@HostListener('input', ['$event'])
onInput() {
  const input = this.elementRef.nativeElement as HTMLInputElement;
  const value = input.value;

  if (!this.isInputProgrammatic) {
    let trimmed = value.replace(/\D/g, '');

    if (trimmed.length > 10) {
      trimmed = trimmed.substr(0, 10);
    }

    let formatted = '';
    if (trimmed.length > 3) {
      formatted += '(' + trimmed.substr(0, 3) + ') ';
      trimmed = trimmed.substr(3);
    }
    if (trimmed.length > 3) {
      formatted += trimmed.substr(0, 3) + '-';
      trimmed = trimmed.substr(3);
    }

    formatted += trimmed;

    this.isInputProgrammatic = true; // Set the flag to true before updating the input value
    input.value = formatted;
    input.dispatchEvent(new Event('input')); // Trigger Angular's input event to update ngModel
    this.isInputProgrammatic = false; // Reset the flag after updating the input value
  }
}

}
  


