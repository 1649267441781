import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-estimate-radio-table",
  templateUrl: "./estimate-radio-table.component.html",
  styleUrls: ["./estimate-radio-table.component.scss"],
})
export class EstimateRadioTableComponent implements OnInit {
  @Input() tableTitle = "Title";
  @Input() selectedValue = "";
  @Input() isReporting = false; 
  @Output() selectedValueChange = new EventEmitter<string>();

  choices: { id: string; tag: string }[] = [];

  ngOnInit() {
    // Initialize choices based on isReporting
    this.updateChoices();
    // Emit the default value when the component initializes
    this.selectedValueChange.emit(this.selectedValue);
  }

  updateChoices() {
    if (this.isReporting) {
      this.choices = [
        { id: "true", tag: "Yes" },
        { id: "false", tag: "No" },
      ];
      // Set default value to "true" or "false" for reporting
      this.selectedValue = "false";
    } else {
      this.choices = [
        { id: "YES", tag: "Yes" },
        { id: "NO", tag: "No" },
        { id: "UNKNOWN", tag: "Unknown" },
      ];
      // Set default value to "UNKNOWN" for non-reporting
      this.selectedValue = "UNKNOWN";
    }
  }

  onValueChange(newValue: string) {
    this.selectedValue = newValue;
    this.selectedValueChange.emit(newValue);
  }
}
