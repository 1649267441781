<form [formGroup]="modalForm">
  <div class="row px-1" *ngIf="formData.length">
    <ng-container *ngFor="let data of formData; let i = index">
      <div class="{{ setWidth() }}" *ngIf="data.type === 'input'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data?.title }}</mat-label>

          <input
            *ngIf="data.name === 'color'"
            matInput
            formControlName="{{ data?.name }}"
            type="color"
            style="height: 18px"
          />
          <input
            *ngIf="data.name !== 'color'"
            formControlName="{{ data?.name }}"
            maxlength="60"
            matInput
            type="{{ data.type }}"
            placeholder="{{ data?.placeholder }}"
            [readonly]="data.edit === false ? true : false"
          />
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'number'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data?.title }}</mat-label>
          <input
            formControlName="{{ data?.name }}"
            (keypress)="this.cos.isNumber($event)"
            type="number"
            min="0"
            [readonly]="data.edit === false ? true : false"
            (ngModelChange)="searchInputData($event, data.name)"
            maxlength="{{ data?.limit }}"
            matInput
            type="text"
            appAmountTask
            placeholder="{{ data?.placeholder }}"
          />
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'negativenumber'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data?.title }}</mat-label>
          <input
            formControlName="{{ data?.name }}"
            type="number"
            [readonly]="data.edit === false ? true : false"
            (ngModelChange)="searchInputData($event, data.name)"
            matInput
            type="text"
            placeholder="{{ data?.placeholder }}"
          />
        </mat-form-field>
      </div>
      
      <div class="{{ setWidth() }}" *ngIf="data.type === 'toggle'">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>{{ data?.title }}</mat-label>
          <span class="text-invisible" style="font-size: 16px;">
            {{ data.toggle1 ? data.toggle1 : "No" }}
          </span>
          <mat-slide-toggle
            color="warn"
            class="ml-1"
            [formControlName]="data.name"
            (change)="onToggleChange($event)"
          >
            <span
              class="text-invisible mr-1"
              style="
                position: absolute !important;
                top: 18px !important;
                font-size: 16px;
                color: #212529 !important;
                font-weight: 400;
              "
            >
              {{ data.toggle2 ? data.toggle2 : "Yes" }}
            </span>
          </mat-slide-toggle>
          <input matInput hidden />
        </mat-form-field>
      </div>
      

      <div class="{{ setWidth() }} d-flex" *ngIf="data.type === 'checkBox'">
        <div style="width: 100%">
          <mat-form-field appearance="outline" class="d-block">
            <mat-label>{{ data?.title }}</mat-label>

            <mat-select
              formControlName="{{ data.name }}"
              [multiple]="data.singleSelection ? false : true"
              (openedChange)="
                triggerEvent($event, data.name, data.multipleValues)
              "
              (selectionChange)="selectValue($event.value, data.name, i, data)"
              (click)="onMatSelect(data)"
              class="ad-text-size"
            >
              <input
                matInput
                placeholder="Search"
                class="searchEffects"
                [(ngModel)]="searchRecord"
                type="text"
                (ngModelChange)="
                  searchRecordName($event, data.name, data.multipleValues)
                "
                [ngModelOptions]="{ standalone: true }"
              />

              <mat-option
                #matOption
                *ngFor="let value of data.multipleValues"
                class="py-2"
                (click)="patchSelectedValue(value.key)"
                value="{{ value.key }}"
              >
                {{ value.value }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <span
          class="ml-1 cursor-pointer"
          *ngIf="data?.addField"
          (click)="addRecord(data)"
          ><i class="iconStyle fa fa-plus-circle" aria-hidden="true"></i>
        </span>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'date'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data.title }}</mat-label>
          <input
            matInput
            [matDatepicker]="datepicker"
            placeholder="MM/DD/YYYY"
            (dateChange)="dateChange($event, data.name)"
            maxlength="10"
            onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
            (click)="datepicker.open()"
            [max]="maxDate && maxDate"
            formControlName="{{ data.name }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #datepicker
          ></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'password'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data.title }}</mat-label>
          <span class="d-flex">
            <input
              matInput
              formControlName="{{ data.name }}"
              placeholder="{{ data.placeholder }}"
              [type]="showPassword ? 'text' : 'password'"
              maxlength="15"
              autocomplete="new-password"
            />
            <i
              class="fa" 
              [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              (click)="togglePasswordVisibility()"
              aria-hidden="true"
            ></i>
          </span>
        </mat-form-field>
      </div>
      
      <div class="{{ setWidth() }}" *ngIf="data.type === 'confirmPassword'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data.title }}</mat-label>
          <span class="d-flex">
            <input
              matInput
              formControlName="{{ data.name }}"
              placeholder="{{ data.placeholder }}"
              [type]="showConfirmPassword ? 'text' : 'password'"
              maxlength="15"
              autocomplete="new-password"
            />
            <i
              class="fa"
              [ngClass]="showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'"
              (click)="toggleConfirmPasswordVisibility()"
              aria-hidden="true"
            ></i>
          </span>
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'email'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data.title }}</mat-label>
          <input
            matInput
            maxlength="50"
            formControlName="{{ data.name }}"
            placeholder="{{ data.placeholder }}"
            autocomplete="nope"
          />
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'phone'">
        <mat-form-field appearance="outline" class="d-block">
          <span matPrefix style="margin-left: -6px">
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              (click)="resetDialCode()"
            >
              +{{ dialCode?.dial_code ? dialCode?.dial_code : 1 }}
            </button>
            <mat-menu #menu="matMenu" class="custom-menu">
              <span class="countryCode">
                <input
                  type="text"
                  #searchInput
                  matInput
                  name="searchCountry"
                  placeholder="Search Country"
                  class="country-code-list-search"
                  [(ngModel)]="searchCode"
                  (input)="searchDialCode($event)"
                  [ngModelOptions]="{ standalone: true }"
                />
              </span>
              <button
                mat-menu-item
                class="ml-1"
                *ngFor="let item of countryDetails"
                (click)="getSelectedCountry(item, data.name)"
              >
                +{{ item.dial_code }} {{ item.name }}
              </button>
            </mat-menu>
          </span>
          <input
            matInput
            formControlName="{{ data.name }}"
            maxlength="15"
            placeholder="{{ data.placeholder }}"
            type="text"
            appContactFormat
          />
        </mat-form-field>
      </div>


      

      <div class="{{ setAddressWidth() }}" *ngIf="data.type === 'address'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data.title }}</mat-label>
          <input
            [value]="modalForm.value.address?.formattedAddress"
            matInput
            autocomplete="off"
            (change)="changeAddress($event)"
            (onSelect)="getAddress($event)"
            appGooglePlaces
            placeholder="{{ data.placeholder }}"
            type="text"
          />
        </mat-form-field>
      </div>

      <div class="{{ setWidth() }}" *ngIf="data.type === 'time'">
        <mat-form-field
          (click)="startTimePicker.open()"
          appearance="outline"
          class="d-block"
        >
          <mat-label>{{ data.title }}</mat-label>
          <input
            matInput
            [ngxTimepicker]="startTimePicker"
            formControlName="{{ data.name }}"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker #startTimePicker></ngx-material-timepicker>
      </div>
    </ng-container>

      <ng-content></ng-content>

    <ng-container *ngFor="let data of formData; let i = index">
      <div class="col-12" *ngIf="data.type === 'summary'">
        <mat-form-field appearance="outline" class="d-block">
          <mat-label>{{ data?.title }}</mat-label>

          <textarea
            formControlName="{{ data?.name }}"
            maxlength="800"
            matInput
            type="text"
            class="ad-text-size"
            placeholder="{{ data?.placeholder }}"
          ></textarea>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="isDocument">
      <app-upload-documents
        (submittedDocs)="submittedDocs($event)"
        [patchData]="patchDocument"
      ></app-upload-documents>
    </div>

    <div *ngIf="isAddressMap">
      <app-google-map (changeAddress)="setAddress($event)"></app-google-map>
    </div>
  </div>
</form>

<!--  Button to save the form  -->
<div class="float-rightd-flex">
  <button
    type="submit"
    *ngFor="let data of state"
    class="btn btn-primary float-right my-2 ml-2 mr-1"
    [class.actionButton]="buttonMargin"
    (click)="submitForm(data)"
  >
    {{ data.buttonName }}
  </button>
</div>
