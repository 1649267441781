import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {CommonService} from 'src/app/_services/common.service';
import {ProjectService} from 'src/app/_services/project.service';
import 'bootstrap';
import {response} from 'src/app/enum/message';
import {MAIL_TO} from "../../../../constants/AppConstants";
import {FunctionsService} from "../../../../_common/functions.service";

@Component({
  selector: 'app-mail-modal-project',
  templateUrl: './mail-modal-project.component.html',
  styleUrls: ['./mail-modal-project.component.scss']
})
export class MailModalProjectComponent implements OnInit {

  /** Property to hold modal ID */
  @Input() modalId = 'mailModal';

  /** Property to hold email dropdown input label */
  @Input() emailDropdownLabel = 'Email';

  /** Property to hold email dropdown input placeholder */
  @Input() emailDropdownPlaceholder = 'Select Email';

  /** Property to hold email attachments options */
  @Input() emailAttachments: any[] = [];

  /** Property to hold vendor orders, Note: This is used for vendor common orders tab only */
  @Input() vendorOrders: any[] = [];

  /** Property to hold vendor orders, Note: This is used for vendor component tab only */
  @Input() vendorData: any[] = [];

  /** Property to hold options for input custom email or from dropdown */
  @Input() mailTo = [
    {
      value: 'Dropdown Option',
      key: MAIL_TO.DROPDOWN_OPTIONS
    },
    {value: 'Custom Mail', key: MAIL_TO.CUSTOM}
  ];

  /** Property to hold email options */
  @Input() emailOptions: any[] = []

  /** Property to emit mailTo option select event */
  @Output() mailToSelectEvent: EventEmitter<boolean> = new EventEmitter();

  /** Property to emit submit button click event */
  @Output() submitButtonEvent: EventEmitter<any> = new EventEmitter();

  /** Property to hold modalProperty */
  @Input() modalProperty = {
    header: 'Send Mail',
    id: 'mailModal'
  };

  /** Property to hold mail to selected option */
  mailToSelected = "";

  /** Property to hold send email form */
  @Input() mailForm = this.fb.group({
    to: [''],
    cc: [''],
    subject: [''],
    body: ['']
  });

  /** Property to hold if cc email required */
  @Input() isVendorOrderEmail = false

  /** Property to hold if cc email required */
  @Input() isVendorOrderEmailNotRequired = false

  /** Property to hold close modal handling */
  @Input() closeEmailModal = false

  /** Property to hold if mail to is */
  @Input() isMailToVisible = true

  /** Property to hold if email options dropdown visible */
  @Input() isEmailOptionsDropdownVisible = true

  /** Property to hold if cc email is visible */
  @Input() isCcMailVisible = true

  /** Property to hold if cc email is required */
  @Input() isCcMandatory = true

  /** Property to hold if custom email field is visible */
  @Input() isCustomMailVisible = false

  /** Property to hold if subject is required */
  @Input() isSubjectRequired = true

  /** Property to hold if body is required */
  @Input() isBodyRequired = true

  constructor(
    private fb: UntypedFormBuilder,
    private cos: CommonService,
    private projectSrvc: ProjectService,
    public commonFunction: FunctionsService,
  ) {
    this.mailForm.reset();
  }

  ngOnInit() {
    this.projectSrvc.changeEmitted$.subscribe(data => {
      if (data) {
        this.resetModal(); // Updated to call resetModal method
      }
    });
  }

  resetModal() {
    this.mailForm.reset();
    if (!this.vendorOrders || this.vendorOrders.length === 0) {
      this.initializeVendorOrders(); 
    }
  }
  
  initializeVendorOrders() {
    this.vendorOrders = []; 
  }

  /**
   * Validate Form Inputs
   */
  public validateForm(): boolean {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // Validate To Email ID
    if (!this.cos.isValidInput(this.mailForm.value.to)) {
      this.cos.toastMessage(response.en.ENTER_RECEMAIL_ID, 'warning');
      return false
    } else if (!validateEmail(this.mailForm.value.to) && this.mailForm.value.to) {
      this.cos.toastMessage(response.en.VALID_EMAIL, 'warning');
      return false
    }

    // Validate CC Email ID
    if (this.isCcMandatory || this.cos.isValidInput(this.mailForm.value.cc)) {
      if (!this.cos.isValidInput(this.mailForm.value.cc)) {
        this.cos.toastMessage(response.en.ENTER_CC_EMAIL_ID, 'warning');
        return false
      } else if (!validateEmail(this.mailForm.value.cc) && this.mailForm.value.cc) {
        this.cos.toastMessage(response.en.VALID_EMAIL, 'warning');
        return false
      }
    }

    // Validate Subject Field
    if (this.isSubjectRequired) {
      if (!this.cos.isValidInput(this.mailForm.value.subject)) {
        this.cos.toastMessage(response.en.ENTER_EMAILSUB, 'warning');
        return false
      } else if (this.mailForm.value.subject.trim().length == 0) {
        this.cos.toastMessage(response.en.VALID_SUBJECT, 'warning');
        return false
      }
    }

    // Validate Body Field
    if (this.isBodyRequired) {
      if (!this.cos.isValidInput(this.mailForm.value.body)) {
        this.cos.toastMessage(response.en.ENTER_EMAILBODY, 'warning');
        return false
      } else if (this.mailForm.value.body.trim().length == 0) {
        this.cos.toastMessage(response.en.VALID_BODY, 'warning');
        return false
      }
    }

    // Validate Vendor Orders
    if (this.vendorOrders) {
      for(const val of this.vendorOrders) {
        if (!val.quantity) {
          this.cos.toastMessage(response.en.ADD_ORDER_QUANTITY_ERROR, 'warning');
          return false;
        }
      }
    }
    return true
  }

  /**
   * Handle Send Button Click Event
   */
  onSendClick() {
    if (this.validateForm()) {
      const requestPayload = {
        to: this.mailForm.value.to,
        ...(this.isSubjectRequired && {subject: this.mailForm.value.subject}), // Email Subject
        ...(this.isBodyRequired && {body: this.mailForm.value.body}), // Email Body
        ...((this.isCcMailVisible && this.mailForm.value.cc) && {cc: this.mailForm.value.cc}), // CC Email
        ...(this.emailAttachments.length && {urls: this.emailAttachments.map(val => val.url ? val.url : val)}), // Email Attachments for Daily Reports
        // Vendor Orders List Payload
        ...(this.vendorOrders.length && {
          items: this.vendorOrders.map(val => ({
            itemId: val.id,
            quantity: val.quantity
          }))
        }),
        // Vendor Data List Payload
        ...(this.vendorData.length && {
          ids: this.vendorData.map(val => ({
            ids: val.id,
          }))
        })
      }

      // Emit Submit Button Click Event
      this.submitButtonEvent.emit(requestPayload)
    }
  }

  /**
   * Handle Mail To Options
   *
   * @param event Selected Option Key
   */
  onMailToOptionClick(event: any) {
    this.mailToSelected = event.value;
    if (event.value === MAIL_TO.DROPDOWN_OPTIONS) {
      this.mailToSelectEvent.emit(true)
    } else {
      this.mailToSelectEvent.emit(false)
    }
    this.mailForm.controls['to'].setValue(null);
  }

  /**
   * Handle Email Option Click
   *
   * @param event Selected Email
   */
  onEmailSelectClick(event) {
    this.mailForm.controls['to'].setValue(event.value)
  }

  /**
   * Handle Quantity input field handling
   *
   * @param event is the order quantity
   * @param index is the order item index
   */
  handleQuantityChange(event: any, index: number) {
    const quantity = event.target.value;
    if (this.vendorOrders[index].quantity !== quantity) {
      this.vendorOrders[index].quantity = quantity; 
    }
  }

  protected readonly MAIL_TO = MAIL_TO;
}
