<div class="my-4 d-flex flex-wrap">
  <div
    class="d-flex btn-success-bg col-lg-9 col-md-8 col-sm-12 pl-0 flex-wrap"
    appAccessControl
    permission="{{ permission }}"
  >
    <!-- Below button are used with text and icon in some cases -->
    <ng-container *ngFor="let data of btnName">
      <button
        (click)="openModal(data)"
        type="button"
        *ngIf="data.theme === 'combinedButton'"
        appAccessControl
        permission="{{ data.permission }}"
        class="mr-2 my-1 btn btn-custon-four btn-success"
        style="height: fit-content"
      >
        <i *ngIf="data.icon" [ngClass]="data.icon"></i>
        {{ data.value }}
      </button>
    </ng-container>
    <ng-content> </ng-content>
  </div>

  <!-- Below button are used without text, only icon -->
  <div class="col-lg-3 col-md-4 col-sm-12 pr-0">
    <ng-container *ngFor="let data of btnName">
      <div
        class="btn-success-bg float-right"
        *ngIf="data.theme === 'iconButton'"
      >
        <button
          appAccessControl
          permission="{{ data.permission }}"
          type="button"
          (click)="openModal(data)"
          class="ml-2 my-1 btn btn-custon-four btn-success"
          style="height: fit-content"
        >
          <i *ngIf="data.icon" [ngClass]="data.icon"></i>
          <span class="count-badge" *ngIf="data.key === 'filter'">{{
            filterCount
          }}</span>
        </button>
        <input
          *ngIf="data.key === 'search' && isSearch"
          class="ml-1"
          [(ngModel)]="search"
          spellcheck="false"
          (ngModelChange)="searchDelay.next($event)"
          placeholder="{{ searchPlaceholder ? searchPlaceholder : 'Search' }}"
          (keydown)="onKeyDown($event)"
        />
      </div>
    </ng-container>
  </div>

  <!-- Below button are used without text to delete, only icon -->
  <div class="col-lg-3 col-md-4 col-sm-12 pr-0">
    <ng-container *ngFor="let data of deleteBtn">
      <div
        class="btn-success-bg float-right"
        *ngIf="data.theme === 'iconButton'"
      >
        <button
          appAccessControl
          permission="{{ data.permission }}"
          type="button"
          (click)="openModal(data)"
          class="ml-2 my-1 btn btn-custon-four btn-success"
          style="height: fit-content"
        >
          <i *ngIf="data.icon" [ngClass]="data.icon"></i>
          <span class="count-badge" *ngIf="data.key === 'filter'">{{
            filterCount
          }}</span>
        </button>
        <input
          *ngIf="data.key === 'search' && isSearch"
          class="ml-1"
          [(ngModel)]="search"
          spellcheck="false"
          (ngModelChange)="searchDelay.next($event)"
          placeholder="{{ searchPlaceholder ? searchPlaceholder : 'Search' }}"
          (keydown)="onKeyDown($event)"
        />
      </div>
    </ng-container>
  </div>
</div>
