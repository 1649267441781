import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-upload-image-file',
  templateUrl: './upload-image-file.component.html',
  styleUrls: ['./upload-image-file.component.scss']
})
export class UploadImageFileComponent { 

  @Input() isUploaded = false;
  @Input() label = 'Upload';
  @Input() imageSource = '';
  @Input() showCloseIcon = true;
  @Input() isRequired = false;
  @Output() uploadImage = new EventEmitter();
  @Output() removeImage = new EventEmitter();
  @Input() changePadding = false;
  randomId: number = Math.random();
  @Input() uploadedImages = [];
  constructor(
    private authService: AuthService,
    private cos: CommonService,
  ) { }

  clearInput(): void {
    this.isUploaded = false;
    this.imageSource = '';
    this.removeImage.emit(true);
  }

  onFileSelected(event: any): void {
    const fileExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const file: File = event.target.files[0];
    
    if (file) {
      const fileNameLower = file.name.toLowerCase();
      const isValidFile = fileExtensions.some(extension => fileNameLower.endsWith(`.${extension}`));
  
      if (isValidFile) {
        this.upload(event, event.target);
      } else {
        this.cos.toastMessage('Please select .pdf, .png, .jpg or .jpeg format files only.', 'warning');
        event.target.value = null;
      }
    }
  }

  upload(event, element): void {
    const file = event.target.files ? event.target.files[0] : event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append('file', file);
  
    this.authService.uploadFiles(formData)
      .subscribe(result => {
        
        if (result) {
          if (!result.status) {
            throw new Error(result.message);
          }
          const data = {
            url: result.data.originalImage, fileName: file.name
          };
          this.uploadedImages = [...this.uploadedImages, data]; 
          this.uploadImage.emit(data);
          element.value = '';
        } else {
          this.cos.toastMessage(result.message, 'warning');
        }
      }, (err) => { this.cos.handleError(err); });
  }
  

  remove(data, element): void {
    this.removeImage.emit(data);
    this.uploadedImages = this.uploadedImages.filter(image => {
      return image.url
        ? image.url !== data.url
        : image.path !== data.path;
    });
    element.value = "";

  }

  viewLink(data) {
    const link = data.url ?? data.fileName;
    window.open(link);
  }

}

