import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectCommunicateService {

  public dropDownData$ = new BehaviorSubject({});
  public basicInfo$ = new BehaviorSubject({});
  public documentInfo$ = new BehaviorSubject([]);
  public employeeEquipInfo$ = new BehaviorSubject([]);
  public equipInfo$ = new BehaviorSubject([]);
  public equipBasicInfo$ = new BehaviorSubject({});
  public equipAddInfo$ = new BehaviorSubject({});
  public equipTireInfo$ = new BehaviorSubject({});
  public equipSpecInfo$ = new BehaviorSubject({});
  public equipDocInfo$ = new BehaviorSubject({});

  // Vendor Data
  public commonOrder$ = new BehaviorSubject({});



  // Add Project details
  updateBasicInfo(data) {
    this.basicInfo$.next(data);
  }

  updateDocumentInfo(data) {
    this.documentInfo$.next(data);
  }

  updateEmpEquip(data) {
    this.employeeEquipInfo$.next(data);
  }

  updateDropDown(data) {
    this.dropDownData$.next(data);
  }

  // Add Equipments data
  updateEquipBasicData(data) {
    this.equipBasicInfo$.next(data);
  }

  updateEquipAdditionalData(data) {
    this.equipAddInfo$.next(data);
  }

  updateSpecsData(data) {
    this.equipSpecInfo$.next(data);
  }
  updateEquipTiresData(data) {
    this.equipTireInfo$.next(data);
  }

  updateEquipDocsData(data) {
    this.equipDocInfo$.next(data);
  }

  // Vendor Details
  vendorOrderData(data) {
    this.commonOrder$.next(data);
  }

}
