<div class="modal common-modal" id="{{modalId}}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title">{{modalType}}</h5>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body padding-none">
        <ul class="nav nav-tabs modl-nabs pl-2">
          <li><a class="nav-link" [ngClass]="{ 'active': activeTab === 'basic-detail' }"
              (click)="changeTab('basic-detail')" style="pointer-events: none">Basic Details</a></li>

          <li><a class="nav-link" [ngClass]="{ 'active': activeTab === 'additional-info' }"
              (click)="changeTab('additional-info')" style="pointer-events: none">Representative Details</a></li>

        </ul>
        <div class="tab-content modal-form-part">
          <div class="tab-pane project-form-common" [ngClass]="{ 'active': activeTab === 'basic-detail' }"
            id="basic-detail">

            <app-section-modal [state]="state" [modalForm]="modalForm" [formData]="formData"
              (submittedValue)="submitForm($event)">
              <p class="ml-3 mb-3">Branch Address</p>

              <app-section-modal [state]="branchState" [modalForm]="modalForm" [formData]="branchData"
                (submittedValue)="addBranch($event)" [dialCode]="dialCode"></app-section-modal>
            </app-section-modal>

            <table class="table table-bordered" *ngIf="branchAddress.length"
              style="font-size:12px; table-layout: fixed;">
              <thead class="searchEffects" style="color:black !important">
                <tr>
                  <th class="col-2"> Branch Name</th>
                  <th class="col-2"> Branch Main #</th>
                  <th class="col-1">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let data of branchAddress;let i=index">
                <tr style="border-bottom-color: lightgray;">
                  <td class="table-item">{{data.branchAddress && data.branchAddress.formattedAddress ?
                    data.branchAddress.formattedAddress : 'N/A'}}
                  </td>
                  <td> {{ data.phone.number ? '+' + data.phone.dialCode + ' ' + data.phone.number : 'N/A' }}
                  </td>
                  <td class="d-flex justify-content-center" style="border:none">
                    <div (click)="deleteAddress(i, 'branch')" class="btn-danger actnbtn"><i class="fa fa-trash-o"
                        aria-hidden="true"></i> </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

          <div class="tab-pane" [ngClass]="{ 'active': activeTab === 'additional-info' }" id="additional-info">
            <div class="d-flex flex-column">
              <form [formGroup]="representativeForm">
              <app-section-modal [state]="representative" [modalForm]="representativeForm"
                [formData]="representativeData" (submittedValue)="submitForm($event)" [dialCode]="dialCode"
                [fieldWidth]=true>
              
                  <div class="col-4">
                    <mat-form-field appearance="outline" class="d-block">
                      <span matPrefix style="margin-left:-6px">
                        <button mat-button [matMenuTriggerFor]="menu" (click)="resetDialCode()">
                          +{{representativeCell?.dial_code ? representativeCell?.dial_code : 1}}</button>
                        <mat-menu #menu="matMenu" class="custom-menu">
                          <span class="countryCode">
                            <input type="text" #searchInput matInput name="searchCountry" placeholder="Search Country"
                              class="country-code-list-search" [(ngModel)]="searchCode" (input)="searchDialCode($event)"
                              [ngModelOptions]="{standalone: true}">
                          </span>
                          <button mat-menu-item class="ml-1 text-size" *ngFor="let item of countryDetails"
                            (click)="getSelectedCountry(item)">
                            <span class="text-size"> +{{item.dial_code}} {{item.name}}</span></button>
                        </mat-menu>
                      </span>
                      <input matInput class="ad-text-size" formControlName="representativeCell" maxlength="15"
                        placeholder="Enter Cell" (change)="repNumber($event)" type="text" appContactFormat>
                    </mat-form-field>
                  </div>
                </app-section-modal>
              </form>

              <div class="scrollType mt-4" *ngIf="showRepTable">
                <table class="table table-bordered" *ngIf="addedRep.length"
                  style="font-size:12px; table-layout: fixed;">
                  <thead class="searchEffects" style="color:black !important">
                    <tr>
                      <th class="col-2">Rep Name</th>
                      <th class="col-2"> Rep Direct Cell #</th>
                      <th class="col-2"> Rep Cell </th>
                      <th class="col-2"> Email</th>
                      <th class="col-1"> Title</th>
                      <th class="col-2"> Address</th>
                      <th class="col-2"> Note</th>
                      <th class="col-1">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let data of addedRep;let i=index">
                    <tr style="border-bottom-color: lightgray;">
                      <td class="table-item">{{data.representativeName?data.representativeName:'N/A'}}
                      </td>
                      <td>{{data.representativeNumber?data.representativeNumber:'N/A'}}
                      </td>
                      <td>{{data.representativeCell?data.representativeCell:'N/A'}}
                      </td>
                      <td class="table-item">{{data.representativeEmail?data.representativeEmail:'N/A'}}
                      </td>
                      <td class="table-item">{{data.representativeTitle?data.representativeTitle:'N/A'}}
                      </td>
                      <td class="table-item">{{data.representativeAddress?data.representativeAddress:'N/A'}}
                      </td>
                      <td class="table-item">{{data.representativeNote?data.representativeNote:'N/A'}}
                      </td>
                      <td class="d-flex justify-content-center" style="border:none">
                        <div class="actnbtn" *ngIf="editClient" (click)="editRep(data,i)">
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </div>
                        <div (click)="deleteAddress(i, 'rep')" class="btn-danger actnbtn"><i class="fa fa-trash-o"
                            aria-hidden="true"></i> </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="addedRep.length===0">
                  <th class="text-center">No Representative Added Yet</th>
                </table>
              </div>

              <div class="text-end my-2">
                <button class="btn btn-primary" (click)="submitClientForm()">Save</button>
              </div>

            </div>
          </div>

        </div>
      </div>



    </div>
  </div>
</div>