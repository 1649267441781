import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: "app-estimates-consumables-table",
  templateUrl: "./estimates-consumables-table.component.html",
  styleUrls: ["./estimates-consumables-table.component.scss"],
})
export class EstimatesConsumablesTableComponent implements OnChanges {
  @Input() orderList: any[] = [];
  @Input() columns: any[] = [];
  @Input() row: any; 
  @Input() options: string[] = ["day", "week"];
  @Input() isConsumables = false;
  @Input() isRentals = false;
  @Input() markupPercentage: number;
  @Input() markupDisplayPercentage = false;
  @Input() isOnlyConsumables = false;

  @Output() save = new EventEmitter<string>();
  @Output() unitChange = new EventEmitter<{
    itemName: string;
    selectedUnit: string;
  }>();
  @Output() fetchUpdatedCost = new EventEmitter<void>();
  @Output() markupPercentageChange = new EventEmitter<number>();
  @Output() checkboxChange = new EventEmitter<boolean>();
  @Output() toggleChange = new EventEmitter<{
    index: number;
    value: boolean;
  }>();

  totalCost = 0;
  totalSellingCost = 0;
  totalCostSum = 0;
  totalSellingCostSum = 0;

  constructor(
    private spinner: NgxSpinnerService,
    public cos: CommonService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotal();
  }

  columnVisible(columnName: string): boolean {
    return this.columns.some((column) => column.name === columnName);
  }

  calculateRowTotal(row: any) {
    const quantity = parseFloat(row.quantity) || 0;
    const cost = parseFloat(row.cost) || 0;
    const sellingCost = parseFloat(row.sellingCost) || 0;

    // Calculate the total costs
    row.totalCost = quantity * cost;
    row.totalSellingCost = quantity * sellingCost;

    // Format the total costs for display
    row.totalCostFormatted = this.cos.formatCurrency(row.totalCost);
    row.totalSellingCostFormatted = this.cos.formatCurrency(row.totalSellingCost);

    this.totalCostSum = row.totalCost;
    this.totalSellingCostSum = row.totalSellingCost;
  }

  calculateTotal() {
    let totalCostSum = 0;
    let totalSellingCostSum = 0;

    this.orderList.forEach((row: any) => {
      const rowTotalCost = parseFloat(row.totalCost) || 0;
      const rowTotalSellingCost = parseFloat(row.totalSellingCost) || 0;

      totalCostSum += rowTotalCost;
      totalSellingCostSum += rowTotalSellingCost;
    });

    // Store the formatted total sums
    this.totalCostSum = this.cos.formatCurrency(totalCostSum);
    this.totalSellingCostSum = this.cos.formatCurrency(totalSellingCostSum);
  }

  updateRecord(action: string) {
    this.save.emit(action);
  }

  onFetchUpdatedCost() {
    this.fetchUpdatedCost.emit();
  }

  onToggleChange(event: any, index: number) {
    this.orderList[index].isFuelRequired = event.checked;
    this.toggleChange.emit({ index, value: event.checked });
  }

  // Function triggered when the unit is changed
  onUnitChange(row: any) {
    const selectedUnit = row.selectedUnit;

    if (selectedUnit) {
      // Emit the unit change
      this.unitChange.emit({ itemName: row.item, selectedUnit });

      // Find the unitInfo for the selected unit
      const unitInfo = this.findUnitInfo(row.item, selectedUnit);

      if (unitInfo) {
        row.costPrice = unitInfo.costPrice.value;
        row.sellingPrice = unitInfo.sellingPrice.value;
        row.totalCost = unitInfo.costPrice.value * (row.quantity || 0); // Update as needed
        row.totalSellingCost =
          unitInfo.sellingPrice.value * (row.quantity || 0); // Update as needed
      } else {
        row.costPrice = null;
        row.sellingPrice = null;
        row.totalCost = null;
        row.totalSellingCost = null;
      }
    }

    // Format costs after unit change
    this.calculateRowTotal(row);
  }

  findUnitInfo(itemName: string, selectedUnit: string) {
    // Find the item from orderList
    const item = this.orderList.find((o) => o.item === itemName);

    if (item) {
      // Find the unitInfo that matches the selected unit
      return item.options.find((u) => u.unit === selectedUnit);
    }

    return null;
  }

  onCostChange(value: string) {
    // Remove any non-numeric characters for proper binding
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    this.row.cost = isNaN(numericValue) ? 0 : numericValue;
  }

  onSellingCostChange(value: string) {
    // Extract numeric value from the formatted string
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    this.row.sellingCost = isNaN(numericValue) ? 0 : numericValue;
  }

  /**
   * Emit change in markup percentage to the parent component
   */
  onMarkupPercentageInputChange(value: string, row: any) {
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
  
    if (!isNaN(numericValue)) {
      row.markupPercentage = numericValue;
    } else {
      row.markupPercentage = 0; // Reset to 0 if input is invalid
    }
  
    // Recalculate the row's totals after markup change
    this.calculateRowTotal(row);
    this.calculateTotal();  // Update the overall total if needed
  }

  onCheckboxChange(isSelected: boolean): void {
    this.checkboxChange.emit(isSelected);
  }

}
