<div class="modal common-modal" #addModal id="{{ modalId }}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      <div class="modal-body padding-none">
        <ul class="nav nav-tabs modl-nabs">
          <li>
            <a
              class="nav-link"
              [ngClass]="{ active: activeTab === 'basic-detail' }"
              (click)="activeTab = 'basic-detail'"
              style="pointer-events: none"
              >Basic Details</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              [ngClass]="{ active: activeTab === 'documents' }"
              (click)="activeTab = 'documents'"
              style="pointer-events: none"
              >Upload Documents</a
            >
          </li>
        </ul>
        <div class="tab-content modal-form-part">
          <div
            class="tab-pane project-form-common"
            [ngClass]="{ active: activeTab === 'basic-detail' }"
            id="basic-detail"
          >
            <app-section-modal
              [state]="state"
              [modalForm]="modalForm"
              [fieldWidth]=true
              [formData]="formData"
              (submittedValue)="submitBasicData($event)"
            >

            <app-google-map
              showCoordinate="true"
              [fieldWidth]="true"
              (changeAddress)="setAddress($event)"
            ></app-google-map>

            <div class="my-2">
            <app-equipment
              [projectEquipments]="projectEquipments"
              [fieldWidth]="true"
              (addedEquipment)="addedEquipment($event)"
            ></app-equipment>
          </div>
          </app-section-modal>
          </div>

          <div
            class="tab-pane"
            [ngClass]="{ active: activeTab === 'documents' }"
            id="documents"
          >
            <app-upload-documents
              [documentBtn]="documentBtn"
              [fieldWidth]="true"
              (docsData)="submitForm($event)"
              [patchData]="patchData"
              (submittedDocs)="submittedDocs($event)"
            ></app-upload-documents>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
