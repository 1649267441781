import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-ad-common-modal',
  templateUrl: './ad-common-modal.component.html',
  styleUrls: ['./ad-common-modal.component.scss']
})
export class AdCommonModalComponent {
  @Input() modalProperty: {
    id: string,
    header: string,
  }
  @Input() modalId: string
}
