<div
  class="modal right-lightbox modal right fade common-modal"
  id="notificationModal"
>
  <div class="modal-dialog push-notification-modal">
    <div class="modal-content">
      <div class="modal-header modalStyle">
        <h4 class="modal-title" id="myModalLabel2">Notifications</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <!-- Modal Sub Heading -->
      <div class="modal-body p-0">
        <ul class="nav nav-tabs modl-nabs p-0 d-flex justify-content-between position-fixed">
          <li>
            <a class="nav-link px-5" [ngClass]="{ active: activeTab === 'all' }"
              >All</a
            >
          </li>
          <li class="pl-5">
            <a class="nav-link pl-5" (click)="markRead()">Mark All as Read</a>
          </li>
        </ul>

        <div class="paginationList mt-4"  (scroll)="onScroll($event)" >
          <ng-container *ngIf="notificationDetails?.length > 0">
            <div
              *ngFor="let data of notificationDetails"
              class="d-flex flex-row p-2 notification-panel"
              [class.bg-theme]="!data.read"
            >
              <span class="notification-title-icon mt-1">{{
                capitalizeFirstCharacter(data?.createdBy?.userName)
              }}</span>
              <div
                class="px-3 cursor-pointer"
                (click)="redirectNotification(data)"
              >
                <div class="time mt-1">
                  {{ this.cos.getFormattedDate(data.createdAt) }}
                </div>
                <span class="ad-text-size">
                  {{
                    getDetails(
                      data.title,
                      data._refId,
                      data?.createdBy?.userName,
                      data.category
                    )
                  }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>        
      </div>
    </div>
  </div>
</div>