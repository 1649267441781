import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Directive({
  selector: '[appHideSpinner]'
})
export class HideSpinnerDirective implements OnInit {
  @Input() excludedComponents: string[] = [];

  constructor(private el: ElementRef, private router: Router) { }

  ngOnInit() {
    // Listen for route changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.routerState.snapshot.root;
        const currentComponent = currentRoute.firstChild?.routeConfig?.component;

        // Check if the current component is in the excluded components list
        if (currentComponent && this.excludedComponents.includes(currentComponent['name'])) {
          this.el.nativeElement.style.display = 'block'; // Show the spinner
        } else {
          this.el.nativeElement.style.display = 'none'; // Hide the spinner
        }
      }
    });
  }
}
