import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  @Input() isUploaded = false;
  @Input() label = 'Upload';
  @Input() imageSource = '';
  @Input() showCloseIcon = true;
  @Input() isRequired = false;
  @Output() uploadImage = new EventEmitter();
  @Output() removeImage = new EventEmitter();
  @Input() changePadding = false;
  @Input() multiple = false;
  @Input() uploadedImages = [];
  randomId: number = Math.random();

  constructor(
    private authService: AuthService,
    private cos: CommonService,
  ) { }

  clearInput(): void {
    this.isUploaded = false;
    this.imageSource = '';
    this.removeImage.emit(true);
  }

  async upload(event: Event, element: HTMLInputElement): Promise<void> {
    const formData = new FormData();
    const fileList = (event.target as HTMLInputElement).files;

    if (fileList) {
        // Convert FileList to an array
        const filesArray = Array.from(fileList);
        const uploadResponses = [];

        for (const file of filesArray) {
            formData.append('file', file);

            try {
                // Await the upload for each file
                const result = await this.authService.uploadFiles(formData).toPromise();

                if (result && result.status) {
                    const data = {
                        url: result.data.originalImage, // Adjust according to your API response
                        fileName: file.name
                    };
                    uploadResponses.push(data);

                    this.uploadedImages = [...this.uploadedImages, data];
                    this.uploadImage.emit(data);
                } else {
                    this.cos.toastMessage(result?.message || 'Please upload Document', 'warning');
                }
            } catch (err) {
                this.cos.handleError(err);
                this.cos.toastMessage('Upload failed. Please try again.', 'error');
            } finally {
                // Clear formData for the next file
                formData.delete('file');
            }
        }

        // Clear the input field after upload
        element.value = '';
    } else {
        this.cos.toastMessage('No files selected.', 'warning');
    }
}

  

  remove(data, element): void {
    this.removeImage.emit(data);
    this.uploadedImages = this.uploadedImages.filter(image => {
      return image.url ? image.url !== data.url : image.path !== data.path;
    });
    element.value = '';
  }

  viewLink(data): void {
    const link = data.url ?? data.fileName;
    window.open(link);
  }
  
}
