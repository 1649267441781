<div class="root-container">
  <div class="content-container mt-4" *ngIf="dataList.length">
    <p>{{ tableTitle }}</p>
    <div class="list-container">
      <div *ngFor="let item of dataList; let i = index" class="list-item first-item"
        [ngClass]="{ 'no-content': !item?.value && !item?.name && !item?.topic }">
        
        <!-- Left Section: Checkbox & Value -->
        <div class="left-section">
          <input type="checkbox" 
                *ngIf="isCheckBox" 
                (change)="onItemCheckChange($event, i)" 
                [checked]="checkedItems[i]" 
                class="custom-checkbox"/>

          <span *ngIf="!isDivisionOfWaterResources && !item?.topic && item?.value">{{ item.value }}</span>
          <span *ngIf="isDivisionOfWaterResources && item?.name">{{ item.name }}</span> 
          <span class="topic" *ngIf="item?.topic">{{ item.topic }}</span>
          <span *ngIf="!item?.value && !item?.name && !item?.topic">&nbsp;</span>
        </div>

        <!-- Right Section: Radio Buttons -->
        <div class="right-section">
          <mat-radio-group [formControl]="form.get('responseField_' + i)" class="d-flex justify-content-end align-items-center">
            <mat-radio-button *ngFor="let choice of choices || []" 
                  [value]="choice.id"
                  [checked]="form.get('responseField_' + i).value === choice.id"
                  (change)="onItemChange(choice.id, i)"
                  class="radio-button ad-text-size">
                  
                  <!-- Ensure label is inline and aligned -->
                  <span class="radio-label">{{ choice.tag }}</span>
        
            </mat-radio-button>
          </mat-radio-group>
        </div>                
      </div>
    </div>
  </div>
</div>
