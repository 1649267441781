import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileResponse, Message } from '../interfaces/common-interface';
import {
  EquipmentList, EquipmentCategory, EquipmentResponse,
  Activity, PartsDetails, PartsData, EquipConfigList, EquipResponse, EquipDocumentList, EquipDocsResponse, EquipPartsList, SubcategoryList, EquipmentLocation, EquipCategoryData
} from '../interfaces/equipment';
import { EquipmentInspectionList, FieldInspectionResponse, Response } from '../interfaces/field-inspection';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  EQUIPMENT = 'aggregation/equipment';
  ACTIVITY_LOG = 'aggregation/activity-logs';
  PARTS_DATA = 'aggregation/parts';
  EQUIPMENT_CRUD = 'aggregation/equip-category';
  DOC_CRUD = 'aggregation/equip-docs';
  PARTS_CRUD = 'aggregation/parts';
  INSPECTION_FORM = 'aggregation/equipment-inspection-form';
  RESPONSE_CRUD = 'aggregation/equipment-inspection-response';

  constructor(private http: HttpClient) { }

  getEquipment(search) {
    return this.http.get<EquipmentList>(`${environment.BASE_URL}${this.EQUIPMENT}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&sorting=${search.sorting}&subCategory=${search.subCategory}&category=${search.category}&disposedDate=${search.disposedDate}`);
  }

  // This function is used to get all record of equipment without pagination
  getEquipments() {
    return this.http.get<EquipmentList>(`${environment.BASE_URL}${this.EQUIPMENT}?pagination=false`);
  }

  getEquipmentsList(data) {
    return this.http.get<EquipmentCategory>(`${environment.BASE_URL}${this.EQUIPMENT}/list?pagination=false&category=${data.category}&subCategory=${data.subCategory}&sort=true`);
  }

  deleteEquipment(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: id
    };

    return this.http.delete<Message>(environment.BASE_URL + this.EQUIPMENT, options);
  }

  addEquipment(data) {
    return this.http.post<EquipmentResponse>(environment.BASE_URL + this.EQUIPMENT, data);
  }

  getEquipDetails(id) {
    return this.http.get<EquipmentResponse>(`${environment.BASE_URL}${this.EQUIPMENT}/details?id=${id}`);
  }

  updateEquipment(data) {
    return this.http.put<EquipmentResponse>(environment.BASE_URL + this.EQUIPMENT, data);
  }

  getEquipmentLog(id) {
    return this.http.get<Activity>(`${environment.BASE_URL}${this.ACTIVITY_LOG}?type=EQUIPMENT&id=${id}`);
  }

  getEquipParts() {
    return this.http.get<PartsDetails>(`${environment.BASE_URL}${this.PARTS_DATA}/list?pagination=false`);
  }

  getPartsDetail(id: string) {
    return this.http.get<PartsData>(`${environment.BASE_URL}${this.PARTS_DATA}?id=${id}`);
  }

  getEquipmentLocation(id: string) {
    return this.http.get<EquipmentLocation>(`${environment.BASE_URL}${this.EQUIPMENT}/current-location?id=${id}`);
  }

  downloadFile() {
    return this.http.get<Message>(`${environment.BASE_URL}${this.EQUIPMENT}/excel-download`);
  }

  uploadFile(data) {
    return this.http.post<Message>(`${environment.BASE_URL + this.EQUIPMENT}/excel-upload`, data);
  }

  /**
   * Configuration Equipment's Services
   * @param search
   * @returns
   */
  getEquipmentList(search) {
    return this.http.get<EquipConfigList>(`${environment.BASE_URL}${this.EQUIPMENT_CRUD}/list?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&category=true&sort=true`);
  }

  getEquipCategory() {
    return this.http.get<EquipConfigList>(`${environment.BASE_URL}${this.EQUIPMENT_CRUD}/list?pagination=false&category=true&sort=true`);
  }

  deleteEquipmentCategory(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.EQUIPMENT_CRUD, options);
  }

  addEquipCategory(data) {
    return this.http.post<EquipResponse>(environment.BASE_URL + this.EQUIPMENT_CRUD, data);
  }

  getCategoryDetails(id: string) {
    return this.http.get<EquipConfigList>(`${environment.BASE_URL}${this.EQUIPMENT_CRUD}/list?pagination=false&categoryId=${id}&sort=true`);

  }
  categoryAllDetails(id: string) {
    return this.http.get<EquipConfigList>(`${environment.BASE_URL}${this.EQUIPMENT_CRUD}/list?pagination=false&categoryId=${id}&selfDetail=true&sort=true`);

  }
  updateEquipCategory(data) {
    return this.http.put<EquipCategoryData>(environment.BASE_URL + this.EQUIPMENT_CRUD, data);
  }

  addSubCategory(data) {
    return this.http.post<EquipCategoryData>(`${environment.BASE_URL + this.EQUIPMENT_CRUD}/sub-category`, data);
  }

  updateSubCategory(data) {
    return this.http.put<EquipCategoryData>(`${environment.BASE_URL + this.EQUIPMENT_CRUD}/sub-category`, data);
  }
  /**
   * Document Services
   */

  updateDocument(data) {
    return this.http.put<EquipDocsResponse>(environment.BASE_URL + this.DOC_CRUD, data);
  }

  addDocument(data) {
    return this.http.post<EquipDocsResponse>(environment.BASE_URL + this.DOC_CRUD, data);
  }

  deleteDocument(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: id
    };
    
    return this.http.delete<Message>(environment.BASE_URL + this.DOC_CRUD,options);
  }

  documentList(search) {
    return this.http.get<EquipDocumentList>(`${environment.BASE_URL}${this.DOC_CRUD}/list?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&id=${search.id}`);
  }

  documentListWithoutPagination(id: string) {
    return this.http.get<EquipDocumentList>(`${environment.BASE_URL}${this.DOC_CRUD}/list?pagination=false&id=${id}`);
  }
  documentDetail(id: string) {
    return this.http.get<EquipDocsResponse>(`${environment.BASE_URL}${this.DOC_CRUD}?id=${id}`);
  }

  /**
 * Parts Services
 */

  updateParts(data) {
    return this.http.put<PartsData>(environment.BASE_URL + this.PARTS_CRUD, data);
  }

  addParts(data) {
    return this.http.post<PartsData>(environment.BASE_URL + this.PARTS_CRUD, data);
  }

  deleteParts(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: id
    };
    return this.http.delete<Message>(environment.BASE_URL + this.PARTS_CRUD,options);
  }

  partsList(search) {
    return this.http.get<EquipPartsList>(`${environment.BASE_URL}${this.PARTS_CRUD}/list?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&id=${search.id}`);
  }
  partDetails(id: string) {
    return this.http.get<PartsData>(`${environment.BASE_URL}${this.PARTS_CRUD}?id=${id}`);
  }

  categoryList(type: string) {
    return this.http.get<SubcategoryList>(`${environment.BASE_URL}${this.EQUIPMENT_CRUD}/category-list?type=${type}`);
  }

  /**
   * API services for equipment inspection
   */
  getEquipmentInspection(search) {
    return this.http.get<any>(`${environment.BASE_URL}${this.INSPECTION_FORM}/list?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  deleteInspection(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: id
    };
    return this.http.delete<Message>(environment.BASE_URL + this.INSPECTION_FORM, options);
  }

  updateEquipmentInspection(data) {
    return this.http.put<any>(environment.BASE_URL + this.INSPECTION_FORM, data);
  }

  addInspection(data) {
    return this.http.post<FieldInspectionResponse>(environment.BASE_URL + this.INSPECTION_FORM, data);
  }

  getInspectionEquipment(search) {
    return this.http.get<EquipmentInspectionList>(`${environment.BASE_URL}${this.INSPECTION_FORM}/equipment-list?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&id=${search.id}`);
  }

  downloadReport(data) {
    return this.http.get<FileResponse>(`${environment.BASE_URL + this.INSPECTION_FORM}/view-report?inspectionId=${data.inspectionId}&equipmentId=${JSON.stringify(data.equipmentId)}`);
  }

  sendReportToUser(data) {
    return this.http.post<Message>(`${environment.BASE_URL + this.INSPECTION_FORM}/send-mail`, data);
  }

  /**
   * Field Inspection Response Services
   */

  getResponse(search) {
    return this.http.get<any>(`${environment.BASE_URL}${this.RESPONSE_CRUD}/list?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&form=${search.form}&equipment=${search.equipment}`);
  }

  deleteResponse(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.RESPONSE_CRUD, options);
  }

  updateResponse(data) {
    return this.http.put<Response>(environment.BASE_URL + this.RESPONSE_CRUD, data);
  }

  addResponse(data) {
    return this.http.post<Response>(environment.BASE_URL + this.RESPONSE_CRUD, data);
  }


}
