import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../_services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VariableService } from '../_common/variable.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private cos: CommonService,
        private spinner: NgxSpinnerService,
        public commonVariable: VariableService
    ) { }

    intercept(request, next: HttpHandler) {

        return next.handle(request).pipe(catchError(err => {

            const status = err.status;
            const message = err.error.message || err.statusText;

            if (status === 401 || status === 400 || status === 404) {
                this.cos.toastMessage(message, 'error');
                this.commonVariable.apiCount = 0;
                this.spinner.hide();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}