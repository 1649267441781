import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/_services/common.service';
import { UtilityLocatesService } from 'src/app/_services/utility-locates.service';
import 'bootstrap';
import * as jQuery from 'jquery';
import { ProjectService } from 'src/app/_services/project.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HoleRecord } from 'src/app/interfaces/hole-info';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-boring-hole',
  templateUrl: './boring-hole.component.html',
  styleUrls: ['./boring-hole.component.scss']
})
export class BoringHoleComponent implements OnChanges {
  @Input() modalId = 'addBoringHole';
  @Input() modalKey: string;
  @Input() patchBoringHole;
  @Output() addedBoringDetail = new EventEmitter();
  modalForm = this.formBuilder.group({
    name: '',
    altId: '',
    gps: '',
    equipment: '',
    coordinate: '',
    id: '',
    address: '',
    note: '',
    status: 'NOT_APPROVED',
  });
  projectEquipments = [];
  gps = [];
  equipments = [];
  patchData = [];
  document = [];
  siteAddress = [];
  documentBtn = [{ name: 'Save', type: 'holeInfo', showBackBtn: true }];
  boringStatus = [
    { key: 'ON_HOLD', value: 'On Hold' },
    { key: 'IN_PROGRESS', value: 'In Progress' },
    { key: 'NOT_APPROVED', value: 'Not Approved' },
    { key: 'APPROVED', value: 'Approved' },
    { key: 'NOT_COMPLETED', value: 'Not Completed' },
    { key: 'COMPLETED', value: 'Completed' }
  ];
  formData = [
    { title: 'Boring Hole Id*', name: 'name', placeholder: 'Enter Boring Hole ID', limit: 30, type: 'input' },
    { title: 'Alternate Id', name: 'altId', placeholder: 'Enter Alternate Id', limit: 30, type: 'input' },
    { title: 'Status*', name: 'status', placeholder: 'Select Boring status', type: 'checkBox', multipleValues: this.boringStatus, singleSelection: true },
    { title: 'Notes', name: 'note', placeholder: 'Enter Notes', limit: 500, type: 'summary' },
  ];
  modalTitle = 'Add Boring Hole';
  activeTab = 'basic-detail';
  state = [{
    buttonName: 'Next',
    type: 'BoringHole'
  }];
  constructor(
    private formBuilder: FormBuilder,
    public cos: CommonService,
    private route: ActivatedRoute,
    private projectSrvc: ProjectService,
    private utilitySrvc: UtilityLocatesService,
  ) {
  }

  ngOnChanges() {
    if (this.patchBoringHole && this.patchBoringHole._id) {
      this.modalTitle = 'Edit Boring Hole';
      this.activeTab = 'basic-detail';
      this.modalForm.reset();
      this.cos.getModalValue('RESET_EQUIPMENT');
      this.cos.resetDocTable('RESET_DOC');
      this.patchBoring(this.patchBoringHole);
    }
  }

  // Function to get the location coordinates
  setAddress(data) {
    this.gps = data.geo.longitude ? [data.geo.longitude, data.geo.latitude] : data.geo;
  }

  // Function give the added equipments
  addedEquipment(body) {
    try {
      const data = Array.isArray(body) && body.length ? body : body.equipments;
      this.equipments = [];
      if (data.length) {
        data.map((val) => {
          if (typeof val === 'string') {
            this.equipments.push(val);
          } else {
            this.equipments.push(val.id ? val.id : val._id);
          }
        });
        this.equipments = this.equipments.flat();
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to save basic detail of boring hole
  submitBasicData(data) {
    try {
      if (data.type === this.state[0].type) {
        if (!this.modalForm.value.name || this.modalForm.value.name.trim().length == 0) {
          return this.cos.toastMessage(response.en.BORINGHOLE_REQUIRED, 'warning');
        } else {
          this.activeTab = 'documents';
        }
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to get added documents
  submittedDocs(data) {
    this.document = data;
  }

  /**
   * Submits the form with the given value.
   * @param value - The value to submit.
   */
  async submitForm(value: { key: string, type: string }[]) {
    try {
      if (value[0].key && value[0].type === 'next') {
        const filterDocs = this.document.map(({ uploadType, ...rest }) => rest);
        const gps = Array.isArray(this.gps) && this.gps.length ? this.gps : [this.siteAddress[1], this.siteAddress[0]];
        const data = {
          boringId: this.modalForm.value.name,
          altId: this.modalForm.value.altId || '',
          documents: filterDocs.flat(),
          gps,
          equipments: this.equipments,
          project: this.route.snapshot.params.id,
          note: this.modalForm.value.note ? this.modalForm.value.note.trim() : '',
          status: this.modalForm.value.status,
        } as HoleRecord;
        const updateHoleInfoCallback = (res) => {
          if (res) {
            this.cos.toastMessage(res.message, 'success');
            this.addedBoringDetail.emit(this.modalKey);
            jQuery('#addBoringHole').modal('hide');
          } else {
            this.cos.toastMessage(res.message, 'error');
          }
        };
        const handleDuplicateRecord = (res) => {
          if (res && res.message === 'Boring Hole already exist') {
            const details = {
              text: `Oh, it seems the Boring Hole Id you entered is not available. However, the Id '${res.data}' is available. Would you like to proceed and save it with this Boring Hole Id`,
              title: 'Duplicate Record Found',
              cancelButtonText: 'No',
            }
            const options: SweetAlertOptions = this.cos.warningMessage(details, true);
            Swal.fire(options).then((result) => {
              if (result.value) {
                data.boringId = res.data;
                if (this.modalForm.value.id) {
                  this.utilitySrvc.updateHoleInfo({ ...data, id: this.modalForm.value.id }).subscribe(updateHoleInfoCallback);
                } else {
                  this.utilitySrvc.addHoleInfo(data).subscribe(updateHoleInfoCallback);
                }
              } else {
                this.modalForm.get('name').setValue('');
                this.activeTab = 'basic-detail';
              }
            });
          } else {
            if (res) {
              updateHoleInfoCallback(res);
            }
          }
        };
        if (this.modalForm.value.id) {
          this.utilitySrvc.updateHoleInfo({ ...data, id: this.modalForm.value.id }).subscribe(handleDuplicateRecord, err => this.cos.handleError(err));
        } else {
          this.utilitySrvc.addHoleInfo(data).subscribe(handleDuplicateRecord, err => this.cos.handleError(err));
        }
      } else {
        this.activeTab = 'basic-detail';
      }
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  //Function to get project equipments and location
  async sendProjectEquipment() {
    try {
      this.projectSrvc.getProject(this.route.snapshot.params.id).subscribe((res) => {
        this.projectEquipments = res.data.equipments;
        this.siteAddress = res.data.address ? [res.data.address.geo[1], res.data.address.geo[0]] : [39.339831974, -104.457664836];
        this.cos.setLatLng(this.siteAddress[0], this.siteAddress[1]); // lat,long
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to reset the form
  resetForm() {
    try {
      this.activeTab = 'basic-detail';
      this.modalForm.reset();
      this.cos.getModalValue('RESET_EQUIPMENT');
      this.cos.resetDocTable('RESET_DOC');
      this.sendProjectEquipment();
      this.modalForm.patchValue({
        status: 'NOT_APPROVED',
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // Function to patch Document
  patchBoring(result) {
    try {
      this.modalForm.patchValue({
        name: result.boringId ? result.boringId : '',
        id: result._id,
        altId: result.altId ? result.altId : '',
        note: result.note ? result.note : '',
        status: result.status ?? 'NOT_APPROVED',
      });
      this.cos.setLatLng(result.gps[1], result.gps[0]);
      this.patchData = result.documents.length ? result.documents : [];
      this.projectEquipments = result.equipments.length ? result.equipments : [];
    } catch (error) {
      this.cos.handleError(error);
    }
  }

}
