import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-ad-textarea',
  templateUrl: './ad-textarea.component.html',
  styleUrls: ['./ad-textarea.component.scss']
})
export class AdTextareaComponent {
  @Input() inputProperty = {
    title: '',
    placeholder: '',
    type: 'text',
    length: 60,
    width: 'col-6',
    name: 'input',
    form: this.formBuilder.group({}),
    addIcon: false,
    value: ''
  }
  @Input() patchInput: object;
  @Output() textAreaValue = new EventEmitter<{fieldValue: string, fieldName: string}>;
  @Output() updateFiled = new EventEmitter();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public cos: CommonService,
  ) {}

   // Function to get the value from input
   changeInput(data: string, type: string) {
    this.textAreaValue.emit({fieldValue: data, fieldName: type});
  }


}
