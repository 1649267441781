<div class="commonTable">
  <table
    mat-table
    [dataSource]="tableData"
    class="mat-elevation-z8"
    *ngIf="tableData.length"
  >
    <!-- Bulk Delete field -->
    <ng-container
      matColumnDef="select"
      appAccessControl
      [permission]="permission?.remove"
    >
      <th
        mat-header-cell
        *matHeaderCellDef="let element"
        style="width: 37px;"
        appAccessControl
        [permission]="permission?.remove"
        appVisibilityControl
        [enabled]="showCheckboxColumn"
      >
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [color]="checkColor"
        >
        </mat-checkbox>
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        appAccessControl
        [permission]="permission?.remove"
        appVisibilityControl
        [enabled]="showCheckboxColumn"
      >
        <mat-checkbox *ngIf="showOnlySelectColumn"
          (change)="singleSelection(row, $event.checked)"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [color]="checkColor"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Content of table  -->
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of column; let j = index"
    >
      <!-- heading of table -->
      <th
        mat-header-cell
        *matHeaderCellDef
        class="text-capitalize"
        appAccessControl
        permission="{{ getPermission(column) }}"
        [ngStyle]="isMinWidth ? {'min-width': '130px'} : {}"
       >    
        <!-- Heading of table with sort button -->
        <span class="d-flex" *ngIf="isSort">
          <span class="d-inline-flex pt-1">
            {{ this.cos.getCamelCaseValue(column) }}
          </span>

          <span class="d-flex flex-column mx-3" *ngIf="sortCheck(column)">
            <i
              (click)="sortName('ascending', column, j)"
              class="fa fa-caret-up cursor-pointer"
              aria-hidden="true"
              [ngClass]="
                sortNameAscd === column ? 'sortTrueEffect' : 'sortFalseEffect'
              "
              style="font-size: 17px"
            ></i>

            <i
              (click)="sortName('descending', column, j)"
              class="fa fa-caret-down cursor-pointer"
              aria-hidden="true"
              [ngClass]="
                sortNameDscd === column ? 'sortTrueEffect' : 'sortFalseEffect'
              "
              style="font-size: 17px; margin-top: -5px"
            ></i>
          </span>
        </span>

        <!-- Heading of table without sort button -->
        <ng-container *ngIf="!isSort">
          {{ this.cos.getCamelCaseValue(column) }}
        </ng-container>
      </th>

      <!-- data of table -->
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        appAccessControl
        permission="{{ getPermission(column) }}"
      >
        <!-- display  color (icon)in table -->
        <span
          *ngIf="getApproval(element[column], column)"
          class="cursor-pointer"
        >
          <mat-icon
            (click)="updateRecord(element, element[column])"
            [ngClass]="
              element[column] === 'SECONDARY_COLOR'
                ? 'disableColor'
                : 'activeColor'
            "
          >
            {{ matICon }}
          </mat-icon>
        </span>

        <!-- display data in table-->
        <span
          class="d-flex flex-row"
          *ngIf="getData(element[column], column)"
          (click)="rightModal(element, j, i)"
          [ngClass]="getClass(j)"
          [style.color]="isNote ? getColorBasedOnNote(element) : null">
          <!-- To show only color  -->
          <ng-container *ngIf="isColor(element[column], element)">
            <input
              [disabled]="isColorEdit"
              type="color"
              (click)="changeColor(element)"
              class="color-box cursor-pointer"
              [value]="getColor(element[column])"
              (change)="getColorFromPicker($event.target.value, element)"
            />
          </ng-container>

          <!-- text -->
          <span
            *ngIf="dataStyle(column, element[column])"
            [style.color]="textColor(element, column)"
            [ngClass]="
              column.includes('note') ||
              column === 'summary' ||
              column === 'description'
                ? 'noteField'
                : ''
            "
          >
            {{ getValue(element[column]) }}
          </span>

          <!-- Show only mat icon -->
          <span *ngIf="isIcon(element[column])">
            <mat-icon class="checkColor" style="cursor: inherit">
              check_circle
            </mat-icon>
          </span>

          <!-- display data with toggle button  ( in employee section )-->
          <label class="switch" *ngIf="isStatusToggle && (column === 'status' || column === 'visible' || column === 'primary')">
            <span *ngIf="!dataStyle(column, element[column])">
              <input
                type="checkbox"
                [checked]="element[column] === true"
                (change)="handleToggle(element, column, $event)"
                [disabled]="disableStatus"
              />
              <span class="slider round"></span>
            </span>
          </label>          

          <!-- display data with in button with theme in active or disable state (in employee section)-->
          <ng-container *ngIf="isStatusApproval && column === 'approval'">
            <button
              *ngIf="element[column] === false"
              class="activeButton px-3 py-2"
              (click)="editApproval(element)"
            >
              Approve
            </button>
            <button *ngIf="element[column] === true" mat-flat-button disabled>
              Approved
            </button>
          </ng-container>
        </span>
      </td>
    </ng-container>

    <!-- Action button of table -->
    <ng-container>
      <ng-container
        matColumnDef="action"
        appAccessControl
        [permission]="permission?.edit + '||' + permission?.remove"
      >
        <th
          appAccessControl
          [permission]="permission?.edit + '||' + permission?.remove"
          mat-header-cell
          *matHeaderCellDef
          [style.width]="actionCellWidth ? actionCellWidth : '6rem'"
          appVisibilityControl
          [enabled]="showActionColumn"
        >
          Action
        </th>
        <td
          appAccessControl
          [permission]="permission?.edit + '||' + permission?.remove"
          mat-cell
          class="cursor-pointer text-center"
          *matCellDef="let element; let i = index"
          appVisibilityControl
          [enabled]="showActionColumn"
        >
          <!-- check icon: used to check/change status to complete the record -->
          <div
            class="d-flex justify-content-end"
            appVisibilityControl
            [enabled]="showActionColumn"
          >
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="showCheck"
              class="actnbtn mt-1"
              (click)="actionButton(element, 'check', i)"
            >
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <!-- clone icon: used to clone the record -->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="showClone"
              class="actnbtn mt-1"
              (click)="actionButton(element, 'clone', i)"
            >
              <i class="fa fa-clone" aria-hidden="true"></i>
            </div>
             <!-- merge icon: used to merge the record -->
             <div
             appAccessControl
             permission="{{ permission?.edit }}"
             *ngIf="showMerge"
             class="actnbtn mt-1"
             (click)="actionButton(element, 'merge', i)"
           >
           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-git-merge">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M7 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M17 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M7 8l0 8" />
            <path d="M7 8a4 4 0 0 0 4 4h4" />
          </svg>
           </div>
            <!-- save icon: used to save the record -->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="isSaved"
              class="actnbtn mt-1"
              (click)="actionButton(element, 'save', i)"
            >
              <i class="fa fa-save" aria-hidden="true"></i>
            </div>
            <!-- document view icon: used to mail the record -->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="element.isDocument"
              (click)="actionButton(element, 'mail', i)"
              class="actnbtn mt-1"
            >
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <!-- add icon: used to add the record -->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="addItem"
              (click)="actionButton(element, 'add', i)"
              class="actnbtn mt-1"
            >
            <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
            <!-- edit icon: used to edit the record, icon is showing on only specific record -->
            <div
              appAccessControl
              permission="{{ permission?.read }}"
              *ngIf="showInfo"
              (click)="actionButton(element, 'info', i)"
              class="actnbtn mt-1"
            >
              <i class="fa fa-info" aria-hidden="true"></i>
            </div>
            <!-- edit icon: used to edit the record, icon is showing on only specific record -->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="element.isEditable"
              (click)="patchData(element, 'edit', i)"
              class="actnbtn mt-1"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </div>
            <!-- edit icon: used to edit the record , present in all record-->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="showEdit"
              class="actnbtn mt-1"
              (click)="patchData(element, 'edit', i)"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </div>
            <!-- map icon: used to view the map of record-->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="showMap"
              class="actnbtn mt-1"
              (click)="this.cos.goToMap(element.GPS)"
            >
              <i class="fa fa-location-arrow" aria-hidden="true"></i>
            </div>
             <!-- eye view icon: used to view the docs-->
            <div
              appAccessControl
              permission="{{ permission?.read }}"
              *ngIf="viewDoc"
              (click)="actionButton(element, 'view', i)"
              class="actnbtn mt-1"
            >
            <i class="fa fa-eye" aria-hidden="true"></i>
            </div>
            <!-- activity icon: used to show the details of record-->
            <div
              appAccessControl
              permission="{{ permission?.edit }}"
              *ngIf="showActivity"
              class="actnbtn mt-1"
              (click)="actionButton(element, 'activity', i)"
            >
              <i class="fa fa-list" aria-hidden="true"></i>
            </div>
            <!-- download icon: used to download the record-->
            <div
              appAccessControl
              permission="{{ permission?.remove }}"
              *ngIf="isDownload"
              class="mt-1 actnbtn"
              (click)="actionButton(element, 'download', i)"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </div>
            <!-- delete icon: used to delete the record, present in specific record-->
            <div
              appAccessControl
              permission="{{ permission?.remove }}"
              *ngIf="element.isEditable"
              class="mt-1 actnbtn btn-danger"
              (click)="patchData(element, 'delete', i)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
            <!-- delete icon: used to delete the record, present in all record-->
            <div
              appAccessControl
              permission="{{ permission?.remove }}"
              *ngIf="isDelete"
              class="mt-1 actnbtn btn-danger"
              (click)="patchData(element, 'delete', i)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- No Record/ Searching Data -->
  <div class="text-center table-no-record py-5" *ngIf="!isEstimateDoc">
    <!-- display : when there is no data -->
    <ng-container
      *ngIf="tableData.length === 0 && commonVariable.apiCount === 0"
    >
      <img
        src="../../../../../assets/images/no-record.jpg"
        alt="No Record Found"
      />
      <div class="record_text">{{ emptyTableData }}</div>
      <div class="record_text">{{ addTableData }}</div>
    </ng-container>

    <!-- display : when data is loading from api -->
    <ng-container *ngIf="tableData.length === 0 && commonVariable.apiCount > 0">
      <img
        src="../../../../../assets/images/search-data.jpg"
        alt="No Record Found"
      />
      <div class="record_text">{{ searchingTableData }}</div>
    </ng-container>
  </div>
</div>

<!--  Pagination  -->
<div class="my-3" *ngIf="isPagination && tableData.length > 0 && !isEstimateDoc">
  <app-pagination
    (updatedList)="setPage($event)"
    [paginationDetail]="completeData"
    [pageSize]="pageSize"
  ></app-pagination>
</div>
