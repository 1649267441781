import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-estimate-radio-table",
  templateUrl: "./estimate-radio-table.component.html",
  styleUrls: ["./estimate-radio-table.component.scss"],
})
export class EstimateRadioTableComponent implements OnInit, OnChanges {
  @Input() tableTitle = "Title";
  @Input() selectedValue = "";
  @Input() isReporting = false; 
  @Output() selectedValueChange = new EventEmitter<string>();

  choices: { id: string; tag: string }[] = [];

  ngOnInit() {
    // Initialize choices based on isReporting
    this.updateChoices();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedValue'] && !changes['selectedValue'].firstChange) {
      this.selectedValue = changes['selectedValue'].currentValue;
    }
  }

  updateChoices() {
    if (this.isReporting) {
      this.choices = [
        { id: "true", tag: "Yes" },
        { id: "false", tag: "No" },
      ];
      // Set default value to "true" or "false" for reporting
      this.selectedValue = "false";
    } else {
      this.choices = [
        { id: "YES", tag: "Yes" },
        { id: "NO", tag: "No" },
        { id: "UNKNOWN", tag: "Unknown" },
        { id: "NOT_ASKED", tag: "Not Asked" }
      ];
    }

    // Ensure default value is only set if not coming from parent
    if (!this.selectedValue) {
      this.selectedValue = this.isReporting ? "false" : "NOT_ASKED";
    }
  }

  onValueChange(newValue: string) {
    this.selectedValue = newValue;
    this.selectedValueChange.emit(newValue);
  }
}
