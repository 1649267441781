
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonService } from '../../../../_services/common.service';
import Swal from 'sweetalert2';
import { ProjectCommunicateService } from 'src/app/modules/project/project-communicate.service';
import { DocumentItem, DataItem } from '../../../../interfaces/upload-document';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-upload-image-documents',
  templateUrl: './upload-image-documents.component.html',
  styleUrls: ['./upload-image-documents.component.scss']
})
export class UploadImageDocumentsComponent implements OnInit, OnChanges {
  @Input() documentBtn = []; 
  @Input() reset: boolean; 
  @Input() patchData = []; 
  @Input() resetDocTable = '';
  @Input() isMultiple = true;
  @Input() fieldWidth = false;
  @Input() smallSizeField = false;
  @Input() isExpiration = false;
  @Input() patchDocType = {};
  @Input() docTypeDetails = {
    title: '',
    name: '',
    singleSelection: false,
    multipleValues: []
  };
  @Output() docsData = new EventEmitter<DataItem[]>(); // send emit value to particular component
  @Output() submittedDocs = new EventEmitter<DocumentItem[]>(); //send added docs
  @Output() deletedDocumentUrl = new EventEmitter<DocumentItem[]>(); //send added docs 
  @Output() uploadedFile = new EventEmitter<any>();
  addDocumentForm = this.fb.group({
    note: '',
    type: '',
    link: '',
    docName: '',
    docType: '',
    expiryDate: ''
  });
  currentDate = new Date();
  displayedDocumentColumns: string[] = [
    'Document',
    'Note',
    'Type',
    'action'
  ];
  documents = [];
  isDocumentDeleted = false;
  isDocumentUrlDeleted = false;
  addDocuments = [];
  docsInfoData = {};
  docsInfoData$;
  constructor(
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private projectCommunicateSrvc: ProjectCommunicateService,
  ) {
    this.addDocumentForm.reset();
    this.addDocuments = [];
  }

  ngOnChanges() {
    this.addDocuments = [];
    if (this.patchData.length) {
      this.patchData.map((val) => {
        this.addDocuments = [
          ...this.addDocuments, {
            note: val.note?.trim() ?? '',
            fileName: val.fileName.trim() ?? '',
            type: val.type,
            link: val.link ?? '',
            url: val.url ?? '',
            uploadType: val.url ? 'DOCUMENT' : 'LINK'
          }
        ];
      });

    }
  }

  ngOnInit() {
    this.cos.resetDoc$.subscribe(data => {
      if (data === 'RESET_DOC') {
        this.resetForm();
      }
    });
    this.docsInfoData$ = this.projectCommunicateSrvc.equipDocInfo$
      .subscribe(res => {
        this.docsInfoData = res;
      });

  }

  // This function is use to upload file
  upload(event) {
    this.uploadedFile.emit(event);
    this.documents = [event];
    this.addDocumentForm.patchValue({
      docName: event.fileName
    });
  }
  

  passImages() {
    return this.documents || [];
  }

  // Function will eun when you delete the document by clicking on icon
  removeImage(event) {
    this.documents = this.documents.filter(document => document.url
      ? document.url !== event.url
      : document.path !== event.path);
      this.addDocumentForm.patchValue({
        docName: '',
      });
  }

  openUrl(item) {
    if (item.url) {
      window.open(item.url);
    } else {
      window.open(item.link);
    }
  }

  deleteAddedDocument(index: number) {
    const deletedDocs = this.addDocuments[index];
    this.deletedDocumentUrl.emit(deletedDocs);

    Swal.fire(this.cos.deleteText).then((result) => {
        if (result.value) {
            this.addDocuments = this.addDocuments.filter((item) => item !== deletedDocs);
            this.submittedDocs.emit(this.addDocuments);
            // Set the flag to true indicating a document was deleted
            this.isDocumentDeleted = true;
            this.isDocumentUrlDeleted = true; 
        }
    });
}
  
  addDocsForm() {
    if (this.docTypeDetails && this.docTypeDetails.title && !this.addDocumentForm.value.docType) {
      return this.cos.toastMessage(response.en.DOCUMENT_TYPE ,'warning');
    } else if (!this.addDocumentForm.value.type) {
      return this.cos.toastMessage(response.en.UPLOAD_TYPE, 'warning');
    } else if (this.addDocumentForm.value.type === 'DOCUMENT' && ((Array.isArray(this.documents) && this.documents.length === 0) || !this.documents)) {
      return this.cos.toastMessage(response.en.UPLOAD_DOC, 'warning');
    } else if (!this.addDocumentForm.value.docName || (this.addDocumentForm.value.docName).trim() == 0) {
      return this.cos.toastMessage(`DOCUMENT ${response.en.NAME}`, 'warning');
    } else if (this.addDocumentForm.value.type === 'LINK' && !this.addDocumentForm.value.link) {
      return this.cos.toastMessage(response.en.LINK, 'warning');
    } else if (this.addDocumentForm.value.link && !this.cos.pattern.test(this.addDocumentForm.value.link)) {
      return this.cos.toastMessage(response.en.VALID_LINK, 'warning');
    } else {
      this.addDocuments = [
        ...this.addDocuments, {
          ...(!!this.docTypeDetails && this.docTypeDetails.title && this.addDocumentForm.value.docType && { docType: this.addDocumentForm.value.docType }),
          note: this.addDocumentForm.value.note?.trim() ?? '',
          fileName: this.addDocumentForm.value.docName?.trim() ?? '',
          type: this.addDocumentForm.value.type,
          link: this.addDocumentForm.value.link ?? '',
          url: Array.isArray(this.documents) && this.documents.length > 0 ? this.documents[0].url : '',
          uploadType: this.addDocumentForm.value.link ? 'LINK' : 'DOCUMENT',
          ...(!!this.isExpiration && this.addDocumentForm.value.expiryDate && { expiryDate: this.cos.getFormattedDate(this.addDocumentForm.value.expiryDate) }),
        }
      ];
    }
    this.submittedDocs.emit(this.addDocuments);
    this.documents = [];
    this.addDocumentForm.reset();

  }


  sendResult(data, value) {
    const result = [{ key: data, type: value }];
    this.submittedDocs.emit(this.addDocuments);
    this.docsData.emit(result);
  }

  resetForm() {
    this.documents = [];
    this.cos.documentValue$.subscribe(data => {
      if (Array.isArray(data) && data.length) {
        this.addDocuments = [];
        this.addDocuments = data;
      } else {
        this.addDocumentForm.reset();
        this.addDocuments = [];
        this.patchData = [];
      }
    });
  }

  fieldSize() {
    return this.fieldWidth ? 'col-4' : (this.smallSizeField ? 'col-3' : 'col-6');
  }

  // Function used when we select the fields from upload type
  SelectedUploadType(data) {
    this.addDocumentForm.patchValue({
      note: '',
      docName: '',
    });
    this.documents = [];
    if (data.value.toLowerCase() === 'clear') {
      this.addDocumentForm.reset();
      this.addDocuments = [];
    }
  }

  // Function used when we select the fields from document type  (comes from other component)
  SelectedDocumentType(data) {
    const expirationDocTypes = ['INSURANCE', 'REGISTRATION', 'OTHER'];
    this.addDocumentForm.patchValue({
      expiryDate: this.addDocumentForm.value.docType === 'MANUALS' ? '' : this.addDocumentForm.value.expiryDate,
      docType: data.ids
    });
    this.isExpiration = expirationDocTypes.includes(this.addDocumentForm.value.docType);
  }

}
