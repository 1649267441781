<div class="form__group">
  <input
    #inputFile
    accept=".pdf, .png, .jpg, .webp, .jpeg, .svg, .apng, .doc, .docx, .docs, .txt, .xls, .xlsx, .ods, .ppt, .pptx, .tiff, .kmz, .kml, .heic, .heif"
    (change)="upload($event, inputFile)"
    [attr.id]="randomId"
    class="file-upload d-none"
    type="file"
    multiple
  />
  <div
    class="upload-container"
    appDnd
    (fileDropped)="upload($event, inputFile)"
    [ngClass]="{ changePadding: changePadding }"
    (click)="inputFile.click()"
  >
    <button type="button" class="upload-btn">
      <i class="fa fa-upload" aria-hidden="true"></i> Upload
    </button>
  </div>
  <div class="documents-container">
  <div *ngFor="let item of uploadedImages" class="documents-div mt-2">
    <div
      class="d-flex cursor-pointer w-100 justify-content-between theme-text-color"
    >
      <span>
        <i class="fa fa-file-text" aria-hidden="true"></i>
        <span class="mx-1" (click)="viewLink(item)">
          {{ item.name ?? item.fileName | slice: 0:30 }}
        </span>
      </span>
      <i
        class="fa fa-trash theme-text-color mt-1"
        aria-hidden="true"
        (click)="remove(item, inputFile)"
      ></i>
    </div>
  </div>
  </div>
</div>
