import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {CommonService} from 'src/app/_services/common.service';
import {ProjectService} from 'src/app/_services/project.service';
import 'bootstrap';
import {response} from 'src/app/enum/message';
import {MAIL_TO} from "../../../../constants/AppConstants";
import {FunctionsService} from "../../../../_common/functions.service";
import { VendorsService } from 'src/app/_services/vendors.service';
import { ClientService } from 'src/app/_services/client.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-mail-modal-project',
  templateUrl: './mail-modal-project.component.html',
  styleUrls: ['./mail-modal-project.component.scss']
})
export class MailModalProjectComponent implements OnInit {

  /** Property to hold modal ID */
  @Input() modalId = 'mailModal';

  /** Property to hold email dropdown input label */
  @Input() emailDropdownLabel = 'Email';

  /** Property to hold email dropdown input placeholder */
  @Input() emailDropdownPlaceholder = 'Select Email';

  /** Property to hold email attachments options */
  @Input() emailAttachments: any[] = [];

  /** Property to hold vendor orders, Note: This is used for vendor common orders tab only */
  @Input() vendorOrders: any[] = [];

  /** Property to hold vendor orders, Note: This is used for vendor component tab only */
  @Input() vendorData: any[] = [];

  /** Property to hold options for input custom email or from dropdown */
  @Input() mailTo = [
    {
      value: 'Dropdown Option',
      key: MAIL_TO.DROPDOWN_OPTIONS
    },
    {value: 'Custom Mail', key: MAIL_TO.CUSTOM}
  ];

   // Dropdown options
   vendorOptions: any[] = [];
   companyEmployeeOptions: any[] = [];
   clientOptions: any[] = [];
   branches: any[] = [];
   representatives: any[] = [];
 
   // Selected options
   selectedMailToType = '';
   selectedClient = '';
   selectedBranch = '';
   selectedRepresentative = '';
   selectedVendor: string | null = null;
   selectedEmployee = '';

   /** Property to hold form data for dynamic dropdowns */
   formData = [
    { title: 'Vendor', name: 'vendor', type: 'checkBox', multipleValues: this.vendorOptions, singleSelection: true },
    { title: 'Client', name: 'client', type: 'checkBox', multipleValues: this.clientOptions, singleSelection: true },
    { title: 'Branch', name: 'branch', type: 'checkBox', multipleValues: this.branches, singleSelection: true },
    { title: 'Representative', name: 'representative', type: 'checkBox', multipleValues: this.representatives, singleSelection: true },
    { title: 'Employee', name: 'companyEmployee', type: 'checkBox', multipleValues: this.companyEmployeeOptions, singleSelection: true },
  ];

  /** Property to hold email options */
  @Input() emailOptions: any[] = []

  /** Property to emit mailTo option select event */
  @Output() mailToSelectEvent: EventEmitter<boolean> = new EventEmitter();

  /** Property to emit submit button click event */
  @Output() submitButtonEvent: EventEmitter<any> = new EventEmitter();

  /** Property to hold if the document is internal */
  @Input() isInternal = false; 

  @Input() isInternalEstimate = false;

  // Add this at the top of your component class
  @Output() saveButtonEvent: EventEmitter<{ isInternal: boolean }> = new EventEmitter();

  @Output() isInternalChange: EventEmitter<boolean> = new EventEmitter();

  // Flag to track if the save button has been clicked
  private isSaveButtonClicked = false;

  /** Selected values for dynamic dropdowns */
  selectedValues: { [key: string]: any } = {};

  /** Property for mat-slide-toggle binding */
  public isInternalToggle = false;

  /** Property to hold modalProperty */
  @Input() modalProperty = {
    header: 'Send Mail',
    id: 'mailModal'
  };

  saveButton = {
    key: 'Mail Modal',
    text: 'Save',
    permission: 'aggregation.write'
  }

  /** Property to hold mail to selected option */
  mailToSelected = "";

  /** Property to hold send email form */
  @Input() mailForm = this.fb.group({
    to: [''],
    cc: [''],
    subject: [''],
    body: [''],
    urls: ['']
  });

  search = {
    pagination: true,
    pageNumber: 1,
    noOfRecord: 10,
    search: "",
    category: [],
    keyword: [],
    pickup: true,
    delivery: true,
    latitude: "",
    longitude: "",
    maxDistance: 160934,
    isLocalized: false,
    client: this.selectedClient,
    branch: this.selectedBranch
  };

  /** Property to hold if cc email required */
  @Input() isVendorOrderEmail = false

  /** Property to hold if cc email required */
  @Input() isVendorOrderEmailNotRequired = false

  /** Property to hold close modal handling */
  @Input() closeEmailModal = false

  /** Property to hold if mail to is */
  @Input() isMailToVisible = true

  /** Property to hold if email options dropdown visible */
  @Input() isEmailOptionsDropdownVisible = true

  /** Property to hold if cc email is visible */
  @Input() isCcMailVisible = true

  /** Property to hold if cc email is required */
  @Input() isCcMandatory = true

  /** Property to hold if custom email field is visible */
  @Input() isCustomMailVisible = false

  /** Property to hold if subject is required */
  @Input() isSubjectRequired = true

  /** Property to hold if body is required */
  @Input() isBodyRequired = true

  /** Property to hold if document will be visible or not */
  @Input() isDocument = false

  /** Property to hold document */
  document = [];

  /** Selected sub-option */
  mailToSubSelected = '';

  /** Flags to control visibility of specific dropdowns */
  isSubOptionVisible = false;
  isVendorVisible = false;
  isClientVisible = false;
  isCompanyEmployeeVisible = false;

  /** Property to hold sub-options for Mail To */
  @Input() mailToSubOptions: any[] = [
    { key: MAIL_TO.VENDOR, value: 'Vendor' },
    { key: MAIL_TO.CLIENT, value: 'Client' },
    { key: MAIL_TO.COMPANY_EMPLOYEE, value: 'Company Employee' },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private cos: CommonService,
    private projectSrvc: ProjectService,
    public commonFunction: FunctionsService,
    private vendorSrvc: VendorsService,
    private clientSrvc: ClientService,
    private authSrvc: AuthService
  ) {
    this.mailForm.reset();
  }

  ngOnInit() {
    this.projectSrvc.changeEmitted$.subscribe(data => {
      if (data) {
        this.resetModal(); // Updated to call resetModal method
      }
    });
  }

  /**
   * Reset Save Button Click Status
   */
  resetModal() {
    if (!this.vendorOrders || this.vendorOrders.length === 0) {
      this.initializeVendorOrders();
    }
  }
  
  initializeVendorOrders() {
    this.vendorOrders = []; 
  }

  /**
   * Validate Form Inputs
   */
  public validateForm(): boolean {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    // Validate To Email ID
    if (!this.cos.isValidInput(this.mailForm.value.to)) {
      this.cos.toastMessage(response.en.ENTER_RECEMAIL_ID,this.cos.ToastTypeEnum.WARNING);
      return false
    } else if (!validateEmail(this.mailForm.value.to) && this.mailForm.value.to) {
      this.cos.toastMessage(response.en.VALID_EMAIL,this.cos.ToastTypeEnum.WARNING);
      return false
    }

    // Validate CC Email ID
    if (this.isCcMandatory || this.cos.isValidInput(this.mailForm.value.cc)) {
      if (!this.cos.isValidInput(this.mailForm.value.cc)) {
        this.cos.toastMessage(response.en.ENTER_CC_EMAIL_ID,this.cos.ToastTypeEnum.WARNING);
        return false
      } else if (!validateEmail(this.mailForm.value.cc) && this.mailForm.value.cc) {
        this.cos.toastMessage(response.en.VALID_EMAIL,this.cos.ToastTypeEnum.WARNING);
        return false
      }
    }

    // Validate Subject Field
    if (this.isSubjectRequired) {
      if (!this.cos.isValidInput(this.mailForm.value.subject)) {
        this.cos.toastMessage(response.en.ENTER_EMAILSUB,this.cos.ToastTypeEnum.WARNING);
        return false
      } else if (this.mailForm.value.subject.trim().length == 0) {
        this.cos.toastMessage(response.en.VALID_SUBJECT,this.cos.ToastTypeEnum.WARNING);
        return false
      }
    }

    // Validate Body Field
    if (this.isBodyRequired) {
      if (!this.cos.isValidInput(this.mailForm.value.body)) {
        this.cos.toastMessage(response.en.ENTER_EMAILBODY,this.cos.ToastTypeEnum.WARNING);
        return false
      } else if (this.mailForm.value.body.trim().length == 0) {
        this.cos.toastMessage(response.en.VALID_BODY,this.cos.ToastTypeEnum.WARNING);
        return false
      }
    }

    // Validate Vendor Orders
    if (this.vendorOrders) {
      for(const val of this.vendorOrders) {
        if (!val.quantity) {
          this.cos.toastMessage(response.en.ADD_ORDER_QUANTITY_ERROR,this.cos.ToastTypeEnum.WARNING);
          return false;
        }
      }
    }
    return true
  }

  /**
   * Handle Send Button Click Event
   */
  onSendClick() {
    if (this.validateForm()) {
      const requestPayload = {
        to: this.mailForm.value.to,
        ...(this.isSubjectRequired && { subject: this.mailForm.value.subject }), // Email Subject
        ...(this.isBodyRequired && { body: this.mailForm.value.body }), // Email Body
        ...((this.isCcMailVisible && this.mailForm.value.cc) && { cc: this.mailForm.value.cc }), // CC Email
        ...(this.emailAttachments.length && { urls: this.emailAttachments.map(val => val.url ? val.url : val) }), // Email Attachments for Daily Reports
        ...(this.selectedRepresentative && { to: this.selectedRepresentative }),
        ...(this.selectedVendor && { to: this.selectedVendor }),
        ...(this.selectedEmployee && { to: this.selectedEmployee }),
        ...(this.isDocument && this.document.some(doc => doc.url || doc.path) && {
          urls: this.document.map(doc => doc.url || doc.path)
        }),
        ...(this.vendorOrders.length && {
          items: this.vendorOrders.map(val => ({
            itemId: val.id,
            quantity: val.quantity
          }))
        }),
        ...(this.vendorData.length && {
          ids: this.vendorData.map(val => ({
            ids: val.id,
          }))
        }),
      };

      // Emit Submit Button Click Event
      this.submitButtonEvent.emit(requestPayload);

      this.isInternalToggle = false; // Reset toggle to false
      this.isInternal = false; // Ensure synchronization
      this.mailToSelected = ''; // Reset mailTo to empty
      this.document = [];
      this.mailForm.reset(); // Reset the form fields
      this.resetSubSelections();
    }
  } 

  /**
   * Handle Mail To Options
   *
   * @param event Selected Option Key
   */
  onMailToOptionClick(event: any) {
    this.mailToSelected = event.value;
  
    // Emit the event for parent component if necessary
    if (event.value === MAIL_TO.DROPDOWN_OPTIONS) {
      this.mailToSelectEvent.emit(true);
      this.isSubOptionVisible = true;
    } else {
      this.mailToSelectEvent.emit(false);
      this.isSubOptionVisible = false;
      this.mailToSubSelected = '';
      this.resetSubSelections();
    }
  
    // Reset the `to` field in the form
    this.mailForm.controls['to'].setValue(null);
  }

  
  /**
   * Handle Mail To Sub-Option Selection
   */
  onMailToSubOptionClick(event: any) {
    this.mailToSubSelected = event.value;

    // Reset previous selections
    this.resetSpecificSelections();

    // Determine which dropdown to show
    switch (event.value) {
      case MAIL_TO.VENDOR:
        this.isVendorVisible = true;
        this.getVendors();
        break;
      case MAIL_TO.CLIENT:
        this.isClientVisible = true;
        this.getClients();
        break;
      case MAIL_TO.COMPANY_EMPLOYEE:
        this.isCompanyEmployeeVisible = true;
        this.getCompanyEmployees();
        break;
      default:
        this.isVendorVisible = false;
        this.isClientVisible = false;
        this.isCompanyEmployeeVisible = false;
        break;
    }
  }

  /**
   * Reset Sub-Selections
   */
  private resetSubSelections() {
    this.mailToSubSelected = '';
    this.isVendorVisible = false;
    this.isClientVisible = false;
    this.isCompanyEmployeeVisible = false;
    this.selectedVendor = null; 
    this.selectedClient = '';
    this.selectedEmployee = '';
    this.selectedBranch = '';
    this.selectedRepresentative = '';
    this.emailAttachments = [];
  }

  /**
   * Reset Specific Selections Based on Sub-Option
   */
  private resetSpecificSelections() {
    this.isVendorVisible = false;
    this.isClientVisible = false;
    this.isCompanyEmployeeVisible = false;
    this.selectedVendor = null; 
    this.selectedClient = '';
    this.selectedBranch = '';
    this.selectedRepresentative = '';
    this.selectedEmployee = '';
    this.emailAttachments = [];
  }

  // Fetch Vendors
  private getVendors() {
    this.vendorSrvc.getVendorList(this.search).subscribe((res) => {
      if (res.data?.length) {
        this.vendorOptions = res.data.map((val) => ({
          name: val.userName || 'N/A',
          email: val.contact?.email || '',
        }));
        this.formData.map((val) => {
          if (val.name === 'vendor') {
            val.multipleValues = this.vendorOptions;
          }
        });
      }
    });
  }

  // Fetch Company Employees
  private getCompanyEmployees() {
    this.authSrvc.getAllCompanyEmployee(this.search).subscribe((res) => {
      if (res.data?.length) {
        this.companyEmployeeOptions = res.data.map((val) => ({
          name: val?.userName || 'N/A',
          email: val?.contact?.email || '',
        }));
         this.formData.map((val) => {
          if (val.name === 'companyEmployee') {
            val.multipleValues = this.companyEmployeeOptions;
          }
        });
      }
    });
  }

  // Fetch Clients
  private getClients() {
    this.clientSrvc.getClients().subscribe((res) => {
      if (res.data?.length) {
        this.clientOptions = res.data.map((val) => ({
          key: val._id,
          value: val.userName || 'N/A',
        }));
        this.formData.map((val) => {
          if (val.name === 'client') {
            val.multipleValues = this.clientOptions;
          }
        });
      }
    });
  }

  // Fetch Branches
  private getBranches(clientId) {
    this.clientSrvc.getAllBranches(clientId?.value).subscribe((res) => {
      if (res.data?.length) {
        this.branches = res.data.map((val) => ({
          id: val._id,
          name: val.branchAddress?.formattedAddress || 'N/A',
        }));
        this.formData.map((val) => {
          if (val.name === 'branch') {
            val.multipleValues = this.branches;
          }
        });
      }
    });
  }

  // Fetch Representatives
  private getRepresentatives(branchId) {
    this.clientSrvc.getRepresentativeList(this.search).subscribe((res) => {
      if (res.data?.length) {
        this.representatives = res.data.map((val) => ({
          name: val.representativeName || 'N/A',
          rep_email: val.representativeEmail || 'N/A',
        }));
        this.formData.map((val) => {
          if (val.name === 'representative') {
            val.multipleValues = this.representatives;
          }
        });
      }
    });
  }

  /**
   * Handle Email Option Click
   *
   * @param event Selected Email
   */
  onEmailSelectClick(event) {
    this.mailForm.controls['to'].setValue(event.value)
  }

  /**
   * Handle Quantity input field handling
   *
   * @param event is the order quantity
   * @param index is the order item index
   */
  handleQuantityChange(event: any, index: number) {
    const quantity = event.target.value;
    if (this.vendorOrders[index].quantity !== quantity) {
      this.vendorOrders[index].quantity = quantity; 
    }
  }
  
  /**
  * Handle Toggle Change
  */
  onIsInternalChange(isChecked: boolean) {
    this.isInternalToggle = isChecked; // Update toggle state
    this.isInternal = isChecked; // Synchronize flag
  }

   /**
   * Handle Save Button Click Event
   */
   async actionEvent(type: string) {
    try {
      if (type === 'save') {

        // Mark save button as clicked
        this.isSaveButtonClicked = true;

        // Emit the event with the payload containing `isInternal`
        this.saveButtonEvent.emit({ isInternal: this.isInternal });
      }
    } catch (error) {
      this.cos.handleError(error)
    }
  }

  // Emit event or call to the parent component
  sendSaveEvent(payload: { isInternal: boolean }) {
    this.saveButtonEvent.emit(payload); // Emit the value for the parent component to catch
  }

   // This function is use to upload file
  public upload(event) {
    this.document = [event];
  }

  public passImages() {
    return this.document || [];
  }

  public removeImage(event) {
    this.document = this.document.filter(document => document.url
      ? document.url !== event.url
      : document.path !== event.path);
  }

  protected readonly MAIL_TO = MAIL_TO;

  // Method to handle vendor selection
  onVendorSelected(event: any) {
    this.selectedVendor = event.value;
    this.mailForm.controls['to'].setValue(event.value)
  }

  // Method to handle employee selection
  onEmployeeSelected(event: any) {
    this.selectedEmployee = event.value;
    this.mailForm.controls['to'].setValue(event.value)
  }

  // Method to handle client selection
  onClientSelected(event: any) {
    this.selectedClient = event.value;
    this.getBranches(event);
    this.mailForm.controls['to'].setValue(event.value)
  }

  // Method to handle branch selection
  onBranchSelected(event: any) {
    this.selectedBranch = event.value;
    this.getRepresentatives(event); 
    this.mailForm.controls['to'].setValue(event.value)
  }

  // Method to handle Representative selection
  onRepresentativeSelected(event: any) {
    this.selectedRepresentative = event.value;
    this.mailForm.controls['to'].setValue(event.value)
  }

  /**
  * Custom comparison function to prevent auto-selection in mat-select
  */
  compareEmails(o1: string | null, o2: string | null): boolean {
    return o1 === o2;
  }

}