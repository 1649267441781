import { Component } from '@angular/core';

@Component({
  selector: 'app-mobe-site-fuel',
  templateUrl: './mobe-site-fuel.component.html',
  styleUrls: ['./mobe-site-fuel.component.scss']
})
export class MobeSiteFuelComponent {

}
