import { Component, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/_services/common.service';
import 'bootstrap';
import * as jQuery from 'jquery';
import { DashboardService } from 'src/app/_services/dashboard.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
})
export class PushNotificationComponent {
  notificationDetails = [];
  activeTab = 'all';

  constructor(
    public cos: CommonService,
    private router: Router,
    private dashboardSrvc: DashboardService
  ) {}
  search = {
    pagination: true,
    pageNumber: 1,
    noOfRecord: 12,
  };
  @HostListener('scroll', ['$event'])
  loadMore() {
    this.search.pageNumber++;
    try {
      this.dashboardSrvc.getPushNotification(this.search).subscribe(
        (response) => {
          response.data.forEach((item) => {
            this.notificationDetails.push(item);
          });
        },
        (error) => {
          this.cos.handleError(error);
        }
      );
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const atBottom =
      element.scrollHeight - element.clientHeight <= element.scrollTop + 1;
    if (atBottom) {
      this.loadMore();
    }
  }

  // Function to get the users name
  getUserName(data) {
    if (data) {
      if (Array.isArray(data.assignedTo) && data.assignedTo.length) {
        const result = [];
        data.assignedTo.map((val) => {
          result.push(val.userName);
        });
        return result;
      }
    }
  }

  // Function to get first character of name
  capitalizeFirstCharacter(str) {
    if (!str || str.length === 0) {
      return 'A';
    }
    return str.charAt(0).toUpperCase();
  }

  // Function to redirect screen to tasks
  async redirectNotification(data) {
    if (data.read === false) {
      this.dashboardSrvc.updateNotification({ ids: [data._id] }).subscribe();
      this.dashboardSrvc.updateNotificationCount('UPDATE_COUNT');
    }
    if (data.title === 'Project Task') {
      this.router.navigateByUrl(
        `/project/task/${data._refId._project}/${data._refId._id}`
      );
    } else if (data.title === 'Schedule') {
      this.router.navigateByUrl('/schedule/job');
    } else if (data.title === 'TimeOff') {
      this.router.navigateByUrl('/off-time-management/pending');
    } else if (data.title === 'Per Diem') {
      this.router.navigateByUrl('/perdiem/list');
    } else if (data.title === 'Per DiemPer Diem Request Changes') {
      this.router.navigateByUrl('/perdiem/request/changes');
    } else if (data.title === 'My Task') {
      this.router.navigateByUrl(
        `/general-task-list/task/${data._refId._tasklist}`
      );
    } else if (data.title === 'Daily Report') {
      this.router.navigateByUrl(
        `/project/project-information/daily-report/${data._refId._project}`
      );
    } else if (data.title === 'Daily Report Site Activity') {
      this.router.navigateByUrl(
        `/project/activities/${data._refId._project}/${data._refId._report}`
      );
    } else if (data.title === 'Meeting') {
      this.router.navigateByUrl(
        `/project/project-information/meeting/${data._refId._project}`
      );
    } else if (data.title === 'Repair & Maintenance') {
      this.router.navigateByUrl(`/maintenance/list`);
    } else if (data.title === 'Group') {
      this.router.navigateByUrl(`/group`);
    }
    jQuery('#notificationModal').modal('hide');
  }

  // Function to mark the notification
  async markRead() {
    try {
      const ids = [];
      this.notificationDetails.map((val) => {
        ids.push(val._id);
      });
      const payload = {
        ids: [ids],
      };
      await this.dashboardSrvc.updateNotification(payload).toPromise();
      this.getNotification();
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  getDetails(title: string, refId, userName: string, category: string) {  
    const referenceName =  refId?.name || refId?.title || refId?.userName || '';
    let titleMessages;
    if (category === 'Add') {
      titleMessages = {
        'Admin Broadcast': `send a notification about ${referenceName}`,
        'Project Task': `created a task ${referenceName}`,
        'My Task': `created a task ${referenceName}`,
        Meeting: `created a meeting ${refId}`,
        Schedule: `created a schedule`,
        'Repair & Maintenance': `create a request in Repair & Maintenance`,
        Group: `updated group ${referenceName}`,
        'Per Diem': 'update a Per Diem',
        TimeOff: 'requested a time off',
        'Daily Report': 'created a daily report',
        Announcement: 'created an announcement',
      };
    } else if (category === 'Update') {
      titleMessages = {
        'Project Task': `updated a task ${referenceName}`,
        'My Task': `updated a task ${referenceName}`,
        Meeting: `updates a meeting ${refId}`,
        Schedule: `updated a schedule`,
        'Repair & Maintenance': `update a request in Repair & Maintenance`,
        Group: `updated group ${referenceName}`,
        'Per Diem': 'update a Per Diem',
        TimeOff: 'requested a time off',
        'Daily Report': 'updated a daily report',
        Announcement: 'updated an announcement',
      };
    } else {
      titleMessages = {
        'Project Task': `deleted a task ${referenceName}`,
        'My Task': `deleted a task ${referenceName}`,
        Meeting: `deletes a meeting ${refId}`,
        Schedule: `deleted a schedule`,
        'Repair & Maintenance': `delete a request in Repair & Maintenance`,
        Group: `updated group ${referenceName}`,
        'Per Diem': 'deleted a Per Diem',
        TimeOff: 'deleted a time off',
        'Daily Report': 'deleted a daily report',
        Announcement: 'deleted an announcement',
      };
    }

    const message = titleMessages[title];
    if (message) {
      userName = userName ? userName : 'Admin';
      return `${userName} has ${message}`;
    }

    // Default message if the title is not found in the mapping
    return 'You have a new notification.';
  }

  // Function to get the notification
  getNotification() {
    this.dashboardSrvc.getPushNotification(this.search).subscribe(
      (res) => {
        this.notificationDetails = res.data;
      },
      (err) => this.cos.handleError(err)
    );
  }
  
}
