import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FunctionsService } from 'src/app/_common/functions.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { response } from 'src/app/enum/message';
import { DocumentType } from 'src/app/interfaces/employee';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employees-document',
  templateUrl: './employees-document.component.html',
  styleUrls: ['./employees-document.component.scss']
})
export class EmployeesDocumentComponent implements OnInit {
  @Input() documentType: string;
  newValues = [];
  deletedId = [];
  subDocName = '';
  rightModal;
  documentIndex: number;
  docIndex;
  documentId = '';
  showEditModal = false;
  fieldData: DocumentType;
  fieldID = '';
  showEffect = [false];
  showDocument = false;
  docName = false;
  showEditButton = true;
  changeType = false;
  newDocumentField = [];
  label = [{ subField: '' }];
  subDocumentType = [{ subField: '' }];
  subFieldsValues = [];
  documentDetail = [];
  documentTypeData;
  subFields = [];
  submittedData = [];
  subFieldId = '';
  documentName = '';
  fieldsValues = [];
  multipleData = [];
  subFieldVal = [];
  showEditIndex = false;
  indexValue;
  newAddedFields = [];
  documentTypeIndex: number;
  search = {
    pagination: true,
    pageNumber: 1,
    noOfRecord: 10,
    search: '',
    type: ''
  };
  subDocumentDetail = [];
  showTable = false;
  addField = [];
  subField = [false];
  fieldLoop = [{ subfields: '' }];
  displayedDocumentColumns: string[] = [
    'name',
    'type',
    'field',
    'subField',
    'action'
  ];
  subLoop = [{ item: '', id: '' }];
  editFields = this.fb.group({
    field: '',
    name: '',
    subField: '',
    subDocumentId: '',
    fieldId: '',
    documentId: '',
    addedField: [],
  });
  addSubDocumentType = this.fb.group({
    docType: '',
    field: '',
    subField: '',
  });
  subDocument = this.fb.group({
    name: '',
    doc: '',
    field: '',
    type: '',
    subfields: '',
  });
  editDocument = this.fb.group({
    name: '',
    doc: '',
    field: '',
  });
  UpdateBtn = 'Save';
  docsField = [
    { key: 'expiry date', value: 'Expiry Date' },
    { key: 'endorsement', value: 'Endorsement' },
    { key: 'restrictions', value: 'Restrictions' },
    { key: 'miscellaneous', value: 'Miscellaneous' },
    { key: 'date', value: 'Date' },
    { key: 'eligible date', value: 'Eligible Date' },
  ];
  subDocumentName: string;
  subDocumentId: string;
  btnName = [
    { value: 'Add Document', key: 'add', type: 'documentType', permission: 'configuration.write',icon: 'fa fa-plus', theme: 'combinedButton'  },
    { key: 'search', type: 'documentType', permission: 'configuration.read', icon: 'fa fa-search', theme: 'iconButton' },
  ];
  tableData = [];
  completeData = {};
  column: string[] = ['name', 'date'];
  modalProperty = [
    {
      header: "Add Document",
      id: "AddModal",
    },
    {
      header: "Edit Document",
      id: "editDocument",
    },
  ];

  constructor(
    private authSrvc: AuthService,
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private commonFunction: FunctionsService) {
  }

  ngOnInit() {
    const urlResult = this.cos.getUrlParams();
    if (Array.isArray(urlResult) && urlResult.length) {
      this.search.pageNumber = urlResult[0].page ? urlResult[0].page : 1;
      this.search.noOfRecord = urlResult[0].limit ? urlResult[0].limit : 10;
      this.getData();
    } else {
      this.getData();
    }
  }

  // Function to search data
  public searchData(data) {
    this.search.search = data.trim();
    this.search.pageNumber = 1,
      this.getData();
  }

  // Function to open modal
  public showModal(data) {
    if (data.key === 'add') {
      this.docName = false;
      this.addField.length = 0;
      this.fieldLoop = [{ subfields: '' }];
      this.subDocument.reset();
      this.commonFunction.modalOpen(this.modalProperty[0]);
    }
  }

  // This function is used to set page limit and range on list
  public setPagination(data) {
    if (data) {
      this.search.pageNumber = data.page;
      this.search.noOfRecord = data.limit;
      this.getData();
    }
  }

  // This function is used to get the list of added users
  public getData() {
    try {
      this.tableData = [];
      this.search.type = this.documentType;
      this.authSrvc.getDocumentType(this.search, true).subscribe((res) => {
        this.completeData = res;
        if (res) {
          res.data.map((item) => {
            this.tableData.push({
              name: item.name,
              id: item._id,
              key: this.btnName[0].type,
              date: item.updatedAt ? this.cos.getFormattedDate(item.updatedAt) : ''
            });
          });
        } else {
          this.tableData = [];
        }
      }, (err) => { this.cos.handleError(err); });
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  

  // This function is used to delete record from table
  public deleteRecord(data) {
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        try {
          this.authSrvc.deleteDocument(data.id, '').subscribe((res) => {
            if (res) {
              this.cos.toastMessage(res.message, 'success');
              this.getData();
            } else {
              this.cos.toastMessage(res.message, 'error');
            }
          }, (err) => { this.cos.handleError(err); });
        } catch (error) {
          this.cos.handleError(error);
        }
      }
    });
  }
  

  //This function is used to patch the details in modal
  public editValues(data) {
    this.commonFunction.modalOpen(this.modalProperty[1]);
    this.deletedId = [];
    this.docIndex = data[0]?.index;
    this.documentId = data[0]?.value?.id;
    this.showEditButton = true;
    this.showEditIndex = false;
    this.showDocument = false;
    this.showEditModal = false;
    this.newDocumentField = [];
    this.documentName = data[0].value.name;
    this.UpdateBtn = 'Save';
    this.editDocument.patchValue({
      name: data[0]?.value?.name ? data[0]?.value?.name.trim() : ''
    });
    this.getDocDetails(data[0]?.value?.id);
  }

  // Function used to get document details
  private getDocDetails(id) {
    try {
      this.authSrvc.getDocumentDetail({ typeId: id }).subscribe((res) => {
        if (res) {
          this.documentTypeData = res.data;
        }
      }, (err) => { this.cos.handleError(err); });
      this.showTable = true;
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  
  // This function specify the addition of document type with  single  or no fields
  public addDocumentType() {
    let data;
    if (this.addField.length === 0) {
      const addedFields = this.fieldLoop.map(rname => rname.subfields);
      if (!this.subDocument.value.name || (this.subDocument.value.name).trim() == 0) {
        return this.cos.toastMessage(response.en.NAME, 'warning');
      } else {
        if (!this.subDocument.value.doc && this.subDocument.value.field) {
          data = {
            name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
            type: this.documentType,
            fields: [
              {
                name: this.subDocument.value.field,
                subFields: addedFields ? addedFields : []
              }
            ]
          }
          this.addedDocument(data);
        } else if (this.subDocument.value.doc) {
          if (this.subDocument.value.field) {
            data = {
              name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
              type: this.documentType,
              subDocumentType: [
                {
                  name: this.subDocument.value.doc ? this.subDocument.value.doc.trim() : '',
                  fields: [{
                    name: this.subDocument.value.field,
                    subFields: addedFields ? addedFields : []
                  }]
                }
              ]
            }
            this.addedDocument(data);
          } else {
            data = {
              name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
              type: this.documentType,
              subDocumentType: [
                {
                  name: this.subDocument.value.doc ? this.subDocument.value.doc.trim() : ''
                }
              ]
            }
            this.addedDocument(data);
          }

        } else {
          data = {
            name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
            type: this.documentType,
            subDocumentType: []
          }

          this.addedDocument(data);
        }
      }
    } else {
      this.multipleFields();
    }
  }

  // This function specify the addition of document type with  multiple fields
  private multipleFields() {
    this.subFields.length = 0;
    this.addField.map((item) => {
      const body = {
        name: item.type ? item.type.trim() : '',
        fields: [{
          name: item.field,
          subFields: item.subField
        }]
      }
      this.subFields.push(body);
    });
    const submittedData = {
      name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
      type: this.documentType,
      subDocumentType: this.subFields
    }
    this.addedDocument(submittedData);
  }

  // This function hit the api to create record
  private addedDocument(data) {
    try {
      this.authSrvc.createDocumentType(data).subscribe((res) => {
        if (res) {
          this.cos.toastMessage(res.message, 'success');
          this.commonFunction.modalClose(this.modalProperty[0]);
          this.getData();
        } else {
          this.cos.toastMessage(res.message, 'error');
        }
      }, (err) => { this.cos.handleError(err); });
    } catch (error) {
      this.cos.handleError(error);
    }
  }  

  // This function describe the adding multiple field in single document type 
  public addFields() {
    const docValue = this.subDocument.value.name ? this.subDocument.value.name.trim() : '';
    if (!this.subDocument.value.name || (this.subDocument.value.name).trim() == 0) {
      return this.cos.toastMessage(response.en.NAME, 'warning');
    } else if (!this.subDocument.value.doc && !this.subDocument.value.field) {
      return this.cos.toastMessage(response.en.ENTER_SUBDOC, 'warning');
    } else {
      const addedFields = this.fieldLoop.map(rname => rname.subfields);
      this.addField = [
        ...this.addField, {
          name: this.subDocument.value.name ? this.subDocument.value.name.trim() : '',
          type: this.subDocument.value.doc ? this.subDocument.value.doc.trim() : '',
          field: this.subDocument.value.field,
          subField: addedFields,
        }
      ]
      this.subFieldVal.push(this.addField);
    }
    this.subDocument.reset();
    this.subDocument.patchValue({
      name: docValue
    });
    this.fieldLoop = [{ subfields: '' }];
    this.docName = true;
  }

  public deleteAddedField(index) {
    const deletedDocs = this.addField[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.addField = this.addField.filter((item) => {
          return item !== deletedDocs;
        })
      }
    });
  }

  // This function describe the functionality to delete sub document details
  public deleteDocType(id, index) {
    const deletedDocs = this.documentTypeData[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.UpdateBtn = 'Save Changes';
        this.deletedId.push(id);
        this.documentTypeData = this.documentTypeData.filter(function (item) {
          return item !== deletedDocs;
        });
      }
    });
  }

  // This function specify the right modal derails
  public rightModalData(data) {
    try {
      const element = data.value;
      jQuery('#rightModal').modal('show');
      this.documentDetail = [];
      this.documentName = element.name;
      this.authSrvc.getDocumentDetails(element.id).subscribe((res) => {
        if (res) {
          this.documentDetail = res.data;
        }
      }, (err) => { this.cos.handleError(err); });
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  

  public showSubDocument() {
    this.showTable = false;
    this.changeType = false;
    this.showEditButton = false;
    this.showDocument = true;
    this.newDocumentField = [];
    this.showEditIndex = false;
    this.subDocumentType = [];
    this.addSubDocumentType.reset();
  }

  // This property specify the adding new sub document's fields of update document
  public addDocField() {
    if (!this.addSubDocumentType.value.docType || (this.addSubDocumentType.value.docType).trim().length == 0) {
      return this.cos.toastMessage(response.en.DOCUMENT_TYPE, 'warning');
    } else if (!this.addSubDocumentType.value.field && this.addSubDocumentType.value.subField) {
      return this.cos.toastMessage(response.en.FIELD_REQUIRED, 'warning');
    } else {
      const addedFields = this.subDocumentType.map(rname => rname.subField);

      this.newDocumentField = [
        ...this.newDocumentField, {
          name: this.addSubDocumentType.value.docType,
          field: this.addSubDocumentType.value.field,
          subField: addedFields
        }
      ];
      this.UpdateBtn = 'Save Changes';
      this.addSubDocumentType.reset();
      this.subDocumentType = [];
      this.changeType = true;

    }
  }

  public deleteSubDocField(index) {
    const deletedDocs = this.newDocumentField[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      if (result.value) {
        this.newDocumentField = this.newDocumentField.filter((item) => {
          return item !== deletedDocs;
        })
      }
      if (this.newDocumentField.length === 0) {
        this.changeType = false;
      } else {
        this.changeType = true;
      }
    });
  }

  // Function is used to create the payload for update existing documents
  async UpdateDocument() {
    try {
      let body;
      if ((this.editDocument.value.name).trim() == 0) {
        return this.cos.toastMessage(response.en.NAME, 'warning');
      } else {
        const updatedValue = this.documentName.toLowerCase() === (this.editDocument.value.name).toLowerCase();
        // update Document name
        if (!updatedValue) {
          body = {
            id: this.documentId,
            name: this.editDocument.value.name
          }
          await this.authSrvc.updateField(body).toPromise();
        }
  
        // Update sub document details(fields, sub fields)
        if (this.subDocumentDetail.length) {
          const payload = {
            name: this.subDocumentName,
            id: this.subDocumentId,
            isField: false,
            fields: this.subDocumentDetail.map(item => ({
              fieldId: item.fieldId,
              name: item.field,
              subFields: item.subFields
            }))
          };
          await this.authSrvc.updateField(payload).toPromise();
        }
  
        // add new sub Document
        if (this.newDocumentField.length) {
          const addedFields = [];
          this.newDocumentField.map(item => {
            addedFields.push({
              name: item.name,
              fields: [
                {
                  name: item.field,
                  subFields: item.subField
                }
              ]
            });
          });
          const payload = {
            docType: this.documentId,
            subDocumentType: addedFields
          }
          await this.authSrvc.addDocumentType(payload).toPromise();
        }
  
        // To delete sub document details
        if (this.deletedId.length) {
          await this.authSrvc.deleteDocType({ ids: this.deletedId }).toPromise();
        }
  
        this.cos.toastMessage(response.en.RECORD_UPDATE, 'success');
        this.getData();
        this.commonFunction.modalClose(this.modalProperty[1]);
      }
    } catch (error) {
      this.cos.toastMessage(error, 'error');
    }
  }
  

  private getFieldLength(data) {
    if (data.fields.length) {
      return data.fields[0].subFields.length;
    }
  }

  // Function give length of subfield
  public getSubFieldLength(data) {
    if (data.subFields.length > 1) {
      const value = data.subFields.length - 1;
      return "+" + value + 'More'
    } else {
      return;
    }
  }

  // Function is used to show subfield  in case their are more sub fields
  public getHoverStatus(data, index) {
    if (data.subFields.length > 1) {
      this.subFieldsValues = data.subFields;
      this.showEffect[index] = true;
    } else {
      this.showEffect[index] = false;
    }
  }


  // Function is used to set the logic on loop in case of endorsement or restriction
  public resetSubField(value) {
    this.newValues.length = 0;
    if (value === 'expiry date') {
      this.label = [];
      this.subDocumentType = [];
    } else {
      this.label = [{ subField: '' }];
      this.subDocumentType = [{ subField: '' }]
    }
  }

  // The below three function specify the set input value, add new field and remove field properties for update document type
  public setSubDoc(index, event) {
    this.indexValue = index;
    this.subDocumentType[index].subField = !!event.target.value === false ? '' : event.target.value;
  }

  public addNewDoc(i: number): void {
    if (!this.subDocumentType[i].subField) {
      this.cos.toastMessage(response.en.ADD_SUBFIELD, 'warning')
    } else {
      this.subDocumentType.push({ subField: '' });
    }
  }

  public removeDoc(i: number): void {
    this.subDocumentType = this.subDocumentType.filter((obj, index) => i !== index);
  }

  //Below three function describe the properties for set value, add more field, remove field for adding document type
  public setFieldValue(index, event) {
    this.indexValue = index
    this.fieldLoop[index].subfields = !!event.target.value === false ? '' : event.target.value;
  }

  public addNewFields(i: number) {
    if (!this.fieldLoop[i].subfields) {
      this.cos.toastMessage(response.en.ADD_VALUE, 'warning')
    } else {
      this.fieldLoop.push({ subfields: '' });

    }
  }

  public removeField(i: number): void {
    this.fieldLoop = this.fieldLoop.filter((obj, index) => i !== index);
  }

  // Function  to redirect admin to home page of edit modal from add document section
  public resetDoc() {
    this.showDocument = false;
    this.showTable = true;
    this.showEditButton = true;
  }

  // Function to show  document name with fields or sub fields
  public showList(data, index) {
    this.editFields.reset();
    this.subLoop = [{ item: '', id: '' }];
    this.subDocumentDetail = [];
    this.documentIndex = index;
    this.subDocName = data.name;
    this.showTable = false;
    this.showEditButton = false;
    this.showEditIndex = true;
    this.editFields.patchValue({
      name: data.name,
      subDocumentId: data._id,
    });
    this.subDocumentId = this.editFields.value.subDocumentId;
    if (Array.isArray(data.fields) && data.fields.length) {
      data.fields.map(val => {
        this.subDocumentDetail.push({
          name: data.name,
          field: val.name,
          subFields: val.subFields,
          documentId: data._parentCategory,
          subDocumentId: data._id,
          fieldId: val._id ? val._id :''
        });
      });
    }
  }

  // Function used to patch the details of document in update modal
  public patchDetails(data, index: number) {
    this.editFields.patchValue({
      documentId: data.documentId,
      subDocumentId: data.subDocumentId,
      fieldId: data.fieldId ? data.fieldId : '',
      name: data.name,
      field: data.field,
    });
    if (Array.isArray(data.subFields) && data.subFields.length) {
        this.subLoop = data.subFields.map(item => ({ id: data.fieldId, item }));
    } else {
      this.subLoop = [{ item: '', id: '' }];
    }
    const deletedDocs = this.subDocumentDetail[index];
        this.subDocumentDetail = this.subDocumentDetail.filter(function (item) {
          return item !== deletedDocs;
    });
  }


  // Function to set text in loop field (update modal)
  public setSubType(index, event) {
    this.indexValue = index
    this.subLoop[index].item = !!event.target.value === false ? '' : event.target.value;
  }

  // Function to add value in loop field (update modal)
  public addSubType(i: number) {
    if (!this.subLoop[i].item) {
      this.cos.toastMessage(`SUB-ACTIVITY ${response.en.NAME}`, "warning");
    } else {
      this.subLoop.push({ item: '', id: '' });
    }
  }

  // Function to remove the sub field
  public removeSubType(i: number): void {
    this.subLoop = this.subLoop.filter((obj, index) => i !== index);
  }

  // Function used to view sub document list with document name on update modal
  public showDocumentList() {
    this.showDocument = false;
    this.showEditIndex = false;
    this.showTable = true;
  }

  // Function to add/edit the sub document details in update modal
  public updateSubDocument() {
    if (!this.editFields.value.field) {
      this.cos.toastMessage("Please select field", 'warning');
    } else {
      this.subDocumentDetail.push({
        name: this.editFields.value.name,
        field: this.editFields.value.field,
        subFields: this.subLoop.map(val => val.item),
        fieldId: this.editFields.value.fieldId ? this.editFields.value.fieldId : ''
      });
      this.subDocumentName = this.editFields.value.name;
      this.editFields.reset();
      this.editFields.patchValue({
        name: this.subDocumentDetail[0].name
      });
      this.subLoop = [{ item: '', id: '' }];
      this.UpdateBtn = ' Save Changes';
    }
  }

  // Function to delete added sub document details in update modal
  public deleteDocField(index: number) {
    const deletedDocs = this.subDocumentDetail[index];
    Swal.fire(this.cos.deleteText).then((result) => {
      this.UpdateBtn = 'Save Changes';
      if (result.value) {
        this.subDocumentDetail = this.subDocumentDetail.filter(function (item) {
          return item !== deletedDocs;
        });

      }
    });
  }

  // Function to check document name is changed or not
  public editDocumentName(name) {
    if (name.toLowerCase() === this.documentName.toLowerCase()) {
      this.UpdateBtn = 'Save';
    } else {
      this.UpdateBtn = 'Save Changes';
    }
  }

}
