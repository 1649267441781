<form [formGroup]="addDocumentForm">
    <div class="row">
      <ng-container *ngIf="docTypeDetails && docTypeDetails.title">
        <div [ngClass]="fieldSize()">
          <app-drop-down
            [formData]="addDocumentForm"
            [dropDownData]="this.docTypeDetails"
            (dropDownIds)="SelectedDocumentType($event)"
            [patchData]="patchDocType"
          ></app-drop-down>
        </div>
      </ng-container>
  
      <div [ngClass]="fieldSize()">
        <app-drop-down
          [formData]="addDocumentForm"
          [dropDownData]="this.cos.typeDocument"
          (dropDownIds)="SelectedUploadType($event)"
        ></app-drop-down>
      </div>
      <ng-container *ngIf="addDocumentForm.value.docType">
        <div *ngIf="isExpiration" [ngClass]="fieldSize()">
          <mat-form-field appearance="outline" class="d-block">
            <mat-label>Expiry Date</mat-label>
            <input
              matInput
              [matDatepicker]="datepicker"
              placeholder="MM/DD/YYYY"
              maxlength="10"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
              (click)="datepicker.open()"
              formControlName="expiryDate"
              [min]="currentDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
  
      <ng-container *ngIf="addDocumentForm.value.type">
        <div
          [ngClass]="fieldSize()"
          *ngIf="addDocumentForm.value.type === 'DOCUMENT'"
        >
          <app-upload-image-file
            (uploadImage)="upload($event)"
            [uploadedImages]="passImages()"
            (removeImage)="removeImage($event)"
          >
          </app-upload-image-file>
        </div>
  
        <div
          [ngClass]="fieldSize()"
          *ngIf="addDocumentForm.value.type === 'LINK'"
        >
          <mat-form-field appearance="outline" class="d-block">
            <mat-label>Link*</mat-label>
            <input
              class="ad-text-size"
              formControlName="link"
              matInput
              placeholder="https://www.example.com"
              type="text"
            />
          </mat-form-field>
        </div>
  
        <div [ngClass]="fieldSize()">
          <mat-form-field appearance="outline" class="d-block">
            <mat-label>Document Name*</mat-label>
            <input
              class="ad-text-size"
              formControlName="docName"
              matInput
              placeholder="Name"
              type="text"
            />
          </mat-form-field>
        </div>
  
        <div class="col-sm-12 mt-3">
          <mat-form-field appearance="outline" class="d-block">
            <mat-label>Document Note</mat-label>
            <textarea
              class="ad-text-size"
              formControlName="note"
              maxlength="500"
              type="text"
              matInput
              placeholder="Enter Document Note"
            ></textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </form>
  
  <div class="d-flex flex-column">
    <div class="align-self-end my-2 d-flex">
      <button
        *ngIf="documentBtn?.length && documentBtn[0]?.showBackBtn"
        class="ml-3 strokeBtn"
        (click)="sendResult(documentBtn[0]?.type, 'previous')"
      >
        Back
      </button>
  
      <button
        (click)="addDocsForm()"
        *ngIf="addDocumentForm.value.type"
        class="btn btn-primary ml-3"
      >
        Save Document
      </button>
  
      <button
        *ngFor="let data of documentBtn"
        class="btn btn-primary ml-3"
        (click)="sendResult(data.type, 'next')"
      >
        {{ data.name }}
      </button>
    </div>
  
    <div class="my-2 scrollType" *ngIf="addDocuments.length">
      <table class="table table-bordered" style="font-size: 12px">
        <thead class="searchEffects">
          <th *ngIf="docTypeDetails && docTypeDetails.title" class="col-1">
            Document Type
          </th>
          <th class="col-3">Name</th>
          <th class="col-3">Note</th>
          <th class="col-1">Type</th>
          <th
            *ngIf="docTypeDetails && docTypeDetails.title && isExpiration"
            class="col-3"
          >
            Expiry Date
          </th>
          <th class="col-1">Action</th>
        </thead>
        <tbody *ngFor="let element of addDocuments; let i = index">
          <tr>
            <td *ngIf="docTypeDetails && docTypeDetails.title">
              {{ this.cos.getCamelCaseValue(element?.docType) }}
            </td>
            <td>{{ element?.fileName ?? "N/A" }}</td>
            <td class="noteField d-flex" style="border: none">
              {{ element.note ? element.note : "N/A" }}
            </td>
            <td>{{ this.cos.getTitleCase(element?.type) }}</td>
            <td *ngIf="docTypeDetails && docTypeDetails.title && isExpiration">
              {{ element?.expiryDate ?? "N/A" }}
            </td>
  
            <td class="d-flex" style="border: none">
              <a (click)="openUrl(element)" class="actnbtn"
                ><i class="fa fa-file-text" aria-hidden="true"></i>
              </a>
              <a (click)="deleteAddedDocument(i)" class="btn-danger actnbtn ml-2"
                ><i class="fa fa-trash-o" aria-hidden="true"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  